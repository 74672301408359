import { NavigationSearch } from "@greco/components";

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "../../store/hooks";
import { setSearchReports } from "../../store/reports-page/reportsPage";
import { AppDispatch } from "../../store/store";

export const Search = () => {
  const search = useSelector((s) => s.reportsPage.search);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <NavigationSearch
      value={search}
      placeholder={t("ram.clientsList.search")}
      onChange={(val) => {
        dispatch(setSearchReports(val));
      }}
    />
  );
};
