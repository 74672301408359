import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ListContextMenuIconButton } from "../../components/styled";
import { routes } from "../../config/routes";
import { setLocationsFilter } from "../../store/filters/filters";
import { useSelector } from "../../store/hooks";
import {
  endAssessmentActions,
  setChangeClientDialog,
  setDeleteDialog,
  setExportToZipDialog,
  setGenerateLocationReportDocumentDialog,
  setUsersReportDocumentDialog,
  startAssessmentActions,
} from "../../store/locations-page/locationsPage";
import { AppDispatch } from "../../store/store";
import { ClientLocation } from "../../types/types";

type Props = {
  loc: ClientLocation;
};

export const ContextButton = ({ loc }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const isAssessmentLoading = useSelector(
    (s) => s.locationsPage.isAssessmentLoading
  );
  const userRole = useSelector((s) => s.userRole.userRole);

  const onClickEdit = () => {
    navigate(
      routes.editLocation.getPath(loc?.webBasePartnerNumber, loc?.locationId)
    );
  };

  const onClickAddReport = () => {
    navigate(
      routes.addReport.getPath(loc?.webBasePartnerNumber, loc?.locationId)
    );
  };

  const onClickShowReports = () => {
    navigate(routes.reports.getPath());
    dispatch(setLocationsFilter([loc?.locationId]));
  };

  const onClickShowRecommendations = () => {
    navigate(routes.recommendations.getPath());
    dispatch(setLocationsFilter([loc?.locationId]));
  };

  const onClickExportToZip = async () => {
    dispatch(
      setExportToZipDialog({
        locationId: loc?.locationId,
        isOpen: true,
      })
    );
  };

  const onClickDelete = () => {
    dispatch(
      setDeleteDialog({
        location: loc,
        isOpen: true,
      })
    );
  };

  const onClickChangeClient = () => {
    dispatch(
      setChangeClientDialog({
        location: loc,
        isOpen: true,
      })
    );
  };

  const onClickUsersReportDocument = () => {
    dispatch(
      setUsersReportDocumentDialog({
        isOpen: true,
        clientId: loc.webBasePartnerNumber,
        locationId: loc.locationId,
      })
    );
  };

  const onClickGenerateLocationReportDocument = () => {
    dispatch(
      setGenerateLocationReportDocumentDialog({
        clientId: loc?.webBasePartnerNumber,
        locationId: loc?.locationId,
        body: null,
        isOpen: true,
      })
    );
  };

  const onClickUpdateDefaultValues = () => {
    navigate(
      routes.locationDefaultValues.getPath(
        loc?.webBasePartnerNumber,
        loc?.locationId
      )
    );
  };

  const onClickStartEndAssessmentPeriod = () => {
    if (loc?.assessmentPeriodStartedAt === null) {
      dispatch(startAssessmentActions.trigger(loc?.locationId));
    }
    //
    else {
      dispatch(endAssessmentActions.trigger(loc?.locationId));
    }
  };

  const items = [
    {
      key: "showReports",
      text: t("ram.locationList.context.showReports"),
      iconProps: { iconName: "CRMReport" },
      onClick: () => onClickShowReports(),
    },
    {
      key: "showRecommendations",
      text: t("ram.locationList.context.showRecommendations"),
      iconProps: { iconName: "ComplianceAudit" },
      onClick: () => onClickShowRecommendations(),
    },
  ];

  var exportToZipObj = {
    key: "exportToZip",
    text: t("ram.locationList.context.exportToZip"),
    iconProps: { iconName: "ZipFolder" },
    onClick: () => onClickExportToZip(),
  };

  var generateLocationReportDocumentObj = {
    key: "generateLocationReportDocument",
    text: t("ram.locationList.context.generateLocationReportDocument"),
    iconProps: { iconName: "FabricReportLibrary" },
    onClick: () => onClickGenerateLocationReportDocument(),
  };

  var editLocationObj = {
    key: "editlocation",
    text: t("ram.locationList.editLocation.btn"),
    iconProps: { iconName: "Edit" },
    onClick: () => onClickEdit(),
  };

  var addReportObj = {
    key: "addReport",
    text: t("ram.locationList.addReport.btn"),
    iconProps: { iconName: "Add" },
    onClick: () => onClickAddReport(),
  };
  var updateDefaultValuesObj = {
    key: "updateDefaultValues",
    text: t("ram.locationList.context.updateDefaultValues"),
    iconProps: { iconName: "CheckList" },
    onClick: () => onClickUpdateDefaultValues(),
  };

  var assessmentObj = {
    key: "changeAssessmentStatus",
    text:
      loc?.assessmentPeriodStartedAt === null
        ? t("ram.locationList.context.startAssesment")
        : t("ram.locationList.context.endAssesment"),
    iconProps: { iconName: "DateTime" },
    onClick: () => onClickStartEndAssessmentPeriod(),
    disabled: isAssessmentLoading,
  };

  const changeClientOption = {
    key: "changeClient",
    text: t("ram.changeClient.label"),
    iconProps: {
      iconName: "Switch",
    },
    onClick: onClickChangeClient,
  };

  const deleteOption = {
    key: "delete",
    text: t("greco.delete"),
    iconProps: {
      iconName: "Trash",
    },
    onClick: onClickDelete,
  };

  items.push(exportToZipObj);
  items.push(generateLocationReportDocumentObj);

  const usersReportItem = {
    key: "usersReport",
    text: t("ram.locationsReportsDocument.label"),
    iconProps: { iconName: "ExcelDocument" },
    disabled: false,
    onClick: onClickUsersReportDocument,
  };
  if (
    userRole === "admin" ||
    userRole === "internal" ||
    userRole === "internal_role" ||
    userRole === "external_advanced"
  ) {
    items.splice(0, 0, addReportObj);
    if (userRole !== "external_advanced") {
      items.splice(1, 0, editLocationObj);
      items.push(updateDefaultValuesObj);
    }
    if (userRole === "admin" || userRole === "external_advanced") {
      items.push(assessmentObj);
    }
    if (userRole === "admin") {
      items.push(changeClientOption);
    }
    if (userRole === "admin") {
      items.push(deleteOption);
    }
  }

  items.push(usersReportItem);

  return (
    <ListContextMenuIconButton
      iconProps={{ iconName: "MoreVertical" }}
      menuIconProps={{ style: { display: "none" } }}
      menuProps={{
        items: items,
      }}
    />
  );
};
