import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../store/hooks";
import { StyledPrimaryButton } from "../../components/styled";
import { CommandBarButton } from "@fluentui/react";

export const SaveButton = () => {
  const { t } = useTranslation();
  const isUpdateLoading = useSelector(
    (s) => s.defaultWeighting.isUpdateLoading
  );
  const areDefaultWeightingsLoading = useSelector(
    (s) => s.defaultWeighting.areDefaultWeightingsLoading
  );

  const userRole = useSelector((s) => s.userRole.userRole);
  const newName = useSelector((s) => s.defaultWeighting.newName);

  const isLoading = isUpdateLoading || areDefaultWeightingsLoading;
  const isDisabled = isLoading || userRole !== "admin";

  return (
    <StyledPrimaryButton
      text={t("greco.save")}
      iconProps={{ iconName: isLoading ? "ProgressRingDots" : "Save" }}
      split
      onClick={() => document.getElementById("loc-def-values-btn")?.click()}
      disabled={isDisabled || !newName || (newName as string).trim() === ""}
      className={clsx({
        isLoading,
      })}
    />
  );
};
