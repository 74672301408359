import * as yup from "yup";

import { mergeStyleSets } from "@fluentui/react";
import { Formik, useFormikContext } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { routes, useCurrentRoute } from "../../config/routes";
import {
  adjustFloatNumberValue,
  adjustTaxonomyValue,
  transformNumberValue,
} from "../../form/util";
import {
  selectEntitiesClients,
  setSelectedClientSicCode,
} from "../../store/clients/clients";
import { useSelector } from "../../store/hooks";
import {
  createLocationWithUsers,
  createLocationWithoutUsers,
  loadLocationWithUsers,
  setDefaultWeightings,
  setLocation,
  setRiskUsers,
  updateLocationWithUsers,
  updateLocationWithoutUsers,
} from "../../store/locationPage";
import { AppDispatch, store } from "../../store/store";
import { setYupLocale } from "../../utils/setYupLocale";
import LocationForm from "./LocationForm";
import LocationSkeleton from "./LocationSkeleton";
import { Navigation } from "./navigation/Navigation";
import { useResetState } from "./useResetState";
import { CopyReportDataDialog } from "./CopyReportDataDialog/CopyReportDataDialog";
import { getDefaultWeightings } from "../../api/api";
import { IStyledTheme } from "../../theme/types";

export type LocationSaveMode = "normal" | "defaultValues";

export const Location = () => {
  const { t } = useTranslation();
  const route = useCurrentRoute();

  const isEdit = route === "editLocation";
  const isLocationLoading = useSelector(
    (s) => s.locationPage.isLocationLoading
  );
  const isSaveLoading = useSelector((s) => s.locationPage.isSaveLoading);
  const location = useSelector((s) => s.locationPage.location);
  const didTrySubmit = useSelector((s) => s.locationPage.didTrySubmit);
  const copyReportDataDialog = useSelector(
    (s) => s.locationPage.copyReportDataDialog
  );
  const [initialValues, setInitialValues] = useState({});

  const dispatch: AppDispatch = useDispatch();
  const userRole = useSelector((s) => s.userRole.userRole);
  const riskUsers = useSelector((s) => s.locationPage.riskUsers);
  const taxonomy = useSelector((s) => s.taxonomy);
  useResetState();

  const { clientId } = useParams() as any;
  const clientEntities = selectEntitiesClients(store.getState());
  const theme = useTheme() as IStyledTheme;

  const classes = getClassNames(theme);

  useEffect(() => {
    if (isEdit && location) {
      setInitialValues({
        webBasePartnerNumber: {
          label: clientEntities?.[location.webBasePartnerNumber]?.clientName,
          value: location.webBasePartnerNumber,
        },
        locationName: location?.locationName,
        address: location?.address,
        city: location?.city,
        postalCode: location?.postalCode,
        latitude: location?.latitude,
        longitude: location?.longitude,
        countryCode: adjustTaxonomyValue(
          location.countryCode,
          taxonomy,
          "Country",
          t
        ),
        defaultSubcategoryWeightingName:
          location.defaultSubcategoryWeightingName
            ? {
                value: location.defaultSubcategoryWeightingName,
                label: t(location.defaultSubcategoryWeightingName),
              }
            : null,

        siccodes: (() => {
          let res = [];
          if (location.siccodes) {
            for (var i = 0; i < location.siccodes.length; i++) {
              var sctax = taxonomy.SicCode.byId[location.siccodes[i]];
              res.push({
                key: sctax.id,
                name: `${sctax.code.split(".")[1]} ${t(sctax.code)}`,
              });
            }
          }
          return res;
        })(),
        //right

        turnoverInMioEur: adjustFloatNumberValue(location.turnoverInMioEur),
        tsiinMioEur: adjustFloatNumberValue(location.tsiinMioEur),

        pmlinMioEur: adjustFloatNumberValue(location.pmlinMioEur),
        emlinMioEur: adjustFloatNumberValue(location.emlinMioEur),
        ebitinMioEur: adjustFloatNumberValue(location.ebitinMioEur),
        ygpcminMioEur: adjustFloatNumberValue(location.ygpcminMioEur),
        deductiblesInMioEur: adjustFloatNumberValue(
          location.deductiblesInMioEur
        ),
        insurersRaiting: location.insurersRaiting,
        riskUsers: riskUsers.map((user) => {
          return {
            label: user.userName,
            value: user.userId,
            userId: user.userId,
            userName: user.userName,
            userEmailAddress: user.userEmailAddress,
          };
        }),
      });
      return;
    }

    setInitialValues({
      webBasePartnerNumber: clientId
        ? {
            label: clientEntities?.[clientId]?.clientName,
            value: clientId,
          }
        : null,
      locationName: null,
      address: null,
      city: null,
      postalCode: null,
      latitude: null,
      longitude: null,
      countryCode: null,
      siccodes: [],
      //right
      turnoverInMioEur: null,
      tsiinMioEur: null,
      pmlinMioEur: null,
      emlinMioEur: null,
      ebitinMioEur: null,
      ygpcminMioEur: null,
      deductiblesInMioEur: null,
      insurersRaiting: null,
      riskUsers: null,
      defaultSubcategoryWeightingName: null,
    });
  }, [
    isEdit,
    location,
    clientId,
    clientEntities,
    isSaveLoading,
    taxonomy,
    t,
    riskUsers,
  ]);

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    let schema = {
      webBasePartnerNumber: yup.mixed().required().nullable(),
      locationName: yup.string().max(128).required().nullable(),
      postalCode: yup.string().max(34).required().nullable(),
      countryCode: yup.mixed().required().nullable(),
      city: yup.string().max(34).required().nullable(),

      turnoverInMioEur: yup.string().required().nullable(),
      tsiinMioEur: yup.string().required().nullable(),
    };

    if (!isEdit) {
      return yup.object().shape({
        ...schema,
        defaultSubcategoryWeightingName: yup.mixed().required().nullable(),
      });
    }
    return yup.object().shape(schema);
  }, [t, isEdit]);

  useEffect(() => {
    // formRef?.current?.resetForm();
    dispatch(setSelectedClientSicCode(null));
  }, []);

  useEffect(() => {
    if (riskUsers) {
      // formRef?.current?.resetForm();
    }
  }, [riskUsers]);

  useEffect(() => {
    getDefaultWeightings().then((res) => {
      if (res) {
        dispatch(setDefaultWeightings(res.data));
      }
    });
  }, []);

  useEffect(() => {
    if (!isEdit) return;
    (async () => {
      await dispatch(loadLocationWithUsers());
    })();
  }, [isEdit]);

  const handleSubmitForm = useCallback(
    (values) => {
      try {
        const mode = values.mode;

        values.countryCode = isNaN(values.countryCode)
          ? values.countryCode?.value
          : values.countryCode;

        values.webBasePartnerNumber = isNaN(values.webBasePartnerNumber)
          ? values.webBasePartnerNumber?.value
          : values.webBasePartnerNumber;

        values.tsiinMioEur = transformNumberValue(values?.tsiinMioEur);
        values.deductiblesInMioEur = transformNumberValue(
          values?.deductiblesInMioEur
        );
        values.ebitinMioEur = transformNumberValue(values?.ebitinMioEur);
        values.emlinMioEur = transformNumberValue(values?.emlinMioEur);
        values.pmlinMioEur = transformNumberValue(values?.pmlinMioEur);
        values.ygpcminMioEur = transformNumberValue(values?.ygpcminMioEur);
        values.turnoverInMioEur = transformNumberValue(
          values?.turnoverInMioEur
        );
        values.siccodes = values?.siccodes?.length
          ? values?.siccodes.map((code) => code?.key)
          : [];

        values.defaultSubcategoryWeightingName =
          values?.defaultSubcategoryWeightingName
            ? values?.defaultSubcategoryWeightingName?.value
            : null;
        if (isEdit) {
          if (userRole === "admin") {
            dispatch(
              updateLocationWithUsers({
                locationWithUsers: {
                  clientLocation: {
                    ...values,
                    rowVersion: location.rowVersion,
                  },
                  riskUsers: values?.riskUsers?.map((u) => ({
                    ...u,
                    isInternal:
                      /((@greco.services)|(@vmg.at)|(@eghv.at))$/.test(
                        u.userEmailAddress
                      ),
                  })),
                },
                mode,
              })
            );
          } else {
            dispatch(
              updateLocationWithoutUsers({
                location: { ...values, rowVersion: location.rowVersion },
                mode,
              })
            );
          }
        }
        //
        else {
          if (userRole === "admin") {
            dispatch(
              createLocationWithUsers({
                locationWithUsers: {
                  clientLocation: values,
                  riskUsers: values?.riskUsers?.map((u) => ({
                    ...u,
                    isInternal:
                      /((@greco.services)|(@vmg.at)|(@eghv.at))$/.test(
                        u.userEmailAddress
                      ),
                  })),
                },
                mode,
              })
            );
          } else {
            dispatch(
              createLocationWithoutUsers({
                location: values,
                mode,
              })
            );
          }
        }
      } catch (error) {
        console.log(error, "error here");
      }
    },
    [isEdit, location, dispatch, t, userRole]
  );

  useEffect(() => {
    return () => {
      dispatch(setLocation(null));
      dispatch(setRiskUsers([]));
    };
  }, []);

  const isLoading = isLocationLoading || isSaveLoading;

  return (
    <Formik
      enableReinitialize
      validateOnChange={didTrySubmit}
      validateOnBlur={didTrySubmit}
      validateOnMount={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, props) => {
        props.setSubmitting(false);

        handleSubmitForm(values);
        props.resetForm();
      }}
    >
      {({ handleSubmit, values }) => {
        return isLoading ? (
          <LocationSkeleton />
        ) : (
          <>
            <Navigation />
            <div className={classes.main}>
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.wrap}>
                  <LocationForm />
                </div>
              </form>
            </div>
            {copyReportDataDialog.isOpen && <CopyReportDataDialog />}
          </>
        );
      }}
    </Formik>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    main: {
      maxWidth: "100%",
      display: "flex",
      margin: "0 auto",
      flexDirection: "column",
      height: "100vh",
      position: "relative",
      paddingTop: 0,
      overflow: "auto", //
    },
    form: {
      marginBottom: 200,
    },
    wrap: {
      background: theme.palette.white,
      boxShadow:
        "0 3.2px 7.2px 0 rgba(0,0,0,.132), 0 0.6px 1.8px 0 rgba(0,0,0,.108)",
      display: "flex",
      margin: "50px auto 100px",
      borderRadius: "4px",
      minWidth: 1200,
      maxWidth: 1200,
      position: "relative",
      flexDirection: "column",
      padding: "20px 20px 250px",
      gap: 50,
    },
  });
