import { Recommendation, RecommendationAzure } from "../types/types";

export const presence = {
  ONLINE: "#6bb700",
  DO_NOT_DISTURB: "#c50f1f",
};

export const messaging = {
  ERROR_BLOCK_ICON: "#a80000",
};

export const neutrals = {
  neutralLighterAlt: "#faf9f8",
  neutralTertiary: "#a19f9d",
  neutralTertiaryAlt: "#c8c6c4",
  neutralQuaternary: "#d2d0ce",
};

export const accent = {
  yellow: "#ffb900",
  red: "#d13438",
  redDark: "#a4262c",
  greenDark: "#004b1c",
  orangeLighter: "#ff8c00",
};

export const riskImplementationStatusColors = {
  "RiskImplementationStatus.DONE_CHECKED": "#059c05",
  "RiskImplementationStatus.DONE": "#059c05",
  "RiskImplementationStatus.IN_PROGRESS": "#5c2e91",
  "RiskImplementationStatus.OPEN": "#005aa1",
  "RiskImplementationStatus.NOTED": "#ff8c00",
  "RiskImplementationStatus.DECLINED": "#e40101",
  "RiskImplementationStatus.ALL": "rgb(50, 49, 48)",
} as { [key: string]: string };

export const riskPriorityColors = {
  "RiskPriority.NO_PRIORITY_DEFINED_BY_GREG": neutrals.neutralTertiaryAlt,
  "RiskPriority.NO_PRIORITY_DEFINED_BY_INSURANCE_COMPANY":
    neutrals.neutralTertiaryAlt,
  "RiskPriority.MINOR": presence.ONLINE,
  "RiskPriority.MODERATE": accent.yellow,
  "RiskPriority.MAJOR": accent.red,
};

export const riskCostColors = {
  "RiskReductionCost.NO_INFO": neutrals.neutralTertiaryAlt,
  "RiskReductionCost.LOW": presence.ONLINE,
  "RiskReductionCost.MODERATE": accent.yellow,
  "RiskReductionCost.HIGH": accent.red,
} as { [key: string]: string };

export const riskImprovementPotentialColors = {
  "RiskImprovementPotential.SIGNIFICANT_IMPROVEMENT_POTENTIAL": accent.red,
  "RiskImprovementPotential.IMPROVEMENT_POTENTIAL": accent.yellow,
  "RiskImprovementPotential.NO_IMPROVEMENT_POTENTIAL": presence.ONLINE,
};

export const recommendationStatusColors = {
  "RiskRecommendationStatus.CLOSED": neutrals.neutralTertiaryAlt,
  "RiskRecommendationStatus.WAITING_FOR_CLIENT": accent.red,
  "RiskRecommendationStatus.WAITING_FOR_GREG": accent.greenDark,
};

export const getRecommendationStatusCircleColor = (
  rec: RecommendationAzure,
  code: string
) => {
  let color =
    recommendationStatusColors["RiskRecommendationStatus.WAITING_FOR_GREG"];

  if (code === "RiskRecommendationStatus.CLOSED") {
    color = recommendationStatusColors["RiskRecommendationStatus.CLOSED"];
  }
  //
  else if (rec.assessmentPeriodStartedAt !== null) {
    color = accent.orangeLighter;
  }
  //
  else if (code === "RiskRecommendationStatus.WAITING_FOR_CLIENT") {
    color =
      recommendationStatusColors["RiskRecommendationStatus.WAITING_FOR_CLIENT"];
  }
  return color;
};

export const getRecommendationStatusCircle = (
  rec: Recommendation,
  code: number,
  assessmentPeriodStartedAt: string
) => {
  let color = accent.greenDark;

  if (code === 3) {
    color = neutrals.neutralTertiaryAlt;
  }
  //
  else if (assessmentPeriodStartedAt !== null) {
    color = accent.orangeLighter;
  }
  //
  else if (code === 2) {
    color = accent.red;
  }
  return color;
};
