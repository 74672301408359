import {
  CreateResponseWithAttachmentsParams,
  createResponseWithAttachments,
} from "api/api";
import { useMutation } from "react-query";
import { MUTATION_KEYS } from "store/constants";

import { handleAxiosError } from "store/util";

const createResponse = async ({
  locationId,
  reportId,
  recommendationId,
  body,
}: CreateResponseWithAttachmentsParams) => {
  return await createResponseWithAttachments(
    locationId,
    reportId,
    recommendationId,
    body
  );
};

export function useCreateResponse() {
  return useMutation(createResponse, {
    mutationKey: MUTATION_KEYS.createResponse,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}
