import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ListContextMenuIconButton } from "../../components/styled";
import { businessObjectIcons } from "../../config/icons";
import { routes } from "../../config/routes";
import { setReportsFilter } from "../../store/filters/filters";
import { useSelector } from "../../store/hooks";
import { Report } from "../../types/types";
import { setPasteDialog } from "../../store/recommendations-page/recommendationsPage";
import {
  changeActiveStatusActions,
  setDeleteDialog,
} from "../../store/reports-page/reportsPage";

export const ContextButton = ({ report }: { report: Report }) => {
  const userRole = useSelector((s) => s.userRole.userRole);
  const recommendation = useSelector(
    (s) => s.recommendationsPage.clipboardRecommendation
  );

  const isChangeActiveStatusLoading = useSelector(
    (s) => s.reportsPage.isChangeActiveStatusLoading
  );
  const reportIdNotified = useSelector(
    (s) => s.reportsPage.reportIdStatusChanged
  );

  const isChangeActiveStatusLoadingDisabled =
    isChangeActiveStatusLoading && reportIdNotified === report?.reportId;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickEditReport = () => {
    navigate(
      routes.editReport.getPath(
        report.webBasePartnerNumber,
        report.locationId,
        report.reportId
      )
    );
  };

  const onClickAddRecommendation = () => {
    navigate(
      routes.addRecommendation.getPath(
        report.webBasePartnerNumber,
        report.locationId,
        report.reportId
      )
    );
  };

  const onClickShowRecommendations = () => {
    dispatch(setReportsFilter([report.reportId]));
    navigate(routes.recommendations.getPath());
  };

  const onClickDelete = () => {
    dispatch(
      setDeleteDialog({
        report: report,
        isOpen: true,
      })
    );
  };

  const onClickPaste = () => {
    dispatch(
      setPasteDialog({
        report: report,
        recommendation: recommendation,
        isOpen: true,
      })
    );
  };

  const onClickChangeActiveStatus = () => {
    dispatch(
      changeActiveStatusActions.trigger({
        locationId: report.locationId,
        reportId: report.reportId,
        activate: report.isReportDeactivated,
      })
    );
  };

  const items: any[] = [];

  var showRecommsObj = {
    key: "showRecommendations",
    text: t("ram.locations.contextMenu.showRecommendations"),
    iconProps: { iconName: businessObjectIcons.recommendation },
    onClick: onClickShowRecommendations,
  };

  if (!report.isReportDeactivated) {
    items.push(showRecommsObj);
  }

  var editReportObj = {
    key: "editReport",
    text: t("ram.reportList.editReport"),
    iconProps: { iconName: "Edit" },
    onClick: onClickEditReport,
  };
  var addRecommendationObj = {
    key: "addRecommendation",
    text: t("ram.reportList.addRecommendation"),
    iconProps: { iconName: "Add" },
    onClick: onClickAddRecommendation,
  };

  const deleteOption = {
    key: "delete",
    text: t("greco.delete"),
    iconProps: {
      iconName: "Trash",
    },
    onClick: onClickDelete,
  };

  var changeActiveStatus = {
    key: "changeActiveStatus",
    text:
      report?.isReportDeactivated === true
        ? t("ram.reportList.activateReport")
        : t("ram.reportList.deactivateReport"),
    iconProps: {
      iconName: report?.isReportDeactivated === true ? "Accept" : "ChromeClose",
    },
    onClick: () => onClickChangeActiveStatus(),
    disabled: isChangeActiveStatusLoadingDisabled,
  };

  if (
    userRole === "admin" ||
    userRole === "internal" ||
    userRole === "internal_role" ||
    userRole === "external_advanced"
  ) {
    if (!report.isReportDeactivated) {
      items.splice(0, 0, addRecommendationObj);
    }
    items.splice(1, 0, editReportObj);
  }

  if (userRole === "admin") {
    items.push(deleteOption);
    items.push(changeActiveStatus);
  }

  const pasteOption = {
    key: "paste",
    text: t("ram.recommendationList.pasteRecommendation"),
    iconProps: {
      iconName: "Paste",
    },
    onClick: onClickPaste,
  };

  if (
    !report.isReportDeactivated &&
    (userRole === "admin" ||
      userRole === "internal" ||
      userRole === "internal_role" ||
      userRole == "external_advanced") &&
    recommendation
  ) {
    items.push(pasteOption);
  }

  return (
    <ListContextMenuIconButton
      iconProps={{ iconName: "MoreVertical" }}
      menuIconProps={{ style: { display: "none" } }}
      menuProps={{
        items: items,
      }}
    />
  );
};
