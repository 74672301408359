import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormSpinner } from "../../components/FormSpinner";
import { Flex, HSpace, StyledPrimaryButton } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import {
  changeClientRoutine,
  setChangeClientDialog,
} from "../../store/locations-page/locationsPage";
import { WebBasePartnerNumber } from "./WebBasePartnerNumber";
import { Text } from "@fluentui/react";

type ClientState = {
  label: string;
  value: number;
} | null;

export const ChangeClientDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { location } = useSelector((s) => s.locationsPage.changeClientDialog);
  const isLoading = useSelector(
    (s) => s.locationsPage.changeClientLoadStatus === "loading"
  );

  const [client, setClient] = useState<ClientState>(null);

  const handleSetClient = useCallback((client) => {
    setClient(client);
  }, []);

  const close = () => {
    dispatch(
      setChangeClientDialog({
        location: null,
        isOpen: false,
      })
    );
  };

  return (
    <Dialog
      minWidth={700}
      hidden={false}
      dialogContentProps={{
        showCloseButton: true,
        title: (
          <Flex>
            <Icon iconName="Switch" /> <HSpace />
            <div>{t("ram.changeClient.label")}</div>
          </Flex>
        ),
      }}
      onDismiss={close}
    >
      <Text
        block
        styles={{
          root: {
            marginBottom: 10,
          },
        }}
      >
        {t("ram.changeClientDialogDescription.label")}
      </Text>
      <div>
        <WebBasePartnerNumber client={client} setClient={handleSetClient} />
      </div>
      <DialogFooter>
        <StyledPrimaryButton
          text={t("ram.changeClient.label")}
          onClick={() => {
            dispatch(
              changeClientRoutine.trigger({
                locationId: location.locationId,
                clientId: client?.value,
              })
            );
          }}
          disabled={isLoading || !client?.value}
        />
      </DialogFooter>
      {isLoading && <FormSpinner />}
    </Dialog>
  );
};
