import { mergeStyleSets, Text, TextField } from "@fluentui/react";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useMask from "react-mask-hook";
import { FieldContainer } from "./FieldContainer";
import { useTheme } from "styled-components";
import { IStyledTheme } from "../../theme/types";

const getClasses = (theme, disabled) =>
  mergeStyleSets({
    label: {
      fontSize: "14px",
      fontWeight: "600",
      padding: "5px",
      display: "block",
      color: disabled ? theme.palette.neutralSecondaryAlt : theme.palette.black,
    },
    labelAsterix: {
      color: theme.palette.redDark,
    },
  });

const getTextFieldStyle = (transparent, theme, isMarked, confidentiality) => ({
  root: {
    ".ms-TextField-fieldGroup": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",

      // background: transparent ? "transparent" : "inherit",
      // ...(isMarked && { background: theme.palette.yellowLight }),
      // ...(confidentiality && {
      //   background: `rgb(${confidentiality < 0.9 ? 255 : 200},${
      //     confidentiality >= 0.8 ? 255 : 200
      //   },200)`,
      // }),
    },
    ".ms-TextField-fieldGroup::after": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
    },
    ".ms-TextField-fieldGroup input": {
      color: isMarked
        ? "black"
        : theme.isDark
        ? confidentiality
          ? "black"
          : "white"
        : "black",
    },

    ".ms-TextField-fieldGroup input:disabled": {
      // background: transparent ? "transparent" : "inherit",
      background: theme.palette.white,
      color: theme.palette.neutralSecondaryAlt,
    },
    ".ms-TextField-wrapper span": {
      background: theme.palette.white,
    },
    ".ms-TextField-fieldGroup textarea:disabled": {
      background: theme.palette.white,
    },
  },

  suffix: {
    background: `transparent`,
  },
});

type Props = {
  value: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  name: string;
  disabled?: boolean;
  label: string;
  errors?: any;
  transparent?: boolean;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  isMarked?: boolean;
  confidentiality?: number;
  [x: string]: any; //rest props
};

const TextInputField = ({
  disabled,
  label,
  name,
  onChange,
  error = "",
  value,
  errors,
  transparent,
  required,
  isMarked,
  confidentiality,
  isDayMonthField,
  ...props
}: Props) => {
  const [textValue, setTextValue] = useState("");
  const theme = useTheme() as IStyledTheme;
  const { t } = useTranslation();

  const classes = getClasses(theme, disabled);

  const styles = getTextFieldStyle(
    transparent,
    theme,
    isMarked,
    confidentiality
  );

  function handleMaskChange(value) {
    setTextValue(value);
  }

  function handleChange(event) {
    setTextValue(event.target.value);
  }

  const maskProps = useMask({
    value: textValue ? textValue?.replace("/", "") : "",
    onChange: handleMaskChange,
    // array of regexp and formatting characters
    mask: "##/##",
    placeholder: "DD/MM",
  });

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  return (
    <FieldContainer isTooltipHidden={disabled} tooltipText={label}>
      <TextField
        key={value}
        id={name + "-input"}
        name={name}
        onBlur={(ev) => {
          onChange(ev);
        }}
        onRenderLabel={() => (
          <Text className={classes.label}>
            {t(label)}{" "}
            <Text className={classes.labelAsterix}>{required && "*"}</Text>
          </Text>
        )}
        styles={styles}
        disabled={disabled}
        label={t(label)}
        onChange={handleChange}
        {...(!isDayMonthField && {
          defaultValue: value || "",
        })}
        errorMessage={name && errors[name]}
        autoComplete="off"
        {...(isDayMonthField && (maskProps as any))}
        {...props}
      />
    </FieldContainer>
  );
};

export default TextInputField;
