import React from "react";
import { useSelector } from "../../../store/hooks";
import { transformSicCode } from "../../../utils/columns";
import { TooltipHost, TooltipOverflowMode } from "@fluentui/react";
import { Location } from "../../../types/types";
import { useTranslation } from "react-i18next";
import { TooltipComponent } from "components/TooltipComponent";

type Props = {
  row: Location;
};

function SicCode({ row }: Props) {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);

  return (
    <TooltipComponent
      content={transformSicCode({ sicCode: row?.siccode, t, taxonomy })}
      overflowMode={TooltipOverflowMode.Parent}
    >
      {transformSicCode({ sicCode: row?.siccode, t, taxonomy })}
    </TooltipComponent>
  );
}

export default SicCode;
