import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import i18next from "i18next";
import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import * as API from "../api/api";
import { toast } from "../components/FluentToast";
import { routes } from "../config/routes";
import { push, replace, goBack } from "redux-first-history";

import {
  Assessment,
  AssessmentAttachment,
  AssessmentWithAttachments,
  ClientLocation,
  ClientResponse,
  ClientResponseAttachment,
  ClientResponseWithAttachments,
  InsurerEvaluation,
  InsurerEvaluationAttachment,
  InsurerEvaluationWithAttachments,
  LocationDefaultValue,
  Recommendation,
  RecommendationAttachment,
  RecommendationWithAttachments,
} from "../types/types";
import { LoadStatus, RootState } from "./store";
import { TaxonomyState } from "./taxonomy/taxonomy";
import {
  createAsyncActions,
  createAsyncRoutine,
  handleAxiosError,
} from "./util";
import { RecommendationSaveMode } from "pages/recommendation/components/RecommendationFormContent/RecommendationFormContent";
import { history } from "utils/_helpers";

export type RecommendationPageState = {
  rec: Recommendation | null;
  attachments: RecommendationAttachment[];
  isRecLoading: boolean;
  isSaveLoading: boolean;
  isShowHideLoading: boolean;
  defaultValues: LocationDefaultValue[];
  areDefaultValuesLoading: boolean;
  error: string | null;
  didTrySubmit: boolean;
  tab: string;
  subTab: string;
  isErrorPanelOpen: boolean;
  responses: ClientResponseWithAttachments[];
  assessments: AssessmentWithAttachments[];
  assessmentsLoadStatus: LoadStatus;
  responsesLoadStatus: LoadStatus;
  evaluations: InsurerEvaluationWithAttachments[];
  evaluationsLoadStatus: LoadStatus;
  assessRecommendationLoadStatus: LoadStatus;
  deleteResponseDialog: {
    isOpen: boolean;
    response: ClientResponse | null;
  };
  deleteAssessmentDialog: {
    isOpen: boolean;
    assessment: Assessment | null;
  };
  deleteAssessmentLoadStatus: LoadStatus;
  addResponseDialog: {
    isOpen: boolean;
    response: ClientResponse | null;
    attachments: ClientResponseAttachment[];
  };
  addAssessmentDialog: {
    isOpen: boolean;
    assessment: Assessment | null;
    attachments: AssessmentAttachment[];
  };
  deleteResponseLoadStatus: LoadStatus;
  addEvaluationDialog: {
    isOpen: boolean;
    evaluation: InsurerEvaluation | null;
    attachments: InsurerEvaluationAttachment[];
  };
  deleteEvaluationDialog: {
    isOpen: boolean;
    evaluation: InsurerEvaluation | null;
  };
  reportDocumentDialog: {
    isOpen: boolean;
  };
  reportDocument: any;
  exportToZipLoadStatus: LoadStatus;
  exportToZipLink: any | null;
  exportToZipDialog: {
    locationId: number | null;
    recommendationId: number | null;
    isOpen: boolean;
  };
  deleteEvaluationLoadStatus: LoadStatus;
  reportDocumentLoadStatus: LoadStatus;
  addEvaluationLoadStatus: LoadStatus;
};

export const loadRec = createAsyncThunk<
  RecommendationWithAttachments,
  void,
  { state: RootState }
>("recommendationPage/loadRec", async (_, thunkAPI) => {
  const loc = thunkAPI.getState().router.location;
  const editRecommendationMatch = routes.editRecommendation.matchPath(loc);

  const { locationId, reportId, recommendationId } =
    editRecommendationMatch?.params;

  let res;
  try {
    res = await API.getRecommendationWithAttachments({
      locationId,
      reportId,
      recommendationId,
    });
  } catch (err: any) {
    if (err.response.status === 403 || err.response.status === 404) {
      thunkAPI.dispatch(replace("/clients"));
    }
  }

  return res.data;
});

export const createRecommendationActions = createAsyncActions<
  {
    recommendation: Recommendation;
    attachments: RecommendationAttachment[];
    mode: RecommendationSaveMode;
    onError?: (
      error: AxiosError<
        ReturnType<typeof API.createRecommendationWithAttachments>
      >
    ) => void;
  },
  void,
  RecommendationWithAttachments,
  AxiosError<ReturnType<typeof API.createRecommendationWithAttachments>>
>("recommendation/create");

export const updateRecommendationActions = createAsyncActions<
  {
    recWithAttachments: RecommendationWithAttachments;
    mode: RecommendationSaveMode;
    onError?: (
      error: AxiosError<
        ReturnType<typeof API.createRecommendationWithAttachments>
      >
    ) => void;
  },
  void,
  {
    recWithAttachments: RecommendationWithAttachments;
    mode: RecommendationSaveMode;
  },
  AxiosError<ReturnType<typeof API.updateRecommendationWithAttachments>>
>("recommendation/update");

export const responseShowHideActions = createAsyncActions<
  {
    response: ClientResponse;
  },
  void,
  {
    response: ClientResponse;
  },
  AxiosError<ReturnType<typeof API.responseShowHide>>
>("recommendation/responseShowHide");

export const assessmentShowHideActions = createAsyncActions<
  {
    assessment: Assessment;
  },
  void,
  {
    assessment: Assessment;
  },
  AxiosError<ReturnType<typeof API.assessmentShowHide>>
>("recommendation/assessmentShowHide");

// export const evaluationShowHideActions = createAsyncActions<
//   {
//     evaluation: InsurerEvaluation;
//   },
//   void,
//   {
//     evaluation: InsurerEvaluation;
//   },
//   AxiosError<ReturnType<typeof API.evaluationShowHide>>
// >("recommendation/evaluationShowHide");

export const loadDefaultValuesActions = createAsyncActions<
  ClientLocation["locationId"],
  void,
  LocationDefaultValue[]
>("recommendation/loadDefaultValues");

export const loadResponsesRoutine = createAsyncRoutine<
  API.GetResponsesWithAttachmentsParams,
  void,
  ClientResponseWithAttachments[],
  any
>("recommendation/loadResponses");

export const loadEvaluationsRoutine = createAsyncRoutine<
  API.GetEvaluationsWithAttachmentsParams,
  void,
  InsurerEvaluationWithAttachments[],
  any
>("recommendation/loadEvaluations");

export const loadResponsesAndEvaluationsRoutine = createAsyncRoutine<
  API.GetClientResponsesAndInsurerEvaluationsWithAttachmentsParams,
  void,
  API.ClientResponsesAndInsurerEvaluationsWithAttachmentsResponse,
  any
>("recommendation/loadResponsesAndEvaluations");

export const assessRecommendationRoutine = createAsyncRoutine<
  API.AssesRecommendationParams & { mode: string },
  void,
  Recommendation,
  any
>("recommendation/assess");

export const deleteResponseRoutine = createAsyncRoutine<
  API.DeleteResponseParams,
  void,
  any,
  any
>("recommendation/deleteResponse");

export const deleteAssessmentRoutine = createAsyncRoutine<
  API.DeleteAssessmentParams,
  void,
  any,
  any
>("recommendation/deleteAssessment");

export const deleteEvaluationRoutine = createAsyncRoutine<
  API.DeleteEvaluationParams,
  void,
  any,
  any
>("recommendation/deleteEvaluation");

export const reportDocumentRoutine = createAsyncRoutine<
  API.ReportDocumentParams,
  void,
  any,
  any
>("recommendation/reportDocument");
export const exportToZipRoutine = createAsyncRoutine<
  API.ExportRecommendationToZipParams,
  void,
  void,
  any
>("recommendation/exportToZip");

const initialState: RecommendationPageState = {
  rec: null,
  attachments: [],
  isRecLoading: false,
  isSaveLoading: false,
  isShowHideLoading: false,
  defaultValues: [],
  areDefaultValuesLoading: false,
  error: "",
  didTrySubmit: false,
  tab: "",
  subTab: "general",
  isErrorPanelOpen: false,
  responses: [],
  evaluations: [],
  assessments: [],
  responsesLoadStatus: "none",
  assessmentsLoadStatus: "none",
  evaluationsLoadStatus: "none",
  assessRecommendationLoadStatus: "none",
  deleteResponseDialog: {
    isOpen: false,
    response: null,
  },
  deleteAssessmentDialog: {
    isOpen: false,
    assessment: null,
  },
  deleteAssessmentLoadStatus: "none",
  addResponseDialog: {
    isOpen: false,
    response: null,
    attachments: [],
  },
  addAssessmentDialog: {
    isOpen: false,
    assessment: null,
    attachments: [],
  },
  deleteEvaluationDialog: {
    isOpen: false,
    evaluation: null,
  },
  reportDocumentDialog: {
    isOpen: false,
  },
  reportDocument: null,
  addEvaluationDialog: {
    isOpen: false,
    evaluation: null,
    attachments: [],
  },
  exportToZipLoadStatus: "none",
  exportToZipLink: null,
  exportToZipDialog: {
    locationId: null,
    recommendationId: null,
    isOpen: false,
  },
  deleteResponseLoadStatus: "none",
  deleteEvaluationLoadStatus: "none",
  reportDocumentLoadStatus: "none",
  addEvaluationLoadStatus: "none",
};

export const recommendationPageSlice = createSlice({
  name: "recommendationPage",
  initialState,
  reducers: {
    setError: (s, a: PayloadAction<RecommendationPageState["error"]>) => {
      s.error = a.payload;
    },
    setDidTrySubmit: (
      s,
      a: PayloadAction<RecommendationPageState["didTrySubmit"]>
    ) => {
      s.didTrySubmit = a.payload;
    },
    setTab: (s, a: PayloadAction<RecommendationPageState["tab"]>) => {
      s.tab = a.payload;
    },
    setSubTab: (s, a: PayloadAction<RecommendationPageState["subTab"]>) => {
      s.subTab = a.payload;
    },
    setIsErrorPanelOpen: (
      s,
      a: PayloadAction<RecommendationPageState["isErrorPanelOpen"]>
    ) => {
      s.isErrorPanelOpen = a.payload;
    },
    setAddResponseDialog: (
      s,
      a: PayloadAction<RecommendationPageState["addResponseDialog"]>
    ) => {
      s.addResponseDialog = a.payload;
    },
    setDeleteResponseDialog: (
      s,
      a: PayloadAction<RecommendationPageState["deleteResponseDialog"]>
    ) => {
      s.deleteResponseDialog = a.payload;
    },
    setDeleteAssessmentDialog: (
      s,
      a: PayloadAction<RecommendationPageState["deleteAssessmentDialog"]>
    ) => {
      s.deleteAssessmentDialog = a.payload;
    },
    setAddAssessmentDialog: (
      s,
      a: PayloadAction<RecommendationPageState["addAssessmentDialog"]>
    ) => {
      s.addAssessmentDialog = a.payload;
    },

    setAddEvaluationDialog: (
      s,
      a: PayloadAction<RecommendationPageState["addEvaluationDialog"]>
    ) => {
      s.addEvaluationDialog = a.payload;
    },
    setDeleteEvaluationDialog: (
      s,
      a: PayloadAction<RecommendationPageState["deleteEvaluationDialog"]>
    ) => {
      s.deleteEvaluationDialog = a.payload;
    },
    setReportDocumentDialog: (
      s,
      a: PayloadAction<RecommendationPageState["reportDocumentDialog"]>
    ) => {
      s.reportDocumentDialog = a.payload;
      s.reportDocument = null;
    },
    setExportToZipDialog: (
      s,
      a: PayloadAction<RecommendationPageState["exportToZipDialog"]>
    ) => {
      s.exportToZipDialog = a.payload;
    },
    setExportToZipLink: (
      s,
      a: PayloadAction<RecommendationPageState["exportToZipLink"]>
    ) => {
      s.exportToZipLink = a.payload;
    },
    reset: (s: any) => {
      Object.assign(s, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadRec.pending, (s, a) => {
      s.isRecLoading = true;
    });
    builder.addCase(loadRec.fulfilled, (s, a) => {
      s.isRecLoading = false;
      s.rec =
        a.payload.recommendation.isDeleted === false
          ? a.payload.recommendation
          : null;
      s.attachments =
        a.payload.recommendation.isDeleted === false
          ? a.payload.recommendationAttachments
          : [];
    });
    builder.addCase(loadRec.rejected, (s, a) => {
      s.isRecLoading = false;
    });
    builder.addCase(createRecommendationActions.loading, (s, a) => {
      s.isSaveLoading = true;
    });
    builder.addCase(createRecommendationActions.success, (s, a) => {
      s.isSaveLoading = false;
      s.error = null;
    });
    builder.addCase(createRecommendationActions.error, (s, a) => {
      s.isSaveLoading = false;
      const error = a.payload;
      s.error = error.response?.data as any;
    });
    builder.addCase(updateRecommendationActions.loading, (s, a) => {
      s.isSaveLoading = true;
    });
    builder.addCase(updateRecommendationActions.success, (s, a) => {
      s.isSaveLoading = false;
      const { recWithAttachments, mode } = a.payload;
      s.rec = recWithAttachments.recommendation;
      s.attachments = recWithAttachments.recommendationAttachments;
      if (mode === "addNew" || mode === "notify") {
        s.attachments = [];
        s.rec = null;
      }
    });
    builder.addCase(updateRecommendationActions.error, (s, a) => {
      s.isSaveLoading = false;
      s.error = a.payload.response?.data as any;
    });

    builder.addCase(responseShowHideActions.loading, (s, a) => {
      s.isShowHideLoading = true;
    });
    builder.addCase(responseShowHideActions.success, (s, a) => {
      s.isShowHideLoading = false;
      const { response } = a.payload;
      s.responses = s.responses.map((r) => {
        if (r.clientResponse.responseId === response.responseId) {
          return { ...r, clientResponse: response };
        }
        return r;
      });
    });
    builder.addCase(responseShowHideActions.error, (s, a) => {
      s.isShowHideLoading = false;
      s.error = a.payload.response?.data as any;
    });

    builder.addCase(assessmentShowHideActions.loading, (s, a) => {
      s.isShowHideLoading = true;
    });
    builder.addCase(assessmentShowHideActions.success, (s, a) => {
      s.isShowHideLoading = false;
      const { assessment } = a.payload;
      s.assessments = s.assessments.map((r) => {
        if (r.assessment.assessmentId === assessment.assessmentId) {
          return { ...r, assessment: assessment };
        }
        return r;
      });
    });
    builder.addCase(assessmentShowHideActions.error, (s, a) => {
      s.isShowHideLoading = false;
      s.error = a.payload.response?.data as any;
    });

    builder.addCase(loadDefaultValuesActions.loading, (s, a) => {
      s.areDefaultValuesLoading = true;
    });
    builder.addCase(loadDefaultValuesActions.success, (s, a) => {
      s.areDefaultValuesLoading = false;
      s.defaultValues = a.payload;
    });
    builder.addCase(loadDefaultValuesActions.error, (s, a) => {
      s.areDefaultValuesLoading = false;
    });
    builder.addCase(loadResponsesRoutine.loading, (s, a) => {
      s.responsesLoadStatus = "loading";
    });
    builder.addCase(loadResponsesRoutine.success, (s, a) => {
      s.responsesLoadStatus = "success";
      s.responses = a.payload;
    });
    builder.addCase(loadResponsesRoutine.error, (s, a) => {
      s.responsesLoadStatus = "error";
    });
    builder.addCase(loadEvaluationsRoutine.loading, (s, a) => {
      s.evaluationsLoadStatus = "loading";
    });
    builder.addCase(loadEvaluationsRoutine.success, (s, a) => {
      s.evaluationsLoadStatus = "success";
      s.evaluations = a.payload;
    });
    builder.addCase(loadEvaluationsRoutine.error, (s, a) => {
      s.evaluationsLoadStatus = "error";
    });
    builder.addCase(loadResponsesAndEvaluationsRoutine.loading, (s, a) => {
      s.responsesLoadStatus = "loading";
    });
    builder.addCase(loadResponsesAndEvaluationsRoutine.success, (s, a) => {
      s.responsesLoadStatus = "success";
      s.responses = a.payload.clientResponsesWithAttachments;
      s.evaluations = a.payload.insurerEvaluationsWithAttachments;
      s.assessments = a.payload.assessmentsWithAttachments;
    });
    builder.addCase(loadResponsesAndEvaluationsRoutine.error, (s, a) => {
      s.responsesLoadStatus = "error";
    });
    builder.addCase(assessRecommendationRoutine.loading, (s, a) => {
      s.assessRecommendationLoadStatus = "loading";
    });
    builder.addCase(assessRecommendationRoutine.success, (s, a) => {
      s.assessRecommendationLoadStatus = "success";
      s.rec = a.payload;
    });
    builder.addCase(assessRecommendationRoutine.error, (s, a) => {
      s.assessRecommendationLoadStatus = "error";
    });
    builder.addCase(deleteResponseRoutine.loading, (s, a) => {
      s.deleteResponseLoadStatus = "loading";
    });
    builder.addCase(deleteResponseRoutine.success, (s, a) => {
      s.deleteResponseLoadStatus = "success";
    });
    builder.addCase(deleteResponseRoutine.error, (s, a) => {
      s.deleteResponseLoadStatus = "error";
    });
    builder.addCase(deleteAssessmentRoutine.loading, (s, a) => {
      s.deleteAssessmentLoadStatus = "loading";
    });
    builder.addCase(deleteAssessmentRoutine.success, (s, a) => {
      s.deleteAssessmentLoadStatus = "success";
    });
    builder.addCase(deleteAssessmentRoutine.error, (s, a) => {
      s.deleteAssessmentLoadStatus = "error";
    });
    builder.addCase(deleteEvaluationRoutine.loading, (s, a) => {
      s.deleteEvaluationLoadStatus = "loading";
    });
    builder.addCase(deleteEvaluationRoutine.success, (s, a) => {
      s.deleteEvaluationLoadStatus = "success";
    });
    builder.addCase(deleteEvaluationRoutine.error, (s, a) => {
      s.deleteEvaluationLoadStatus = "error";
    });
    builder.addCase(reportDocumentRoutine.loading, (s, a) => {
      s.reportDocumentLoadStatus = "loading";
    });
    builder.addCase(reportDocumentRoutine.success, (s, a) => {
      s.reportDocumentLoadStatus = "success";
      s.reportDocument = a.payload;
    });
    builder.addCase(reportDocumentRoutine.error, (s, a) => {
      s.reportDocumentLoadStatus = "error";
    });
    builder.addCase(exportToZipRoutine.loading, (s, a) => {
      s.exportToZipLoadStatus = "loading";
    });
    builder.addCase(exportToZipRoutine.success, (s, a) => {
      s.exportToZipLoadStatus = "success";
      s.exportToZipLink = a.payload;
    });
    builder.addCase(exportToZipRoutine.error, (s, a) => {
      s.exportToZipLoadStatus = "error";
    });
  },
});

export const recommendationPageReducer = recommendationPageSlice.reducer;

export const {
  setError: setErrorRecommendation,
  setDidTrySubmit: setDidTrySubmitRecommendation,
  setTab,
  setIsErrorPanelOpen,
  reset,
  setSubTab,
  setAddResponseDialog,
  setAddAssessmentDialog,
  setDeleteResponseDialog,
  setDeleteAssessmentDialog,
  setAddEvaluationDialog,
  setDeleteEvaluationDialog,
  setReportDocumentDialog,
  setExportToZipDialog,
  setExportToZipLink,
} = recommendationPageSlice.actions;

export const selectCurrentRecommendationIndex = createSelector(
  (s: any) => s.recommendationPage,
  (s: any) => s.recommendations,
  (recPageState: any, recommendationsState: any) => {
    if (
      recPageState &&
      recommendationsState &&
      recommendationsState.ids &&
      recommendationsState.ids.length > 0 &&
      recPageState.rec &&
      recPageState.rec.recommendationId
    ) {
      try {
        const recId = recPageState.rec.recommendationId;
        const recIndex = recommendationsState?.ids?.findIndex(
          (r) => r === recId + ""
        );
        return recIndex;
      } catch (e) {
        // console.log(e);
      }
    }
    return undefined;
  }
);

export const selectPreviousRecommendation = createSelector(
  (s: any) => s.recommendationPage,
  (s: any) => s.recommendations,
  (recPageState: any, recommendationsState: any) => {
    if (
      recPageState &&
      recommendationsState &&
      recommendationsState.ids &&
      recommendationsState.ids.length > 0 &&
      recPageState.rec &&
      recPageState.rec.recommendationId
    ) {
      try {
        const recId = recPageState.rec.recommendationId;
        const recIndex = recommendationsState?.ids?.findIndex(
          (r) => r === recId + ""
        );
        if (recIndex > 0) {
          return recommendationsState.entities[
            recommendationsState.ids[recIndex - 1]
          ];
        } else {
          return null;
        }
      } catch (e) {
        // console.log(e);
      }
    }
    return undefined;
  }
);

export const selectNextRecommendation = createSelector(
  (s: any) => s.recommendationPage,
  (s: any) => s.recommendations,
  (recPageState: any, recommendationsState: any) => {
    if (
      recPageState &&
      recommendationsState &&
      recommendationsState.ids &&
      recommendationsState.ids.length > 0 &&
      recPageState.rec &&
      recPageState.rec.recommendationId
    ) {
      try {
        const recId = recPageState.rec.recommendationId;
        const recIndex = recommendationsState.ids.findIndex(
          (r) => r === recId + ""
        );
        if (recIndex > -1 && recIndex < recommendationsState.ids.length - 1) {
          return recommendationsState.entities[
            recommendationsState.ids[recIndex + 1]
          ];
        } else {
          return null;
        }
      } catch (e) {
        // console.log(e);
      }
    }
    return undefined;
  }
);

// User can notify for recommendation in edit mode if
// - he is not an external user
// - assessment period for location is not undergoing
// - recommendation status is "WAITING_FOR_GREG"
export const selectCanNotifyEdit = createSelector(
  (s: any) => s.recommendationPage,
  (s: any) => s.locations,
  (s: any) => s.taxonomy,
  (recPageState: any, locationsState: any, taxonomy: any) => {
    if (
      recPageState.isRecLoading ||
      locationsState.isLoading ||
      taxonomy.isLoading
    ) {
      return {
        isLoading: true,
        result: false,
      };
    }

    if (!recPageState.rec) {
      return {
        isLoading: true,
        result: false,
      };
    }

    const loc = locationsState.entities[recPageState.rec!.locationId];

    if (!loc) {
      return {
        isLoading: true,
        result: false,
      };
    }

    const hasAssessmentPeriodStart = loc.assessmentPeriodStartedAt !== null;

    const statusGREGId =
      taxonomy.RiskRecommendationStatus.byCode[
        "RiskRecommendationStatus.WAITING_FOR_GREG"
      ].id;
    const isStatusGREG =
      recPageState.rec!.recommendationStatusCode === statusGREGId;

    return {
      isLoading: false,
      result: !hasAssessmentPeriodStart && isStatusGREG,
    };
  }
);

// User can notify for recommendation in create mode if
// - he is not an external user
// - assessment period for location is not undergoing
export const selectCanNotifyAdd = createSelector(
  (s: any) => s.locations,
  (s: any) => s.router.location,
  (locationsState: any, routerLocation) => {
    if (locationsState.isLoading) {
      return {
        isLoading: true,
        result: false,
      };
    }

    const addRecommendationMatch =
      routes.addRecommendation.matchPath(routerLocation);

    if (!addRecommendationMatch) {
      return {
        isLoading: false,
        result: false,
      };
    }

    const { locationId } = addRecommendationMatch.params;

    const loc = locationsState.entities[locationId];

    if (!loc) {
      return {
        isLoading: true,
        result: false,
      };
    }

    const hasAssessmentPeriodStart = loc.assessmentPeriodStartedAt !== null;

    return {
      isLoading: false,
      result: !hasAssessmentPeriodStart,
    };
  }
);

export function* createRecommendationSaga() {
  yield takeEvery(
    createRecommendationActions.trigger.type,
    function* (a: ReturnType<typeof createRecommendationActions.trigger>) {
      const historyLocation = yield select((s: any) => s.router.location);
      const addRecommendationMatch =
        routes.addRecommendation.matchPath(historyLocation);

      const { locationId, reportId } = addRecommendationMatch?.params;
      const { recommendation, attachments, mode, onError } = a.payload;
      yield put(createRecommendationActions.loading());
      try {
        const res = yield call(
          API.createRecommendationWithAttachments,
          locationId,
          reportId,
          {
            recommendation,
            recommendationAttachments: attachments,
          }
        );
        const newRecWithAttachments = res.data;
        toast.success(
          i18next.t("greco.notification.create.success", {
            object: `${i18next.t("ram.recommendation").toLowerCase()} '${
              newRecWithAttachments.recommendation.title
            }'`,
          })
        );
        if (mode === "normal" || mode === "notify") {
          yield put(push(routes.recommendations.getPath()));
        }
        // add new
        else {
          yield put(push("/temp"));
          yield put(goBack());
        }
        yield put(createRecommendationActions.success(newRecWithAttachments));
      } catch (err: any) {
        handleAxiosError(err);

        yield put(createRecommendationActions.error(err));
        onError && onError(err);
      }
    }
  );
}

export function* updateRecommendationSaga() {
  yield takeEvery(
    updateRecommendationActions.trigger.type,
    function* (a: ReturnType<typeof updateRecommendationActions.trigger>) {
      const historyLocation = yield select((s: any) => s.router.location);
      const editRecommendationMatch =
        routes.editRecommendation.matchPath(historyLocation);

      const { clientId, locationId, reportId, recommendationId } =
        editRecommendationMatch?.params;
      const { recWithAttachments, mode, onError } = a.payload;
      yield put(updateRecommendationActions.loading());
      try {
        const res = yield call(API.updateRecommendationWithAttachments, {
          locationId,
          reportId,
          recommendationId,
          body: recWithAttachments,
        });
        const newRecWithAttachments = res.data;
        toast.success(
          i18next.t("greco.notification.update.success", {
            object: `${i18next.t("ram.recommendation").toLowerCase()} '${
              newRecWithAttachments.recommendation.title
            }'`,
          })
        );
        if (mode === "addNew") {
          // yield put(push("/temp"));
          // yield put(
          //   push(
          //     routes.addRecommendation.getPath(clientId, locationId, reportId)
          //   )
          // );
          window.location.href = routes.addRecommendation.getPath(
            clientId,
            locationId,
            reportId
          );
        }
        if (mode === "notify") {
          yield put(push(routes.recommendations.getPath()));
        }
        yield put(
          updateRecommendationActions.success({
            recWithAttachments: newRecWithAttachments,
            mode,
          })
        );
      } catch (err: any) {
        handleAxiosError(err);

        yield put(updateRecommendationActions.error(err));
        onError && onError(err);
      }
    }
  );
}

export function* responseShowHideSaga() {
  yield takeEvery(
    responseShowHideActions.trigger.type,
    function* (a: ReturnType<typeof responseShowHideActions.trigger>) {
      const { response } = a.payload;
      yield put(responseShowHideActions.loading());
      try {
        const res = yield call(API.responseShowHide, {
          locationId: response.locationId,
          reportId: response.reportId,
          recommendationId: response.recommendationId,
          responseId: response.responseId,
          showHide: !response.hideFromInsurer,
        });
        const updatedResponse = res.data;
        toast.success(
          i18next.t("greco.notification.update.success", {
            object: `${i18next.t("ram.responsePage.response").toLowerCase()}`,
          })
        );
        yield put(
          responseShowHideActions.success({
            response: updatedResponse,
          })
        );
      } catch (err: any) {
        handleAxiosError(err);
        yield put(responseShowHideActions.error(err));
        //onError && onError(err);
      }
    }
  );
}

export function* assessmentShowHideSaga() {
  yield takeEvery(
    assessmentShowHideActions.trigger.type,
    function* (a: ReturnType<typeof assessmentShowHideActions.trigger>) {
      const { assessment } = a.payload;
      yield put(assessmentShowHideActions.loading());
      try {
        const res: any = yield call(API.assessmentShowHide, {
          locationId: assessment.locationId,
          reportId: assessment.reportId,
          recommendationId: assessment.recommendationId,
          assessmentId: assessment.assessmentId,
          showHide: !assessment.hideFromInsurer,
        });
        const updatedAssessment = res.data;
        toast.success(
          i18next.t("greco.notification.update.success", {
            object: `${i18next.t("ram.riskAssessment.label").toLowerCase()}`,
          })
        );
        yield put(
          assessmentShowHideActions.success({
            assessment: updatedAssessment,
          })
        );
      } catch (err: any) {
        handleAxiosError(err);
        yield put(assessmentShowHideActions.error(err));
        //onError && onError(err);
      }
    }
  );
}

export function* loadDefaultValuesSaga() {
  yield takeEvery(loadDefaultValuesActions.trigger.type, function* (a: any) {
    const locationId = a.payload;
    try {
      yield put(loadDefaultValuesActions.loading());
      const res = yield call(API.getDefaultValues, locationId);
      yield put(loadDefaultValuesActions.success(res.data));
    } catch (err: any) {
      console.log(err);
      yield put(loadDefaultValuesActions.error(err));
    }
  });
}

export function* loadResponsesSaga() {
  yield takeEvery(loadResponsesRoutine.trigger, function* (a) {
    const { locationId, reportId, recommendationId } = a.payload;
    try {
      yield put(loadResponsesRoutine.loading());
      const res = yield call(
        API.getResponsesWithAttachments,
        locationId,
        reportId,
        recommendationId
      );
      yield put(loadResponsesRoutine.success(res.data));
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status === 403 || err?.response?.status === 404) {
        yield put(replace("/clients"));
      }
      yield put(loadResponsesRoutine.error(err));
    }
  });
}

export function* loadResponsesAndEvaluationsSaga() {
  yield takeEvery(loadResponsesAndEvaluationsRoutine.trigger, function* (a) {
    const { locationId, reportId, recommendationId } = a.payload;
    try {
      yield put(loadResponsesAndEvaluationsRoutine.loading());
      const res = yield call(
        API.getClientResponsesAndInsurerEvaluationsWithAttachments,
        locationId,
        reportId,
        recommendationId
      );
      yield put(loadResponsesAndEvaluationsRoutine.success(res.data));
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status === 403 || err?.response?.status === 404) {
        yield put(replace("/clients"));
      }
      yield put(loadResponsesAndEvaluationsRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* loadEvaluationsSaga() {
  yield takeEvery(loadEvaluationsRoutine.trigger, function* (a) {
    const { locationId, reportId, recommendationId } = a.payload;
    try {
      yield put(loadEvaluationsRoutine.loading());
      const res = yield call(
        API.getEvaluationsWithAttachments,
        locationId,
        reportId,
        recommendationId
      );
      yield put(loadEvaluationsRoutine.success(res.data));
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status === 403 || err?.response?.status === 404) {
        yield put(replace("/clients"));
      }
      yield put(loadEvaluationsRoutine.error(err));
    }
  });
}

export function* assessRecommendationSaga() {
  yield takeEvery(assessRecommendationRoutine.trigger, function* (a) {
    const { locationId, reportId, recommendationId, data, mode } = a.payload;
    try {
      yield put(assessRecommendationRoutine.loading());
      const res = yield call(API.assessRecommendation, {
        locationId,
        reportId,
        recommendationId,
        data,
      });
      yield put(assessRecommendationRoutine.success(res.data));
      toast.success(
        i18next.t("ram.notification.assessment.success", {
          recommendation: res.data.title,
        })
      );
      if (mode === "notify") {
        yield put(push(routes.recommendations.getPath()));
      }
    } catch (err: any) {
      console.log(err);
      yield put(assessRecommendationRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* deleteResponseSaga() {
  yield takeEvery(deleteResponseRoutine.trigger, function* (a) {
    const { locationId, reportId, recommendationId, responseId } = a.payload;
    try {
      yield put(deleteResponseRoutine.loading());
      const res = yield call(API.deleteResponse, {
        locationId,
        reportId,
        recommendationId,
        responseId,
      });
      yield put(deleteResponseRoutine.success(res.data));
      yield put(
        loadResponsesAndEvaluationsRoutine.trigger({
          locationId,
          recommendationId,
          reportId,
        })
      );
      toast.success(i18next.t("greco.success"));
      yield put(
        setDeleteResponseDialog({
          isOpen: false,
          response: null,
        })
      );
    } catch (err: any) {
      yield put(deleteResponseRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* deleteAssessmentSaga() {
  yield takeEvery(deleteAssessmentRoutine.trigger, function* (a) {
    const { locationId, reportId, recommendationId, assessmentId } = a.payload;
    try {
      yield put(deleteAssessmentRoutine.loading());
      const res = yield call(API.deleteAssessment, {
        locationId,
        reportId,
        recommendationId,
        assessmentId,
      });
      yield put(deleteAssessmentRoutine.success(res.data));
      yield put(
        loadResponsesAndEvaluationsRoutine.trigger({
          locationId,
          recommendationId,
          reportId,
        })
      );
      toast.success(i18next.t("greco.success"));
      yield put(
        setDeleteAssessmentDialog({
          isOpen: false,
          assessment: null,
        })
      );
    } catch (err: any) {
      yield put(deleteAssessmentRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* deleteEvaluationSaga() {
  yield takeEvery(deleteEvaluationRoutine.trigger, function* (a) {
    const { locationId, reportId, recommendationId, evaluationId } = a.payload;
    try {
      yield put(deleteEvaluationRoutine.loading());
      const res = yield call(API.deleteEvaluation, {
        locationId,
        reportId,
        recommendationId,
        evaluationId,
      });
      yield put(deleteEvaluationRoutine.success(res.data));
      yield put(
        loadResponsesAndEvaluationsRoutine.trigger({
          locationId,
          recommendationId,
          reportId,
        })
      );
      toast.success(i18next.t("greco.success"));
      yield put(
        setDeleteEvaluationDialog({
          isOpen: false,
          evaluation: null,
        })
      );
    } catch (err: any) {
      yield put(deleteEvaluationRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* reportDocumentSaga() {
  yield takeEvery(reportDocumentRoutine.trigger, function* (a) {
    const historyLocation = yield select((s: any) => s.router.location);
    const editRecommendationMatch =
      routes.editRecommendation.matchPath(historyLocation);

    const { clientId, locationId, recommendationId } =
      editRecommendationMatch?.params;
    try {
      yield put(reportDocumentRoutine.loading());
      const res = yield call(API.reportDocument, {
        clientId,
        locationId,
        recommendationId,
      });
      yield put(reportDocumentRoutine.success(res.data));

      toast.success(i18next.t("greco.success"));
      // yield put(
      //   setReportDocumentDialog({
      //     isOpen: false,
      //   })
      // );
    } catch (err: any) {
      yield put(reportDocumentRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* exportToZipSaga() {
  yield takeEvery(exportToZipRoutine.trigger, function* (a) {
    const { recommendationId, locationId } = a.payload;
    try {
      yield put(exportToZipRoutine.loading());
      const res = yield call(
        API.exportRecommendationToZip,
        locationId,
        recommendationId
      );
      toast.success(i18next.t("greco.success"));
      // yield put(
      //   setExportToZipDialog({
      //     isOpen: false,
      //     recommendationId: null,
      //     locationId: null,
      //   })
      // );
      yield put(exportToZipRoutine.success(res.data));
    } catch (err) {
      yield put(exportToZipRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* recommendationPageSaga() {
  yield fork(updateRecommendationSaga);
  yield fork(createRecommendationSaga);
  yield fork(loadDefaultValuesSaga);
  yield fork(loadResponsesSaga);
  yield fork(loadEvaluationsSaga);
  yield fork(assessRecommendationSaga);
  yield fork(deleteResponseSaga);
  yield fork(deleteAssessmentSaga);
  yield fork(deleteEvaluationSaga);
  yield fork(reportDocumentSaga);
  yield fork(responseShowHideSaga);
  yield fork(assessmentShowHideSaga);
  yield fork(loadResponsesAndEvaluationsSaga);
  yield fork(exportToZipSaga);
}

export const selectCanAddResponse = createSelector(
  (s: any) => s.recommendationPage.rec,
  (s: any) => s.taxonomy,
  (s: any) => s.recommendationPage.responses,
  (s: any) => s.recommendationPage.responsesLoadStatus,
  (rec: any, taxonomy: any, responses: any, responsesLoadStatus) => {
    if (!rec) return false;
    const recommendationStatusCode =
      taxonomy.RiskRecommendationStatus.byId[rec?.recommendationStatusCode]
        ?.code;
    if (!recommendationStatusCode) return false;

    const isStatusWaitingForClient =
      recommendationStatusCode ===
      "RiskRecommendationStatus.WAITING_FOR_CLIENT";

    if (responsesLoadStatus === "none" || responsesLoadStatus === "loading")
      return false;

    const hasDraft = responses.some((r) => !r.clientResponse.isArchived);

    return isStatusWaitingForClient && !hasDraft;
  }
);

export const selectCanAddAssessment = createSelector(
  (s: any) => s.recommendationPage.rec,
  (s: any) => s.taxonomy,
  (s: any) => s.recommendationPage.assessments,
  (s: any) => s.recommendationPage.assessmentsLoadStatus,
  (rec: any, taxonomy: any, assessments: any, assessmentsLoadStatus: any) => {
    if (!rec) return false;
    const recommendationStatusCode =
      taxonomy.RiskRecommendationStatus.byId[rec?.recommendationStatusCode]
        ?.code;
    if (!recommendationStatusCode) return false;

    const isStatusWaitingForClient =
      recommendationStatusCode ===
      "RiskRecommendationStatus.WAITING_FOR_CLIENT";

    if (assessmentsLoadStatus === "none" || assessmentsLoadStatus === "loading")
      return false;

    const hasDraft = assessments.some((r) => !r.assessment.isArchived);

    return isStatusWaitingForClient && !hasDraft;
  }
);

export const selectCanNotify = createSelector(
  (s: any) => s.recommendationPage,
  (s: any) => s.locations,
  (recommendationPageState: any, locationsState: any) => {
    const loc =
      locationsState.entities[recommendationPageState.rec?.locationId];
    const hasAssessmentPeriodStart = loc?.assessmentPeriodStartedAt !== null;

    const isStatusGREG =
      recommendationPageState.rec?.recommendationStatusCode === 1;

    return !hasAssessmentPeriodStart && isStatusGREG;
  }
);
