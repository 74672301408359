import React, { useEffect, useRef, useState } from "react";

import { Selection, SelectionMode } from "@fluentui/react/lib/Selection";
import { useDispatch } from "react-redux";
import { selectShownClients } from "../../store/clients-page/clientsPage";
import { loadClients } from "../../store/clients/clients";
import { AppDispatch } from "../../store/store";

import { useBoolean, useConst } from "@fluentui/react-hooks";
import { useSelector } from "../../store/hooks";
import { Navigation } from "./Navigation";

import {
  ScrollablePane,
  ScrollbarVisibility,
} from "@fluentui/react/lib/ScrollablePane";
import { ContextButtonForTree } from "./ContextButtonForTree";

import { useTranslation } from "react-i18next";
import { ReactComponent as CompanyIcon } from "../../assets/icons/company.svg";
import { useWindowSize } from "../../utils/useWindowSize";
import { SetRatingLimitsDialog } from "./SetRatingLimitsDialog/SetRatingLimitsDialog";

//import { defaultCols } from "../clients/cols";

import "./ClientTree.scss";

import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsHeaderProps,
  IGroupHeaderProps,
  IRenderFunction,
  IconButton,
  MarqueeSelection,
} from "@fluentui/react";

import { TableSpinner } from "../../components/TableSpinner";

import { Sticky, StickyPositionType } from "@fluentui/react/lib/Sticky";

import { CenteredCell } from "../../components/styled";
import { ReportDocumentDialog } from "./ReportDocumentDialog";
import { UsersReportDocumentDialog } from "./UsersReportDocumentDialog";

export interface IItem {
  key: number;
  name: string;
  companyid: string;
}

export interface IDetailsListDocumentsExampleState {
  columns: IColumn[];
  items: ITree[];
  selectionDetails: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
  announcedMessage?: string;
}

export interface ITree {
  id: number;
  name: string;
  parentid: number;
  startIndex: number;
  count: number;
  level: number;

  children: any[];
  companyid: number;
  countryCodeID: number;
  costCenterCodeID: number;
  goodRangeMin: number;
  moderateRangeMin: number;
  poorRangeMin: number;
  number: number;
}

export interface IButtonExampleProps {
  // These are set based on the toggles shown above the examples (not needed in real code)
  disabled?: boolean;
  checked?: boolean;
}

export interface IWidths {
  col: {
    key: string;
    value: number;
  }[];
}

export let ClientsTree = () => {
  const clients = useSelector(selectShownClients as any) as any[];
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const company = useSelector((s) => s.clients);
  const [dark, setDark] = useState(true);

  const [items, setItems] = useState([]);
  const [groups, setGroups] = useState(null);
  const [firstGroup, setFirstGroup] = useState(null);
  const dispatch: AppDispatch = useDispatch();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const reportDocumentDialog = useSelector(
    (s) => s.clientsPage.reportDocumentDialog
  );
  const filteredClients = Object.values(useSelector((s) => s.clients.entities));

  const usersReportDocumentDialog = useSelector(
    (s) => s.clientsPage.usersReportDocumentDialog
  );

  useEffect(() => {
    dispatch(loadClients.trigger());
  }, []);

  const columns = [
    {
      key: "name",
      name: t("ram.clientsList.clientName"),
      fieldName: "name",
      minWidth: 303.5,
      maxWidth: 303.5,
      isResizable: false,
      styles: { cellTitle: { justifyContent: "left" } },
    },
    {
      key: "column2",
      name: "",
      fieldName: "companyName",
      minWidth: 12,
      maxWidth: 12,
      isResizable: false,
      isSorted: false,
      styles: { cellTitle: { justifyContent: "left" } },
    },
    {
      key: "companyId",
      name: t("ram.clientsList.clientId"),
      fieldName: "companyId",
      minWidth: 233.5,
      maxWidth: 233.5,
      isResizable: false,
      isSorted: false,
      styles: { cellTitle: { justifyContent: "center" } },
    },
    {
      key: "country",
      name: t("ram.clientList.countryCode"),
      fieldName: "country",
      minWidth: 233.5,
      maxWidth: 233.5,
      isResizable: false,
      isSorted: false,
      styles: { cellTitle: { justifyContent: "center" } },
    },
    {
      key: "costCenter",
      name: t("ram.clientsList.costCenterCode"),
      fieldName: "costCenter",
      minWidth: 233.2,
      maxWidth: 233.2,
      isResizable: false,
      isSorted: false,
      styles: { cellTitle: { justifyContent: "center" } },
    },
    {
      key: "goodRateMin",
      name: t("ram.clientsList.goodRangeMin"),
      fieldName: "goodRateMin",
      minWidth: 233.5,
      maxWidth: 233.5,
      isResizable: false,
      isSorted: false,
      styles: { cellTitle: { justifyContent: "center" } },
    },
    {
      key: "moderateRateMin",
      name: t("ram.clientsList.moderateRangeMin"),
      fieldName: "moderateRateMin",
      minWidth: 233.5,
      maxWidth: 233.5,
      isResizable: false,
      isSorted: false,
      styles: { cellTitle: { justifyContent: "center" } },
    },
    {
      key: "poorRateMin",
      name: t("ram.clientsList.poorRangeMin"),
      fieldName: "poorRateMin",
      minWidth: 233.5,
      maxWidth: 233.5,
      isResizable: false,
      isSorted: false,
      styles: { cellTitle: { justifyContent: "center" } },
    },
  ];

  const companyNameRef = useRef(null);

  useEffect(() => {
    if (localStorage.getItem("darkMode") === "true") {
      setDark(true);
    } else {
      setDark(false);
    }
  });

  const setRatingLimitsDialog = useSelector(
    (s) => s.clientsPage.setRatingLimitsDialog
  );

  useEffect(() => {
    let newArr = [...searchParentNodes(), ...searchChildrenNodes()];
    newArr = uniqueTreeElements(newArr, (it) => it.clientId);

    setItems(
      newArr.map((c, index) => {
        return {
          id: c.clientId,
          name: c.clientName,
          parentid: c.parentClientId,
          startIndex: 0,
          count: 0,
          level: 0,
          isCollapsed: false,
          children: [],
          companyid: c.clientId,
          countryCodeID: c.countryCodeID,
          costCenterCodeID: c.costCenterCodeID,
          goodRangeMin: c.goodRangeMin,
          moderateRangeMin: c.moderateRangeMin,
          poorRangeMin: c.poorRangeMin,
          data: [
            { number: 0 }, //0
            { level: 0 }, //1
            { companyName: c.clientName }, //2
            { companyId: c.clientId }, //3
            { parentCompany: c.parentClientId }, //4
            { country: c.countryCodeID }, //5
            { costCenter: c.costCenterCodeID }, //6
            { goodRate: c.goodRangeMin }, //7
            { moderateRate: c.moderateRangeMin }, //8
            { poorRate: c.poorRangeMin }, //9
          ],
        };
      })
    );

    if (firstGroup === null && groups !== null && groups.length !== 0) {
      setFirstGroup(groups);
    }
  }, [clients]);

  useEffect(() => {
    setGroups(createTree(items));
  }, [items]);

  //---------------------------SEARCHING AND FILTERING FUNCTIONS----------------------------------------------------

  const searchChildrenNodes = () => {
    let tree = [];
    clients
      ?.filter((item) => item !== undefined)
      .forEach((c) => {
        tree.push(c);
        fillInChildren(c, tree);
      });

    return tree;
  };

  const fillInChildren = (topc, children) => {
    let immediateChildren = [];
    filteredClients.map((f) => {
      if (topc.clientId === f.parentClientId) {
        immediateChildren.push(f);
        if (!children.includes(f)) {
          children.push(f);
        }
      }
    });

    immediateChildren.forEach((ic) => {
      fillInChildren(ic, children);
    });
  };

  function getDepth(array) {
    return (
      1 + Math.max(0, ...array.map(({ children = [] }) => getDepth(children)))
    );
  }

  const searchParentNodes = () => {
    let tree = [];

    clients
      .filter((item) => item !== undefined)
      .map((c) => {
        if (!tree.includes(c)) {
          tree.push(c);
        }
        var node = filteredClients.find((x) => x.clientId === c.parentClientId);
        if (node !== undefined && !tree.includes(node)) {
          tree.push(node);
        }
        if (node !== undefined) {
          while (node.parentClientId !== null) {
            node = filteredClients.find(
              (x) => x.clientId === node.parentClientId
            );
            if (!tree.includes(node)) {
              tree.push(node);
            }
          }
        } else {
          return null;
        }
      });
    return tree;
  };

  function uniqueTreeElements(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  // -------------------------FUNCTIONS FOR DEEP NESTING--------------------------------------------
  function depthDecorator(array, level = 0) {
    return array.map((child) =>
      Object.assign(child, {
        level,
        children: depthDecorator(child.children || [], level + 1),
      })
    );
  }

  function numberNodes(tree, n = 0) {
    return (function recurse(children) {
      return children.map((node) =>
        Object.assign({}, node, {
          data: [...node.data, { number: n++ }],
          children: recurse(node.children),
        })
      );
    })(tree);
  }

  const createTree = (arr) => {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.id] = arrElem;
      mappedArr[arrElem.id]["children"] = [];
    }

    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];

        if (mappedElem.parentid) {
          mappedArr[mappedElem["parentid"]]["children"].push(mappedElem);
        } else {
          tree.push(mappedElem);
        }
      }
    }

    tree.sort(function (x, y) {
      let a = x.name?.toUpperCase(),
        b = y.name?.toUpperCase();
      return a == b ? 0 : a > b ? 1 : -1;
    });

    tree = depthDecorator(tree);
    tree = numberNodes(tree);

    return tree;
  };

  //-----------------------------COLUMNS FOR LIST-----------------------------------

  const [isCompactMode] = useBoolean(false);
  const selection = useConst(() => {
    const s = new Selection();
    s.setItems(items, true);
    return s;
  });

  //-----------------------------ROW RENDERING--------------------------------------------
  const onRenderHeader = (props?: IGroupHeaderProps): JSX.Element | null => {
    if (props) {
      const toggleCollapse = (): void => {
        props.onToggleCollapse!(props.group!);
      };

      return (
        <div
          className={dark ? "item_dark" : "item"}
          style={
            dark
              ? {
                  backgroundColor:
                    props.group.data[10].number % 2 ? "#171717" : "#02070a",
                }
              : {
                  backgroundColor:
                    props.group.data[10].number % 2 ? "#FFFFFF" : "#F1F7FB",
                }
          }
        >
          {<span style={{ paddingLeft: `${props.group?.level}7px` }}></span>}

          {props.group?.isCollapsed ? (
            props.group?.children.length !== 0 ? (
              <IconButton
                className="item__arrowicon"
                iconProps={{ iconName: "ChevronRightMed" }}
                title="Expand"
                ariaLabel="Expand"
                onClick={toggleCollapse}
              />
            ) : (
              <CompanyIcon
                style={{
                  margin: "8.5px",
                  display: "flex",
                  justifyContent: "center",
                  flexShrink: 0,
                }}
              />
            )
          ) : props.group?.children.length !== 0 ? (
            <IconButton
              className="item__arrowicon"
              iconProps={{ iconName: "ChevronDownMed" }}
              title="Collapse"
              ariaLabel="Collapse"
              onClick={toggleCollapse}
              styles={{ root: {} }}
            />
          ) : (
            <CompanyIcon
              style={{
                margin: " 8.5px",
                display: "flex",
                justifyContent: "center",

                flexShrink: 0,
              }}
            />
          )}

          <span
            id="companyName"
            style={{
              marginRight: `-${props.group?.level}7px`,
              paddingRight: "70px",
              display: "flex",
              flex: 1,
            }}
          >
            {props.group?.data[2].companyName}
          </span>

          <span
            id="kebabMenu"
            style={{ margin: "0px", display: "flex", boxSizing: "content-box" }}
          >
            <CenteredCell>
              <ContextButtonForTree
                clientId={props.group?.data[3].companyId}
                poorRangeMin={props.group?.data[9].poorRate}
                moderateRangeMin={props.group?.data[8].moderateRate}
                goodRangeMin={props.group?.data[7].goodRate}
              />
            </CenteredCell>
          </span>

          <span id="companyId" className="item__field">
            {props.group?.data[3].companyId}
          </span>

          <span id="country" className="item__field">
            {t(taxonomy.Country.byId[props.group?.data[5].country]?.code)}
          </span>

          <span id="costCenter" className="item__field">
            {props.group?.data[6].costCenter !== null ? (
              t(taxonomy.CostCenter.byId[props.group?.data[6].costCenter]?.code)
            ) : (
              <span className="item__misingMandate">
                {t("ram.clientsList.missingMandate")}
              </span>
            )}
          </span>

          <span id="goodRate" className="item__field">
            {props.group?.data[7].goodRate}
          </span>

          <span id="moderateRate" className="item__field">
            {props.group?.data[8].moderateRate}
          </span>

          <span id="poorRate" className="item__field">
            {props.group?.data[9].poorRate}
          </span>
        </div>
      );
    }

    return null;
  };

  const groupedListProps = {
    onRenderHeader,
    showEmptyGroups: true,
    isAllGroupsCollapsed: true,
    // isHeaderVisible: true,
  };

  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }

    return (
      <Sticky
        stickyClassName="sticky"
        stickyPosition={StickyPositionType.Header}
        isScrollSynced
      >
        {defaultRender!({
          styles: { root: { paddingTop: 0 } },
          ...props,
        })}
      </Sticky>
    );
  };

  const areClientsLoading = useSelector((s) => s.clients.isLoading);

  return (
    <div className="clients_page">
      <Navigation />
      {
        clients && clients?.length > 0 ? (
          <div
            style={{
              position: "relative",
              minHeight: windowHeight - 97,
              marginTop: "0px",
              paddingTop: "0px",
              display: "absolute",
            }}
          >
            <ScrollablePane
              scrollbarVisibility={ScrollbarVisibility.auto}
              initialScrollPosition={0}
              style={{ overflowX: "visible" }}
              //  scrollContainerFocus={true}
            >
              <MarqueeSelection selection={selection}>
                <div className="item_list" ref={companyNameRef}>
                  <DetailsList
                    // onRenderDetailsHeader={() => { return <div>Test 1234</div>}}
                    items={items}
                    columns={columns}
                    isHeaderVisible={true}
                    selection={selection}
                    selectionMode={SelectionMode.none}
                    groups={groups}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    constrainMode={ConstrainMode.unconstrained}
                    selectionPreservedOnEmptyClick
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    // compact={true}
                    isPlaceholderData={true}
                    groupProps={groupedListProps}
                    styles={{
                      headerWrapper: {
                        //  position: "fixed",
                        width: "100%",

                        paddingTop: 0,
                        zIndex: 1,

                        selectors: {
                          div: { position: "relative", paddingTop: 0 },
                        },
                      },
                      focusZone: {
                        //marginTop: 0,
                        // overflow: "auto",
                      },
                      contentWrapper: { paddingBottom: 5 },
                      root: { flexShrink: 0, marginBottom: 0 },
                    }}
                  />
                </div>
              </MarqueeSelection>
            </ScrollablePane>
            {areClientsLoading && <TableSpinner />}
            {setRatingLimitsDialog.isOpen && <SetRatingLimitsDialog />}
            {reportDocumentDialog.isOpen && <ReportDocumentDialog />}
            {usersReportDocumentDialog.isOpen && <UsersReportDocumentDialog />}
          </div>
        ) : null //<Spinner size={SpinnerSize.large} />
      }
    </div>
  );
};
