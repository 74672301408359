import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  ConstrainMode,
  DetailsListLayoutMode,
  DetailsRow,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  IRenderFunction,
  mergeStyleSets,
  ScrollablePane,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  TooltipHost,
} from "@fluentui/react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { TableSpinner } from "../../components/TableSpinner";
import { useSelector } from "../../store/hooks";
import {
  selectShownLocations,
  setSortLocations,
} from "../../store/locations-page/locationsPage";
import {
  loadLocationsActions,
  setSelectedColumn,
} from "../../store/locations/locations";
import { AppDispatch } from "../../store/store";
import { ClientLocation } from "../../types/types";
import { defaultColumns } from "./columns/columns";
import { DeleteDialog } from "./DeleteDialog";
import { ExportToZipDialog } from "./ExportToZipDialog";
import { GenerateLocationReportDocumentDialog } from "./GenerateLocationReportDocumentDialog/GenerateLocationReportDocumentDialog";
import "./Locations.scss";
import { Navigation } from "./navigation/Navigation";
import { Summary } from "./Summary";

import { ChangeClientDialog } from "./ChangeClientDialog";

import { useConst } from "@uifabric/react-hooks";
import _ from "lodash";
import { useTheme } from "styled-components";
import { IStyledTheme } from "../../theme/types";
import { UsersReportDocumentDialog } from "./UsersReportDocumentDialog";
import { CenteredCell } from "components/styled";

export type PanelState = {
  isOpen: boolean;
  loc: ClientLocation | null;
};

export const Locations = () => {
  const { t } = useTranslation();
  const locations = useSelector(selectShownLocations);
  const sort = useSelector((s) => s.locationsPage.sort);
  const dispatch: AppDispatch = useDispatch();
  const areLocationsLoading = useSelector((s) => s.locations.isLoading);
  const deleteDialog = useSelector((s) => s.locationsPage.deleteLocationDialog);
  const changeClientDialog = useSelector(
    (s) => s.locationsPage.changeClientDialog
  );
  const usersReportDocumentDialog = useSelector(
    (s) => s.locationsPage.usersReportDocumentDialog
  );

  // const selectedColumn = useSelector((s) => s.locations.selectedColumn);

  const [panel, setPanel] = useState<PanelState>({
    isOpen: false,
    loc: null,
  });
  const exportToZipDialog = useSelector(
    (s) => s.locationsPage.exportToZipDialog
  );
  const generateLocationReportDocumentDialog = useSelector(
    (s) => s.locationsPage.generateLocationReportDocumentDialog
  );

  const theme = useTheme() as IStyledTheme;
  const classes = getClassNames(theme);

  const selection = useConst(
    () =>
      new Selection({
        onSelectionChanged: async () => {
          const selected = selection.getSelection() as any;
          dispatch(setSelectedColumn(selected[0]));
        },
      })
  );

  useEffect(() => {
    dispatch(loadLocationsActions.trigger());
  }, []);

  const detailsListColumns = useMemo(() => {
    const withTranslatedHeader = defaultColumns
      .map((column: any) => {
        let iconName = column.iconName;

        if (column.key === sort.key && column.isSortable) {
          iconName =
            sort.key === column.key
              ? sort!.dir === "asc"
                ? "SortUp"
                : "SortDown"
              : "Sort";
        }
        return {
          ...column,
          name: t(column.name),
          iconName: iconName,
        };
      })
      .concat([
        {
          key: "filler",
          label: "",
          width: 0,
        },
      ])
      .map((column) => ({
        ...column,
        minWidth: column.isResizable ? 0 : column.minWidth,
        maxWidth: column.isResizable
          ? column.maxWidth < column.minWidth
            ? column.maxWidth
            : column.minWidth
          : column.maxWidth, //getColumnContentWidth(locations, column.fieldName),
      }));
    return [...withTranslatedHeader];
  }, [sort, t, locations]);

  const onClickHeaderCell = (column) => {
    if (!column.isSortable) return;
    const isSorted = sort.key === column.key;
    if (isSorted) {
      if (sort.dir === "asc") {
        dispatch(
          setSortLocations({
            key: column.key,
            dir: "desc",
          })
        );
      }
      //
      else {
        dispatch(
          setSortLocations({
            key: "locationCreatedAt",
            dir: "desc",
          })
        );
      }
    }
    //
    else {
      dispatch(
        setSortLocations({
          key: column.key,
          dir: "asc",
        })
      );
    }
  };

  const onRenderColumnHeaderTooltip: IRenderFunction<
    IDetailsColumnRenderTooltipProps
  > = (tooltipHostProps) => {
    return (
      <TooltipHost
        {...tooltipHostProps}
        content={tooltipHostProps?.column?.name}
      />
    );
  };

  const onRenderDetailsHeader = (
    props: IDetailsHeaderProps | undefined,
    defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
  ) => (
    <Sticky
      stickyPosition={StickyPositionType.Header}
      isScrollSynced
      stickyClassName={classes.table}
    >
      {defaultRender!({
        ...props,
        onRenderColumnHeaderTooltip,
      })}
    </Sticky>
  );

  const handleSetSummaryPanel = useCallback((value) => {
    setPanel(value);
  }, []);

  const onRenderRow = (props) => {
    return <DetailsRow {...props} />;
  };

  return (
    <>
      <Navigation />
      <div className={classes.container} id="scrollableDiv">
        <ScrollablePane
          styles={{
            stickyAbove: {
              zIndex: 3,
            },
          }}
        >
          <ShimmeredDetailsList
            items={locations || []}
            compact={false}
            selection={selection}
            columns={detailsListColumns || []}
            selectionMode={SelectionMode.single}
            setKey="single"
            isSelectedOnFocus
            layoutMode={DetailsListLayoutMode.fixedColumns}
            isHeaderVisible={true}
            // onRenderItemColumn={(item, index, column) => {
            //   if (column.key === "options") {
            //     return (
            //       <FixedCell no={index} row={item}>
            //         <CenteredCell>
            //           <ContextButton loc={item} />
            //         </CenteredCell>
            //       </FixedCell>
            //     );
            //   }
            //   return column[index];
            // }}
            enableShimmer={areLocationsLoading && !locations}
            shimmerLines={20}
            selectionPreservedOnEmptyClick={true}
            onItemInvoked={(row) => {
              handleSetSummaryPanel({ isOpen: true, loc: row });
            }}
            enterModalSelectionOnTouch={true}
            // onItemContextMenu={onItemContextMenu}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            constrainMode={ConstrainMode.unconstrained}
            onColumnHeaderClick={(ev, column) => {
              onClickHeaderCell(column);
            }}
            onRenderRow={onRenderRow}
            className={classes.table}
            onRenderDetailsHeader={(
              props: IDetailsHeaderProps | undefined,
              defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
            ) => onRenderDetailsHeader(props, defaultRender)}
          />
        </ScrollablePane>
      </div>
      {deleteDialog.isOpen && <DeleteDialog />}
      {changeClientDialog.isOpen && <ChangeClientDialog />}
      {areLocationsLoading && <TableSpinner />}
      {exportToZipDialog.isOpen && <ExportToZipDialog />}
      {generateLocationReportDocumentDialog.isOpen && (
        <GenerateLocationReportDocumentDialog />
      )}
      {usersReportDocumentDialog.isOpen && <UsersReportDocumentDialog />}

      {panel.isOpen && (
        <Summary panel={panel} setPanel={handleSetSummaryPanel} />
      )}
    </>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    container: {
      // height: "90vh",
      height: "calc(100vh  - 100px)",

      marginBottom: "20px",
      position: "relative",
      overflow: "auto",
      width: "100%",
    },
    listContainer: {
      display: "flex",
    },

    table: {
      ".ms-List-cell:nth-child(odd) .ms-DetailsRow": {
        background: theme.isDark
          ? "rgb(2, 7, 10)"
          : theme.palette?.themeLighterAlt,
      },
      ".ms-DetailsRow ": {
        height: "30px !important",
      },

      ".ms-DetailsHeader-cellTitle": {
        justifyContent: "flex-start",
      },
      "[data-item-key='fleetName'] span": {
        justifyContent: "flex-start",
      },

      ".ms-DetailsRow-cell": {
        display: "flex",
        justifyContent: "flex-start",
      },
      ".ms-DetailsRow-cell:first-child": {
        display: "flex",
        justifyContent: "flex-start",
      },

      ".ms-DetailsHeader-cellName i": {
        marginRight: "5px",
        fontSize: "smaller",
        fontWeight: "400",
      },

      //******************************************************************************************************* */
      ///header cell

      ".ms-DetailsHeader-cell:nth-child(1)": {
        position: "sticky",
        inset: 0,
        zIndex: 99999,
        background: theme.palette.white,
      },

      ".ms-DetailsHeader-cell:nth-child(2)": {
        position: "sticky",
        inset: 48,
        zIndex: 99999,
        background: theme.palette.white,
      },
      ".ms-DetailsHeader-cell:nth-child(3)": {
        position: "sticky",
        inset: 108,
        zIndex: 99999,
        background: theme.palette.white,
      },
      ".ms-DetailsHeader-cell:nth-child(4)": {
        position: "sticky",
        inset: 370,
        zIndex: 99999,
        background: theme.palette.white,
      },

      ".ms-DetailsHeader-cell:nth-child(2):hover, .ms-DetailsHeader-cell:nth-child(3):hover, .ms-DetailsHeader-cell:nth-child(4):hover":
        {
          backgroundColor: theme.palette.neutralLighter,
        },

      /// row cell

      ".ms-DetailsRow-cell:nth-child(1)": {
        position: "sticky",
        left: 48,
        zIndex: 99999,
        alignItems: "center",
      },

      ".ms-DetailsRow-cell:nth-child(2)": {
        position: "sticky",
        left: 108,
        zIndex: 99999,
      },

      ".ms-DetailsRow-cell:nth-child(3)": {
        position: "sticky",
        left: 370,
        zIndex: 99999,
      },

      // checkbox in row
      ".ms-DetailsRow-cellCheck:nth-child(1)": {
        position: "sticky",
        left: 0,
        zIndex: 1,
      },

      // ********************************************************* //
      ".ms-List-cell:nth-child(odd) .ms-DetailsRow-cell:nth-child(1)": {
        background: theme.palette?.themeLighterAlt,
      },

      ".ms-List-cell:nth-child(odd) .ms-DetailsRow-cell:nth-child(2)": {
        background: theme.palette?.themeLighterAlt,
      },

      ".ms-List-cell:nth-child(odd) .ms-DetailsRow-cell:nth-child(3)": {
        background: theme.palette?.themeLighterAlt,
      },

      ".ms-List-cell:nth-child(even) .ms-DetailsRow-cell:nth-child(1)": {
        background: theme.palette?.white,
      },

      ".ms-List-cell:nth-child(even) .ms-DetailsRow-cell:nth-child(2)": {
        background: theme.palette?.white,
      },

      ".ms-List-cell:nth-child(even) .ms-DetailsRow-cell:nth-child(3)": {
        background: theme.palette?.white,
      },

      ///
      ".ms-List-cell .ms-DetailsRow:hover": {
        backgroundColor: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
      },
      ".ms-List-cell:hover, .ms-List-cell .is-selected": {
        backgroundColor: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
      },

      //
      ".ms-List-cell:hover .ms-DetailsRow-cell > * ": {
        height: "41px",
        padding: "0 !important",
        backgroundColor: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
        position: "relative",
      },
      //

      ".ms-List-cell:hover, .ms-List-cell .is-selected .ms-DetailsRow-cell > *":
        {
          height: "41px",
          padding: "0 !important",
          backgroundColor: theme.isDark
            ? "rgb(9, 27, 41) !important"
            : "rgb(208, 231, 248) !important",
          position: "relative",
        },

      //
      ".ms-List-cell:hover .ms-DetailsRow-cell > *  .ms-DetailsRow-cell[class^='cellUnpadded'], , div[class*=' cellUnpadded']":
        {
          paddingRight: "0 !important",
          paddingLeft: "0 !important",
        },

      ".ms-List-cell:hover, .ms-List-cell .is-selected .ms-DetailsRow-cell > *  .ms-DetailsRow-cell[class^='cellUnpadded'], , div[class*=' cellUnpadded']":
        {
          paddingRight: "0 !important",
          paddingLeft: "0 !important",
        },
    },
  });
