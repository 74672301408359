import { GrecoHeader } from "@greco/components";
import { User } from "@microsoft/microsoft-graph-types";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { logout, MSGraphAuth } from "../api/GraphService";
import { headerConfig } from "../config/headerConfig";
import Logo from "../assets/GrECo_Logo_small.jpg";
import { inContextEditorPostProcessor } from "../i18n";
import i18n from "../i18n";

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ISettingsToggle } from "@greco/components/dist/components/GrecoHeader/GrecoPanel/Settings/Settings";
import { useSelector } from "../store/hooks";
import { Setting, SupportedLanguage } from "../types/types";
import { addAppSetting, getAppSettings, updateAppSetting } from "../api/api";
import { IDropdownOption } from "@fluentui/react";
import { useTheme } from "styled-components";
import { IStyledTheme } from "../theme/types";
import { AppContext } from "../AppContext";
import {
  ErrorHandlerMessage,
  errorHandlerClasses,
} from "../utils/errorHandler";
import { ActionButton } from "@fluentui/react";
import { history } from "utils/_helpers";

const languageList: { [key: string]: string } = {
  "Language.EN_GB": "en-GB",
  "Language.DE_AT": "de-AT",
};

type Props = {
  user: UserHeader | null;
  darkMode: any;
  tooltipStatusChange?: any;
  tooltipsStatus?: any;
};

export type UserHeader = User & {
  photoSmall: string;
  photoMedium: string;
};

export const AppHeader = ({
  user,
  darkMode,
  tooltipStatusChange,
  tooltipsStatus,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [shouldShowPhrase, setShouldShowPhrase] = useState(false);
  const [appSettings, setAppSettings] = useState([]);
  const appContext = useContext(AppContext);

  const { notifications } = appContext;

  history.navigate = useNavigate();
  history.location = useLocation();
  history.params = useParams();

  const [phraseModalOpen, setPhraseModalOpen] = useState(
    inContextEditorPostProcessor.phraseEnabled
  );

  const theme = useTheme() as IStyledTheme;

  const applicationCodeTaxonomy = useSelector(
    (s) => s.taxonomy.ApplicationCode
  );
  const applicationSettingsTaxonomy = useSelector(
    (s) => s.taxonomy.ApplicationSetting
  );

  const supportedLangsTaxonomy = useSelector(
    (s) => s.taxonomy.SupportedLanguage
  );

  const applicationCodeId =
    applicationCodeTaxonomy.byCode["Application.RAM"]?.id;

  const appSettingCodeId =
    applicationSettingsTaxonomy?.byCode["AppSetting.UserSettings"]?.id;

  const phraseToggle: ISettingsToggle = {
    title: "Phrase",
    toggleProps: {
      styles: {
        root: {
          ".ms-Toggle-background": {
            background: phraseModalOpen
              ? theme.palette.themePrimary
              : theme.palette.white,
          },
        },
      },
      defaultChecked:
        !!new URLSearchParams(location.search).get("phrase_context") ||
        inContextEditorPostProcessor.phraseEnabled,
      onChange: (_event, checked) => {
        setPhraseModalOpen(checked);

        if (checked) {
          inContextEditorPostProcessor.phraseEnabled = true;
        } else {
          inContextEditorPostProcessor.phraseEnabled = false;
          const queryParams = new URLSearchParams(location.search);
          if (queryParams.get("phrase_context")) {
            queryParams.delete("phrase_context");
            navigate({
              pathname: location.pathname,
              search: queryParams.toString(),
            });
          }
          // Reload is needed because we have to remove
          // Phrase scripts from the client
          window.location.reload();
        }
      },
    },
  };

  const enableInContextEditor = async () => {
    const account = await MSGraphAuth.accountObj;

    if (!(account && account.idTokenClaims)) return false;

    const roles: string[] = (account.idTokenClaims as { roles: string[] })
      .roles;
    return !!(roles && roles.includes("PHRASE"));
  };

  useEffect(() => {
    (async () => {
      const shouldShowPhrase = await enableInContextEditor();

      setShouldShowPhrase(shouldShowPhrase);
    })();
  }, []);

  useEffect(() => {
    if (applicationCodeId) {
      getAppSettings(Number(applicationCodeId)).then((res) => {
        setAppSettings(res.data);
      });
    }
  }, [applicationCodeId]);

  return (
    <GrecoHeader
      headerConfig={headerConfig}
      user={user}
      logo={Logo}
      logout={logout}
      tooltipStatusChange={tooltipStatusChange}
      tooltipsStatus={tooltipsStatus}
      defaultLanguage={i18n.resolvedLanguage}
      onChangeLanguage={async (__event: any, languageOption?: any) => {
        if (!languageOption) return;

        const createData: any = {
          applicationCodeId: applicationCodeId,
          appSettingCodeId: appSettingCodeId,
          userAppSettingName: "Language",
          userAppSettingValue: languageOption.key,
          isDefault: true,
        };
        try {
          const userSettingsForViewOptions: any = appSettings
            .filter((item: any) => item.appSettingCodeId === appSettingCodeId)
            ?.find((userSetting: any) => userSetting.isDefault);

          if (userSettingsForViewOptions) {
            const editData = {
              applicationCodeId: userSettingsForViewOptions.applicationCodeId,
              appSettingCodeId: userSettingsForViewOptions.appSettingCodeId,
              userAppSettingId: userSettingsForViewOptions.userAppSettingId,
              userAppSettingName: userSettingsForViewOptions.userAppSettingName,
              userAppSettingDescription:
                userSettingsForViewOptions.userAppSettingDescription,
              userAppSettingValue: languageOption.key,
              isDefault: userSettingsForViewOptions.isDefault,
            } as Setting;

            await updateAppSetting(editData);
          } else await addAppSetting(createData);

          i18n.changeLanguage(languageOption.key as string);
        } catch (err) {
          i18n.changeLanguage(languageOption.key as string);
        }
      }}
      languageOptions={
        supportedLangsTaxonomy
          ? (supportedLangsTaxonomy?.items as SupportedLanguage[])
              .filter(
                (language: SupportedLanguage) =>
                  language.isGrECoLanguage &&
                  (language.code === "Language.EN_GB" ||
                    language.code === "Language.DE_AT")
              )
              .map<IDropdownOption>((language: SupportedLanguage) => ({
                key: languageList[language.code],
                text: t(language.code),
              }))
          : []
      }
      headerStrings={{
        notificationsPanelStrings: {
          panelTitle: t("header.Notifications"),
          noNotificationsTitle: t("header.NoNotificationsTitle"),
          noNotificationsSubtitle: t("header.NoNotificationsSubtitle"),
        },
        helpPanelStrings: {
          panelTitle: t("header.Help"),
        },
        settingsPanelStrings: {
          panelTitle: t("header.Settings"),
        },
        userSettingsPanelStrings: {
          panelTitle: t("header.UserSettings"),
          myOfficeProfileLinkText: t("header.MyOfficeProfile"),
          myAccountLinkText: t("header.UserSettings"),
          signOutLinkText: t("header.SignOut"),
        },
        searchPlaceholder: t("header.Search"),
      }}
      darkMode={darkMode}
      additionalSettingsToggles={shouldShowPhrase ? [phraseToggle] : undefined}
      showErrorAndWarningMessages={() => {
        return (
          <>
            {notifications?.map((item) => {
              return (
                <div
                  key={item.notify?.label}
                  onClick={item.handleNotificationClick}
                  className={errorHandlerClasses.notificationMessage}
                >
                  <ErrorHandlerMessage
                    notify={item.notify}
                    errors={null}
                    fieldName={null}
                  />
                </div>
              );
            })}
            <ActionButton
              iconProps={{ iconName: "RingerRemove" }}
              allowDisabledFocus
              styles={{
                root: { float: "right", margin: "10px 10px 0 0" },
              }}
              onClick={() => appContext.clearAllNotifications()}
            >
              {t("greco.clear")}
            </ActionButton>
          </>
        );
      }}
      messagesCount={notifications?.length}
    />
  );
};
