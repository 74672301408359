import { formatISO } from "date-fns";
import { TaxonomyKey, TaxonomyState } from "../store/taxonomy/taxonomy";
import { formatIntegerNumber, formatNumber } from "../utils/number";
import { stringToNumber } from "../utils/utils";
import { isArray } from "util";
import moment from "moment";

export const adjustTaxonomyValue = (
  id: number | null,
  taxonomy: TaxonomyState,
  taxonomyKey: TaxonomyKey,
  t: any
) => {
  return id !== null
    ? {
        label: t(taxonomy[taxonomyKey].byId[id]?.code),
        value: id,
      }
    : null;
};

export const adjustDateValue = (date?: string) => {
  return date ? new Date(date) : null;
};

export const adjustFloatNumberValue = (n?: number) => {
  return n !== null && n !== undefined ? formatNumber(n) : null;
};

export const adjustIntegerNumberValue = (n?: number) => {
  return n !== null && n !== undefined ? formatIntegerNumber(n) : null;
};

export const transformSelectValue = (value: any) => {
  if (value === null || value === undefined) {
    return null;
  }
  return value.value;
};
export const formatDoubleFieldValue = (value) =>
  value
    ?.toString()
    ?.replaceAll(" ", "")
    .replaceAll(".", "")
    ?.replaceAll(",", ".");

export const transformSelectValues = (value: any) => {
  if (value === null || value === undefined) {
    return null;
  }
  var ret = [];
  for (var i = 0; i < value.length; i++) {
    ret.push(value[i].key);
  }
  return ret;
};

export const transformDateValue = (value: any) => {
  if (value === null || value === undefined) {
    return null;
  }
  return formatISO(value, { representation: "date" });
};

export const transformNumberValue = (value: any) => {
  if (value === null || value === undefined) {
    return null;
  }
  return stringToNumber(value);
};

export const transformStringValue = (value: any) => {
  if (value === null || value === undefined || typeof value !== "string") {
    return null;
  }
  return value.trim();
};

export const transformForPayload = ({
  values,
  stringToNumberFields = [],
  dateFields = [],
}: {
  values: any;
  stringToNumberFields?: string[];
  dateFields?: string[];
}) => {
  const payload = {};
  Object.keys(values).forEach((key) => {
    if (values[key]?.label) {
      payload[key] = values[key]?.value;
    } else if (values[key] === undefined) {
      payload[key] = null;
    } else if (stringToNumberFields.includes(key)) {
      payload[key] = transformNumberValue(values[key]?.toString());
    } else if (dateFields.includes(key) && values[key]) {
      payload[key] = moment(values[key]).format("YYYY-MM-DD");
    } else {
      payload[key] = values[key];
    }
  });

  return payload;
};
