import {
  Persona,
  PersonaPresence,
  PersonaSize,
  Text,
  mergeStyleSets,
  Icon,
  IconButton,
  Label,
  Link,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../../store/hooks";
import {
  formatDate,
  getFileExtension,
  getFileIcon,
  getIconColor,
} from "../../../../utils/utils";
import moment from "moment";
import { Flex, Truncate } from "components/styled";
import styled from "styled-components";

export const Description = () => {
  const { t } = useTranslation();
  const recommendation = useSelector((s) => s.recommendationPage.rec);
  const attachments = useSelector((s) => s.recommendationPage.attachments);

  return (
    <div className={classes.container} id="responses-tab-description">
      {recommendation?.title ? (
        <div className={classes.gridLeft}>
          <div>
            <Label>{t("ram.recommendationForm.title.label")}</Label>
            <Text>{recommendation?.title} </Text>
          </div>
        </div>
      ) : null}
      <div className={classes.gridLeft}>
        <div>
          <Persona
            title={recommendation?.recommendationCreatedByUserName}
            text={recommendation?.recommendationCreatedByUserName}
            presence={PersonaPresence.online}
            styles={{
              root: {
                width: "40px",
                alignSelf: "flex-start",
                marginTop: "10px",
              },
            }}
            size={PersonaSize.size40}
          />
        </div>
        <div>
          <Label>
            {t("ram.recommendationForm.dateOfRecommendation.label")}
          </Label>
          <Text>
            {formatDate(moment(recommendation?.dateOfRecommendation).toDate())}{" "}
          </Text>
        </div>
      </div>
      {recommendation?.recommendationNumberInternal ||
      recommendation?.recommendationNumberExternal ? (
        <div className={classes.grid}>
          <div>
            <Label>
              {t("ram.recommendationForm.recommendationNumberInternal.label")}
            </Label>
            <Text>{recommendation?.recommendationNumberInternal} </Text>
          </div>
          <div>
            <Label>
              {t("ram.recommendationForm.recommendationNumberExternal.label")}
            </Label>
            <Text>{recommendation?.recommendationNumberExternal} </Text>
          </div>
        </div>
      ) : null}
      {recommendation?.description ? (
        <div className={classes.fullWidth}>
          <Label>{t("ram.recommendationForm.description.label")}</Label>
          <Text style={{ whiteSpace: "pre-wrap" }}>
            {recommendation?.description}
          </Text>
        </div>
      ) : null}
      {attachments?.length > 0 ? (
        <div className={classes.fullWidth}>
          <Label>{t("ram.responsesPage.attachments")}</Label>

          <AttachmentListContainer>
            {attachments.map((att, index) => {
              return (
                <AttachmentListItem key={att.attachmentName}>
                  <Icon
                    iconName={getFileIcon(getFileExtension(att.attachmentName))}
                    style={{
                      color: getIconColor(getFileExtension(att.attachmentName)),
                    }}
                  />
                  <AttachmentName>
                    {att.attachmentPath ? (
                      <Link href={att.attachmentPath} target="_blank">
                        {att.attachmentName}
                      </Link>
                    ) : (
                      att.attachmentName
                    )}
                  </AttachmentName>
                </AttachmentListItem>
              );
            })}
          </AttachmentListContainer>
        </div>
      ) : null}
    </div>
  );
};

const classes = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  grid: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
    overfow: "auto",
  },
  gridLeft: {
    width: "100%",
    display: "flex",
    justifyContent: "left",
    gap: "10px",
    flexDirection: "row",
  },
  fullWidth: {
    width: "100%",
  },
  label: {},
});
const Email = styled.div`
  font-size: 12px;
  color: ${(p) => p.theme.palette.neutralSecondary};
`;

const AttachmentListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 15px;
`;
const AttachmentListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLighterAlt};
  width: 350px;
`;

const AttachmentName = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
  max-width: 300px;
`;
