import { createAction } from "@reduxjs/toolkit";
import { toast } from "../components/FluentToast";
import { BackendError } from "../types/types";
import i18next from "i18next";

export const sort = (sort: any) => (a: any, b: any) => {
  let aValue = a[sort.key];
  let bValue = b[sort.key];

  if (aValue === null) aValue = "";
  if (bValue === null) bValue = "";

  if (aValue > bValue) {
    if (sort.dir === "desc") return -1;
    else return 1;
  }
  //
  else if (aValue < bValue) {
    if (sort.dir === "desc") return 1;
    else return -1;
  }
  //
  else {
    return 0;
  }
};

export const createAsyncActions = <T = any, P = any, F = any, R = any>(
  prefix: string
) => {
  return {
    trigger: createAction<T>(`${prefix}/trigger`),
    loading: createAction<P>(`${prefix}/loading`),
    success: createAction<F>(`${prefix}/success`),
    error: createAction<R>(`${prefix}/error`),
  };
};

export const createAsyncRoutine = <T = void, L = void, S = void, E = void>(
  prefix: string
) => {
  return {
    trigger: createAction<T>(`${prefix}/trigger`),
    loading: createAction<L>(`${prefix}/loading`),
    success: createAction<S>(`${prefix}/success`),
    error: createAction<E>(`${prefix}/error`),
  };
};

export const isUndefinedOrNullOrEmpty = (obj) => {
  return typeof obj === "undefined" || obj === null || obj === "";
};

export const handleAxiosError = (err?: any) => {
  if (err?.isAxiosError) {
    const response = err.response;
    if (response) {
      const responseData = err.response.data;
      if (responseData) {
        if (Array.isArray(responseData)) {
          responseData.forEach((e: BackendError) => {
            console.log(e);
            const translatedMessageVariables = e.messageVariables.map(
              (item: string) => {
                return i18next.t(item);
              }
            );
            toast.error(i18next.t(e.messageCode, translatedMessageVariables));
          });
        }
        //
        else {
          if (
            responseData instanceof Object &&
            responseData?.messageCode !== undefined &&
            responseData?.messageVariables !== undefined
          ) {
            toast.error(
              i18next.t(responseData.messageCode, responseData.messageVariables)
            );
          } else {
            toast.error("Internal Server Error");
          }
        }
      }
    }
  } else {
    toast.error(err?.toString());
  }
};

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}
