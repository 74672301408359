import React from "react";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { NavButtons } from "../../../components/navigation/NavButtons";
import { NavigationBar } from "../../../components/styled";
import { useSelector } from "../../../store/hooks";
import { mainNavigationTypes } from "../constants";
import { SaveRecomendationButton } from "./SaveRecomendationButton";

import { mergeStyles } from "@fluentui/react";
import { ResponsesBtns } from "./ResponsesBtns/ResponsesBtns";

type Props = {
  setDidTrySubmit: () => void;
};

export const Navigation = ({ setDidTrySubmit }: Props) => {
  const tab = useSelector((s) => s.recommendationPage.tab);

  return (
    <div className={containerClass}>
      <NavigationBar>
        <NavButtons />
        <Breadcrumb />

        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            gap: 10,
          }}
        >
          {tab === mainNavigationTypes.Recommendation && (
            <SaveRecomendationButton setDidTrySubmit={setDidTrySubmit} />
          )}
          {/* {tab === mainNavigationTypes.Assessment && (
          <SaveAssesmentButton setDidTrySubmit={setDidTrySubmit} />
        )} */}
          {tab === mainNavigationTypes.Responses && (
            <ResponsesBtns setDidTrySubmit={setDidTrySubmit} />
          )}
        </div>
      </NavigationBar>
    </div>
  );
};

const containerClass = mergeStyles({
  position: "sticky",
  zIndex: 2,
  top: 0,
});
