import { IconButton } from "@fluentui/react";
import React from "react";
import { useDispatch } from "react-redux";
import { loadRecommendationsActions } from "../../../store/recommendations/recommendations";

export const RefreshButton = () => {
  const dispatch = useDispatch();

  return (
    <IconButton
      iconProps={{ iconName: "Refresh" }}
      onClick={() => {
        dispatch(
          loadRecommendationsActions.trigger({
            top: 40,
            skip: 0,
            reset: true,
          })
        );
      }}
    />
  );
};
