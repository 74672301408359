export const mainNavigationTypes = {
  Recommendation: "recommendation",
  Assessment: "assessment",
  Responses: "responses",
};

export const sideNavigationTypes = {
  General: "general",
  Other: "other",
  Attachments: "attachments",
};
