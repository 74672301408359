import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { setYupLocale } from "../../../utils/setYupLocale";

function useResponseValidationSchema() {
  const { t } = useTranslation();
  const validationSchema = (() => {
    setYupLocale(t);
    return yup.object().shape({
      dateOfResponse: yup.date().required().nullable(),
      statusCompanyCode: yup.mixed().required(),
      comment: yup.string().trim().required(),
      costEstimateCode: yup.mixed().required(),
    });
  })();

  return validationSchema;
}

export default useResponseValidationSchema;
