import { TFunction } from "i18next";
import { TaxonomyState } from "../store/taxonomy/taxonomy";
import { ExpandedColumn } from "../types/columns";
import { RecommendationAzure } from "../types/types";

export const transformRecommendationsForExcel = ({
  recommendations,
  columns,
  t,
  taxonomy,
}: {
  recommendations: RecommendationAzure[];
  columns: ExpandedColumn[];
  t: TFunction;
  taxonomy: TaxonomyState;
}) => {
  return recommendations.map((v) => {
    return columns
      .filter((c) => c.isVisible)
      .reduce((transformed, c) => {
        if (c.excel && c.excel.render) {
          transformed[c.key] = c.excel.render({
            t,
            taxonomy,
            recommendation: v,
          });
        }
        //
        else {
          transformed[c.key] = v[c.key];
        }
        return transformed;
      }, {});
  });
};
