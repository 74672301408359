import { AsyncSelect } from "@greco/components";
import debounce from "debounce-promise";
import { Label } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { searchClients } from "../../api/api";
import { FieldContainer } from "../../components/controls/FieldContainer";
import { VSpace } from "../../components/styled";
import { setSicCodeList } from "../../store/clients/clients";
import { getSelectStyles } from "../../utils/utils";
import { IStyledTheme } from "../../theme/types";

type Props = {
  client: any;

  setClient: (value: any) => void;
};

export const WebBasePartnerNumber = ({ client, setClient }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const dispatch = useDispatch();

  return (
    <FieldContainer
      isTooltipHidden={false}
      tooltipText={"ram.locationForm.webBasePartnerNumber.tooltip"}
    >
      <Label required>{t("ram.locationForm.webBasePartnerNumber.label")}</Label>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <AsyncSelect
          id="webBasePartnerNumber"
          loadOptions={debounce(async (input: string) => {
            const trimmedInput = input?.trim();
            if (trimmedInput.length === 0) return [];
            const clients = await searchClients(trimmedInput);
            dispatch(
              setSicCodeList(
                clients.data.map((c) => {
                  return { clientId: c.clientId, sicCode: c.sicCode };
                })
              )
            );
            return clients.data.map((c) => {
              return {
                label: c.clientName,
                value: c.clientId,
              };
            });
          }, 300)}
          clearable
          placeholder={t("greco.form.searchPlaceholder")}
          noOptionsMessage={() => t("greco.noResults")}
          styles={getSelectStyles(false, theme as IStyledTheme)}
          isClearable
          loadingMessage={() => t("greco.searching")}
          value={client}
          onChange={(client) => {
            setClient(client);
          }}
        />

        <VSpace height={300} />
      </div>
    </FieldContainer>
  );
};
