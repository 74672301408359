import { Dialog, DialogFooter } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  reportDocumentRoutine,
  setReportDocumentDialog,
} from "../../../../store/recommendationPage";
import { StyledPrimaryButton } from "../../../../components/styled";
import { FormSpinner } from "../../../../components/FormSpinner";
import { useSelector } from "../../../../store/hooks";

export const ReportDocumentDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoading = useSelector(
    (s) => s.recommendationPage.reportDocumentLoadStatus === "loading"
  );
  const reportDocument = useSelector(
    (s) => s.recommendationPage.reportDocument
  );
  const close = () => {
    dispatch(
      setReportDocumentDialog({
        isOpen: false,
      })
    );
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        showCloseButton: true,
        title: t("ram.reportDocumentDialog.label"),
      }}
      onDismiss={close}
      maxWidth={1500}
    >
      {t("ram.reportDocumentDialogText.label")}
      <DialogFooter>
        {reportDocument !== null ? (
          <StyledPrimaryButton
            iconProps={{ iconName: "Download" }}
            text={t("ram.reportDocumentDialogDownload.label")}
            onClick={() => {
              window.open(reportDocument.url, "_blank");
              close();
            }}
          />
        ) : (
          <StyledPrimaryButton
            iconProps={{ iconName: "TextDocument" }}
            text={t("ram.reportDocumentDialogGenerate.label")}
            onClick={() => {
              dispatch(reportDocumentRoutine.trigger({}));
            }}
            disabled={isLoading}
          />
        )}
      </DialogFooter>
      {isLoading && <FormSpinner />}
    </Dialog>
  );
};
