import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  setExportToZipDialog,
  setReportDocumentDialog,
} from "../../../../store/recommendationPage";
import { AppDispatch } from "../../../../store/store";
import { ActionButton, CommandBarButton } from "@fluentui/react";
import { useSelector } from "store/hooks";

export const ReportDocumentButton = () => {
  const { t } = useTranslation();
  const recommendation = useSelector((s) => s.recommendationPage.rec);

  const dispatch: AppDispatch = useDispatch();

  return (
    <>
      <CommandBarButton
        text={t("Reports")}
        iconProps={{ iconName: "TextDocument" }}
        split
        readOnly={true}
        menuProps={{
          items: [
            {
              key: "reportDocumentDialog",
              text: t("ram.reportDocumentDialog.label"),
              iconProps: {
                iconName: "TextDocument",
              },
              onClick: () => {
                dispatch(
                  setReportDocumentDialog({
                    isOpen: true,
                  })
                );
              },
            },
            {
              key: "exportToZipDialog",
              text: t("ram.recommendationExportToZip.label"),
              iconProps: {
                iconName: "TextDocument",
              },
              onClick: () => {
                dispatch(
                  setExportToZipDialog({
                    locationId: recommendation.locationId,
                    recommendationId: recommendation.recommendationId,
                    isOpen: true,
                  })
                );
              },
            },
          ],
        }}
      />
    </>
  );
};
