import { useFormikContext } from "formik";
import { Label } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FieldContainer } from "../../../components/controls/FieldContainer";
import { ErrorMessage } from "../../../components/styled";
import styled, { useTheme } from "styled-components";
import { IStyledTheme } from "../../../theme/types";

type Props = {
  name: string;
  isRequired?: boolean;
  isDisabled?: boolean;

  label: string;
};

export const EditorField = ({ name, isRequired, isDisabled, label }: Props) => {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<any>();
  const fieldName = name;
  const theme = useTheme() as IStyledTheme;
  const hasError = !!errors[fieldName];

  return (
    <div>
      <FieldContainer isTooltipHidden={isDisabled} tooltipText={label ?? ""}>
        <Label required={isRequired}>{t(label)}</Label>
        <StyledReactQuill
          id={t(label)?.replaceAll(" ", "_") + "_field"}
          theme="snow"
          style={{
            color: theme.palette.black,
            background: theme.palette.white,
          }}
          value={values[name]}
          onChange={(value) => {
            setFieldValue(fieldName, value);
          }}
          readOnly={isDisabled}
        />
      </FieldContainer>

      {hasError && (
        <ErrorMessage id={t(label)?.replaceAll(" ", "_") + "_error"}>
          {errors[fieldName] as string}
        </ErrorMessage>
      )}
    </div>
  );
};

const StyledReactQuill = styled(ReactQuill)`
  .ql-toolbar.ql-snow {
    padding: 3px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .ql-container.ql-snow {
    min-height: 200px;
  }
`;
