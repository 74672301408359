import { useTranslation } from "react-i18next";
import { adjustTaxonomyValue } from "../../../form/util";
import { useSelector } from "../../../store/hooks";
import useIsDisabledAssesmentForm from "./useIsDisabledAssesmentForm";
import { useCurrentRoute } from "../../../config/routes";

function useAssesmentFormInitialValues() {
  const recommendation = useSelector((s) => s.recommendationPage.rec);

  const route = useCurrentRoute();
  const taxonomy = useSelector((s) => s.taxonomy);

  const { t } = useTranslation();

  const isDisabled = useIsDisabledAssesmentForm();
  const assessment = useSelector(
    (s) => s.recommendationPage.addAssessmentDialog.assessment
  );

  const initialValues = (() => {
    if (assessment) {
      return {
        dateOfAssessment: assessment?.dateOfAssessment
          ? new Date(assessment?.dateOfAssessment)
          : null,
        improvementPotentialCode: adjustTaxonomyValue(
          assessment?.improvementPotentialCode,
          taxonomy,
          "RiskImprovementPotential",
          t
        ),
        noGo: isDisabled ? assessment?.noGo : null,
        shouldNotifyClient: assessment?.shouldNotifyClient,
        statusCompanyCode: adjustTaxonomyValue(
          assessment?.statusCompanyCode,
          taxonomy,
          "RiskImplementationStatus",
          t
        ),
        statusGrECoCode: adjustTaxonomyValue(
          assessment?.statusGrEcoCode,
          taxonomy,
          "RiskImplementationStatus",
          t
        ),
        comment: assessment?.comment,

        hideFromInsurer: assessment?.hideFromInsurer || false,
        attachments: assessment?.attachments || [],
      };
    }
    if (
      recommendation &&
      (route === "addAssessment" || route === "editRecommendation")
    ) {
      return {
        dateOfAssessment: new Date(),

        improvementPotentialCode: adjustTaxonomyValue(
          recommendation.improvementPotentialCode,
          taxonomy,
          "RiskImprovementPotential",
          t
        ),
        statusCompanyCode: adjustTaxonomyValue(
          recommendation?.statusCompanyCode,
          taxonomy,
          "RiskImplementationStatus",
          t
        ),
        statusGrECoCode: adjustTaxonomyValue(
          recommendation?.statusGrECoCode,
          taxonomy,
          "RiskImplementationStatus",
          t
        ),
        noGo: isDisabled ? recommendation.noGo : false,
        shouldNotifyClient: true,
        comment: recommendation?.comment,

        hideFromInsurer: recommendation?.hideFromInsurer || false,
        attachments: recommendation?.attachments || [],
      };
    }
    return {};
  })();

  return initialValues;
}

export default useAssesmentFormInitialValues;
