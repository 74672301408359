import { mergeStyleSets } from "@fluentui/react";
import { useFormikContext } from "formik";
import React from "react";
import CheckboxField from "../../../../components/controls/CheckboxField";
import ComboBoxField from "../../../../components/controls/ComboBoxField";
import DatePickerField from "../../../../components/controls/DatePickerField";
import DoubleNumberInputField from "../../../../components/controls/DoubleNumberInputField";
import useGetTaxonomyOptions from "../../../../utils/hooks/useGetTaxonomyOptions";
import AttachmentsFormField from "../../components/AttachmentsFormFields/AttachmentsFormField";
import { EditorField } from "../../components/EditorField";
import { useImplementationCostsMaxValueValidation } from "../../hooks/useMaxValueValidation";

function AssessmentFields() {
  const { values, handleChange, errors, setFieldValue } =
    useFormikContext<any>();

  useImplementationCostsMaxValueValidation();

  const statusCompanyCodeOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskImplementationStatus",
    exclude: [
      "RiskImplementationStatus.DONE_CHECKED",
      "RiskImplementationStatus.HELD_IN_ABEYENCE",
    ],
  });
  const statusGrECoCodeOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskImplementationStatus",
  });

  const improvementPotentialOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskImprovementPotential",
  }).sort((a, b) => b.value - a.value);
  return (
    <div className={classes.container}>
      <div className={classes.grid}>
        <DatePickerField
          disabled={false}
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values?.dateOfAssessment}
          name={"dateOfAssessment"}
          label={"ram.assessmentsPage.dateOfAssessment.label"}
          placeholder={"ram.selectDate"}
          required={true}
          maxDate={new Date()}
          confidentiality={null}
        />

        <ComboBoxField
          disabled={false}
          errors={errors}
          name="improvementPotentialCode"
          label={"ram.recommendationForm.improvementPotentialCode.label"}
          setFieldValue={setFieldValue}
          options={improvementPotentialOptions || []}
          defaultValue={values?.improvementPotentialCode}
          confidentiality={null}
          required
        />
        <ComboBoxField
          disabled={false}
          errors={errors}
          name="statusCompanyCode"
          label={"ram.assessmentsPage.statusCompanyCode.label"}
          setFieldValue={setFieldValue}
          options={statusCompanyCodeOptions || []}
          defaultValue={values?.statusCompanyCode}
          confidentiality={null}
          required
        />
        <ComboBoxField
          disabled={false}
          errors={errors}
          name="statusGrECoCode"
          label={"ram.evaluationsPage.statusGrecoCode.label"}
          setFieldValue={setFieldValue}
          options={statusGrECoCodeOptions || []}
          defaultValue={values?.statusGrECoCode}
          confidentiality={null}
          required
        />
      </div>

      <CheckboxField
        checked={values?.noGo}
        disabled={false}
        label={"ram.recommendationForm.required.label"}
        name={"noGo"}
        setFieldValue={setFieldValue}
        errors={errors}
      />
      <div className={classes.fullWidth}>
        <AttachmentsFormField isDisabled={false} />
      </div>
      <div className={classes.fullWidth}>
        <EditorField
          name="comment"
          isRequired
          label="ram.assessmentsPage.comment.label"
        />
      </div>
      <div className={classes.grid}>
        <CheckboxField
          checked={values?.shouldNotifyClient}
          disabled={false}
          label={"ram.assessmentsPage.shouldNotifyClient.label"}
          name={"shouldNotifyClient"}
          setFieldValue={setFieldValue}
          errors={errors}
        />
        <CheckboxField
          checked={values?.hideFromInsurer}
          disabled={false}
          label={"ram.assessmentsPage.displayedToInsurer.label"}
          name={"hideFromInsurer"}
          setFieldValue={setFieldValue}
          errors={errors}
        />
      </div>
    </div>
  );
}

export default AssessmentFields;

const classes = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingBottom: 100,
  },
  grid: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
    overfow: "auto",
  },
  fullWidth: {
    width: "100%",
  },
});
