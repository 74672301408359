import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "../api/api";
import { routes } from "../config/routes";
import { LocationDefaultValue } from "../types/types";
import { RootState } from "./store";
import { toast } from "../components/FluentToast";
import i18next from "i18next";
import { LocationDefaultValuesSaveMode } from "../pages/location-default-values/LocationDefaultValues";
import { handleAxiosError } from "./util";
import { history } from "utils/_helpers";
import { push, replace } from "redux-first-history";
export type LocationDefaultValuesState = {
  defaultValues: LocationDefaultValue[];
  areDefaultValuesLoading: boolean;
  isUpdateLoading: boolean;
};

export const loadDefaultValues = createAsyncThunk<
  LocationDefaultValue[],
  void,
  { state: RootState }
>("locationDefaultValues/loadDefaultValues", async (_, thunkAPI) => {
  const loc = thunkAPI.getState().router.location;
  const locationDefaultValuesMatch =
    routes.locationDefaultValues.matchPath(loc);

  const { locationId } = locationDefaultValuesMatch?.params;

  let res;
  try {
    res = await API.getDefaultValues(locationId);
  } catch (err) {
    if (err.response.status === 403 || err.response.status === 404) {
      thunkAPI.dispatch(replace("/clients"));
    }
  }

  return res.data;
});

export const updateDefaultValues = createAsyncThunk<
  LocationDefaultValue[],
  {
    defaultValues: LocationDefaultValue[];
    mode: LocationDefaultValuesSaveMode;
  },
  { state: RootState }
>(
  "locationDefaultValues/updateDefaultValues",
  async ({ defaultValues, mode }, thunkAPI) => {
    const loc = thunkAPI.getState().router.location;
    const locationDefaultValuesMatch =
      routes.locationDefaultValues.matchPath(loc);

    const { clientId, locationId } = locationDefaultValuesMatch?.params;
    try {
      const res = await API.updateDefaultValues(locationId, defaultValues);
      toast.success(i18next.t("ram.locationDefaultValues.updateSuccess"));
      if (mode === "addReport") {
        thunkAPI.dispatch(push(routes.addReport.getPath(clientId, locationId)));
      }
      return res.data;
    } catch (err) {
      handleAxiosError(err);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const locationDefaultValuesSlice = createSlice({
  name: "locationDefaultValues",
  initialState: {
    defaultValues: [],
    areDefaultValuesLoading: false,
    isUpdateLoading: false,
  } as LocationDefaultValuesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadDefaultValues.pending, (s, a) => {
      s.areDefaultValuesLoading = true;
    });
    builder.addCase(loadDefaultValues.fulfilled, (s, a) => {
      s.areDefaultValuesLoading = false;
      s.defaultValues = a.payload;
    });
    builder.addCase(loadDefaultValues.rejected, (s, a) => {
      s.areDefaultValuesLoading = false;
    });
    builder.addCase(updateDefaultValues.pending, (s, a) => {
      s.isUpdateLoading = true;
    });
    builder.addCase(updateDefaultValues.fulfilled, (s, a) => {
      s.isUpdateLoading = false;
      s.defaultValues = a.payload;
    });
    builder.addCase(updateDefaultValues.rejected, (s, a) => {
      s.isUpdateLoading = false;
    });
  },
});

export const locationDefaultValuesReducer = locationDefaultValuesSlice.reducer;
