import { mergeStyleSets } from "@fluentui/react";
import debounce from "debounce-promise";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Async from "react-select/async";
import { useTheme } from "styled-components";
import { searchClients } from "../../../api/api";
import { useCurrentRoute } from "../../../config/routes";
import {
  setSelectedClientSicCode,
  setSicCodeList,
} from "../../../store/clients/clients";
import { useSelector } from "../../../store/hooks";
import { IStyledTheme } from "../../../theme/types";

import { useFormikContext } from "formik";
import { mergeStyles } from "react-select";
import ErrorMsg from "../../../components/controls/ErrorMsg";
import { FieldContainer } from "../../../components/controls/FieldContainer";
import { getSelectStyles } from "../../../utils/utils";

export const ClientName = ({ required, name }: any) => {
  const { values, errors, setFieldValue } = useFormikContext() as any;
  const { t } = useTranslation();
  const { clientId } = useParams() as any;
  const clientEntities = useSelector((s) => s.clients.entities);
  const sicCodeList = useSelector((s) => s.clients.sicCodeList);
  const taxonomy = useSelector((s) => s.taxonomy);
  const dispatch = useDispatch();
  const isDisabled = !!clientId;
  const route = useCurrentRoute();

  const isEdit = route === "editLocation";
  const value = values[name];
  const theme = useTheme() as IStyledTheme;
  const classes = getClassNames(theme, required, isDisabled);

  useEffect(() => {
    // if (isEdit) return;
    if (clientId && isEdit) return;
    if (!values.webBasePartnerNumber) return;

    const sicCodeObj = sicCodeList.find(
      (c) => c.clientId === values.webBasePartnerNumber.value
    );

    if (sicCodeObj && taxonomy.SicCode.byId[sicCodeObj.sicCode]) {
      dispatch(
        setSelectedClientSicCode({
          key: taxonomy.SicCode.byId[sicCodeObj.sicCode].id,
          name: t(taxonomy.SicCode.byId[sicCodeObj.sicCode].code),
        })
      );
    }
  }, [values.webBasePartnerNumber, clientId]);

  return (
    <>
      <FieldContainer
        isTooltipHidden={false}
        tooltipText={"ram.locationForm.webBasePartnerNumber.tooltip"}
      >
        <label
          id="aria-label"
          htmlFor="aria-example-input"
          className={classes.label}
        >
          {t("ram.locationForm.webBasePartnerNumber.label")}
          {required && <span className={classes.required}>*</span>}
        </label>
        <div>
          <Async
            id="webBasePartnerNumber"
            loadOptions={debounce(async (input: string) => {
              const trimmedInput = input.trim();
              if (trimmedInput.length === 0) return [];
              const clients = await searchClients(trimmedInput);
              dispatch(
                setSicCodeList(
                  clients.data.map((c) => {
                    return { clientId: c.clientId, sicCode: c.sicCode };
                  })
                )
              );
              return clients.data.map((c) => {
                return {
                  label: c.clientName,
                  value: c.clientId,
                };
              });
            }, 300)}
            placeholder={t("greco.form.searchPlaceholder")}
            noOptionsMessage={() => t("greco.noResults")}
            isClearable
            isDisabled={isDisabled}
            loadingMessage={() => t("greco.searching")}
            value={value}
            onChange={(value) => {
              setFieldValue(name, value);
            }}
            styles={mergeStyles(getSelectStyles(!!false, theme), {
              container: (p) => ({
                ...p,
                width: "100%",
              }),
              menuPortal: (p) => ({
                ...p,
                background: theme.palette.neutralLighter,
                zIndex: 9999999,
              }),
              menu: (p) => ({
                ...p,
                zIndex: 9999,
                background: theme.palette.white,
              }),
              control: (p, state) => {
                return {
                  ...p,

                  background: state.isDisabled
                    ? "transparent"
                    : theme.palette.white,

                  borderBottom: state.isFocused
                    ? `2px solid rgb(0, 90, 161) !important`
                    : `1px solid ${theme.palette.black} !important`,
                  boxShadow: "none",
                  ...(state.isDisabled && {
                    borderBottom: `1px solid ${theme.palette.neutralLighter} !important`,
                  }),
                };
              },
              option: (p, state) => ({
                ...p,
                background:
                  state.isSelected || state.isFocused
                    ? theme.palette.neutralLighter
                    : theme.palette.white,
                "&:hover": {
                  background: theme.palette.neutralLighter,
                },
                color: theme.palette.black,
                fontSize: "14px",
                fontWeight: 400,
                wordWrap: "break-word",
              }),
              input: (p) => ({
                ...p,
                color: theme.palette.black,
              }),
              singleValue: (p, state) => ({
                ...p,
                color: theme.palette.black,
                fontSize: "14px",
                fontWeight: 400,
              }),
              dropdownIndicator: (p, state) => ({
                ...p,
                ...(state.isDisabled && { visibility: "hidden" }),
              }),
              indicatorSeparator: (p, state) => ({
                ...p,
                ...(state.isDisabled && { visibility: "hidden" }),
              }),
            })}
          />
          {errors[name] && <ErrorMsg>{errors[name]}</ErrorMsg>}
        </div>
      </FieldContainer>
    </>
  );
};

const getClassNames = (theme, required, disabled) =>
  mergeStyleSets({
    root: {
      width: "100%",
      marginTop: "2px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "600",
      color: disabled ? theme.palette.neutralSecondaryAlt : theme.palette.black,
      padding: "5px",
    },
    required: {
      color: theme.palette.redDark,
      fontSize: 14,
      marginLeft: 3,
    },
  });
