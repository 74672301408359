import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  ConstrainMode,
  DetailsListLayoutMode,
  DetailsRow,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  IRenderFunction,
  mergeStyleSets,
  ScrollablePane,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  TooltipHost,
} from "@fluentui/react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { TableSpinner } from "../../components/TableSpinner";
import { useSelector } from "../../store/hooks";
import {
  selectShownDefaultWeightings,
  setSortDefaultWeightings,
} from "../../store/defaultWeightings-page/defaultWeightingsPage";
import {
  loadDefaultWeightingsActions,
  setHoverColumn,
  setSelectedColumn,
} from "../../store/defaultWeightings/defaultWeightings";
import { AppDispatch } from "../../store/store";
import { DefaultWeightingName } from "../../types/types";
import { defaultColumns } from "./columns/columns";
import { DeleteDialog } from "./DeleteDialog";
import "./DefaultWeightings.scss";
import { Navigation } from "./navigation/Navigation";

import { useConst } from "@uifabric/react-hooks";
import _ from "lodash";
import { useTheme } from "styled-components";
import { IStyledTheme } from "../../theme/types";
import { RenameDefaultWeightingDialog } from "./RenameDefaultWeightingDialog";
import { setNewName } from "store/defaultWeightingPage";

export type PanelState = {
  isOpen: boolean;
  loc: DefaultWeightingName | null;
};

export const DefaultWeightings = () => {
  const { t } = useTranslation();
  const defaultWeightings = useSelector(selectShownDefaultWeightings);
  console.log(defaultWeightings);
  const sort = useSelector((s) => s.defaultWeightingsPage.sort);
  const dispatch: AppDispatch = useDispatch();
  const areDefaultWeightingsLoading = useSelector(
    (s) => s.defaultWeightings.isLoading
  );
  const deleteDialog = useSelector(
    (s) => s.defaultWeightingsPage.deleteDefaultWeightingDialog
  );
  const renameDefaultWeightingDialog = useSelector(
    (s) => s.defaultWeightingsPage.renameDefaultWeightingDialog
  );

  // const selectedColumn = useSelector((s) => s.defaultWeightings.selectedColumn);

  const [panel, setPanel] = useState<PanelState>({
    isOpen: false,
    loc: null,
  });

  const theme = useTheme() as IStyledTheme;
  const classes = getClassNames(theme);

  const selection = useConst(
    () =>
      new Selection({
        onSelectionChanged: async () => {
          const selected = selection.getSelection() as any;
          dispatch(setSelectedColumn(selected[0]));
        },
      })
  );

  useEffect(() => {
    dispatch(loadDefaultWeightingsActions.trigger());
  }, []);

  const detailsListColumns = useMemo(() => {
    const withTranslatedHeader = defaultColumns
      .map((column: any) => {
        let iconName = column.iconName;

        if (column.key === sort.key && column.isSortable) {
          iconName =
            sort.key === column.key
              ? sort!.dir === "asc"
                ? "SortUp"
                : "SortDown"
              : "Sort";
        }
        return {
          ...column,
          name: t(column.name),
          iconName: iconName,
        };
      })
      .concat([
        {
          key: "filler",
          label: "",
          width: 0,
        },
      ]);
    return [...withTranslatedHeader];
  }, [sort, t]);

  const onClickHeaderCell = (column) => {
    if (!column.isSortable) return;
    const isSorted = sort.key === column.key;
    if (isSorted) {
      if (sort.dir === "asc") {
        dispatch(
          setSortDefaultWeightings({
            key: column.key,
            dir: "desc",
          })
        );
      }
      //
      else {
        dispatch(
          setSortDefaultWeightings({
            key: "defaultWeightingCreatedAt",
            dir: "desc",
          })
        );
      }
    }
    //
    else {
      dispatch(
        setSortDefaultWeightings({
          key: column.key,
          dir: "asc",
        })
      );
    }
  };

  const onRenderColumnHeaderTooltip: IRenderFunction<
    IDetailsColumnRenderTooltipProps
  > = (tooltipHostProps) => {
    return (
      <TooltipHost
        {...tooltipHostProps}
        content={tooltipHostProps?.column?.name}
      />
    );
  };

  const onRenderDetailsHeader = (
    props: IDetailsHeaderProps | undefined,
    defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
  ) => (
    <Sticky
      stickyPosition={StickyPositionType.Header}
      isScrollSynced
      stickyClassName={classes.table}
    >
      {defaultRender!({
        ...props,
        onRenderColumnHeaderTooltip,
      })}
    </Sticky>
  );

  const handleHoverColumn = useCallback(
    _.throttle((data) => {
      dispatch(setHoverColumn(data));
    }, 1), //ensure timeout before dispatch in ms
    []
  );

  const handleSetSummaryPanel = useCallback((value) => {
    setPanel(value);
  }, []);

  const onRenderRow = (props) => {
    return (
      <DetailsRow
        onMouseEnter={() => {
          dispatch(setHoverColumn(props.item));
          // handleHoverColumn(props.item);
        }}
        onMouseLeave={() => {
          // handleHoverColumn(null);

          dispatch(setHoverColumn(null));
        }}
        {...props}
      />
    );
  };

  useEffect(() => {
    return () => {
      handleHoverColumn(null);
      dispatch(setSelectedColumn(null));
    };
  }, []);

  return (
    <>
      <Navigation />
      <div className={classes.container} id="scrollableDiv">
        <ScrollablePane
          styles={{
            stickyAbove: {
              zIndex: 3,
            },
          }}
        >
          <ShimmeredDetailsList
            items={defaultWeightings || []}
            compact={false}
            columns={detailsListColumns || []}
            selectionMode={SelectionMode.none}
            setKey="single"
            isSelectedOnFocus
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            // onRenderItemColumn={(item, index, column) => {
            //   if (column.key === "options") {
            //     return (
            //       <FixedCell no={index} row={item}>
            //         <CenteredCell>
            //           <ContextButton loc={item} />
            //         </CenteredCell>
            //       </FixedCell>
            //     );
            //   }
            //   return colu mn[index];
            // }}
            enableShimmer={areDefaultWeightingsLoading}
            shimmerLines={20}
            selectionPreservedOnEmptyClick={true}
            onItemInvoked={(row) => {
              handleSetSummaryPanel({ isOpen: true, loc: row });
              handleHoverColumn(null);
            }}
            enterModalSelectionOnTouch={true}
            // onItemContextMenu={onItemContextMenu}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            constrainMode={ConstrainMode.unconstrained}
            onColumnHeaderClick={(ev, column) => {
              onClickHeaderCell(column);
            }}
            onRenderRow={onRenderRow}
            className={classes.table}
            onRenderDetailsHeader={(
              props: IDetailsHeaderProps | undefined,
              defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
            ) => onRenderDetailsHeader(props, defaultRender)}
          />
        </ScrollablePane>
      </div>
      {deleteDialog.isOpen && <DeleteDialog />}
      {renameDefaultWeightingDialog.isOpen && <RenameDefaultWeightingDialog />}
      {areDefaultWeightingsLoading && <TableSpinner />}
    </>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    container: {
      // height: "90vh",
      height: "calc(100vh  - 100px)",

      marginBottom: "20px",
      position: "relative",
      overflow: "auto",
      width: "100%",
    },
    listContainer: {
      display: "flex",
    },

    table: {
      ".ms-List-cell:nth-child(odd) .ms-DetailsRow": {
        background: theme.isDark
          ? "rgb(2, 7, 10)"
          : theme.palette?.themeLighterAlt,
      },
      ".ms-DetailsRow ": {
        height: "30px !important",
      },

      ".ms-List-cell:hover, .ms-List-cell .is-selected": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
      },
      ".ms-List-cell .ms-DetailsRow:hover": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
      },

      ".ms-DetailsHeader-cellTitle": {
        justifyContent: "flex-start",
      },
      "[data-item-key='fleetName'] span": {
        justifyContent: "flex-start",
      },

      ".ms-DetailsRow-cell": {
        display: "flex",
        justifyContent: "flex-start",
      },
      ".ms-DetailsRow-cell:first-child": {
        display: "flex",
        justifyContent: "flex-start",
      },

      ".ms-DetailsHeader-cellName i": {
        marginRight: "5px",
        fontSize: "smaller",
        fontWeight: "400",
      },

      //******************************************************************************************************* */
      ///header cell

      ".ms-DetailsHeader-cell:nth-child(1)": {
        position: "sticky",
        inset: 0,
        zIndex: 2,
        background: theme.palette.white,
      },

      ".ms-DetailsHeader-cell:nth-child(2)": {
        position: "sticky",
        inset: 48,
        zIndex: 2,
        background: theme.palette.white,
      },
      ".ms-DetailsHeader-cell:nth-child(3)": {
        position: "sticky",
        inset: 108,
        zIndex: 2,
        background: theme.palette.white,
      },
      ".ms-DetailsHeader-cell:nth-child(4)": {
        position: "sticky",
        inset: 370,
        zIndex: 2,
        background: theme.palette.white,
      },

      ".ms-DetailsHeader-cell:nth-child(2):hover, .ms-DetailsHeader-cell:nth-child(3):hover, .ms-DetailsHeader-cell:nth-child(4):hover":
        {
          background: theme.palette.neutralLighter,
        },

      /// row cell

      ".ms-DetailsRow-cell:nth-child(1)": {
        position: "sticky",
        inset: 48,
        zIndex: 1,

        background: "inherit",

        alignItems: "center",
        borderBottom: theme.isDark
          ? "1px solid rgb(42, 42, 42)"
          : "1px solid rgb(243, 242, 241)",
      },

      ".ms-DetailsRow-cell:nth-child(2)": {
        position: "sticky",
        inset: 108,
        zIndex: 1,
        background: "inherit",

        borderBottom: theme.isDark
          ? "1px solid rgb(42, 42, 42)"
          : "1px solid rgb(243, 242, 241)",
      },

      ".ms-DetailsRow-cell:nth-child(3)": {
        position: "sticky",
        inset: 370,
        zIndex: 1,
        background: "inherit",

        borderBottom: theme.isDark
          ? "1px solid rgb(42, 42, 42)"
          : "1px solid rgb(243, 242, 241)",
      },

      // checkbox in row
      ".ms-DetailsRow-cellCheck:nth-child(1)": {
        position: "sticky",
        inset: 0,
        zIndex: 1,
        background: "inherit",

        borderBottom: theme.isDark
          ? "1px solid rgb(42, 42, 42)"
          : "1px solid rgb(243, 242, 241)",
      },

      // ********************************************************* //
      //       ".ms-List-cell:nth-child(odd) .ms-DetailsRow-cell:nth-child(1)": {
      //   background: theme.palette?.themeLighterAlt,
      // },

      // ".ms-List-cell:nth-child(odd) .ms-DetailsRow-cell:nth-child(2)": {
      //   background: theme.palette?.themeLighterAlt,
      // },

      // ".ms-List-cell:nth-child(odd) .ms-DetailsRow-cell:nth-child(3)": {
      //   background: theme.palette?.themeLighterAlt,
      // },
    },
  });
