import styled from "styled-components";
import { Pivot, PivotItem } from "@fluentui/react";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useCurrentRoute } from "../../../../config/routes";
import { useSelector } from "../../../../store/hooks";
import {
  setAddEvaluationDialog,
  setAddResponseDialog,
  setSubTab,
  setTab,
} from "../../../../store/recommendationPage";
import { mainNavigationTypes, sideNavigationTypes } from "../../constants";
import { RecommendationPagination } from "./RecommendationPagination";
import { useNavigate } from "react-router-dom";

export const MainNav = () => {
  const selectedTab = useSelector((s) => s.recommendationPage.tab);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useCurrentRoute();

  const userRole = useSelector((s) => s.userRole.userRole);

  const addResponseDialogIsOpen = useSelector(
    (s) => s.recommendationPage.addResponseDialog.isOpen
  );
  const addEvaluationDialogIsOpen = useSelector(
    (s) => s.recommendationPage.addEvaluationDialog.isOpen
  );

  const navigationTabs = useMemo(() => {
    const allTabs = [
      {
        name: "Recommendation",
        key: mainNavigationTypes.Recommendation,
        url: undefined,
        iconName: "Globe",
      },
      // {
      //   name: "Assessment",
      //   key: mainNavigationTypes.Assessment,
      //   url: undefined,
      //   iconName: "Globe",
      // },
      {
        name: "Responses",
        url: undefined,
        key: mainNavigationTypes.Responses,
        iconName: "Globe",
      },
    ];

    if (route === "addRecommendation") {
      return allTabs.filter(
        (tab) => tab.key === mainNavigationTypes.Recommendation
      );
    }

    if (userRole === "external" || userRole === "insurer") {
      return allTabs.filter((tab) => tab.key === mainNavigationTypes.Responses);
    }

    return allTabs;
  }, [route, userRole]);

  useEffect(() => {
    if (selectedTab !== mainNavigationTypes.Responses) {
      if (addEvaluationDialogIsOpen) {
        dispatch(
          setAddEvaluationDialog({
            isOpen: false,
            evaluation: null,
            attachments: [],
          })
        );
      }
      if (addResponseDialogIsOpen) {
        dispatch(
          setAddResponseDialog({
            isOpen: false,
            response: null,
            attachments: [],
          })
        );
      }
    }
  }, [selectedTab, addResponseDialogIsOpen, addEvaluationDialogIsOpen]);

  if (!userRole) return null;

  return (
    <Container>
      <Wrap>
        <Pivot
          selectedKey={selectedTab}
          aria-label="Pivot Example"
          onLinkClick={(item) => {
            dispatch(setTab(item.props.itemKey));
            dispatch(setSubTab(sideNavigationTypes.General));
            navigate("?tab=" + item.props.itemKey); // + "?tab=" + tab);
          }}
        >
          {navigationTabs.map((item) => {
            return (
              <PivotItem
                headerText={item.name}
                key={item.key}
                itemIcon={item.iconName}
                itemKey={item.key}
              ></PivotItem>
            );
          })}
        </Pivot>
        <RecommendationPagination />
      </Wrap>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
