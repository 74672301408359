import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../store/hooks";
import { Location } from "../../../types/types";

type Props = {
  row: Location;
};

function CountryCode({ row }: Props) {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  return <>{t(taxonomy.Country.byId[+row?.countryCode]?.code)}</>;
}

export default CountryCode;

// columns :

// 1: 40
// 2:  250
// 3: 40
