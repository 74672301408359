import clsx from "clsx";
import {
  DirectionalHint,
  Icon,
  Label,
  Link,
  Persona,
  PersonaPresence,
  PersonaSize,
  PrimaryButton,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NoData } from "../../../../../components/NoData";
import { Flex } from "../../../../../components/styled";
import { useSelector } from "../../../../../store/hooks";
import { setDeleteEvaluationDialog } from "../../../../../store/recommendationPage";
import { InsurerEvaluationWithAttachments } from "../../../../../types/types";
import {
  formatDateString,
  getFileExtension,
  getFileIcon,
  getIconColor,
} from "../../../../../utils/utils";
import { StatusGrecoCode } from "./StatusGrecoCode";
import styled from "styled-components";
import { TooltipComponent } from "components/TooltipComponent";

type Props = {
  evaluation: InsurerEvaluationWithAttachments;
};

export const Evaluation = ({ evaluation: r }: Props) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);

  const userRole = useSelector((s) => s.userRole.userRole);
  const dispatch = useDispatch();

  return (
    <Root
      className={clsx({
        draft: true,
        "not-draft": false,
      })}
    >
      <Persona
        title={r.insurerEvaluation.evaluationCreatedByUserName}
        text={r.insurerEvaluation.evaluationCreatedByUserName}
        presence={PersonaPresence.online}
        styles={{
          root: {
            width: "40px",
            alignSelf: "flex-start",
            marginTop: "10px",
          },
        }}
        size={PersonaSize.size40}
      />
      <Bubble>
        <Top>
          <BubbleWrap>
            <div>
              <Label>{t("ram.evaluationsPage.dateOfEvaluation")}</Label>
              {r.insurerEvaluation.dateOfEvaluation &&
                formatDateString(r.insurerEvaluation.dateOfEvaluation)}
            </div>
            <div>
              <Label>{t("ram.evaluationsPage.plannedimplementation")}</Label>
              <div>
                {r.insurerEvaluation.implementationDateInsurer ? (
                  formatDateString(
                    r.insurerEvaluation.implementationDateInsurer
                  )
                ) : (
                  <NoData />
                )}
              </div>
            </div>
            <StatusGrecoCode
              statusGrECo={r.insurerEvaluation.statusGrEcoCode}
            />
          </BubbleWrap>

          <Flex direction="column" gap={5}>
            <Flex
              direction="column"
              style={{
                visibility: userRole === "admin" ? "visible" : "hidden",
              }}
            >
              <PrimaryButton
                text={t("greco.delete")}
                iconProps={{ iconName: "Trash" }}
                onClick={() => {
                  dispatch(
                    setDeleteEvaluationDialog({
                      isOpen: true,
                      evaluation: r.insurerEvaluation,
                    })
                  );
                }}
              />
            </Flex>
          </Flex>
        </Top>
        <Middle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Label>{t("ram.evaluationsPage.priorityCode.label")}</Label>
            {r.insurerEvaluation.priorityCode ? (
              <span>
                {t(
                  taxonomy.RiskPriority.byId[r.insurerEvaluation.priorityCode]
                    ?.code
                )}
              </span>
            ) : (
              <NoData />
            )}
          </div>
        </Middle>

        <div
          style={{
            paddingTop: "10px",
          }}
        >
          <div
            style={{
              paddingTop: "10px",
            }}
          >
            <Label>{t("ram.responsesPage.comment")}</Label>
            <div
              dangerouslySetInnerHTML={{ __html: r.insurerEvaluation.comment }}
            />
          </div>
          {r.insurerEvaluationAttachments.length !== 0 && (
            <div>
              <Label>{t("ram.responsesPage.attachments")}</Label>
              {r.insurerEvaluationAttachments?.map((a) => {
                return (
                  <TooltipComponent
                    key={a.attachmentId}
                    content={a.attachmentName}
                    directionalHint={DirectionalHint.leftTopEdge}
                  >
                    <div
                      key={a.attachmentId}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Icon
                        iconName={getFileIcon(
                          getFileExtension(a.attachmentName)
                        )}
                        style={{
                          color: getIconColor(
                            getFileExtension(a.attachmentName)
                          ),
                        }}
                        styles={{
                          root: {
                            marginRight: "5px",
                            fontSize: "14px",
                          },
                        }}
                      ></Icon>
                      <Link
                        styles={{
                          root: {
                            fontSize: " 14px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            flex: 1,
                          },
                        }}
                        onClick={() => {
                          window.open(a.attachmentPath);
                        }}
                      >
                        {a.attachmentName}
                      </Link>
                    </div>
                  </TooltipComponent>
                );
              })}
            </div>
          )}
        </div>
      </Bubble>
    </Root>
  );
};

const Top = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralPrimary};
  padding-bottom: 10px;
  @media (max-width: 700px) {
    padding-bottom: 0;
  }
`;

const Middle = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralPrimary};
`;

const BubbleWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex: 1;
  gap: 10px;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    > *:last-child {
      margin-bottom: 10px;
    }
  }
`;

export const Bubble = styled.div`
  padding: 15px 20px;
  border: 1px solid ${(p) => p.theme.palette.neutralPrimary};
  max-width: 600px;
  flex: 1;
  background-color: ${(p) => p.theme.palette.neutralLight};
`;

export const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
  gap: 10px;
  font-size: 14px;
  background-color: ${(p) => p.theme.palette.white};
  color: ${(p) => p.theme.palette.neutralPrimary};
  @media (max-width: 500px) {
    flex-direction: column;
    &&.draft {
      flex-direction: column;
    }
  }
  &.not-draft {
    justify-content: flex-start;
    ${Bubble} {
      border-radius: 0px 40px 40px 40px;
    }
  }
  &.draft {
    justify-content: end;
    flex-direction: row-reverse;
    ${Bubble} {
      justify-self: flex-end;
      border-radius: 40px 0px 40px 40px;
    }
  }
`;
