import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  Recommendation,
  RecommendationAzure,
  RecommendationAzureResponse,
} from "../../types/types";
import { RootState } from "../store";
import { createAsyncActions } from "../util";

export type RecommendationsState = {
  isLoading: boolean;
  count: number | null;
  selectedColumn: Recommendation | null;
  hoveredColumn: Recommendation | null;
};

export const recommendationsAdapter = createEntityAdapter<RecommendationAzure>({
  selectId: (r) => r.recommendationId,
  // sortComparer: (a, b) => b.recommendationId - a.recommendationId,
});

export const loadRecommendationsActions = createAsyncActions<
  { top: number; skip: number; reset: boolean },
  void,
  {
    res: RecommendationAzureResponse;
    reset: boolean;
  }
>("recommendations/load");

export const recommendationsSlice = createSlice({
  name: "recommendations",
  initialState: recommendationsAdapter.getInitialState<RecommendationsState>({
    isLoading: false,
    count: null,
    selectedColumn: null,
    hoveredColumn: null,
  }),
  reducers: {
    setSelectedColumn: (s, a) => {
      s.selectedColumn = a.payload;
    },
    setRecommendationsIds(s, action: PayloadAction<string[]>) {
      s.ids = action.payload;
    },
    updateRecommendation: recommendationsAdapter.updateOne,
    upsertRecommendation: recommendationsAdapter.upsertOne,
    removeAll: recommendationsAdapter.removeAll,
    removeOne: recommendationsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(loadRecommendationsActions.loading, (s, a) => {
      s.isLoading = true;
    });
    builder.addCase(loadRecommendationsActions.success, (s, a) => {
      s.isLoading = false;
      const { res, reset } = a.payload;
      s.count = res["@odata.count"];
      if (reset) {
        recommendationsAdapter.setAll(s, res.value);
      }
      //
      else {
        recommendationsAdapter.addMany(s, res.value);
      }
    });
    builder.addCase(loadRecommendationsActions.error, (s, a) => {
      s.isLoading = false;
    });
  },
});

export const recommendationsReducer = recommendationsSlice.reducer;

export const {
  updateRecommendation,
  upsertRecommendation,
  removeAll: removeAllRecommendations,
  setSelectedColumn,
  removeOne,
  setRecommendationsIds,
} = recommendationsSlice.actions;

export const {
  selectAll: selectAllRecommendations,
  selectEntities: selectEntitiesRecommendations,
} = recommendationsAdapter.getSelectors<RootState>((s) => s.recommendations);
