import clsx from "clsx";
import {
  DirectionalHint,
  Icon,
  Label,
  Link,
  Persona,
  PersonaPresence,
  PersonaSize,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Flex } from "../../../../../components/styled";
import { useSelector } from "../../../../../store/hooks";
import { selectByIdLocations } from "../../../../../store/locations/locations";
import {
  assessmentShowHideActions,
  setDeleteAssessmentDialog,
} from "../../../../../store/recommendationPage";
import { AssessmentWithAttachments } from "../../../../../types/types";
import {
  formatDateString,
  getFileExtension,
  getFileIcon,
  getIconColor,
} from "../../../../../utils/utils";
import { ImprovementPotential } from "./ImprovementPotential";
import { StatusCompanyCode } from "./StatusCompanyCode";
import { StatusGrecoCode } from "./StatusGrecoCode";
import { TooltipComponent } from "components/TooltipComponent";

type Props = {
  assessment: AssessmentWithAttachments;
};

export const Assessment = ({ assessment: r }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const rec = useSelector((s) => s.recommendationPage.rec);
  const isWaitingForClient = rec?.recommendationStatusCode === 2;
  const isShowHideLoading = useSelector(
    (s) => s.recommendationPage.isShowHideLoading
  );

  const { locationId } = useParams() as any;
  const urlSearchParams = new URLSearchParams(location.search);
  const assessmentId = urlSearchParams.get("assessmentId");
  const loc = useSelector((s) => selectByIdLocations(s, locationId));
  const isAssessmentPeriod = loc?.assessmentPeriodStartedAt ? true : false;
  const userRole = useSelector((s) => s.userRole.userRole);
  const dispatch = useDispatch();
  const [showinsurerButtons, setShowInsurerButtons] = React.useState(false);

  useEffect(() => {
    if (userRole !== "insurer" && userRole !== "external") {
      setShowInsurerButtons(true);
    } else {
      setShowInsurerButtons(false);
    }
  }, [userRole]);

  const bubbleStyle = useMemo(() => {
    let retVal: any = {};
    if (r.assessment.hideFromInsurer) {
      retVal.border = "1px dashed #faa";
    }
    if (assessmentId === r.assessment.assessmentId + "") {
      retVal.borderWidth = "3px";
    } else {
      retVal.borderWidth = "1px";
    }
    return retVal;
  }, [r.assessment, assessmentId]);

  useEffect(() => {
    if (
      document.getElementById("assessment_" + assessmentId) &&
      document.getElementById("assessments-tab-description")
    ) {
      document.getElementById("assessments-tab").scrollTop =
        document.getElementById("assessment_" + assessmentId).offsetTop +
        document.getElementById("assessments-tab-description").offsetHeight +
        50;
    }
  }, [assessmentId, rec]);

  return (
    <Root
      id={"assessment_" + r.assessment.assessmentId}
      data-assessmentid={r.assessment.assessmentId}
      className={clsx({
        draft: !r.assessment.isArchived,
        "not-draft": r.assessment.isArchived,
      })}
    >
      <Persona
        title={r.assessment.assessmentCreatedByUserName}
        text={r.assessment.assessmentCreatedByUserName}
        presence={PersonaPresence.online}
        styles={{
          root: {
            width: "40px",
            alignSelf: "flex-start",
            marginTop: "10px",
          },
        }}
        size={PersonaSize.size40}
      />
      <Bubble style={bubbleStyle}>
        <Top>
          <BubbleWrap>
            <div>
              <Label>{t("ram.assessmentsPage.dateOfAssessment")}</Label>
              {r.assessment.dateOfAssessment &&
                formatDateString(r.assessment.dateOfAssessment)}
            </div>
            <ImprovementPotential
              improvementPotentialCode={r.assessment.improvementPotentialCode}
            />
          </BubbleWrap>

          <Flex direction="column" gap={5}>
            <Flex
              direction="column"
              style={{
                visibility:
                  userRole === "admin" ||
                  userRole === "internal" ||
                  userRole === "internal_role" ||
                  userRole === "external_advanced"
                    ? "visible"
                    : "hidden",
              }}
            >
              <PrimaryButton
                text={t("greco.delete")}
                iconProps={{ iconName: "Trash" }}
                onClick={() => {
                  dispatch(
                    setDeleteAssessmentDialog({
                      isOpen: true,
                      assessment: r.assessment,
                    })
                  );
                }}
              />
            </Flex>
          </Flex>
        </Top>
        <Middle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "25px",
            }}
          >
            <StatusCompanyCode statusCompany={r.assessment.statusCompanyCode} />
            <StatusGrecoCode statusGrECo={r.assessment.statusGrEcoCode} />
          </div>
        </Middle>
        <div
          style={{
            paddingTop: "10px",
          }}
        >
          <div
            style={{
              paddingTop: "10px",
            }}
          >
            <Label>{t("ram.assessmentsPage.comment")}</Label>
            <div dangerouslySetInnerHTML={{ __html: r.assessment.comment }} />
          </div>
          {r.assessmentAttachments.length !== 0 && (
            <div>
              <Label>{t("ram.assessmentsPage.attachments")}</Label>
              {r.assessmentAttachments?.map((a) => {
                return (
                  <TooltipComponent
                    key={a.attachmentId}
                    content={a.attachmentName}
                    directionalHint={DirectionalHint.leftTopEdge}
                  >
                    <div
                      key={a.attachmentId}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Icon
                        iconName={getFileIcon(
                          getFileExtension(a.attachmentName)
                        )}
                        style={{
                          color: getIconColor(
                            getFileExtension(a.attachmentName)
                          ),
                        }}
                        styles={{
                          root: {
                            marginRight: "5px",
                            fontSize: "14px",
                          },
                        }}
                      ></Icon>
                      <Link
                        styles={{
                          root: {
                            fontSize: "14px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            flex: 1,
                          },
                        }}
                        onClick={() => {
                          window.open(a.attachmentPath);
                        }}
                      >
                        {a.attachmentName}
                      </Link>
                    </div>
                  </TooltipComponent>
                );
              })}
            </div>
          )}
        </div>

        {showinsurerButtons ? (
          isShowHideLoading ? (
            <InsurerCheckboxSection>
              <InsurerSpinner>
                <Spinner />
              </InsurerSpinner>
            </InsurerCheckboxSection>
          ) : (
            <InsurerCheckboxSection>
              <InsurerCheckboxField
                onClick={() => {
                  if (isShowHideLoading) return;
                  dispatch(
                    assessmentShowHideActions.trigger({
                      assessment: r.assessment,
                    })
                  );
                }}
              >
                {r.assessment.hideFromInsurer
                  ? t("ram.assessmentPage.hiddenFromInsurer")
                  : t("ram.assessmentPage.displayedToInsurer")}
              </InsurerCheckboxField>
            </InsurerCheckboxSection>
          )
        ) : null}
      </Bubble>
    </Root>
  );
};

const InsurerCheckboxSection = styled.div`
  padding: 10px 0;
  border-top: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
`;

const InsurerSpinner = styled.div`
  float: right;
  text-align: right;
  margin-left: auto;
  margin-right: 45px;
  padding: 8px 15px;
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralSecondary};
  &:hover {
    // text-decoration: underline;
    color: ${(p) => p.theme.palette.neutralPrimary};
    cursor: pointer;
  }
`;

const InsurerCheckboxField = styled.div`
  float: right;
  text-align: right;
  margin-left: auto;
  padding: 8px 15px;
  font-size: 14px;
  border: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
  color: ${(p) => p.theme.palette.neutralSecondary};
  &:hover {
    // text-decoration: underline;
    color: ${(p) => p.theme.palette.neutralPrimary};
    cursor: pointer;
  }
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
  padding-bottom: 10px;
  @media (max-width: 700px) {
    padding-bottom: 0;
  }
`;

const Middle = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
`;
const BubbleWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex: 1;
  gap: 10px;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    > *:last-child {
      margin-bottom: 10px;
    }
  }
`;

export const Bubble = styled.div`
  padding: 15px 20px;
  border: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
  max-width: 600px;
  flex: 1;
  background-color: ${(p) => p.theme.palette.white};
`;

export const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
  gap: 10px;
  font-size: 14px;
  background-color: ${(p) => p.theme.palette.white};
  color: ${(p) => p.theme.palette.neutralPrimary};
  @media (max-width: 500px) {
    flex-direction: column;
    &&.draft {
      flex-direction: column;
    }
  }
  &.not-draft {
    justify-content: center;
    ${Bubble} {
      background-color: ${(p) => p.theme.palette.neutralLighter};
      justify-self: flex-end;
      border-radius: 0px 0px 0px 0px;
    }
  }
  &.draft {
    justify-content: center;
    flex-direction: row-reverse;
    ${Bubble} {
      background-color: ${(p) => p.theme.palette.neutralLighter};
      justify-self: flex-end;
      border-radius: 0px 0px 0px 0px;
    }
  }
`;
