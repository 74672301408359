import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../store/hooks";
import { setSearchLocations } from "../../../store/locations-page/locationsPage";
import { AppDispatch } from "../../../store/store";
import { NavigationSearch } from "@greco/components";
import { useTranslation } from "react-i18next";


export const Search = () => {
  const search = useSelector((s) => s.locationsPage.search);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <NavigationSearch
      value={search}
      placeholder={t("ram.clientsList.search")}
      onChange={(val) => {
        dispatch(setSearchLocations(val));
      }}
    />
  );
};
