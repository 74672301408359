import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { NoData } from "../../../components/NoData";
import { useSelector } from "../../../store/hooks";
import { Label, Row } from "./RecommendationPanel";

export const NoGo = () => {
  const { t } = useTranslation();
  const loadRecommendationStatus = useSelector(
    (s) => s.recommendationPanel.loadRecommendationStatus
  );
  const recommendation = useSelector(
    (s) => s.recommendationPanel.recommendation
  );
  const isLoading = loadRecommendationStatus === "loading";

  let render;
  if (isLoading) render = <Skeleton width={150} />;
  else if (!recommendation) render = <NoData />;
  else render = recommendation.noGo ? t("greco.yes") : t("greco.no");

  return (
    <Row>
      <Label>{t("greco.required")}:</Label>
      {render}
    </Row>
  );
};
