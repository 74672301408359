import i18next, { TFunction } from "i18next";
import pdfMake from "pdfmake";
import { accent, recommendationStatusColors } from "../config/colors";
import { TaxonomyState } from "../store/taxonomy/taxonomy";
import { ExpandedColumn } from "../types/columns";
import { RecommendationAzure } from "../types/types";
import { formatDateWithTime } from "./utils";

export const createPDFRecommendations = ({
  recommendations,
  columns,
  taxonomy,
  t,
}: {
  recommendations: RecommendationAzure[];
  columns: ExpandedColumn[];
  taxonomy: TaxonomyState;
  t: TFunction;
}) => {
  const docDefinition = {
    pageOrientation: "landscape",
    pageMargins: [20, 20, 20, 20],
    pageSize: {
      width:
        columns.reduce((sum, c) => {
          if (c.pdf && c.pdf.width !== undefined) {
            return sum + c.pdf.width;
          }
          return sum + c.width;
        }, 0) * 1.1,
      height: 1200,
    },
    info: {
      title: `${t("ram.selectMenu.recommendations")} ${formatDateWithTime(
        new Date()
      )}`,
    },
    content: [
      {
        columns: [
          {
            width: 300,
            stack: [
              {
                columns: [
                  {
                    text: "",
                    style: {
                      font: "Remix",
                      color:
                        recommendationStatusColors[
                          "RiskRecommendationStatus.WAITING_FOR_GREG"
                        ],
                      fontSize: 10,
                    },
                    width: 10,
                  },
                  {
                    text: t("RiskRecommendationStatus.WAITING_FOR_GREG"),
                  },
                ],
                columnGap: 2,
              },
              {
                columns: [
                  {
                    text: "",
                    style: {
                      font: "LineAwesome",
                      color:
                        recommendationStatusColors[
                          "RiskRecommendationStatus.WAITING_FOR_GREG"
                        ],
                      fontSize: 13,
                    },
                    width: 13,
                  },
                  {
                    text: `${t(
                      "RiskRecommendationStatus.WAITING_FOR_GREG"
                    )} ${t("greco.and")} ${t("ram.assessed")}`,
                  },
                ],
                columnGap: 2,
              },
              {
                columns: [
                  {
                    text: "",
                    style: {
                      font: "Remix",
                      color: accent.orangeLighter,
                      fontSize: 10,
                    },
                    width: 10,
                  },
                  {
                    text: `${t(
                      "RiskRecommendationStatus.WAITING_FOR_GREG"
                    )} ${t("greco.and")} ${t("ram.assessmentPeriodStarted")}`,
                  },
                ],
                columnGap: 2,
              },
              {
                columns: [
                  {
                    text: "",
                    style: {
                      font: "LineAwesome",
                      color: accent.orangeLighter,
                      fontSize: 13,
                    },
                    width: 13,
                  },
                  {
                    text: `${t(
                      "RiskRecommendationStatus.WAITING_FOR_GREG"
                    )}, ${t("ram.assessmentPeriodStarted")} ${t(
                      "greco.and"
                    )} ${t("ram.assessed")}`,
                  },
                ],
                columnGap: 2,
              },
              {
                columns: [
                  {
                    text: "",
                    style: {
                      font: "Remix",
                      color:
                        recommendationStatusColors[
                          "RiskRecommendationStatus.WAITING_FOR_CLIENT"
                        ],
                      fontSize: 10,
                    },
                    width: 10,
                  },
                  {
                    text: t("RiskRecommendationStatus.WAITING_FOR_CLIENT"),
                  },
                ],
                columnGap: 2,
              },
              {
                columns: [
                  {
                    text: "",
                    style: {
                      font: "Remix",
                      color:
                        recommendationStatusColors[
                          "RiskRecommendationStatus.CLOSED"
                        ],
                      fontSize: 10,
                    },
                    width: 10,
                  },
                  {
                    text: t("RiskRecommendationStatus.CLOSED"),
                  },
                ],
                columnGap: 2,
              },
            ],
          },
          // {
          //   stack: [
          //     {
          //       columns: [
          //         {
          //           text: "",
          //           style: {
          //             font: "Remix",
          //             color: accent.redDark,
          //             fontSize: 17,
          //           },
          //           width: 17,
          //         },
          //         {
          //           text: t("greco.required"),
          //         },
          //       ],
          //       columnGap: 2,
          //     },
          //     {
          //       columns: [
          //         {
          //           text: "",
          //           style: {
          //             font: "LineAwesome",
          //             color: accent.redDark,
          //             fontSize: 17,
          //           },
          //           width: 17,
          //         },
          //         {
          //           text: t("ram.recommendations.hasDraft"),
          //         },
          //       ],
          //       columnGap: 2,
          //     },
          //     {
          //       columns: [
          //         {
          //           text: "",
          //           style: {
          //             font: "LineAwesome",
          //             color: accent.redDark,
          //             fontSize: 17,
          //           },
          //           width: 17,
          //         },
          //         {
          //           text: t("greco.overdue"),
          //         },
          //       ],
          //       columnGap: 2,
          //     },
          //     {
          //       columns: [
          //         {
          //           text: "",
          //           style: {
          //             font: "LineAwesome",
          //             color: accent.redDark,
          //             fontSize: 17,
          //           },
          //           width: 17,
          //         },
          //         {
          //           text: t("ram.recommendations.plannedImplementationLate"),
          //         },
          //       ],
          //       columnGap: 2,
          //     },
          //   ],
          // },
        ],
      },
      { text: " " },
      {
        table: {
          headerRows: 1,
          widths: columns.map((c) => {
            if (c.pdf && c.pdf.width !== undefined) {
              return c.pdf.width;
            }
            return c.width;
          }),
          body: [
            columns.map((c) => {
              if (c.pdf && c.pdf.label !== undefined) {
                return {
                  text: c.pdf.label,
                  style: "headerCell",
                };
              }
              return {
                text: i18next.t(c.labelKey),
                style: "headerCell",
              };
            }),
          ].concat(
            recommendations.map((r) => {
              return columns.map((c) => {
                const value = r[c.key];
                if (c.pdf && c.pdf.render) {
                  try {
                    return {
                      style: "bodyCell",
                      ...c.pdf.render({
                        recommendation: r,
                        taxonomy,
                        t: i18next.t.bind(i18next),
                      }),
                    };
                  } catch (err) {
                    console.log(err);
                  }
                }
                if (value === undefined || value === null) return "";
                return {
                  text: value,
                  style: "bodyCell",
                };
              });
            })
          ),
        },
      },
    ],
    styles: {
      headerCell: {
        fillColor: "#005aa1",
        color: "#fff",
        bold: true,
        alignment: "center",
      },
      bodyCell: {
        alignment: "center",
      },
    },
    defaultStyle: {
      font: "SegoeUI",
    },
  };

  return pdfMake.createPdf(docDefinition);
};
