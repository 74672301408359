import debounce from "debounce-promise";
import { Icon, Label } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { searchUsers } from "../../api/api";
import { PeoplePicker } from "../../components/PeoplePicker";
import { ErrorMessage } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import { getSelectStyles } from "../../utils/utils";
import { FieldContainer } from "../../components/controls/FieldContainer";
import { mergeStyles } from "react-select";
import { useTheme } from "styled-components";
import { IStyledTheme } from "../../theme/types";

export const ReportInsurers = ({ value, error, name, setFieldValue }) => {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const userRole = useSelector((s) => s.userRole.userRole);
  const report = useSelector((s) => s.reportPage.report);
  const isDisabled = report?.isReportDeactivated === true;

  const errorId = t("ram.reportForm.addUsers").replaceAll(" ", "_") + "_error";

  return (
    <>
      <FieldContainer
        isTooltipHidden={false}
        tooltipText={"ram.reportForm.addUsers.tooltip"}
      >
        <Label id="aria-label" htmlFor="aria-example-input">
          <Icon
            iconName="AddFriend"
            style={{
              marginRight: "5px",
              fontSize: "16px",
            }}
          />
          {t("ram.reportForm.addUsers.label")}
        </Label>
        <PeoplePicker
          loadOptions={debounce(async (input: string) => {
            const trimmedInput = input?.trim();
            if (trimmedInput.length === 0) return [];
            const users = await searchUsers(trimmedInput);

            return users
              .filter((u) => !value?.find((o) => o.value?.userId === u.userId))
              .map((u) => {
                return {
                  label: u.userName,
                  user: u,
                  value: u.userId,
                  userName: u.userName,
                  userEmailAddress: u.userEmailAddress,
                };
              });
          }, 300)}
          value={value}
          onChange={(value) => {
            setFieldValue(name, value);
          }}
          isMulti
          isDisabled={userRole !== "admin" || isDisabled}
          styles={mergeStyles(getSelectStyles(!!error, theme), {
            multiValue: (p) => ({
              ...p,
              background: theme.palette.neutralLight,
              "& *": {
                color: theme.palette.black,
              },
            }),
          })}
        />
        {!!error && <ErrorMessage id={errorId}>{error}</ErrorMessage>}
      </FieldContainer>
    </>
  );
};
