import atlas from "azure-maps-control";

const geocodeServiceUrlTemplate =
  "https://{azMapsDomain}/search/{searchType}/json?typeahead=true&api-version=1.0&query={query}&view=Auto&&subscription-key={subscriptionKey}";
const azureMapsClientId = process.env.REACT_APP_AZURE_MAPS_CLIENT_ID;
const azureMapsSubscriptionKey = process.env.REACT_APP_AZURE_MAPS_KEY;

const tokenServiceUrl = "https://samples.azuremaps.com/api/GetAzureMapsToken";

export async function getAzureMapsToken() {
  const response = await fetch(tokenServiceUrl);
  if (!response.ok) {
    throw new Error("Failed to retrieve Azure Maps token.");
  }
  return response.text();
}

export async function azureMapsSearch(query, map) {
  const center = map.getCamera().center;
  const countryIso = "US"; // Replace with your country code logic

  const requestUrl = geocodeServiceUrlTemplate
    .replace("{azMapsDomain}", "atlas.microsoft.com")
    .replace("{subscriptionKey}", azureMapsSubscriptionKey)
    .replace("{query}", encodeURIComponent(query))
    .replace("{searchType}", "address");

  const response = await fetch(requestUrl, {
    headers: {
      "x-ms-client-id": azureMapsClientId,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch search results.");
  }

  const data = await response.json();
  return data.results;
}
