import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { NoData } from "../../../components/NoData";
import { Right } from "../../../components/styled";
import { useSelector } from "../../../store/hooks";
import { formatNumber } from "../../../utils/number";
import { BottomRow, BottomRowRightText } from "./RecommendationPanel";

export const CostBenifitFactor = () => {
  const { t } = useTranslation();
  const recommendation = useSelector(
    (s) => s.recommendationPanel.recommendation
  );
  const loadRecommendationStatus = useSelector(
    (s) => s.recommendationPanel.loadRecommendationStatus
  );
  const isLoading = loadRecommendationStatus === "loading";

  let render;
  if (isLoading) render = <Skeleton width={100} />;
  else if (!recommendation) render = <NoData />;
  else if (recommendation.costBenifitFactor === null) render = <NoData />;
  else
    render = (
      <BottomRowRightText>
        {formatNumber(recommendation.costBenifitFactor)}
      </BottomRowRightText>
    );

  return (
    <BottomRow>
      <strong>{t("ram.recommendationForm.costBenifitFactor.label")}:</strong>
      <Right>{render}</Right>
    </BottomRow>
  );
};
