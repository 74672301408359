import { useFormikContext } from "formik";
import { useEffect } from "react";
import { transformNumberValue } from "../../../form/util";

const implementationCostsEurMaxValue = 100_000_000_000;
const emlReductionPercentMaxValue = 100;

export function useEmlReductionPercentMaxValueValidation() {
  const { values, setFieldValue } = useFormikContext<any>();
  useEffect(() => {
    if (values?.emlReductionPercent) {
      if (
        transformNumberValue(values?.emlReductionPercent.toString()) >
        emlReductionPercentMaxValue
      ) {
        setFieldValue(
          "emlReductionPercent",
          emlReductionPercentMaxValue.toString()
        );
      }
    }
  }, [setFieldValue, values?.emlReductionPercent]);
}

export function useImplementationCostsMaxValueValidation() {
  const { values, setFieldValue } = useFormikContext<any>();
  useEffect(() => {
    if (values?.implementationCostsEur) {
      if (
        transformNumberValue(values?.implementationCostsEur.toString()) >
        implementationCostsEurMaxValue
      ) {
        setFieldValue(
          "implementationCostsEur",
          implementationCostsEurMaxValue.toString()
        );
      }
    }
  }, [setFieldValue, values?.implementationCostsEur]);
}
