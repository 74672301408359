import { AccountInfo } from "@azure/msal-browser";
import { initializeIcons } from "@uifabric/icons";
import { mergeStyles } from "@fluentui/react";
import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Axios from "axios";

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "react-virtualized/styles.css";
import "../node_modules/@greco/services/dist/lib/main.css";
import "../node_modules/react-toastify/dist/ReactToastify.css";
import { MSGraphAuth } from "./api/GraphService";
import { App } from "./App";
import { RamBFFParams } from "./config/MSGraphConfig";
import "./i18n";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store/store";
import { GrecoSpinner } from "@greco/components";

import { QueryClient, QueryClientProvider } from "react-query";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const isDev = process.env.NODE_ENV === "development";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const APP_URL = isDev ? "http://localhost:3000" : process.env.REACT_APP_APP_URL;

pdfMake.fonts = {
  SegoeUI: {
    normal: `${APP_URL}/fonts/SEGOEUI.ttf`,
    bold: `${APP_URL}/fonts/SEGOEUI.ttf`,
  },
  Remix: {
    normal: `${APP_URL}/fonts/remixicon.ttf`,
  },
  LineAwesome: {
    normal: `${APP_URL}/fonts/la-regular-400.ttf`,
  },
};

mergeStyles({
  selectors: {
    ":global(body), :global(html), :global(#root)": {
      margin: 0,
      padding: 0,
      height: "100vh",
    },
  },
});

Axios.interceptors.request.use(async (config) => {
  let authResponse = await MSGraphAuth.getAccessToken(RamBFFParams);
  if (!authResponse) return config;
  if (config.url?.indexOf("thumbnailPhoto") !== -1) {
    return config;
  }
  config.headers.Authorization = `Bearer ${authResponse.accessToken}`;
  return config;
});

const startApplication = () => {
  const queryClient = new QueryClient();

  initializeIcons();
  root.render(
    <Suspense fallback={<GrecoSpinner />}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
        </Provider>
      </QueryClientProvider>
    </Suspense>
  );
};

MSGraphAuth.accountObj
  .then((accountInfo: AccountInfo | null) => {
    if (accountInfo) {
      startApplication();
    } else {
      MSGraphAuth.login();
    }
  })
  .catch((error) => {
    if (error === "There isn't any account detected.") {
      MSGraphAuth.login();
    } else {
      console.log(error);
    }
  });

serviceWorker.unregister();
