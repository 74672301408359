import { differenceInDays } from "date-fns";
import { RecommendationAzure } from "../types/types";

export const isPlannedImplementationLate = (
  recommendation: RecommendationAzure
) => {
  return (
    recommendation.plannedImplementationDate &&
    new Date() > new Date(recommendation.plannedImplementationDate)
  );
};

const dueDays = process.env.REACT_APP_CLIENT_RESPONSE_DUE_DAYS;

export const isLate = (recommendation: RecommendationAzure) => {
  const currentDate = new Date();
  const lastStatusDate = new Date(recommendation.recommendationLastStatusTime);
  const n = dueDays ? Number(dueDays) : 60;
  const dayDiff = differenceInDays(currentDate, lastStatusDate);
  const isLate = dayDiff > n;

  return isLate;
};
