import { mergeStyleSets } from "@fluentui/react";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { ClientName } from "./components/ClientName";
import LocationFields from "./components/LocationFields";
import { RiskUsers } from "./components/RiskUsers/RiskUsers";
import { useSelector } from "../../store/hooks";
import DoubleNumberInputField from "../../components/controls/DoubleNumberInputField";
import TextInputField from "../../components/controls/TextInputField";
import { transformNumberValue } from "../../form/util";

const riskUserRoles = [
  "admin",
  "internal",
  "internal_role",
  "external_advanced",
];

const maxNumberValue = 1000000;

const numberFields = [
  "turnoverInMioEur",
  "tsiinMioEur",
  "pmlinMioEur",
  "emlinMioEur",
  "ebitinMioEur",
  "ygpcminMioEur",
  "deductiblesInMioEur",
];

function LocationForm() {
  const userRole = useSelector((s) => s.userRole.userRole);

  const { values, handleChange, errors, setFieldValue } =
    useFormikContext<any>();

  const showRiskUsers = riskUserRoles.includes(userRole);

  useEffect(() => {
    numberFields.forEach((field) => {
      if (values[field]) {
        if (transformNumberValue(values[field].toString()) > maxNumberValue) {
          setFieldValue(field, maxNumberValue.toString());
        }
      }
    });
  }, [values]);

  return (
    <div className={classes.grid}>
      <div className={classes.gridRow}>
        <ClientName name="webBasePartnerNumber" required />
        <LocationFields />
      </div>
      <div className={classes.gridRow}>
        <DoubleNumberInputField
          required
          setFieldValue={setFieldValue}
          value={values?.turnoverInMioEur}
          onChange={handleChange}
          isNegativeAllowed
          errors={errors}
          name={"turnoverInMioEur"}
          disabled={false}
          label={"ram.locationForm.turnoverInMioEUR.label"}
        />
        <DoubleNumberInputField
          required
          setFieldValue={setFieldValue}
          value={values?.tsiinMioEur}
          onChange={handleChange}
          isNegativeAllowed
          errors={errors}
          name={"tsiinMioEur"}
          disabled={false}
          label={"ram.locationForm.TSIInMioEUR.label"}
        />
        <DoubleNumberInputField
          required={false}
          setFieldValue={setFieldValue}
          value={values?.pmlinMioEur}
          onChange={handleChange}
          isNegativeAllowed
          errors={errors}
          name={"pmlinMioEur"}
          disabled={false}
          label={"ram.locationForm.pmlinMioEur.label"}
        />
        <DoubleNumberInputField
          required={false}
          setFieldValue={setFieldValue}
          value={values?.emlinMioEur}
          onChange={handleChange}
          isNegativeAllowed
          errors={errors}
          name={"emlinMioEur"}
          disabled={false}
          label={"ram.locationForm.EMLInMioEUR.label"}
        />
        <DoubleNumberInputField
          required={false}
          setFieldValue={setFieldValue}
          value={values?.ebitinMioEur}
          onChange={handleChange}
          isNegativeAllowed
          errors={errors}
          name={"ebitinMioEur"}
          disabled={false}
          label={"ram.locationForm.EBITInMioEUR.label"}
        />
        <DoubleNumberInputField
          required={false}
          setFieldValue={setFieldValue}
          value={values?.ygpcminMioEur}
          onChange={handleChange}
          isNegativeAllowed
          errors={errors}
          name={"ygpcminMioEur"}
          disabled={false}
          label={"ram.locationForm.YGPCMInMioEUR.label"}
        />
        <DoubleNumberInputField
          required={false}
          setFieldValue={setFieldValue}
          value={values?.deductiblesInMioEur}
          onChange={handleChange}
          isNegativeAllowed
          errors={errors}
          name={"deductiblesInMioEur"}
          disabled={false}
          label={"ram.locationForm.deductiblesInMioEUR.label"}
        />
        <TextInputField
          value={values?.insurersRaiting}
          onChange={handleChange}
          errors={errors}
          name={"insurersRaiting"}
          multiline
          rows={4}
          // resizable={false}
          disabled={false}
          label={"ram.locationForm.insurersRaiting.label"}
        />
        {showRiskUsers && <RiskUsers />}
      </div>
    </div>
  );
}

export default LocationForm;

const classes = mergeStyleSets({
  grid: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
    overfow: "auto",
  },
  gridRow: {
    width: "100%",
  },
});
