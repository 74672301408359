import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { Setting, SettingsResponse, Taxonomy } from "../types/types";
import { RootState } from "./store";
import {
  loadTaxonomyUADActions,
  selectTaxonomyByCode,
  TaxonomyKey,
} from "./taxonomy/taxonomy";
import { createAsyncActions, handleAxiosError } from "./util";
import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import * as API from "../api/api";
import {
  setColumnsRecommendations,
  setSelectedViewIdRecommendations,
} from "./recommendations-page/recommendationsPage";
import { selectColumnsSelectedView } from "./recommendations-page/selectors";
import { setColumns } from "./recommendationsColumnOptions";

export type SettingsState = {
  isLoading: boolean;
  isLoadingCreate: boolean;
  isLoadingDelete: boolean;
  isLoadingUpdate: boolean;
  isLoaded: boolean;
  error: string | null;
};

export const loadSettingsActions = createAsyncActions<
  void,
  void,
  SettingsResponse
>("settings/load");

export const createSettingActions = createAsyncActions<
  {
    setting: Partial<Setting>;
    onSuccess: () => void;
  },
  void,
  {
    newSetting: Setting;
    allSettings: Setting[];
  }
>("settings/create");

export const updateSettingActions = createAsyncActions<
  {
    setting: Partial<Setting>;
    onSuccess: () => void;
  },
  void,
  Setting[]
>("settings/update");

export const deleteSettingActions = createAsyncActions<
  Setting["userAppSettingId"],
  void,
  Setting[]
>("settings/delete");

export const settingsAdapter = createEntityAdapter<Setting>({
  selectId: (s) => s.userAppSettingId,
});

export const settingsSlice = createSlice({
  name: "settings",
  initialState: settingsAdapter.getInitialState({
    isLoading: false,
    isLoadingCreate: false,
    isLoadingDelete: false,
    isLoaded: false,
  } as SettingsState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadSettingsActions.loading, (s, a) => {
      s.isLoading = true;
    });
    builder.addCase(loadSettingsActions.success, (s, a) => {
      s.isLoading = false;
      s.isLoaded = true;
      settingsAdapter.setAll(s, a.payload);
    });
    builder.addCase(loadSettingsActions.error, (s, a) => {
      s.isLoading = false;
    });
    builder.addCase(createSettingActions.loading, (s, a) => {
      s.isLoadingCreate = true;
    });
    builder.addCase(createSettingActions.success, (s, a) => {
      const { allSettings } = a.payload;
      settingsAdapter.setAll(s, allSettings);
      s.isLoadingCreate = false;
    });
    builder.addCase(createSettingActions.error, (s, a) => {
      s.isLoadingCreate = false;
      const error = a.payload;
      s.error = error.response?.data as any;
    });
    builder.addCase(updateSettingActions.loading, (s, a) => {
      s.isLoadingUpdate = true;
    });
    builder.addCase(updateSettingActions.success, (s, a) => {
      const settings = a.payload;
      settingsAdapter.setAll(s, settings);
      s.isLoadingUpdate = false;
    });
    builder.addCase(updateSettingActions.error, (s, a) => {
      s.isLoadingUpdate = false;
    });
    builder.addCase(deleteSettingActions.loading, (s, a) => {
      s.isLoadingDelete = true;
    });
    builder.addCase(deleteSettingActions.success, (s, a) => {
      const settings = a.payload;
      settingsAdapter.setAll(s, settings);
      s.isLoadingDelete = false;
    });
    builder.addCase(deleteSettingActions.error, (s, a) => {
      s.isLoadingDelete = false;
    });
  },
});

export const settingsReducer = settingsSlice.reducer;

export const {
  selectAll: selectAllSettings,
  selectEntities: selectEntitiesSettings,
  selectById: selectSettingById,
} = settingsAdapter.getSelectors<RootState>((s) => s.settings);

export const selectColumnOptionsSettings = createSelector(
  selectAllSettings,
  selectTaxonomyByCode,
  (settings, getTaxonomyByCode) => {
    const ramTaxonomy = getTaxonomyByCode("ApplicationCode", "Application.RAM");
    const columnOptionsSettingTaxonomy = getTaxonomyByCode(
      "ApplicationSetting",
      "AppSetting.ColumnOptions"
    );
    if (!columnOptionsSettingTaxonomy || !ramTaxonomy) return [];
    return settings.filter((s) => {
      return (
        s.appSettingCodeId === columnOptionsSettingTaxonomy.id &&
        s.applicationCodeId === ramTaxonomy.id
      );
    });
  }
);

export function* loadSettingsSaga() {
  yield takeEvery(loadTaxonomyUADActions.success.type, function* () {
    const tax = yield select(
      (s) => s.taxonomy.ApplicationCode.byCode["Application.RAM"]
    );
    try {
      yield put(loadSettingsActions.loading());
      const res = yield call(API.getAppSettings, tax.id);
      yield put(loadSettingsActions.success(res.data));
    } catch (err) {
      yield put(loadSettingsActions.error(err));
    }
  });
}

export function* loadSettingsSuccessSaga() {
  yield takeEvery(loadSettingsActions.success.type, function* () {
    const columnOptionsSettings = selectColumnOptionsSettings(yield select());
    const defaultView = columnOptionsSettings.find((s) => s.isDefault);
    if (defaultView) {
      yield put(setSelectedViewIdRecommendations(defaultView.userAppSettingId));
      let columns = selectColumnsSelectedView(yield select());

      const userRole = yield select((s) => s.userRole.userRole);

      if (userRole === "insurer") {
        columns = columns.filter(
          (col) =>
            col.key !== "dateOfLastResponse" &&
            col.key !== "commentOfLastResponse" &&
            col.key !== "numberOfResponses" &&
            col.key !== "dateOfLastAssessment" &&
            col.key !== "commentOfLastAssessment" &&
            col.key !== "numberOfAssessments" &&
            col.key !== "lastAction"
        );
      } else {
        columns = columns.filter(
          (col) =>
            col.key !== "dateOfLastResponseInsurer" &&
            col.key !== "commentOfLastResponseInsurer" &&
            col.key !== "numberOfResponsesInsurer" &&
            col.key !== "dateOfLastAssessmentInsurer" &&
            col.key !== "commentOfLastAssessmentInsurer" &&
            col.key !== "numberOfAssessmentsInsurer" &&
            col.key !== "lastActionInsurer"
        );
      }

      yield put(setColumnsRecommendations(columns));
      yield put(setColumns(columns));
    }
  });
}

export function* createSettingSaga() {
  yield takeEvery(
    createSettingActions.trigger.type,
    function* (a: ReturnType<typeof createSettingActions.trigger>) {
      try {
        const { setting: newSettingPartial } = a.payload;
        yield put(createSettingActions.loading());
        const res = yield call(API.addAppSetting, a.payload.setting);
        const allSettings = (res as any).data as Setting[];
        const applicationCodeTax = selectTaxonomyByCode(yield select())(
          "ApplicationCode",
          "Application.RAM"
        );
        const newSetting = allSettings.find((s) => {
          return (
            s.applicationCodeId === applicationCodeTax?.id &&
            s.userAppSettingName === newSettingPartial.userAppSettingName
          );
        });
        yield put(
          createSettingActions.success({
            allSettings,
            newSetting,
          })
        );
        a.payload.onSuccess();
      } catch (err) {
        handleAxiosError(err);
        yield put(createSettingActions.error(err));
      }
    }
  );
}

export function* createSettingSuccessSaga() {
  yield takeEvery(
    createSettingActions.success.type,
    function* (a: ReturnType<typeof createSettingActions.success>) {
      const { newSetting } = a.payload;
      yield put(setSelectedViewIdRecommendations(newSetting.userAppSettingId));
    }
  );
}

export function* updateSettingSaga() {
  yield takeEvery(updateSettingActions.trigger.type, function* (a: any) {
    try {
      yield put(updateSettingActions.loading());
      const res = yield call(API.updateAppSetting, a.payload.setting);
      if (res.data.length > 0) {
        const firstSettingAppSettingCodeId = res.data[0].appSettingCodeId;
        const settings = selectAllSettings(yield select()).filter(
          (el) => el.appSettingCodeId !== firstSettingAppSettingCodeId
        );
        yield put(updateSettingActions.success([...res.data, ...settings]));
      }
      a.payload.onSuccess();
    } catch (err) {
      yield put(updateSettingActions.error(err));
    }
  });
}

export function* deleteSettingSaga() {
  yield takeEvery(deleteSettingActions.trigger.type, function* (a: any) {
    try {
      yield put(deleteSettingActions.loading());
      const res = yield call(API.deleteAppSetting, a.payload);
      yield put(setSelectedViewIdRecommendations(null));
      yield put(deleteSettingActions.success(res.data));
    } catch (err) {
      yield put(deleteSettingActions.error(err));
    }
  });
}

export function* settingsSaga() {
  yield fork(loadSettingsSaga);
  yield fork(createSettingSaga);
  yield fork(updateSettingSaga);
  yield fork(deleteSettingSaga);
  yield fork(loadSettingsSuccessSaga);
  yield fork(createSettingSuccessSaga);
}
