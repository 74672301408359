import * as yup from "yup";
import { setYupLocale } from "../../../utils/setYupLocale";
import { useTranslation } from "react-i18next";

function useRecomendationFormValidationSchema() {
  const { t } = useTranslation();
  const validationSchema = (() => {
    setYupLocale(t);
    return yup.object().shape({
      dateOfRecommendation: yup.date().required().nullable(),
      recommendationNumberInternal: yup
        .string()
        .max(64)
        .nullable()
        .test(
          "or-external",
          t("ram.recommendationForm.recommendationNumber.validation"),
          function (value) {
            // "this.parent" refers to the entire object being validated, allowing access to other fields
            const { recommendationNumberExternal } = this.parent;
            // Check if either current field or the other field is filled
            return Boolean(
              value?.trim() || recommendationNumberExternal?.trim()
            );
          }
        ),
      recommendationNumberExternal: yup
        .string()
        .max(64)
        .nullable()
        .test(
          "or-internal",
          t("ram.recommendationForm.recommendationNumber.validation"),
          function (value) {
            // Access the sibling field's value
            const { recommendationNumberInternal } = this.parent;
            // Check if either current field or the other field is filled
            return Boolean(
              value?.trim() || recommendationNumberInternal?.trim()
            );
          }
        ),
      title: yup.string().trim().required().max(128).nullable(),
      description: yup.string().trim().max(16000).required().nullable(),
      typeCode: yup.mixed().required(),
      statusCompanyCode: yup.mixed().required(),
      statusGrECoCode: yup.mixed().required(),
      objectOrInfrastructure: yup.string().trim().max(254).nullable(),
      subCategory1Code: yup.mixed().required(),
      improvementPotentialCode: yup.mixed().required(),
      costEstimateCode: yup.mixed().required(),
    });
  })();

  return validationSchema;
}

export default useRecomendationFormValidationSchema;
