import {
  CreateEvaluationWithAttachmentsParams,
  createEvaluationWithAttachments,
} from "api/api";
import { useMutation } from "react-query";
import { MUTATION_KEYS } from "store/constants";

import { handleAxiosError } from "store/util";

const createEvaluation = async ({
  locationId,
  reportId,
  recommendationId,
  body,
}: CreateEvaluationWithAttachmentsParams) => {
  return await createEvaluationWithAttachments(
    locationId,
    reportId,
    recommendationId,
    body
  );
};

export function useCreateEvaluation() {
  return useMutation(createEvaluation, {
    mutationKey: MUTATION_KEYS.createEvaluation,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}
