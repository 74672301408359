import { Dialog, DialogFooter, Icon, TextField } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormSpinner } from "../../components/FormSpinner";
import { Flex, HSpace, StyledPrimaryButton } from "../../components/styled";
import { useSelector } from "../../store/hooks";

import { Text } from "@fluentui/react";
import {
  renameDefaultWeightingRoutine,
  setRenameDefaultWeightingDialog,
} from "store/defaultWeightings-page/defaultWeightingsPage";
import TextInputField from "components/controls/TextInputField";
import { FieldContainer } from "components/controls/FieldContainer";

type ClientState = {
  label: string;
  value: number;
} | null;

export const RenameDefaultWeightingDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { name } = useSelector(
    (s) => s.defaultWeightingsPage.renameDefaultWeightingDialog
  );
  const isLoading = useSelector(
    (s) =>
      s.defaultWeightingsPage.renameDefaultWeightingLoadStatus === "loading"
  );

  const [newName, setNewName] = useState<string>("");

  useEffect(() => {
    setNewName(name);
  }, [name]);

  const close = () => {
    dispatch(
      setRenameDefaultWeightingDialog({
        name: null,
        isOpen: false,
      })
    );
  };

  return (
    <Dialog
      minWidth={700}
      hidden={false}
      dialogContentProps={{
        showCloseButton: true,
        title: (
          <Flex>
            <Icon iconName="Switch" /> <HSpace />
            <div>{t("ram.renameDefaultWeighting.label")}</div>
          </Flex>
        ),
      }}
      onDismiss={close}
    >
      <Text
        block
        styles={{
          root: {
            marginBottom: 10,
          },
        }}
      >
        {t("ram.renameDefaultWeightingDialogDescription.label")}
      </Text>
      <div>
        <FieldContainer
          isTooltipHidden={false}
          tooltipText={t("ram.renameDefaultWeightingDialogDescription.label")}
        >
          <TextField
            id={"newName-input"}
            name={"newName-input"}
            onChange={(e: any) => {
              setNewName(e.target.value);
            }}
            value={newName}
            autoComplete="off"
          />
        </FieldContainer>
      </div>
      <DialogFooter>
        <StyledPrimaryButton
          text={t("ram.renameDefaultWeighting.label")}
          onClick={() => {
            dispatch(
              renameDefaultWeightingRoutine.trigger({
                oldName: name,
                newName: (newName as string).trim(),
              })
            );
          }}
          disabled={
            isLoading || !name || !newName || (newName as string).trim() === ""
          }
        />
      </DialogFooter>
      {isLoading && <FormSpinner />}
    </Dialog>
  );
};
