import React from "react";
import { useDispatch } from "react-redux";
import { ClearFilters } from "../../components/filters/ClearFilters";
import { ClientsFilter } from "../../components/filters/ClientsFilter";
import { Filters } from "../../components/filters/Filters";
import { LocationsFilter } from "../../components/filters/LocationsFilter";
import { NavButtons } from "../../components/navigation/NavButtons";
import { SearchResultsCount } from "@greco/components";
import { NavigationBar, Right } from "../../components/styled";
import { setClientsFilter } from "../../store/filters/filters";
import { useSelector } from "../../store/hooks";
import { Search } from "./Search";
import { useTranslation } from "react-i18next";
import { setManageLocationsDialog } from "../../store/users";
import { setManageReportsDialog } from "../../store/usersinsurer";
import { Checkbox } from "@fluentui/react";
import { setShowActiveReportsOnly } from "../../store/reports-page/reportsPage";

export const Navigation = () => {
  const count = useSelector((s) => s.reportsPage.shownIds.length);
  const showActiveReportsOnly = useSelector(
    (s) => s.reportsPage.showActiveReportsOnly
  );
  const dispatch = useDispatch();
  const clientsFilter = useSelector((s) => s.filters.clientsFilter);
  const locationsFilter = useSelector((s) => s.filters.locationsFilter);
  const { t } = useTranslation();

  return (
    <NavigationBar>
      <NavButtons />
      <Search />
      <Filters>
        <ClientsFilter />
        <LocationsFilter />
        <Checkbox
          label={t("ram.reports.showActiveOnly.label")}
          checked={showActiveReportsOnly}
          onChange={() => {
            dispatch(setShowActiveReportsOnly(!showActiveReportsOnly));
          }}
        />
        <ClearFilters
          isDisabled={
            clientsFilter.length === 0 && locationsFilter.length === 0
          }
          onClear={() => {
            dispatch(setClientsFilter([]));
            dispatch(
              setManageLocationsDialog({
                user: null,
                isOpen: false,
              })
            );
            dispatch(
              setManageReportsDialog({
                user: null,
                isOpen: false,
              })
            );
          }}
        />
      </Filters>
      <Right>
        <SearchResultsCount count={count} label={t("greco.searchResults")} />
      </Right>
    </NavigationBar>
  );
};
