export const riskImplmentationStatusIcons = {
  "RiskImplementationStatus.DONE_CHECKED": "CheckMark",
  "RiskImplementationStatus.DONE": "CheckMark",
  "RiskImplementationStatus.IN_PROGRESS": "HourGlass",
  "RiskImplementationStatus.OPEN": "Play",
  "RiskImplementationStatus.NOTED": "DietPlanNotebook",
  "RiskImplementationStatus.DECLINED": "Cancel",
};

export const businessObjectIcons = {
  clientTree:"BranchMerge",
  client: "BusinessCenterLogo",
  location: "MapPin",
  report: "CRMReport",
  recommendation: "ComplianceAudit",
};
