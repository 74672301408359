import { TooltipComponent } from "components/TooltipComponent";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  value: string;
  children: JSX.Element;
};
function TooltipWithContentCell({ value, children }: Props) {
  const { t } = useTranslation();
  return <TooltipComponent content={t(value)}>{children}</TooltipComponent>;
}

export default TooltipWithContentCell;
