import { fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { applyFiltersLocations } from "../locations-page/locationsPage";
import { loadRecommendationsActions } from "../recommendations/recommendations";
import { applyFiltersReports } from "../reports-page/reportsPage";
import { loadUsersRoutine } from "../users";
import {
  setClientsFilter,
  setClientsFilterBasedOnQueryParamAction,
  setLocationsFilter,
  setLocationsFilterIds,
  setReportsFilter,
  setReportsFilterIds,
} from "./filters";

import { routes } from "config/routes";
import queryString from "query-string";
import { store } from "store/store";
import { loadUsersInsurerRoutine } from "../usersinsurer";

const TOP = 40;

export function* setClientsFilterSaga() {
  yield takeEvery(setClientsFilter.type, function* () {
    yield put(setLocationsFilter([]));
    yield put(setLocationsFilterIds() as any);
    yield put(applyFiltersLocations() as any);
    yield put(applyFiltersReports() as any);
    yield put(
      loadRecommendationsActions.trigger({ top: TOP, skip: 0, reset: true })
    );
    const loc = store.getState().router.location;
    const usersMatchPath = routes.users.matchPath(loc);

    if (usersMatchPath) {
      yield put(loadUsersRoutine.trigger());
    }
    // const insurerUsersMatchPath = routes.insurerusers.matchPath(loc);
    const insurerUsersMatchPath = true;
    if (insurerUsersMatchPath) {
      yield put(loadUsersInsurerRoutine.trigger());
    }
  });
}

export function* setLocationsFilterSaga() {
  yield takeEvery(setLocationsFilter.type, function* () {
    yield put(setReportsFilter([]));
    yield put(setReportsFilterIds() as any);
    yield put(applyFiltersReports() as any);
    yield put(
      loadRecommendationsActions.trigger({ top: TOP, skip: 0, reset: true })
    );
    const loc = store.getState().router.location;
    const usersMatchPath = routes.users.matchPath(loc);
    if (usersMatchPath) {
      yield put(loadUsersRoutine.trigger());
    }
    const insurerUsersMatchPath = routes.insurerusers.matchPath(loc);
    if (insurerUsersMatchPath) {
      yield put(loadUsersInsurerRoutine.trigger());
    }
  });
}

export function* setReportsFilterSaga() {
  yield takeLatest(setReportsFilter.type, function* () {
    yield put(
      loadRecommendationsActions.trigger({ top: TOP, skip: 0, reset: true })
    );
  });
}

export function* setClientsFilterBasedOnQueryParamSaga() {
  yield takeEvery(setClientsFilterBasedOnQueryParamAction.type, function* () {
    const routerLocation = yield select((s) => s.router.location);

    const queryParams = queryString.parse(routerLocation?.search);

    const webBasePartnerNumber = queryParams?.webBasePartnerNumber;

    if (webBasePartnerNumber) {
      yield put(setClientsFilter([Number(webBasePartnerNumber)]));
    }
  });
}

export function* filtersSaga() {
  yield fork(setClientsFilterSaga);
  yield fork(setLocationsFilterSaga);
  yield fork(setReportsFilterSaga);
  yield fork(setClientsFilterBasedOnQueryParamSaga);
}
