import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import * as API from "../../api/api";
import { setHasRights } from "../auth";
import { setReportsFilterIds } from "../filters/filters";
import { applyFiltersReports } from "../reports-page/reportsPage";
import { loadReportsActions } from "./reports";

export function* loadReportsSaga() {
  yield takeEvery(loadReportsActions.trigger, function* () {
    try {
      yield put(loadReportsActions.loading());
      const res = yield call(API.getReports);
      const locationEntities = yield select((s) => s.locations.entities);
      const clientEntities = yield select((s) => s.clients.entities);
      yield put(
        loadReportsActions.success(
          res.data.map((e: any) => {
            return {
              ...e,
              locationName: locationEntities[e?.locationId]?.locationName,
              clientName: clientEntities[e?.webBasePartnerNumber]?.clientName,
            };
          })
        )
      );
    } catch (err) {
      yield put(loadReportsActions.error(err));
      if (err.response.status === 403) {
        yield put(setHasRights(false));
      }
    }
  });
}

export function* loadReportsSuccessSaga() {
  yield takeEvery(loadReportsActions.success, function* () {
    yield put(setReportsFilterIds() as any);
    yield put(applyFiltersReports() as any);
  });
}

export function* reportsSaga() {
  yield fork(loadReportsSaga);
  yield fork(loadReportsSuccessSaga);
}
