import { TextField, mergeStyleSets } from "@fluentui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { DefaultButton, IDropdownOption, Label } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components";
import { StyledDropdown } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";
import { DefaultWeightingItem } from "../../types/types";
import DefaultWeightingsSkeleton from "./DefaultWeightingSkeleton";
import { Navigation } from "./Navigation";
import { IStyledTheme } from "../../theme/types";
import {
  createDefaultWeightings,
  loadDefaultWeightings,
  loadDefaultWeightingsByName,
  setDefaultWeightingsValue,
  setNewName,
  updateDefaultWeightings,
} from "store/defaultWeightingPage";
import { useLocation, useNavigate, useParams } from "react-router";
import { FieldContainer } from "components/controls/FieldContainer";
import { routes, useCurrentRoute } from "config/routes";

export type DefaultWeightingsSaveMode = "normal" | "addReport";

export const DefaultWeighting = () => {
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();
  const [defaultWeightings, defaultWeightingsSet] = useState<
    DefaultWeightingItem[]
  >([]);
  const theme = useTheme() as IStyledTheme;
  const classes = getClassNames(theme);
  const userRole = useSelector((s) => s.userRole.userRole);
  const route = useCurrentRoute();
  const isEdit = route === "defaultWeighting";
  const [defaultWeightingsMap, defaultWeightingsMapSet] = useState({});

  const setDefaultWeightings = (defaultWeightings: DefaultWeightingItem[]) => {
    defaultWeightingsSet(defaultWeightings);
    var valuesMap = defaultWeightings.reduce(function (map, obj) {
      map[obj.subcategoryCode] = obj;
      return map;
    }, {});
    defaultWeightingsMapSet(valuesMap);
  };

  const dispatch: AppDispatch = useDispatch();
  const areDefaultWeightingsLoading = useSelector(
    (s) => s.defaultWeighting.areDefaultWeightingsLoading
  );
  const isUpdateLoading = useSelector(
    (s) => s.defaultWeighting.isUpdateLoading
  );
  const originalDefaultWeightings = useSelector(
    (s) => s.defaultWeighting.defaultWeightings
  );

  const newName = useSelector((s) => s.defaultWeighting.newName);
  const navigate = useNavigate();

  const isLoading = isUpdateLoading || areDefaultWeightingsLoading;
  const isDisabled = isLoading || userRole !== "admin";

  const { name } = useParams() as any;

  const location = useLocation();

  useEffect(() => {
    (async () => {
      let defaultWeightings = [];
      if (isEdit) {
        // Update
        const action = await dispatch(loadDefaultWeightings());
        defaultWeightings = unwrapResult(action);
        dispatch(setNewName(name));
      } else {
        const urlSearchParams = new URLSearchParams(location.search);
        const template = urlSearchParams.get("template");

        // Duplicate
        if (template) {
          const action = await dispatch(
            loadDefaultWeightingsByName({ name: template })
          );
          defaultWeightings = unwrapResult(action);
          dispatch(setNewName(template + "_COPY"));
        } else {
          // New
          dispatch(setNewName(""));
          defaultWeightings = taxonomy.RiskAssessmentSubcategory.items.map(
            (item) => {
              return {
                subcategoryDefaultName: "",
                subcategoryCode: item.id,
                defaultWeighting: 5,
              };
            }
          );
        }
      }
      dispatch(setDefaultWeightingsValue(defaultWeightings));
      setDefaultWeightings(defaultWeightings as DefaultWeightingItem[]);
    })();
  }, []);

  const onSave = async (isAddReport?: boolean) => {
    (async () => {
      if (isEdit) {
        //edit
        const action = await dispatch(
          updateDefaultWeightings({ name, defaultWeightings })
        );
        const updatedDefaultWeightings = unwrapResult(action);
        setDefaultWeightings(
          updatedDefaultWeightings as DefaultWeightingItem[]
        );
      } else {
        //create
        const action = await dispatch(
          createDefaultWeightings({
            name: (newName as string).trim(),
            defaultWeightings,
          })
        );
        const updatedDefaultWeightings = unwrapResult(action);
        setDefaultWeightings(
          updatedDefaultWeightings as DefaultWeightingItem[]
        );
      }
      navigate(routes.defaultWeightings.getPath());
    })();
  };

  const onChangeDropdown = (taxId: number) => (_: any, o?: IDropdownOption) => {
    setDefaultWeightings(
      defaultWeightings.map((dv) => {
        if (dv.subcategoryCode === taxId) {
          return {
            ...dv,
            defaultWeighting: Number(o!.key),
          };
        }
        return dv;
      })
    );
  };

  const onClickReset = () => {
    setDefaultWeightings([...originalDefaultWeightings]);
  };

  const shouldShowSpinner = areDefaultWeightingsLoading || isUpdateLoading;

  return (
    <>
      <Navigation isLoading={shouldShowSpinner} />
      {shouldShowSpinner ? (
        <DefaultWeightingsSkeleton />
      ) : (
        <div className={classes.main}>
          <div className={classes.wrap}>
            <FieldContainer
              isTooltipHidden={false}
              tooltipText={t(
                "ram.renameDefaultWeightingDialogDescription.label"
              )}
            >
              <Label>{t("greco.name")}</Label>
              <TextField
                disabled={isDisabled || isEdit}
                id={"newName-input"}
                name={"newName-input"}
                onChange={(e: any) => {
                  dispatch(setNewName(e.target.value));
                }}
                value={newName}
                autoComplete="off"
              />
            </FieldContainer>

            {taxonomy.RiskAssessmentCategory.items.map((cat_tax) => {
              return (
                <Block key={cat_tax.code}>
                  <CategoryHeader>{t(cat_tax.code)}</CategoryHeader>
                  <Grid>
                    {taxonomy.RiskAssessmentSubcategory.items
                      .filter(
                        (item) =>
                          item.code
                            .replaceAll("RiskAssessmentSubcategory.", "")
                            .substring(0, 1) ===
                          cat_tax.code.replaceAll("RiskAssessmentCategory.", "")
                      )
                      .map((tax) => {
                        const dv = defaultWeightingsMap[tax.id];
                        return (
                          <Row key={tax.id}>
                            <StyledLabel>{t(tax.code)}</StyledLabel>
                            <StyledDropdown
                              styles={{
                                root: {
                                  marginBottom: 0,
                                },
                              }}
                              options={Array(10)
                                .fill(0)
                                .map((_, idx) => ({
                                  text: String(idx + 1),
                                  key: idx + 1,
                                }))}
                              selectedKey={dv?.defaultWeighting}
                              disabled={isDisabled}
                              onChange={onChangeDropdown(tax.id)}
                            />
                          </Row>
                        );
                      })}
                  </Grid>
                </Block>
              );
            })}
            <button
              id="loc-def-values-btn"
              onClick={() => onSave()}
              style={{
                display: "none",
              }}
            />
            <button
              id="loc-def-values-add-report-btn"
              onClick={() => onSave(true)}
              style={{
                display: "none",
              }}
            />
            <div
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <DefaultButton
                text={t("greco.reset")}
                styles={{
                  root: {
                    marginLeft: "auto",
                  },
                }}
                onClick={onClickReset}
                disabled={isDisabled}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 20px;
  flex: 1;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const Block = styled.div`
  padding: 10px 20px 0px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  padding-right: 30px;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
`;

const StyledLabel = styled(Label)`
  margin-right: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
`;

const CategoryHeader = styled(Label)`
  color: ${(p) => p.theme.palette.neutralPrimary};
  display: block;
  font-size: 1.17em;
  padding: 0;
  font-weight: bold;
`;

const getClassNames = (theme) =>
  mergeStyleSets({
    main: {
      maxWidth: "100%",
      display: "flex",
      margin: "0 auto",
      flexDirection: "column",
      height: "100vh",
      position: "relative",
      paddingTop: 0,
      overflow: "auto", //
    },

    wrap: {
      background: theme.palette.white,
      boxShadow:
        "0 3.2px 7.2px 0 rgba(0,0,0,.132), 0 0.6px 1.8px 0 rgba(0,0,0,.108)",
      display: "flex",
      margin: "50px auto 200px",
      borderRadius: "4px",
      minWidth: 1200,
      maxWidth: 1200,
      position: "relative",
      flexDirection: "column",
      padding: "20px 20px 50px",

      gap: 50,
    },
  });
