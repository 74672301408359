import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Client, ClientLocation, Report, Taxonomy } from "../../types/types";
import { selectAllClients } from "../clients/clients";
import { selectAllLocations } from "../locations/locations";
import { selectAllReports } from "../reports/reports";
import { AppThunk } from "../store";
import { sort } from "../util";

export type FiltersState = {
  clientsFilter: Client["clientId"][];
  clientsFilterIds: Client["clientId"][];
  locationsFilter: ClientLocation["locationId"][];
  locationsFilterIds: ClientLocation["locationId"][];
  reportsFilter: Report["reportId"][];
  reportsFilterIds: Report["reportId"][];
  categoriesFilter: Taxonomy["id"][];
  //categoriesFilterIds: Taxonomy["id"][];
};

export const setClientsFilterBasedOnQueryParamAction = createAction(
  "setClientsFilterBasedOnQueryParam"
);

// by default, liability and internal audits categories are not included
const allCategories = [1, 2, 3, 4, 5, 6]; // TODO: how to get taxonomy riskassesmentcategory dynamically?

export const filtersSlice = createSlice({
  name: "filters",
  initialState: {
    clientsFilter: [],
    clientsFilterIds: [],
    locationsFilter: [],
    locationsFilterIds: [],
    reportsFilter: [],
    reportsFilterIds: [],
    categoriesFilter: allCategories,
    //categoriesFilterIds: allCategories,
  } as FiltersState,
  reducers: {
    setClientsFilter: (s, a: PayloadAction<FiltersState["clientsFilter"]>) => {
      s.clientsFilter = a.payload;
    },
    setClientsFilterIds: (
      s,
      a: PayloadAction<FiltersState["clientsFilterIds"]>
    ) => {
      s.clientsFilterIds = a.payload;
    },
    setLocationsFilter: (
      s,
      a: PayloadAction<FiltersState["locationsFilter"]>
    ) => {
      s.locationsFilter = a.payload;
    },
    setLocationsFilterIds: (
      s,
      a: PayloadAction<FiltersState["locationsFilterIds"]>
    ) => {
      s.locationsFilterIds = a.payload;
    },
    setReportsFilter: (s, a: PayloadAction<FiltersState["reportsFilter"]>) => {
      s.reportsFilter = a.payload;
    },
    setReportsFilterIds: (
      s,
      a: PayloadAction<FiltersState["reportsFilterIds"]>
    ) => {
      s.reportsFilterIds = a.payload;
    },
    setCategoriesFilter: (
      s,
      a: PayloadAction<FiltersState["categoriesFilter"]>
    ) => {
      s.categoriesFilter = a.payload;
    },
    /*setCategoriesFilterIds: (
      s,
      a: PayloadAction<FiltersState["categoriesFilterIds"]>
    ) => {
      s.categoriesFilterIds = a.payload;
    },*/
  },
});

export const filtersReducer = filtersSlice.reducer;

export const {
  setClientsFilter,
  setLocationsFilter,
  setReportsFilter,
  setCategoriesFilter,
} = filtersSlice.actions;

export const setClientsFilterIds = (): AppThunk => (dispatch, getState) => {
  const clients = selectAllClients(getState()).slice();

  const sortedClientsIds = clients
    .sort(sort({ key: "clientName", dir: "asc" }))
    .map((c) => c.clientId);

  dispatch(filtersSlice.actions.setClientsFilterIds(sortedClientsIds));
};

export const setLocationsFilterIds = (): AppThunk => (dispatch, getState) => {
  const locations = selectAllLocations(getState());
  const clientsFilter = getState().filters.clientsFilter;

  let filteredLocations = locations;
  if (clientsFilter.length > 0) {
    filteredLocations = locations.filter((l) => {
      return clientsFilter.includes(l.webBasePartnerNumber);
    });
  }

  const sortedLocationIds = filteredLocations
    .sort(sort({ key: "locationName", dir: "asc" }))
    .map((l) => l.locationId);

  dispatch(filtersSlice.actions.setLocationsFilterIds(sortedLocationIds));
};

export const setReportsFilterIds = (): AppThunk => (dispatch, getState) => {
  const reports = selectAllReports(getState());
  const clientsFilter = getState().filters.clientsFilter;
  const locationsFilter = getState().filters.locationsFilter;

  let filteredReports = reports;
  filteredReports = reports.filter((r) => {
    let isClientsFilter = true;
    if (clientsFilter.length > 0) {
      isClientsFilter = clientsFilter.includes(r.webBasePartnerNumber);
    }
    let isLocationsFilter = true;
    if (locationsFilter.length > 0) {
      isLocationsFilter = locationsFilter.includes(r.locationId);
    }
    return isClientsFilter && isLocationsFilter;
  });

  const sortedReportIds = filteredReports
    .sort(sort({ key: "reportName", dir: "asc" }))
    .map((r) => r.reportId);

  dispatch(filtersSlice.actions.setReportsFilterIds(sortedReportIds));
};

/*
export const setCategoriesFilterIds = (): AppThunk => (dispatch, getState) => {
  const taxonomy = useSelector((s) => s.taxonomy);

  const categories = taxonomy.RiskAssessmentCategory;

  const categoryIds = categories.items.map((c) => c.id);

  dispatch(filtersSlice.actions.setCategoriesFilterIds(categoryIds));
};*/
