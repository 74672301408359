import { all } from "@redux-saga/core/effects";
import { TFunction } from "i18next";
import { SelectableOptionMenuItemType } from "@fluentui/react";
import { useMemo } from "react";
import { RootState } from "../../../store/store";
import { LocationDefaultValue } from "../../../types/types";

export const useSubCategoryCodeOptions = (
  defaultValues: LocationDefaultValue[],
  taxonomy: RootState["taxonomy"],
  t: TFunction
) => {
  // create subcategory options from taxonomy and filter out the ones
  // that are marked as isUsed=false in the default values (subcategory) config
  return useMemo(() => {
    if (!defaultValues) return [];

    let allOptions = [];

    taxonomy.RiskAssessmentCategory.items.map((cat_tax) => {
      const subcatOptions = taxonomy.RiskAssessmentSubcategory.items
        .filter(
          (item) =>
            item.code
              .replaceAll("RiskAssessmentSubcategory.", "")
              .substring(0, 1) ===
            cat_tax.code.replaceAll("RiskAssessmentCategory.", "")
        )
        .filter((tax) => {
          const dv = defaultValues.find((dv) => dv.subcategoryCode === tax.id);
          return dv?.isUsed;
        })
        .map((tax) => {
          return {
            label: t(tax.code),
            value: tax.id,
          };
        }) as any[];
      allOptions.push({ label: t(cat_tax.code), options: subcatOptions });
      return allOptions;
    });
    return allOptions;
  }, [taxonomy, defaultValues, t]);
};
