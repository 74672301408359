import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    hasRights: true,
  },
  reducers: {
    setHasRights: (state, action: PayloadAction<boolean>) => {
      state.hasRights = action.payload;
    },
  },
});

export const {
  actions: { setHasRights },
  reducer: authReducer,
} = authSlice;
