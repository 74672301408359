import { Label, Slider } from "@fluentui/react";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FieldContainer } from "../../../../../components/controls/FieldContainer";
import { ErrorMessage } from "../../../../../components/styled";
import { useSelector } from "../../../../../store/hooks";

export const WeightingField = () => {
  // const recommendation = useSelector((s) => s.recommendationPage.rec);
  const [weightingValue, setWeightingValue] = useState(0);

  // const userRole = useSelector((s) => s.userRole.userRole);
  // const isWaitingForGreg = recommendation?.recommendationStatusCode === 1;
  // const isWaitingForClient = recommendation?.recommendationStatusCode === 2;
  // const isClosed = recommendation?.recommendationStatusCode === 3;
  const isDisabled = false;
  // (isWaitingForGreg &&
  //   (userRole === "insurer" || userRole === "internal_limited")) ||
  // isWaitingForClient ||
  // isClosed;

  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<any>();
  const subCategory1Code = values?.subCategory1Code;
  const defaultValues = useSelector((s) => s.recommendationPage.defaultValues);
  const name = "weighting";

  // when the selected subCategoryCode1 option changes
  // we set the weighting field to the defaultWeighting for that subcategory
  useEffect(() => {
    if (defaultValues.length === 0) return;
    if (!subCategory1Code) {
      setWeightingValue(1);
      setFieldValue(name, 1);
      return;
    }

    if (!isDisabled) {
      const dv = defaultValues?.find(
        (dv) => dv.subcategoryCode === subCategory1Code?.value
      );
      setWeightingValue(dv?.defaultWeighting);
      setFieldValue(name, dv?.defaultWeighting);
      return;
    }
  }, [subCategory1Code, defaultValues, isDisabled, setFieldValue]);

  const errorId =
    t("ram.recommendationForm.weighting.label").replaceAll(" ", "_") + "_error";

  const hasError = !!errors[name];

  return (
    <div>
      <FieldContainer
        isTooltipHidden={isDisabled}
        tooltipText={t("ram.recommendationForm.weighting.tooltip")}
      >
        <Label required disabled={isDisabled}>
          {t("ram.recommendationForm.weighting.label")}
        </Label>
        <StyledSlider
          min={1}
          max={10}
          snapToStep
          step={1}
          showValue
          value={weightingValue}
          onChange={(value) => {
            setWeightingValue(value);
            setFieldValue(name, value);
          }}
          disabled={isDisabled}
        />
      </FieldContainer>
      {hasError && (
        <ErrorMessage id={errorId}>{errors[name] as string}</ErrorMessage>
      )}
    </div>
  );
};

const StyledSlider = styled(Slider)`
  width: 300px;
  .ms-Slider-slideBox:hover {
    .ms-Slider-inactive {
      background-color: #c7e0f4;
    }
  }
  .ms-Slider-inactive {
    background-color: #c8c6c4;
  }
`;
