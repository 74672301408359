import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Flex, HSpace } from "../../../components/styled";
import { RefProvider } from "../../../contexts/RefProvider";
import { setCopyReportDataDialog } from "../../../store/locationPage";
import { ReportsList } from "./ReportsList";
import { useFormikContext } from "formik";
import { adjustFloatNumberValue } from "../../../form/util";

export const CopyReportDataDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext() as any;

  const close = () => {
    dispatch(
      setCopyReportDataDialog({
        reportData: [],
        isOpen: false,
      })
    );
  };

  const title = (
    <Flex>
      <Icon iconName={"Copy"} />

      <HSpace width={10} />
      <div>{t("ram.locationForm.copyDataFromReport.label")}</div>
    </Flex>
  );

  const copyReportDialogCallback = (report) => {
    setFieldValue("emlinMioEur", adjustFloatNumberValue(report.emlinMioEur));
    setFieldValue("pmlinMioEur", adjustFloatNumberValue(report.pmlinMioEur));
    setFieldValue("insurersRaiting", report.insurersRaiting);
    dispatch(
      setCopyReportDataDialog({
        reportData: [],
        isOpen: false,
      })
    );
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title,
        showCloseButton: true,
      }}
      minWidth={"80%"}
      onDismiss={close}
      modalProps={{ isBlocking: true }}
    >
      <RefProvider>
        <ReportsList callback={copyReportDialogCallback} />
        <DialogFooter></DialogFooter>
      </RefProvider>
    </Dialog>
  );
};
