import { format } from "date-fns";
import * as React from "react";
import { IconButton } from "@fluentui/react";
import { RecommendationAttachment } from "../types/types";
import i18n from "../i18n";
import { TFunction } from "i18next";
import { IStyledTheme } from "../theme/types";

export const formBase64String = (file: File) => {
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing file"));
    };

    reader.onload = () => {
      resolve(
        reader.result
          ? typeof reader.result === "string"
            ? reader.result.substring(reader.result.indexOf(",") + 1)
            : undefined
          : undefined
      );
    };
    reader.readAsDataURL(file);
  });
};

export const getSelectStyles = (
  hasError: boolean,
  theme: IStyledTheme
): any => {
  return {
    control: (p, s) => {
      return {
        ...p,
        border: 0,
        borderRadius: 0,
        borderBottom: "1px solid",
        borderBottomColor: s.isFocused
          ? hasError
            ? theme.palette.redDark
            : theme.palette.themePrimary
          : theme.palette.neutralTertiaryAlt,
        outline: "none",
        "&:hover": {
          borderBottomColor: s.isFocused
            ? hasError
              ? theme.palette.redDark
              : theme.palette.themePrimary
            : theme.palette.neutralTertiaryAlt,
        },
        backgroundColor: s.isDisabled ? theme.palette.white : undefined,
      };
    },
    groupHeading: (p, s) => {
      return {
        ...p,
        borderBottom: "2px solid",
        fontWeight: "bold",
        color: theme.palette.themeSecondary,
      };
    },
  };
};

export const formatDateString = (str: string) =>
  format(new Date(str), "dd.MM.yyyy.");

export const formatDate = (date?: Date) => format(date, "dd.MM.yyyy.");

export const formatDateWithTime = (date?: Date) =>
  format(date, "dd.MM.yyyy. kk:mm:ss");

export function yupTransformNumber(this: any, value: any, originalValue: any) {
  if (typeof originalValue === "string" && originalValue.trim() === "") {
    return null;
  }
  return value;
}

export function lightenDarkenColor(col: string, amt: number) {
  var usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export const pickBy = (
  object: { [key: string]: any },
  predicate: (value: any, key: string) => boolean
) => {
  const obj: typeof object = {};
  for (const key in object) {
    if (predicate(object[key], key)) {
      obj[key] = object[key];
    }
  }
  return obj;
};

export const mapValues = (
  obj: { [key: string]: any },
  fn: (value: any, key: string) => any
) => {
  return Object.entries(obj).reduce<typeof obj>((newObj, [key, value]) => {
    newObj[key] = fn(value, key);
    return newObj;
  }, {});
};

export const submitForm = (formId: string, options?: CustomEventInit) =>
  document
    .getElementById(formId)
    ?.dispatchEvent(new CustomEvent("submit", options));

export const timeZoneOffset = new Date().getTimezoneOffset() * 60000;

export const getFileIcon = (type: any) => {
  let iconName = "TextDocumentShared";
  if (type === "pdf") {
    iconName = "PDF";
  } else if (type === "jpg" || type === "png" || type === "jpeg") {
    iconName = "Photo2";
  } else if (type === "msg") {
    iconName = "OutlookLogoInverse16";
  } else if (
    type === "xls" ||
    type === "xlsx" ||
    type === "xlsm" ||
    type === "csv"
  ) {
    iconName = "ExcelDocument";
  } else if (type === "zip") {
    iconName = "ZipFolder";
  } else if (type === "docx" || type === "docm") {
    iconName = "WordDocument";
  } else if (
    type === "pptx" ||
    type === "ppsx" ||
    type === "ppsm" ||
    type === "pptm"
  ) {
    iconName = "PowerPointDocument";
  } else if (type === "xml") {
    iconName = "FileCode";
  } else if (type === "avi" || type === "mov" || type === "mp4") {
    iconName = "MyMoviesTV";
  } else if (type === "vcf") {
    iconName = "ContactInfo";
  }

  return iconName;
};

export const getIconColor = (type: any) => {
  let color = "rgb(0, 120, 212)";
  if (type === "pdf") {
    color = "rgb(255, 47, 53)";
  } else if (type === "jpg" || type === "png" || type === "jpeg") {
    color = "#ff7600";
  } else if (
    type === "xls" ||
    type === "xlsx" ||
    type === "xlsm" ||
    type === "csv"
  ) {
    color = "#217346";
  } else if (type === "docx" || type === "docm") {
    color = "rgb(43, 87, 154)";
  } else if (
    type === "pptx" ||
    type === "ppsx" ||
    type === "ppsm" ||
    type === "pptm"
  ) {
    color = "#B7472A";
  }
  return color;
};

export const getFileExtension = (file: any) => {
  return file?.substring(file.lastIndexOf(".") + 1).toLocaleLowerCase();
};

export const numberFormat = new Intl.NumberFormat("de-DE", {});

export const getDatePickerClearSuffix =
  (value: Date | null | any, setValue: (value: Date | null) => void) => () => {
    if (value !== null && value !== undefined && value !== "") {
      return (
        <IconButton
          iconProps={{
            iconName: "ChromeClose",
          }}
          onMouseDown={() => setValue(null)}
          styles={{
            root: {
              cursor: "auto",
              "&:hover": {
                background: "transparent",
              },
              ".ms-Icon.ms-Icon": {
                fontSize: 11,
                color: "#ababab",
              },
            },
          }}
        />
      );
    }
  };

export const stringToNumber = (str: string) => {
  let _str = str;
  _str = _str.replace(/\./g, "");
  _str = String(_str).replace(",", ".");
  return parseFloat(_str);
};

export const isStringEmpty = (v: any) => {
  return (
    typeof v !== "string" ||
    v === null ||
    v === undefined ||
    (typeof v === "string" && v.length === 0)
  );
};

export const attachmentToBase64 = (f: RecommendationAttachment) => {
  return new Promise<RecommendationAttachment>(async (resolve, reject) => {
    try {
      const b64 = await formBase64String(f.attachmentContent as any as File);
      resolve({
        attachmentName: f.attachmentName,
        attachmentMimeType: f.attachmentMimeType,
        attachmentContent: b64,
      } as RecommendationAttachment);
    } catch (err) {
      reject(err);
    }
  });
};

export const isUploadSizeOverLimit = (attachmentList: any[]): boolean => {
  const cumulativeAttachmentSize = attachmentList?.reduce(function (acc, curr) {
    if (curr.attachmentSize) {
      return acc + curr.attachmentSize;
    } else {
      return acc;
    }
  }, 0);
  if (cumulativeAttachmentSize > 50000000) {
    return true;
  }
  return false;
};

export const capitalize = (s: string) => {
  if (s.length === 0) return "";
  return `${s[0].toUpperCase()}${s.slice(1)}`;
};

export const sortAlphabetically = (arr: any[], t: TFunction) => {
  if (!arr) return [];
  let newArray: any[] = [];
  let language = i18n.languages[0];
  if (language === "sr-Latn-RS") language = "sr-sp";

  newArray = arr.sort((a: any, b: any) => {
    const str1 = t(a.code);
    const str2 = t(b.code);

    return str1.localeCompare(str2, language);
  });
  return newArray;
};
