import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Client } from "../../types/types";
import { RootState } from "../store";
import { createAsyncActions } from "../util";

export const clientsAdapter = createEntityAdapter<Client>({
  selectId: (c) => c.clientId,
});

export const loadClients = createAsyncActions<void, void, Client[]>(
  "clients/load"
);

export const clientsSlice = createSlice({
  name: "clients",
  initialState: clientsAdapter.getInitialState({
    isLoading: false,
    sicCodeList: [],
    selectedClientSicCode: null,
  }),
  reducers: {
    updateClient: clientsAdapter.updateOne,
    updateClients: clientsAdapter.updateMany,
    setSicCodeList: (s, a: PayloadAction<any[]>) => {
      s.sicCodeList = a.payload;
    },
    setSelectedClientSicCode: (s, a: PayloadAction<any>) => {
      s.selectedClientSicCode = a.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadClients.loading, (s, a) => {
      s.isLoading = true;
    });
    builder.addCase(loadClients.success, (s, a) => {
      s.isLoading = false;
      clientsAdapter.setAll(s, a.payload);
    });
    builder.addCase(loadClients.error, (s, a) => {
      s.isLoading = false;
    });
  },
});

export const clientsReducer = clientsSlice.reducer;

export const {
  updateClient,
  updateClients,
  setSicCodeList,
  setSelectedClientSicCode,
} = clientsSlice.actions;

export const {
  selectAll: selectAllClients,
  selectEntities: selectEntitiesClients,
  selectById: selectByIdClients,
} = clientsAdapter.getSelectors<RootState>((s) => s.clients);
