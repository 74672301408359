import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { DefaultWeightingName } from "../../types/types";
import { RootState } from "../store";
import { createAsyncActions } from "../util";

export const defaultWeightingsAdapter =
  createEntityAdapter<DefaultWeightingName>({
    selectId: (dw) => dw.name,
  });

export const loadDefaultWeightingsActions = createAsyncActions<
  void,
  void,
  string[],
  any
>("defaultWeightings/load");

export const defaultWeightingsSlice = createSlice({
  name: "defaultWeightings",
  initialState: defaultWeightingsAdapter.getInitialState({
    isLoading: false,
    selectedColumn: null,
    hoveredColumn: null,
  }),
  reducers: {
    setSelectedColumn: (s, a) => {
      s.selectedColumn = a.payload;
    },
    setHoverColumn: (s, a) => {
      s.hoveredColumn = a.payload;
    },
    addDefaultWeighting: defaultWeightingsAdapter.addOne,
    updateDefaultWeighting: defaultWeightingsAdapter.updateOne,
    removeOne: defaultWeightingsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(loadDefaultWeightingsActions.loading, (s, a) => {
      s.isLoading = true;
    });
    builder.addCase(loadDefaultWeightingsActions.success, (s, a) => {
      s.isLoading = false;
      defaultWeightingsAdapter.setAll(
        s,
        a.payload.map((item) => {
          return {
            name: item,
          };
        })
      );
    });
    builder.addCase(loadDefaultWeightingsActions.error, (s, a) => {
      s.isLoading = false;
    });
  },
});

export const defaultWeightingsReducer = defaultWeightingsSlice.reducer;

export const {
  addDefaultWeighting,
  updateDefaultWeighting,
  removeOne,
  setSelectedColumn,
  setHoverColumn,
} = defaultWeightingsSlice.actions;

export const {
  selectAll: selectAllDefaultWeightings,
  selectEntities: selectEntitiesDefaultWeightings,
  selectById: selectByIdDefaultWeightings,
} = defaultWeightingsAdapter.getSelectors<RootState>(
  (s) => s.defaultWeightings
);
