import { CommandBarButton } from "@fluentui/react";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentRoute } from "../../../config/routes";
import { useSelector } from "../../../store/hooks";
import {
  selectCanNotifyAdd,
  selectCanNotifyEdit,
} from "../../../store/recommendationPage";

type Props = {
  setDidTrySubmit: () => void;
};

export const SaveRecomendationButton = ({ setDidTrySubmit }: Props) => {
  const { t } = useTranslation();

  const { submitForm, setFieldValue } = useFormikContext();

  const route = useCurrentRoute();

  const isEdit = route === "editRecommendation";
  const isSaveRecLoading = useSelector(
    (s) => s.recommendationPage.isSaveLoading
  );
  const isRecLoading = useSelector((s) => s.recommendationPage.isRecLoading);
  const canNotifyEdit = useSelector(selectCanNotifyEdit as any);
  const canNotifyAdd = useSelector(selectCanNotifyAdd as any);
  const isLoading = isSaveRecLoading || isRecLoading;
  const isDisabled = isLoading || isRecLoading;

  let canNotify;

  if (isEdit) {
    canNotify = canNotifyEdit;
  }
  //
  else {
    canNotify = canNotifyAdd;
  }

  return (
    <div>
      <CommandBarButton
        text={t("greco.save")}
        iconProps={{ iconName: "Save" }}
        split
        onClick={() => {
          setFieldValue("mode", "normal");
          setDidTrySubmit();
          submitForm();
        }}
        disabled={isDisabled}
        menuProps={{
          items: [
            {
              key: "saveAndNotify",
              text: t("ram.navBar.saveAndNotify"),
              iconProps: {
                iconName: canNotify.isLoading ? "ProgressRingDots" : "Ringer",
              },
              onClick: () => {
                setFieldValue("mode", "notify");
                setDidTrySubmit();
                submitForm();
              },
              disabled: !canNotify.result,
            },
            {
              key: "saveAndNew",
              text: t("ram.navBar.saveAndNew"),
              iconProps: { iconName: "Add" },
              onClick: () => {
                setFieldValue("mode", "addNew");
                setDidTrySubmit();
                submitForm();
              },
            },
          ],
        }}
      />
    </div>
  );
};
