import styled from "styled-components";
import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { NoData } from "../../../components/NoData";
import { useSelector } from "../../../store/hooks";

export const Description = () => {
  const { t } = useTranslation();
  const recommendation = useSelector(
    (s) => s.recommendationPanel.recommendation
  );
  const loadRecommendationStatus = useSelector(
    (s) => s.recommendationPanel.loadRecommendationStatus
  );
  const isLoading = loadRecommendationStatus === "loading";

  let render;
  if (isLoading) render = <Skeleton height={100} />;
  else if (!recommendation) render = <NoData />;
  else
    render = (
      <Text style={{ whiteSpace: "pre-wrap" }}>
        {recommendation.description}
      </Text>
    );

  return (
    <Root>
      <strong>{t("ram.recommendationForm.description.label")}:</strong>
      {render}
    </Root>
  );
};

const Root = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  background-color: ${(p) => p.theme.palette.neutralLighterAlt};
`;

const Text = styled.div`
  color: ${(p) => p.theme.palette.neutralPrimary};
`;
