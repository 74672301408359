import {
  Dialog,
  DialogContent,
  DialogFooter,
  Icon,
  PrimaryButton,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormSpinner } from "../../components/FormSpinner";
import { Flex, HSpace, StyledPrimaryButton } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import {
  deleteRecommendationRoutine,
  setDeleteDialog,
} from "../../store/recommendations-page/recommendationsPage";

export const DeleteDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { recommendation } = useSelector(
    (s) => s.recommendationsPage.deleteRecommendationDialog
  );
  const isLoading = useSelector(
    (s) => s.recommendationsPage.deleteRecommendationLoadStatus === "loading"
  );

  const close = () => {
    dispatch(
      setDeleteDialog({
        recommendation: null,
        isOpen: false,
      })
    );
  };

  const hasAttachments =
    recommendation.hasRecommendationAttachments ||
    recommendation.hasResponseAttachments;

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        showCloseButton: true,
        title: (
          <Flex>
            <Icon iconName="Warning" /> <HSpace />
            <div>{t("greco.areYouSure")}</div>
          </Flex>
        ),
      }}
      onDismiss={close}
    >
      <div>{t("greco.deletingIsPermanent")}</div>
      {hasAttachments && (
        <div>{t("ram.recommendations.delete.hasAttachmentsWarning")}</div>
      )}
      <DialogFooter>
        <StyledPrimaryButton
          text={t("greco.delete")}
          onClick={() => {
            dispatch(
              deleteRecommendationRoutine.trigger({
                locationId: recommendation.locationId,
                reportId: recommendation.reportId,
                recommendationId: recommendation.recommendationId,
              })
            );
          }}
          disabled={isLoading}
        />
      </DialogFooter>
      {isLoading && <FormSpinner />}
    </Dialog>
  );
};
