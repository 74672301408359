export const QUERY_KEYS = {
  recommendation: "recommendation",
};

export const MUTATION_KEYS = {
  createAssesment: "createAssesment",
  updateAssesment: "updateAssesment",
  createResponse: "createResponse",
  updateResponse: "updateResponse",
  createEvaluation: "createEvaluation",
  updateEvaluation: "updateEvaluation",
};
