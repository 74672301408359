import React, { useCallback, useEffect } from "react";
import { Label } from "@fluentui/react";
import {
  TagPicker,
  IBasePicker,
  ITag,
  IBasePickerSuggestionsProps,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../store/hooks";
import { useBoolean } from "@uifabric/react-hooks";
import { useParams } from "react-router-dom";
import { FieldContainer } from "../../../components/controls/FieldContainer";
import { useFormikContext } from "formik";

export const SicCodePicker: React.FunctionComponent = ({
  name = "siccodes",
}: any) => {
  const { t } = useTranslation();
  const sicCodeList = useSelector((s) => s.taxonomy)["SicCode"].items;
  const sicCodeByIdMap = useSelector((s) => s.taxonomy)["SicCode"].byId;
  const { setFieldValue, values } = useFormikContext() as any;
  const selectedClientSicCode = useSelector(
    (s) => s.clients.selectedClientSicCode
  );

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: t("greco.form.selectPlaceholder"),
    noResultsFoundText: t("greco.noResults"),
  };

  const sicCodeTags: ITag[] = sicCodeList.map((item) => ({
    key: item.id,
    name: item.code.split(".")[1] + " " + t(item.code),
  }));

  const { locationId } = useParams() as any;
  const sicCodes = useSelector((s) => s.locations).entities[locationId]
    ?.siccodes;

  // All pickers extend from BasePicker specifying the item type.
  const picker = React.useRef<IBasePicker<ITag>>(null);
  const [tagPicker, { toggle: toggleIsTagPickerVisible }] = useBoolean(false);

  const defaultTags: ITag[] = sicCodes
    ? sicCodes.map((item) => ({
        key: item,
        name:
          sicCodeByIdMap[item]?.code.split(".")[1] +
          " " +
          t(sicCodeByIdMap[item]?.code),
      }))
    : [];

  const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag?.key === tag?.key);
  };

  const filterSuggestedTags = (filterText: string, tagList: ITag[]): ITag[] => {
    return filterText
      ? sicCodeTags.filter(
          (tag) =>
            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0 &&
            !listContainsTagList(tag, tagList)
        )
      : [];
  };

  const filterSelectedTags = (filterText: string, tagList: ITag[]): ITag[] => {
    return filterText
      ? sicCodeTags.filter(
          (tag) =>
            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0
        )
      : [];
  };

  const getTextFromItem = (item: ITag) => item.name;

  const onItemSelected = React.useCallback((item: ITag): ITag | null => {
    if (picker.current && listContainsTagList(item, picker.current.items)) {
      return null;
    }
    return item;
  }, []);

  const onChange = useCallback((tagList: ITag[]) => {
    if (tagList && tagList.length >= 1) {
      setFieldValue(name, tagList);
    } else {
      setFieldValue(name, []);
    }
  }, []);

  useEffect(() => {
    if (selectedClientSicCode) {
      onChange([selectedClientSicCode]);
    }
  }, [onChange, selectedClientSicCode]);

  return (
    <div>
      <FieldContainer
        isTooltipHidden={false}
        tooltipText={"ram.locationForm.SICCode.tooltip"}
      >
        <Label>{t("ram.locationForm.SICCode.label")}</Label>
        <TagPicker
          defaultSelectedItems={defaultTags}
          removeButtonAriaLabel="Remove"
          onResolveSuggestions={filterSuggestedTags}
          getTextFromItem={getTextFromItem}
          pickerSuggestionsProps={pickerSuggestionsProps}
          itemLimit={5}
          disabled={tagPicker}
          onChange={onChange}
          onItemSelected={onItemSelected}
          selectedItems={
            values[name] && values[name].length > 0 && values[name][0] !== null
              ? values[name]
              : []
          }
        />
      </FieldContainer>
    </div>
  );
};
