import i18next from "i18next";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api/api";
import { toast } from "../../components/FluentToast";
import { loadDefaultWeightingsActions } from "../defaultWeightings/defaultWeightings";

import { removeOne } from "../defaultWeightings/defaultWeightings";
import { handleAxiosError } from "../util";
import {
  deleteDefaultWeightingRoutine,
  renameDefaultWeightingRoutine,
  setDeleteDialog,
  setRenameDefaultWeightingDialog,
} from "./defaultWeightingsPage";

export function* deleteDefaultWeightingSaga() {
  yield takeEvery(deleteDefaultWeightingRoutine.trigger, function* (a) {
    const name = a.payload;
    try {
      yield put(deleteDefaultWeightingRoutine.loading());
      const res = yield call(API.deleteDefaultWeighting, name);
      yield put(deleteDefaultWeightingRoutine.success(res.data));
      yield put(removeOne(name));
      yield put(loadDefaultWeightingsActions.trigger());
      toast.success(i18next.t("greco.success"));
      yield put(
        setDeleteDialog({
          isOpen: false,
          name: null,
        })
      );
    } catch (err) {
      yield put(deleteDefaultWeightingRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* renameDefaultWeightingSaga() {
  yield takeEvery(renameDefaultWeightingRoutine.trigger, function* (a) {
    const { oldName, newName } = a.payload;
    try {
      yield put(renameDefaultWeightingRoutine.loading());
      const res = yield call(API.renameDefaultWeighting, {
        OldName: oldName,
        NewName: newName,
      });
      yield put(renameDefaultWeightingRoutine.success(res.data));
      yield put(removeOne(oldName));
      yield put(loadDefaultWeightingsActions.trigger());
      toast.success(i18next.t("greco.success"));
      yield put(
        setRenameDefaultWeightingDialog({
          isOpen: false,
          name: null,
        })
      );
    } catch (err) {
      yield put(renameDefaultWeightingRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* defaultWeightingsPageSaga() {
  yield fork(renameDefaultWeightingSaga);
  yield fork(deleteDefaultWeightingSaga);
}
