import { Label } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { FieldContainer } from "../../../../../components/controls/FieldContainer";
import { getSelectStyles } from "../../../../../utils/utils";
import { ErrorMessage } from "../../../../../components/styled";
import { useSelector } from "../../../../../store/hooks";
import { useFormikContext } from "formik";
import Select, { mergeStyles } from "react-select";
import { useTheme } from "styled-components";
import { useSubCategoryCodeOptions } from "../../../hooks/useSubCategoryCodeOptions";
import { IStyledTheme } from "../../../../../theme/types";

type Props = {
  name: string;
  label: string;
  isRequired?: boolean;
};

export const SubCategoryCodeField = ({
  name,
  label,
  isRequired = false,
}: Props) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const defaultValues = useSelector((s) => s.recommendationPage.defaultValues);
  const theme = useTheme() as IStyledTheme;

  const { errors, values, setFieldValue } = useFormikContext();

  const subCategoryOptions = useSubCategoryCodeOptions(
    defaultValues,
    taxonomy,
    t
  );

  //const recommendation = useSelector((s) => s.recommendationPage.rec);

  // const userRole = useSelector((s) => s.userRole.userRole);
  // const isWaitingForGreg = recommendation?.recommendationStatusCode === 1;
  // const isWaitingForClient = recommendation?.recommendationStatusCode === 2;
  // const isClosed = recommendation?.recommendationStatusCode === 3;
  const isDisabled = false;
  //   (isWaitingForGreg &&
  //     (userRole === "insurer" || userRole === "internal_limited")) ||
  //   isWaitingForClient ||
  //   isClosed;

  const hasError = !!errors[name];
  return (
    <div>
      <FieldContainer isTooltipHidden={isDisabled} tooltipText={label ?? ""}>
        <Label required={isRequired} disabled={isDisabled}>
          {t(label)}
        </Label>
        <Select
          name={name}
          menuPosition="fixed"
          id={t(label)?.replaceAll(" ", "_") + "_field"}
          options={subCategoryOptions}
          placeholder={t("greco.form.searchPlaceholder")}
          noOptionsMessage={() => t("greco.noResults")}
          styles={mergeStyles(getSelectStyles(!!false, theme as IStyledTheme), {
            container: (p) => ({
              ...p,
              width: "100%",
            }),
            menuPortal: (p) => ({
              ...p,
              background: theme.palette.neutralLighter,
              zIndex: 9999999,
            }),
            menu: (p) => ({
              ...p,
              zIndex: 9999,
              background: theme.palette.white,
            }),
            control: (p, state) => {
              return {
                ...p,

                background: state.isDisabled
                  ? "transparent"
                  : theme.palette.white,

                borderBottom: state.isFocused
                  ? `2px solid rgb(0, 90, 161) !important`
                  : `1px solid ${theme.palette.black} !important`,
                boxShadow: "none",
                ...(state.isDisabled && {
                  borderBottom: `1px solid ${theme.palette.neutralLighter} !important`,
                }),
              };
            },
            groupHeading: (p, s) => {
              return {
                ...p,
                borderBottom: "2px solid",
                fontWeight: "bold",
                background: theme.palette.white,
              };
            },
            option: (p, state) => ({
              ...p,
              background:
                state.isSelected || state.isFocused
                  ? theme.palette.neutralLighter
                  : theme.palette.white,
              "&:hover": {
                background: theme.palette.neutralLighter,
              },
              color: theme.palette.black,
              fontSize: "14px",
              fontWeight: 400,
              wordWrap: "break-word",
            }),
            input: (p) => ({
              ...p,
              color: theme.palette.black,
            }),
            singleValue: (p, state) => ({
              ...p,
              color: theme.palette.black,
              fontSize: "14px",
              fontWeight: 400,
            }),
            dropdownIndicator: (p, state) => ({
              ...p,
              ...(state.isDisabled && { visibility: "hidden" }),
            }),
            indicatorSeparator: (p, state) => ({
              ...p,
              ...(state.isDisabled && { visibility: "hidden" }),
            }),
          })}
          isClearable
          loadingMessage={() => t("greco.loading")}
          value={values[name]}
          onChange={(value) => {
            setFieldValue(name, value);
          }}
          isDisabled={isDisabled}
        />
      </FieldContainer>

      {hasError && (
        <ErrorMessage id={t(label)?.replaceAll(" ", "_") + "_error"}>
          {errors[name]}
        </ErrorMessage>
      )}
    </div>
  );
};
