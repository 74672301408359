import { Form, Formik } from "formik";
import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { Flex, HSpace } from "../../../components/styled";
import { RefProvider } from "../../../contexts/RefProvider";
import {
  setRatingLimitsRoutine,
  setSetRatingLimitsDialog,
} from "../../../store/clients-page/clientsPage";
import { useSelector } from "../../../store/hooks";
import { setYupLocale } from "../../../utils/setYupLocale";
import FormContent from "./FormContent";
import SaveButton from "./SaveButton";
import { Shimmer, ShimmerElementType } from "@fluentui/react";

export const SetRatingLimitsDialog = () => {
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schema = useMemo(() => {
    setYupLocale(t);
    return yup.object({
      goodRangeMin: yup.string().required(),
      moderateRangeMin: yup.string().required(),
      poorRangeMin: yup.string().required(),
    });
  }, [t]);

  const isLoading = useSelector(
    (s) => s.clientsPage.setRatingLimitsLoadStatus === "loading"
  );
  const { clientId, body } = useSelector(
    (s) => s.clientsPage.setRatingLimitsDialog
  );

  const onSubmit = (data) => {
    const payload = {
      goodRangeMin: Number(data?.goodRangeMin),
      moderateRangeMin: Number(data?.moderateRangeMin),
      poorRangeMin: Number(data?.poorRangeMin),
    };

    dispatch(
      setRatingLimitsRoutine.trigger({
        clientId: clientId,
        updateHierarchically: data?.updateHierarchically,
        body: {
          clientId: clientId,
          ...payload,
        },
      })
    );
  };

  const close = () => {
    dispatch(
      setSetRatingLimitsDialog({
        clientId: null,
        body: {
          goodRangeMin: null,
          moderateRangeMin: null,
          poorRangeMin: null,
        },
        isOpen: false,
      })
    );
  };

  const title = (
    <Flex>
      <Icon iconName="Refresh" /> <HSpace />
      <div>{t("ram.clientsList.contextMenu.setRatingLimits")}</div>
    </Flex>
  );

  const initialValues = useMemo(() => {
    return {
      goodRangeMin: body.goodRangeMin,
      moderateRangeMin: body.moderateRangeMin,
      poorRangeMin: body.poorRangeMin,
    };
  }, [body]);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title,
        showCloseButton: true,
      }}
      minWidth={300}
      onDismiss={close}
      isBlocking
    >
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
          validationSchema={schema}
        >
          {({ submitForm }) => {
            return isLoading ? (
              <Shimmer
                shimmerElements={[
                  {
                    type: ShimmerElementType.line,
                    verticalAlign: "bottom",
                    width: "100%",
                    height: 200,
                  },
                ]}
              />
            ) : (
              <Form>
                <FormContent />
                <DialogFooter>
                  <SaveButton
                    onClick={() => {
                      setDidTrySubmit(true);
                      submitForm();
                    }}
                  />
                </DialogFooter>
              </Form>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};
