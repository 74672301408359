import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../store/hooks";
import { StyledPrimaryButton } from "../../components/styled";
import { CommandBarButton } from "@fluentui/react";

export const SaveButton = () => {
  const { t } = useTranslation();
  const isUpdateLoading = useSelector(
    (s) => s.locationDefaultValues.isUpdateLoading
  );
  const areDefaultValuesLoading = useSelector(
    (s) => s.locationDefaultValues.areDefaultValuesLoading
  );

  const userRole = useSelector((s) => s.userRole.userRole);

  const isLoading = isUpdateLoading || areDefaultValuesLoading;
  const isDisabled = isLoading || userRole !== "admin";

  return (
    <CommandBarButton
      text={t("greco.save")}
      iconProps={{ iconName: isLoading ? "ProgressRingDots" : "Save" }}
      split
      onClick={() => document.getElementById("loc-def-values-btn")?.click()}
      disabled={isDisabled}
      className={clsx({
        isLoading,
      })}
      menuProps={{
        items: [
          {
            key: "saveAndAddReport",
            text: t("ram.navbar.locationDefaultValues.saveAndAddReport"),
            iconProps: { iconName: "Add" },
            onClick: () =>
              document.getElementById("loc-def-values-add-report-btn")?.click(),
          },
        ],
      }}
    />
  );
};
