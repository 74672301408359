import clsx from "clsx";
import { Icon } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { StatusCellSpinner } from "../../components/styled";
import { getRecommendationStatusCircleColor } from "../../config/colors";
import { useSelector } from "../../store/hooks";
import { RecommendationAzure } from "../../types/types";
import styled from "styled-components";
import { TooltipComponent } from "components/TooltipComponent";

type Props = {
  rec: RecommendationAzure;
};

export const StatusCell = ({ rec }: Props) => {
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();
  const isNotifyLoading = useSelector(
    (s) => s.recommendationsPage.isNotifyLoading
  );
  const recIdNotified = useSelector((s) => s.recommendationsPage.recIdNotified);

  const shouldShowSpinner =
    isNotifyLoading && recIdNotified === rec?.recommendationId;

  if (shouldShowSpinner) {
    return <StatusCellSpinner />;
  }

  const code =
    taxonomy.RiskRecommendationStatus.byId[rec?.recommendationStatusCode]?.code;
  return (
    <TooltipComponent content={t(code)}>
      <Container
        className={clsx({
          isAssessed:
            !!rec.isAssessed &&
            code === "RiskRecommendationStatus.WAITING_FOR_GREG",
        })}
      >
        <Icon
          iconName={"CircleFill"}
          style={{
            color: getRecommendationStatusCircleColor(rec, code),
          }}
          styles={{
            root: {
              fontSize: "8px",
            },
          }}
        />
      </Container>
    </TooltipComponent>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid transparent;
  width: 16px;
  height: 16px;
  &.isAssessed {
    border-color: #323130;
  }
`;
