import {
  Dialog,
  DialogContent,
  DialogFooter,
  Icon,
  PrimaryButton,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormSpinner } from "../../components/FormSpinner";
import { Flex, HSpace, StyledPrimaryButton } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import i18next from "i18next";
import { toast } from "../../components/FluentToast";
import {
  deleteReportRoutine,
  setDeleteDialog,
} from "../../store/reports-page/reportsPage";

export const DeleteDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { report } = useSelector((s) => s.reportsPage.deleteReportDialog);
  const isLoading = useSelector(
    (s) => s.reportsPage.deleteReportLoadStatus === "loading"
  );

  const close = () => {
    dispatch(
      setDeleteDialog({
        report: null,
        isOpen: false,
      })
    );
  };

  const hasAttachments =
    report.hasReportAttachments || report.hasResponseAttachments;

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        showCloseButton: true,
        title: (
          <Flex>
            <Icon iconName="Warning" /> <HSpace />
            <div>{t("greco.areYouSure")}</div>
          </Flex>
        ),
      }}
      onDismiss={close}
    >
      <div>{t("ram.delete.report.label")}</div>
      {hasAttachments && (
        <div>{t("ram.reports.delete.hasAttachmentsWarning")}</div>
      )}
      <DialogFooter>
        <StyledPrimaryButton
          text={t("greco.delete")}
          onClick={() => {
            dispatch(
              deleteReportRoutine.trigger({
                locationId: report.locationId,
                reportId: report.reportId,
                onSuccess: () => {
                  toast.success(i18next.t("greco.success"));
                  dispatch(
                    setDeleteDialog({
                      isOpen: false,
                      report: null,
                    })
                  );
                },
              })
            );
          }}
          disabled={isLoading}
        />
      </DialogFooter>
      {isLoading && <FormSpinner />}
    </Dialog>
  );
};
