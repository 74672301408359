import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormSpinner } from "../../components/FormSpinner";
import { Flex, HSpace, StyledPrimaryButton } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import {
  exportToZipRoutine,
  setExportToZipDialog,
} from "../../store/locations-page/locationsPage";

export const ExportToZipDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { locationId } = useSelector((s) => s.locationsPage.exportToZipDialog);
  const isLoading = useSelector(
    (s) => s.locationsPage.exportToZipLoadStatus === "loading"
  );

  const close = () => {
    dispatch(
      setExportToZipDialog({
        locationId: null,
        isOpen: false,
      })
    );
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        showCloseButton: true,
        title: (
          <Flex>
            <Icon iconName="Info" /> <HSpace />
            <div>{t("ram.locationList.context.exportToZip")}</div>
          </Flex>
        ),
      }}
      onDismiss={close}
    >
      <div>{t("ram.locationList.context.exportToZip.success")}</div>
      <DialogFooter>
        <StyledPrimaryButton
          text={t("ram.locationList.context.exportToZip")}
          onClick={() => {
            dispatch(
              exportToZipRoutine.trigger({
                locationId: locationId,
              })
            );
          }}
          disabled={isLoading}
        />
      </DialogFooter>
      {isLoading && <FormSpinner />}
    </Dialog>
  );
};
