import {
  ActionButton,
  CommandBarButton,
  IContextualMenuItem,
} from "@fluentui/react";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsMutating } from "react-query";
import { useDispatch } from "react-redux";
import { MUTATION_KEYS } from "store/constants";
import { HSpace } from "../../../../components/styled";
import { useSelector } from "../../../../store/hooks";
import {
  setAddAssessmentDialog,
  setAddEvaluationDialog,
  setAddResponseDialog,
} from "../../../../store/recommendationPage";
import { AppDispatch } from "../../../../store/store";

type Props = {
  setDidTrySubmit: () => void;
};

export const AddResponseButton = ({ setDidTrySubmit }: Props) => {
  const { t } = useTranslation();

  const createEvaluationInProgress = !!useIsMutating({
    mutationKey: MUTATION_KEYS.createEvaluation,
  });

  const updateEvaluationInProgress = !!useIsMutating({
    mutationKey: MUTATION_KEYS.updateEvaluation,
  });
  const isDisabled = createEvaluationInProgress || updateEvaluationInProgress;

  const { setFieldValue, submitForm } = useFormikContext();
  const taxonomy = useSelector((s) => s.taxonomy);
  const recommendation = useSelector((s) => s.recommendationPage.rec);
  const locations = useSelector((s) => s.locations.entities);

  const createAssesmentInProgress = !!useIsMutating({
    mutationKey: MUTATION_KEYS.createAssesment,
  });

  const updateAssesmentInProgress = !!useIsMutating({
    mutationKey: MUTATION_KEYS.updateAssesment,
  });

  const createResponseInProgress = !!useIsMutating({
    mutationKey: MUTATION_KEYS.createResponse,
  });

  const updateResponseInProgress = !!useIsMutating({
    mutationKey: MUTATION_KEYS.updateResponse,
  });

  const [assessmentPeriodStartedAt, setAssessmentPeriodStartedAt] =
    useState(null);
  useEffect(() => {
    if (recommendation?.locationId && locations[recommendation?.locationId]) {
      setAssessmentPeriodStartedAt(
        locations[recommendation?.locationId].assessmentPeriodStartedAt
      );
    }
  }, [recommendation, locations]);

  const userRole = useSelector((s) => s.userRole.userRole);
  const canAddResponseState = assessmentPeriodStartedAt === null; //useSelector(selectCanAddResponse as any);
  const canAddAssessmentState = true; //useSelector(selectCanAddAssessment as any);

  const canAddResponse =
    canAddResponseState &&
    userRole !== "internal_limited" &&
    userRole !== "insurer";

  const isClosed = recommendation?.recommendationStatusCode === 3;

  const canAddAssessment = userRole !== "external" && userRole !== "insurer";

  const canAddEvaluation =
    (!isClosed && userRole === "admin") || userRole === "insurer";
  const dispatch: AppDispatch = useDispatch();
  const response = useSelector(
    (s) => s.recommendationPage.addResponseDialog.response
  );

  const assessment = useSelector(
    (s) => s.recommendationPage.addAssessmentDialog.assessment
  );
  const isResponseDialogOpen = useSelector(
    (s) => s.recommendationPage.addResponseDialog.isOpen
  );
  const isAssessmentDialogOpen = useSelector(
    (s) => s.recommendationPage.addAssessmentDialog.isOpen
  );
  const isEvaluationDialogOpen = useSelector(
    (s) => s.recommendationPage.addEvaluationDialog.isOpen
  );

  const isResponseFormDisabled =
    createResponseInProgress || updateResponseInProgress;
  const isAssessmentFormDisabled =
    createAssesmentInProgress || updateAssesmentInProgress;
  const isEvaluationFormDisabled =
    createEvaluationInProgress || updateEvaluationInProgress;

  const menuItems: IContextualMenuItem[] = [
    {
      key: "addResponse",
      text: t("ram.navBar.addResponse"),
      iconProps: { iconName: "Add" },
      onClick: () =>
        dispatch(
          setAddResponseDialog({
            isOpen: true,
            response: null,
            attachments: [],
          })
        ),
      hidden: !canAddResponse,
    },
    {
      key: "addAssessment",
      text: t("ram.navBar.addAssessment"),
      iconProps: { iconName: "Add" },
      onClick: () =>
        dispatch(
          setAddAssessmentDialog({
            isOpen: true,
            assessment: null,
            attachments: [],
          })
        ),
      hidden: !canAddAssessment,
    },
    {
      key: "addEvaluation",
      text: t("ram.navBar.addEvaluation"),
      iconProps: { iconName: "Add" },
      onClick: () =>
        dispatch(
          setAddEvaluationDialog({
            isOpen: true,
            evaluation: null,
            attachments: [],
          })
        ),
      hidden: !canAddEvaluation,
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        {(canAddResponse || response) &&
        !isEvaluationDialogOpen &&
        !isAssessmentDialogOpen &&
        isResponseDialogOpen ? (
          <>
            <CommandBarButton
              text={t("ram.responsePage.saveDraft.btn")}
              iconProps={{ iconName: "Save" }}
              split
              onClick={() => {
                setFieldValue("mode", "draft");
                setDidTrySubmit();
                submitForm();
              }}
              disabled={isResponseFormDisabled}
              menuProps={{
                items: [
                  {
                    key: "saveAndNotify",
                    text: t("ram.navBar.saveAndNotify"),
                    iconProps: {
                      iconName: "Ringer",
                    },
                    onClick: () => {
                      setFieldValue("mode", "notify");
                      setDidTrySubmit();
                      submitForm();
                    },
                    disabled: isResponseFormDisabled,
                  },
                ],
              }}
            />
            <HSpace width={10} />
            <ActionButton
              text={t("greco.cancel")}
              onClick={() => {
                dispatch(
                  setAddResponseDialog({
                    isOpen: false,
                    response: null,
                    attachments: [],
                  })
                );
              }}
              disabled={isResponseFormDisabled}
            />
          </>
        ) : null}
        {(canAddAssessment || assessment) &&
        !isEvaluationDialogOpen &&
        !isResponseDialogOpen &&
        isAssessmentDialogOpen ? (
          <>
            <CommandBarButton
              text={t("ram.navBar.saveAndNotify")}
              iconProps={{
                iconName: "Ringer",
              }}
              onClick={() => {
                setFieldValue("mode", "normal");
                setDidTrySubmit();
                submitForm();
              }}
              disabled={isAssessmentFormDisabled}
            />
            <HSpace width={10} />
            <ActionButton
              text={t("greco.cancel")}
              onClick={() => {
                dispatch(
                  setAddAssessmentDialog({
                    isOpen: false,
                    assessment: null,
                    attachments: [],
                  })
                );
              }}
              disabled={isAssessmentFormDisabled}
            />
          </>
        ) : null}
        {canAddEvaluation &&
        canAddResponseState !== undefined &&
        !isResponseDialogOpen &&
        !isAssessmentDialogOpen &&
        isEvaluationDialogOpen ? (
          <>
            <CommandBarButton
              text={t("ram.navBar.saveAndNotify")}
              iconProps={{ iconName: "Save" }}
              onClick={() => {
                setFieldValue("mode", "normal");
                setDidTrySubmit();
                submitForm();
              }}
              disabled={isDisabled}
            />
            <HSpace width={10} />
            <ActionButton
              text={t("greco.cancel")}
              onClick={() => {
                dispatch(
                  setAddEvaluationDialog({
                    isOpen: false,
                    evaluation: null,
                    attachments: [],
                  })
                );
              }}
              disabled={isEvaluationFormDisabled}
            />
          </>
        ) : null}
      </div>

      {!isResponseDialogOpen &&
      !isAssessmentDialogOpen &&
      !isEvaluationDialogOpen ? (
        <div style={{ display: "flex" }}>
          <CommandBarButton
            iconProps={{ iconName: "Add" }}
            text={t("ram.navBar.addAction")}
            menuProps={{
              items: menuItems.filter((item) => !item.hidden), // Filters out hidden items
            }}
          />
          <HSpace width={10} />
        </div>
      ) : null}
    </>
  );
};
