import { mergeStyleSets, Text, TextField } from "@fluentui/react";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Rifm } from "rifm";

import { useTheme } from "styled-components";
import * as Float from "../../components/use-number-format/float";
import { FieldContainer } from "./FieldContainer";
import { IStyledTheme } from "../../theme/types";

const getTextFieldStyle = (transparent, theme, isMarked) => ({
  root: {
    ".ms-TextField-fieldGroup": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
      // background: transparent ? "transparent" : "inherit",
      // ...(isMarked && { background: theme.palette.yellowLight }),
    },
    ".ms-TextField-fieldGroup::after": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
    },
    ".ms-TextField-fieldGroup input": {
      ...(isMarked && theme.isDark && { color: "black" }),
    },
    ".ms-TextField-wrapper span": {
      background: theme.palette.white,
    },
    ".ms-TextField-fieldGroup input:disabled": {
      background: theme.palette.white,
    },
  },
});

const DoubleNumberInputField = ({
  disabled,
  label,
  required = false,
  name,
  error = "",
  value,
  onChange,
  setFieldValue = () => {},
  isNegativeAllowed = false,
  errors,
  transparent,
  isMarked,
  defaultValueFunction = null,
  insurerSettingsFunction = null,
  maxDecimals = 2,
  ...props
}: any) => {
  const theme = useTheme() as IStyledTheme;

  const isDarkMode = theme.isDark;
  const { t } = useTranslation();

  const styles = getTextFieldStyle(transparent, theme, isMarked);
  const classes = mergeStyleSets({
    label: {
      fontSize: "14px",
      fontWeight: "600",
      padding: "5px",
      display: "block",
      color: (function () {
        if (disabled) {
          return theme.palette.neutralSecondaryAlt;
        }
        if (isDarkMode) {
          return "white";
        }
        return theme.palette.black;
      })(),
    },
    labelAsterix: {
      color: theme.palette.redDark,
    },
  });

  const onRenderLabel = () => (
    <Text className={classes.label}>
      {t(label)}

      <Text className={classes.labelAsterix}>{required && " *"}</Text>
    </Text>
  );

  const formatValue = (value) => {
    const retVal = Float.format({
      value,
      maxDecimals: maxDecimals,
      isNegativeAllowed: isNegativeAllowed,
      max: Infinity,
    }).toString();
    return retVal;
  };

  useEffect(() => {
    if (value) {
      const lastChar = value.toString().slice(-1);

      if (
        !Number(lastChar) &&
        lastChar !== "," &&
        lastChar !== "-" &&
        lastChar.toString() !== "0"
      ) {
        const numberValue = value.substring(0, value.length - 1);
        setFieldValue(name, numberValue);
      }
    }
  }, [value]);

  return (
    <FieldContainer isTooltipHidden={disabled} tooltipText={label}>
      <Rifm
        accept={Float.accept}
        format={(v) => formatValue(v)}
        value={value}
        onChange={(value) => setFieldValue(name, formatValue(value))}
      >
        {({ value, onChange }) => (
          <TextField
            id={name + "-input"}
            type="tel"
            onRenderLabel={onRenderLabel}
            label={t(label)}
            name={name}
            styles={styles}
            disabled={disabled}
            onChange={onChange}
            value={value}
            errorMessage={errors[name]}
            autoComplete="off"
            {...props}
          />
        )}
      </Rifm>
    </FieldContainer>
  );
};

export default DoubleNumberInputField;
