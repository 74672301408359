import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ListContextMenuIconButton } from "../../components/styled";
import { AppDispatch } from "../../store/store";
import { ClientLocation } from "../../types/types";
import {
  setRenameDefaultWeightingDialog,
  setDeleteDialog,
} from "store/defaultWeightings-page/defaultWeightingsPage";
import { routes } from "../../config/routes";

type Props = {
  loc: ClientLocation;
};

export const ContextButton = ({ loc }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const onClickEdit = () => {
    navigate(routes.defaultWeighting.getPath(loc.name));
  };

  const onClickRename = () => {
    dispatch(
      setRenameDefaultWeightingDialog({
        name: loc.name,
        isOpen: true,
      })
    );
  };

  const onClickDuplicate = () => {
    navigate(
      routes.addDefaultWeighting.getPath(loc.name) + `?template=${loc.name}`
    );
  };

  const onClickDelete = () => {
    dispatch(
      setDeleteDialog({
        name: loc.name,
        isOpen: true,
      })
    );
  };

  const items = [
    {
      key: "edit",
      text: t("ram.edit.label"),
      iconProps: { iconName: "Edit" },
      onClick: () => onClickEdit(),
    },
    {
      key: "duplicate",
      text: t("ram.useAsTemplateToCreateNew.label"),
      iconProps: { iconName: "Copy" },
      onClick: () => onClickDuplicate(),
    },
    {
      key: "rename",
      text: t("ram.rename"),
      iconProps: { iconName: "Switch" },
      onClick: () => onClickRename(),
    },
    {
      key: "delete",
      text: t("greco.delete"),
      iconProps: {
        iconName: "Trash",
      },
      onClick: onClickDelete,
    },
  ];

  return (
    <ListContextMenuIconButton
      iconProps={{ iconName: "MoreVertical" }}
      menuIconProps={{ style: { display: "none" } }}
      menuProps={{
        items: items,
      }}
    />
  );
};
