import { PrimaryButton } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { copyTextToClipboard } from "../../store/util";
import { CommandBarButton } from "@fluentui/react";

type Props = {
  isDisabled: boolean;
  users: any[];
  singleCopy: boolean;
};

function copyUsersEmailsToClipboard(users, external) {
  let clientEmails = "";
  users.forEach((u) => {
    if (
      external === null ||
      (external === true && u.isInternal === false) ||
      (external === false && u.isInternal === true)
    ) {
      clientEmails += u.userEmailAddress + ";";
    }
  });
  copyTextToClipboard(clientEmails);
}

export const CopyUsersEmailsToClipboard = ({
  isDisabled,
  users,
  singleCopy,
}: Props) => {
  const { t } = useTranslation();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const isReallyDisabled = isDisabled || buttonDisabled;
  useEffect(() => {
    if (buttonDisabled) {
      setTimeout(() => {
        setButtonDisabled(false);
      }, 300);
    }
  }, [buttonDisabled]);
  let menuProps = null;
  if (!singleCopy) {
    menuProps = {
      items: [
        {
          key: "external",
          text: t("ram.nav.copyExternalUsersEmailsToClipboard.label"),
          iconProps: { iconName: "FollowUser" },
          onClick: () => {
            copyUsersEmailsToClipboard(users, true);
            setButtonDisabled(true);
          },
        },
        {
          key: "internal",
          text: t("ram.nav.copyInternalUsersEmailsToClipboard.label"),
          iconProps: { iconName: "FabricUserFolder" },
          onClick: () => {
            copyUsersEmailsToClipboard(users, false);
            setButtonDisabled(true);
          },
        },
      ],
    };
  }

  return (
    <CommandBarButton
      text={t("ram.nav.copyUsersEmailsToClipboard.label")}
      iconProps={{ iconName: "UserFollowed" }}
      split
      onClick={() => {
        copyUsersEmailsToClipboard(users, null);
        setButtonDisabled(true);
      }}
      disabled={isReallyDisabled}
      menuProps={menuProps}
    />
  );
};
