import {
  Dialog,
  DialogContent,
  DialogFooter,
  Icon,
  PrimaryButton,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormSpinner } from "../../components/FormSpinner";
import { Flex, HSpace, StyledPrimaryButton } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import {
  copyRecommendationRoutine,
  setCopyDialog,
} from "../../store/recommendations-page/recommendationsPage";

export const CopyDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { recommendation } = useSelector(
    (s) => s.recommendationsPage.copyRecommendationDialog
  );
  const isLoading = useSelector(
    (s) => s.recommendationsPage.copyRecommendationLoadStatus === "loading"
  );

  const close = () => {
    dispatch(
      setCopyDialog({
        recommendation: null,
        isOpen: false,
      })
    );
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        showCloseButton: true,
        title: (
          <Flex>
            <Icon iconName="Info" /> <HSpace />
            <div>{t("ram.recommendationList.copyRecommendation")}</div>
          </Flex>
        ),
      }}
      onDismiss={close}
    >
      <div>{t("ram.recommendationList.copyRecommendation.message")}</div>
      <DialogFooter>
        <StyledPrimaryButton
          text={t("ram.recommendationList.copyRecommendation")}
          onClick={() => {
            dispatch(
              copyRecommendationRoutine.trigger({
                recommendation: recommendation,
              })
            );
          }}
          disabled={isLoading}
        />
      </DialogFooter>
      {isLoading && <FormSpinner />}
    </Dialog>
  );
};
