import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { errorHandler } from "../../../utils/errorHandler";
import { AppContext, IAppContext } from "../../../AppContext";
import { useFormikContext } from "formik";

type Props = {
  fieldsConfig: {
    [key: string]: {
      label: string;
    };
  };
};
export const useErrorMessagesHandler = ({ fieldsConfig }: Props) => {
  const { errors, isSubmitting } = useFormikContext();
  const { t } = useTranslation();

  const appContext = useContext<IAppContext>(AppContext);

  useEffect(() => {
    if (isSubmitting) {
      appContext.filterDuplicates(errors);

      //show error message only on submit (formik is set to validate on blur and change)
      Object.keys(errors).forEach((fieldName) => {
        errorHandler({
          value: `${t(`${fieldsConfig[fieldName].label}`)}: ${
            errors[fieldName]
          }`,
          type: "error",
          label: `${fieldsConfig[fieldName].label}`,
          fieldName,
          errors,
        });
      });
    }
  }, [errors, isSubmitting, t]);
};
