import {
  Dialog,
  DialogContent,
  DialogFooter,
  Icon,
  PrimaryButton,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormSpinner } from "../../components/FormSpinner";
import { Flex, HSpace, StyledPrimaryButton } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import {
  deleteDefaultWeightingRoutine,
  setDeleteDialog,
} from "../../store/defaultWeightings-page/defaultWeightingsPage";

export const DeleteDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { name } = useSelector(
    (s) => s.defaultWeightingsPage.deleteDefaultWeightingDialog
  );
  const isLoading = useSelector(
    (s) =>
      s.defaultWeightingsPage.deleteDefaultWeightingLoadStatus === "loading"
  );

  const close = () => {
    dispatch(
      setDeleteDialog({
        name: null,
        isOpen: false,
      })
    );
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        showCloseButton: true,
        title: (
          <Flex>
            <Icon iconName="Warning" /> <HSpace />
            <div>{t("greco.areYouSure")}</div>
          </Flex>
        ),
      }}
      onDismiss={close}
    >
      <div>{t("ram.delete.defaultweighting.label", [name])}</div>

      <DialogFooter>
        <StyledPrimaryButton
          text={t("greco.delete")}
          onClick={() => {
            dispatch(deleteDefaultWeightingRoutine.trigger(name));
          }}
          disabled={isLoading}
        />
      </DialogFooter>
      {isLoading && <FormSpinner />}
    </Dialog>
  );
};
