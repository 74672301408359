import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  adjustFloatNumberValue,
  transformNumberValue,
} from "../../../../../../form/util";
import { useSelector } from "../../../../../../store/hooks";
import { selectByIdLocations } from "../../../../../../store/locations/locations";
import { ClientLocation, Recommendation } from "../../../../../../types/types";

function useCostBenifitFactorCalculation() {
  const { values, setFieldValue } = useFormikContext<any>();
  const { locationId } = useParams() as any;

  const location = useSelector((s) => selectByIdLocations(s, locationId));

  const calculateCostBenefitFactor = ({
    implementationCostsEur,
    emlReductionPercent,
    emlinMioEur,
  }: {
    implementationCostsEur: Recommendation["implementationCostsEur"];
    emlReductionPercent: Recommendation["emlReductionPercent"];
    emlinMioEur: ClientLocation["emlinMioEur"];
  }) => {
    if (implementationCostsEur === null || implementationCostsEur <= 0)
      return null;
    if (emlReductionPercent === null) return null;
    if (emlinMioEur === null) return null;

    return (
      ((emlReductionPercent / 100) * emlinMioEur * 1_000_000) /
      implementationCostsEur
    );
  };

  const handleBenifitsFactorValue = (values) => {
    if (
      values?.implementationCostsEur === undefined ||
      values?.implementationCostsEur === null
    )
      return;

    const implementationCostsEurNumber = transformNumberValue(
      values?.implementationCostsEur?.toString()
    );

    const costBenefitFactor = calculateCostBenefitFactor({
      implementationCostsEur: isNaN(implementationCostsEurNumber)
        ? null
        : implementationCostsEurNumber,
      emlReductionPercent: values?.emlReductionPercent,
      emlinMioEur: location ? location.emlinMioEur : null,
    });

    if (costBenefitFactor === null || isNaN(costBenefitFactor)) {
      setFieldValue("costBenifitFactor", null);
    } else {
      setFieldValue(
        "costBenifitFactor",
        adjustFloatNumberValue(costBenefitFactor)
      );
    }
  };

  useEffect(() => {
    handleBenifitsFactorValue(values);
  }, [values?.implementationCostsEur, values?.emlReductionPercent]);
}

export default useCostBenifitFactorCalculation;
