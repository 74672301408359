import { ActionButton, Icon, Stack } from "@fluentui/react";
import React from "react";
import { DefaultButton } from "@fluentui/react";
import { businessObjectIcons } from "../../../config/icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { setCopyReportDataDialog } from "../../../store/locationPage";
import { useSelector } from "../../../store/hooks";

export const CopyReportDataButton = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const reports = useSelector((s) => s.reports.entities);
  const location = useSelector((s) => s.locationPage.location);
  return (
    <ActionButton
      title={t("ram.locationForm.copyDataFromReport.label")}
      onClick={() => {
        dispatch(
          setCopyReportDataDialog({
            reportData: Object.values(reports).filter(
              (r) => r.locationId === location.locationId
            ),
            isOpen: true,
          })
        );
      }}
      disabled={
        Object.values(reports).filter(
          (r) => r?.locationId === location?.locationId
        ).length === 0
      }
    >
      <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
        <Icon iconName={businessObjectIcons["report"]} />
        <Icon iconName="ChromeBackMirrored" />
        <Icon iconName={businessObjectIcons["location"]} />
      </Stack>
    </ActionButton>
  );
};
