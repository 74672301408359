import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLocationsFilter } from "../../store/filters/filters";
import { selectLocationsFilterLocations } from "../../store/filters/selectors";
import { useSelector } from "../../store/hooks";
import { selectEntitiesLocations } from "../../store/locations/locations";
import { AppDispatch } from "../../store/store";
import { setManageLocationsDialog } from "../../store/users";
import { setManageReportsDialog } from "../../store/usersinsurer";
import { StyledCheckboxDropdown } from "../styled";

export const LocationsFilter = () => {
  const locations = useSelector(selectLocationsFilterLocations);
  const locationEntities = useSelector(selectEntitiesLocations);
  const locationsFilter = useSelector((s) => s.filters.locationsFilter);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const hasValue = locationsFilter.length > 0;

  return (
    <StyledCheckboxDropdown
      value={locationsFilter.map((id) => {
        return {
          label: locationEntities[id]!.locationName,
          value: id,
        };
      })}
      onChange={(value) => {
        dispatch(setLocationsFilter(value.map((o: any) => o.value)));
        dispatch(
          setManageLocationsDialog({
            user: null,
            isOpen: false,
          })
        );
        dispatch(
          setManageReportsDialog({
            user: null,
            isOpen: false,
          })
        );
      }}
      options={locations
        .filter((r) => (r ? true : false))
        .map((l: any) => ({
          label: l.locationName,
          value: l.locationId,
        }))}
      label={t("ram.nav.locationsFilter.label")}
      mulitpleSelectedLabel={`${locationsFilter.length} ${t(
        "ram.nav.locationsFilter.multiSelectedLabel"
      )}`}
      searchPlaceholder={t("ram.nav.locationsFilter.searchPlaceholder")}
      className={clsx({
        hasValue,
      })}
    />
  );
};
