import React from "react";
import { useSelector } from "../../../../store/hooks";
import { AddResponseButton } from "./AddResponseButton";
import { ReportDocumentButton } from "./ReportDocumentButton";

type Props = {
  setDidTrySubmit: () => void;
};

export function ResponsesBtns({ setDidTrySubmit }: Props) {
  const addResponseDialogIsOpen = useSelector(
    (s) => s.recommendationPage.addResponseDialog.isOpen
  );
  const addEvaluationDialogIsOpen = useSelector(
    (s) => s.recommendationPage.addEvaluationDialog.isOpen
  );
  const addAssessmentDialogIsOpen = useSelector(
    (s) => s.recommendationPage.addAssessmentDialog.isOpen
  );
  return (
    <>
      {!addEvaluationDialogIsOpen &&
        !addResponseDialogIsOpen &&
        !addAssessmentDialogIsOpen && <ReportDocumentButton />}
      <AddResponseButton setDidTrySubmit={setDidTrySubmit} />
    </>
  );
}
