import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "../api/api";
import { routes } from "../config/routes";
import { DefaultWeightingName, DefaultWeightingItem } from "../types/types";
import { RootState } from "./store";
import { toast } from "../components/FluentToast";
import i18next from "i18next";
import { handleAxiosError } from "./util";
import { history } from "utils/_helpers";
import { push, replace } from "redux-first-history";

export type DefaultWeightingsState = {
  defaultWeightings: DefaultWeightingItem[];
  areDefaultWeightingsLoading: boolean;
  isUpdateLoading: boolean;
  newName: string;
};

export const loadDefaultWeightings = createAsyncThunk<
  DefaultWeightingItem[],
  void,
  { state: RootState }
>("defaultWeightings/loadDefaultWeightings", async (_, thunkAPI) => {
  const loc = thunkAPI.getState().router.location;
  const defaultWeightingsMatch = routes.defaultWeighting.matchPath(loc);

  const { name } = defaultWeightingsMatch?.params;

  let res;
  try {
    res = await API.getDefaultWeightingByName({ name });
  } catch (err) {
    if (err.response.status === 403 || err.response.status === 404) {
      thunkAPI.dispatch(replace("/defaultweightings"));
    }
  }

  return res.data;
});

export const loadDefaultWeightingsByName = createAsyncThunk<
  DefaultWeightingItem[],
  { name: string },
  { state: RootState }
>(
  "defaultWeightings/loadDefaultWeightingsByName",
  async ({ name }, thunkAPI) => {
    let res;
    try {
      res = await API.getDefaultWeightingByName({ name });
    } catch (err) {
      if (err.response.status === 403 || err.response.status === 404) {
        thunkAPI.dispatch(replace("/defaultweightings"));
      }
    }

    return res.data;
  }
);

export const updateDefaultWeightings = createAsyncThunk<
  DefaultWeightingItem[],
  {
    name: string;
    defaultWeightings: DefaultWeightingItem[];
    callback?: () => void;
  },
  { state: RootState }
>(
  "defaultWeightings/updateDefaultWeightings",
  async ({ name, defaultWeightings }, thunkAPI) => {
    const loc = thunkAPI.getState().router.location;
    const defaultWeightingsMatch = routes.defaultWeightings.matchPath(loc);

    try {
      const res = await API.updateDefaultWeighting(name, defaultWeightings);
      toast.success(i18next.t("ram.defaultWeightings.updateSuccess"));
      // if (mode === "addReport") {
      //   thunkAPI.dispatch(push(routes.addReport.getPath(clientId, locationId)));
      // }
      return res.data;
    } catch (err) {
      handleAxiosError(err);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createDefaultWeightings = createAsyncThunk<
  DefaultWeightingItem[],
  {
    name: string;

    defaultWeightings: DefaultWeightingItem[];
  },
  { state: RootState }
>(
  "defaultWeightings/createDefaultWeightings",
  async ({ name, defaultWeightings }, thunkAPI) => {
    const loc = thunkAPI.getState().router.location;

    try {
      let body = defaultWeightings.map((dw) => {
        return {
          ...dw,
          subcategoryDefaultName: name,
        };
      });
      const res = await API.createDefaultWeighting(body);
      toast.success(i18next.t("ram.defaultWeightings.createSuccess"));
      // if (mode === "addReport") {
      //   thunkAPI.dispatch(push(routes.addReport.getPath(clientId, locationId)));
      // }
      return res.data;
    } catch (err) {
      handleAxiosError(err);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const defaultWeightingSlice = createSlice({
  name: "defaultWeighting",
  initialState: {
    defaultWeightings: [],
    areDefaultWeightingsLoading: false,
    isUpdateLoading: false,
    newName: "",
  } as DefaultWeightingsState,
  reducers: {
    setNewName: (s, a: PayloadAction<DefaultWeightingsState["newName"]>) => {
      s.newName = a.payload;
    },
    setDefaultWeightingsValue: (
      s,
      a: PayloadAction<DefaultWeightingsState["defaultWeightings"]>
    ) => {
      s.defaultWeightings = a.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadDefaultWeightings.pending, (s, a) => {
      s.areDefaultWeightingsLoading = true;
    });
    builder.addCase(loadDefaultWeightings.fulfilled, (s, a) => {
      s.areDefaultWeightingsLoading = false;
      s.defaultWeightings = a.payload;
    });
    builder.addCase(loadDefaultWeightings.rejected, (s, a) => {
      s.areDefaultWeightingsLoading = false;
    });
    builder.addCase(loadDefaultWeightingsByName.pending, (s, a) => {
      s.areDefaultWeightingsLoading = true;
    });
    builder.addCase(loadDefaultWeightingsByName.fulfilled, (s, a) => {
      s.areDefaultWeightingsLoading = false;
      s.defaultWeightings = a.payload;
    });
    builder.addCase(loadDefaultWeightingsByName.rejected, (s, a) => {
      s.areDefaultWeightingsLoading = false;
    });
    builder.addCase(updateDefaultWeightings.pending, (s, a) => {
      s.isUpdateLoading = true;
    });
    builder.addCase(updateDefaultWeightings.fulfilled, (s, a) => {
      s.isUpdateLoading = false;
      s.defaultWeightings = a.payload;
    });
    builder.addCase(updateDefaultWeightings.rejected, (s, a) => {
      s.isUpdateLoading = false;
    });
  },
});

export const { setNewName, setDefaultWeightingsValue } =
  defaultWeightingSlice.actions;

export const defaultWeightingReducer = defaultWeightingSlice.reducer;
