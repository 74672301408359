import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { defaultCols } from "../pages/recommendations/cols";
import { BooleanFilter, ExpandedColumn, NumberFilter } from "../types/columns";
import { RootState } from "./store";

export type RecommendationsColumnOptionsState = {
  columns: EntityState<ExpandedColumn>;
};

const columnsAdapter = createEntityAdapter<ExpandedColumn>({
  selectId: (c) => c.key,
});

export const recommendationsColumnOptionsSlice = createSlice({
  name: "recommendationsColumnOptions",
  initialState: {
    columns: columnsAdapter.getInitialState(),
  },
  reducers: {
    setColumns: (s, a: PayloadAction<any[]>) => {
      columnsAdapter.setAll(s.columns, a.payload);
    },
    toggleColumnVisibility: (s, a: PayloadAction<any>) => {
      const col = s.columns.entities[a.payload];
      if (!col) return;
      columnsAdapter.updateOne(s.columns, {
        id: a.payload,
        changes: {
          isVisible: !col.isVisible,
        },
      });
    },
    showAllColumns: (s) => {
      s.columns.ids.forEach((id) => {
        s.columns.entities[id].isVisible = true;
      });
    },
    hideAllColumns: (s) => {
      s.columns.ids.forEach((id) => {
        const col = s.columns.entities[id];
        if (!col.isFixed) {
          col.isVisible = false;
        }
      });
    },
    reorderColumn: (
      s,
      a: PayloadAction<{ index1: number; index2: number }>
    ) => {
      const { index1, index2 } = a.payload;
      s.columns.ids.splice(index2, 0, s.columns.ids.splice(index1, 1)[0]);
    },
    updateNumberFilter: (
      s,
      a: PayloadAction<{ key: ExpandedColumn["key"]; filter: NumberFilter }>
    ) => {
      const { key, filter } = a.payload;
      const col = s.columns.entities[key];
      if (col.filter && col.filter.type === "number") {
        col.filter = filter;
      }
    },
    toggleTaxonomyFilter: (
      s,
      a: PayloadAction<{ key: ExpandedColumn["key"]; taxonomyId: number }>
    ) => {
      const { key, taxonomyId } = a.payload;
      const col = s.columns.entities[key];
      if (col.filter && col.filter.type === "taxonomy") {
        const index = col.filter.value.findIndex((id) => id === taxonomyId);
        if (index !== -1) {
          col.filter.value.splice(index, 1);
        } else {
          col.filter.value.push(taxonomyId);
        }
      }
    },
    setFilter: (
      s,
      a: PayloadAction<{
        columnKey: ExpandedColumn["key"];
        filter: any;
      }>
    ) => {
      const { columnKey, filter } = a.payload;

      const col = s.columns.entities[columnKey];
      col.filter = filter;
    },
    clearAllFilters: (
      s,
      a: PayloadAction<{
        key: any;
        value: BooleanFilter["value"];
      }>
    ) => {
      s.columns.ids.forEach((id) => {
        const col = s.columns.entities[id] as any;
        const defaultCol = defaultCols.find((c) => c.key === col.key);
        col.filter = defaultCol?.filter;
      });
    },
  },
});

export const {
  actions: {
    setColumns,
    toggleColumnVisibility,
    showAllColumns,
    hideAllColumns,
    reorderColumn,
    updateNumberFilter,
    toggleTaxonomyFilter,
    setFilter,
    clearAllFilters,
  },
  reducer: recommendationsColumnOptionsReducer,
} = recommendationsColumnOptionsSlice;

export const { selectAll: selectAllColumnsRecommendationsColumnOptions } =
  columnsAdapter.getSelectors<RootState>(
    (s) => s.recommendationsColumnOptions.columns
  );

const localSelectors = columnsAdapter.getSelectors();

export const selectAreAllVisible = createSelector(
  (s) => localSelectors.selectAll(s.recommendationsColumnOptions.columns),
  (columns: any) => {
    return !columns.some((c) => !c.isVisible);
  }
);

export const selectAreColumnsDirty = createSelector(
  (s) => localSelectors.selectAll(s.recommendationsColumnOptions.columns),
  (localColumns) => (reduxColumns) => {
    return JSON.stringify(localColumns) !== JSON.stringify(reduxColumns);
  }
);
