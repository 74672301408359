import { PrimaryButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../store/hooks";
import { setDidTrySubmitLocation } from "../../../store/locationPage";
import { AppDispatch } from "../../../store/store";
import { useFormikContext } from "formik";
import { CommandBarButton } from "@fluentui/react";

export const SaveButton = () => {
  const { t } = useTranslation();
  const isLocationLoading = useSelector(
    (s) => s.locationPage.isLocationLoading
  );
  const isSaveLoading = useSelector((s) => s.locationPage.isSaveLoading);
  const { setFieldValue, submitForm } = useFormikContext();
  const isDisabled = isLocationLoading || isSaveLoading;
  const didTrySubmit = useSelector((s) => s.locationPage.didTrySubmit);
  const dispatch: AppDispatch = useDispatch();

  return (
    <CommandBarButton
      text={t("greco.save")}
      iconProps={{ iconName: "Save" }}
      split
      onClick={() => {
        setFieldValue("mode", "normal");

        if (!didTrySubmit) {
          dispatch(setDidTrySubmitLocation(true));
        }
        submitForm();
      }}
      disabled={isDisabled}
      menuProps={{
        items: [
          {
            key: "defaultValues",
            text: t("ram.navBar.location.saveUpdateValues"),
            iconProps: { iconName: "CheckList" },
            onClick: () => {
              setFieldValue("mode", "defaultValues");

              if (!didTrySubmit) {
                dispatch(setDidTrySubmitLocation(true));
              }
              submitForm();
            },
          },
        ],
      }}
    />
  );
};
