import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { NoData } from "../../../components/NoData";
import { useSelector } from "../../../store/hooks";
import { Label, Row } from "./RecommendationPanel";

export const SubCategory2Code = () => {
  const { t } = useTranslation();
  const loadRecommendationStatus = useSelector(
    (s) => s.recommendationPanel.loadRecommendationStatus
  );
  const recommendation = useSelector(
    (s) => s.recommendationPanel.recommendation
  );
  const taxonomy = useSelector((s) => s.taxonomy);
  const isLoading = loadRecommendationStatus === "loading";

  let render;
  if (isLoading) render = <Skeleton width={150} />;
  else if (!recommendation || !recommendation.subCategory2Code)
    render = <NoData />;
  else
    render = t(
      taxonomy.RiskAssessmentSubcategory.byId[recommendation.subCategory2Code]
        ?.code
    );

  return (
    <Row>
      <Label>{t("ram.recommendationForm.subCategory2Code.label")}:</Label>
      {render}
    </Row>
  );
};
