import { Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

export const TableSpinner = () => {
  return createPortal(
    <Container>
      <Spinner size={SpinnerSize.large} />
    </Container>,
    document.body
  );
};

const Container = styled.div`
  position: absolute;
  bottom: 5px;
  display: flex;
  justify-content: center;
  width: 100%;
`;
