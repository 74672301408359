import { useFormikContext } from "formik";
import { useEffect } from "react";

function useResultCalculation() {
  const { values, setFieldValue } = useFormikContext<any>();

  const calculateResult = (
    improvementPotentialCode: number | null,
    weighting: number | null
  ) => {
    if (improvementPotentialCode !== null && weighting !== null) {
      return weighting * improvementPotentialCode;
    }
    return 0;
  };

  const handleCalculateResult = () => {
    const result = calculateResult(
      values?.improvementPotentialCode?.value
        ? values?.improvementPotentialCode?.value
        : null,
      values?.weighting
    );

    setFieldValue("result", result);
  };

  useEffect(() => {
    handleCalculateResult();
  }, [values?.improvementPotentialCode, values.weighting]);
}

export default useResultCalculation;
