import React from "react";
import styled, { useTheme } from "styled-components";

function FixedCell({ row, no, children }) {
  const theme = useTheme();

  return (
    <FixedColumnStyled no={no} theme={theme}>
      {children}
    </FixedColumnStyled>
  );
}

export default FixedCell;

const FixedColumnStyled = styled.div<{
  no: number;
  theme;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 !important;
  width: 100%;
  height: 41px;
`;
