import styled from "styled-components";
import { Icon, Label } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Flex } from "../../../../../components/styled";
import { useSelector } from "../../../../../store/hooks";
import { riskImplmentationStatusIcons } from "../../../../../config/icons";
import { riskImplementationStatusColors } from "../../../../../config/colors";

export const StatusCompanyCode = ({
  statusCompany,
}: {
  statusCompany: number;
}) => {
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();
  const statusCompanyCode =
    taxonomy.RiskImplementationStatus.byId[statusCompany]?.code;

  return (
    <div>
      <Label>{t("ram.responsesPage.statusCompany")}</Label>
      <Flex>
        <Icon
          iconName={riskImplmentationStatusIcons[statusCompanyCode]}
          style={{ color: riskImplementationStatusColors[statusCompanyCode] }}
          styles={{
            root: {
              fontSize: "18px",
              marginRight: "3px",
            },
          }}
          data-for="tooltip"
          data-tip={t(statusCompanyCode)}
        />
        <Text>{t(statusCompanyCode)}</Text>
      </Flex>
    </div>
  );
};

const Text = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;
