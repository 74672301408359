import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  Icon,
  SelectionMode,
  mergeStyleSets,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { useSelector } from "../../store/hooks";
import { IStyledTheme } from "../../theme/types";
import { formatDateString } from "../../utils/date";

function ReportHistoryTable() {
  const { t } = useTranslation();

  const reportHistory = useSelector((s) => s.reportPage.reportHistory);

  const theme = useTheme() as IStyledTheme;

  const classes = getClassNames(theme);

  const columns: IColumn[] = [
    {
      key: "surveyDate",
      name: t("ram.reportForm.surveyDate"),
      fieldName: "surveyDate",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isCollapsible: true,

      onRender: (row) => {
        if (!row?.surveyDate) return null;

        return <span>{formatDateString(row?.surveyDate)}</span>;
      },
    },
    {
      key: "reportName",
      name: t("ram.reportForm.reportName"),
      fieldName: "reportName",
      minWidth: 200,
      isResizable: true,
      isCollapsible: true,
      onRender: (row) => {
        if (!row?.reportName) return null;

        return <span>{row.reportName}</span>;
      },
    },
    {
      key: "download-key",
      name: "",
      fieldName: "",
      minWidth: 50,
      maxWidth: 50,
      isResizable: false,
      isCollapsible: false,
      iconName: "Download",

      onRender: (row) => {
        if (!row?.reportDocumentPath) return null;

        return (
          <Icon
            iconName="Download"
            styles={{
              root: {
                cursor: "pointer",
                marginRight: 5,
                fontSize: "16px",
              },
            }}
            onClick={() => window.open(row.reportDocumentPath)}
          />
        );
      },
    },
  ];

  return (
    <div>
      {reportHistory?.length > 0 ? (
        <>
          <div className={classes.label}>
            <h5> {t("ram.reportForm.reportsHistory")}</h5>
          </div>
          <DetailsList
            items={reportHistory || []}
            compact={false}
            columns={columns || []}
            selectionMode={SelectionMode.none}
            setKey="single"
            isSelectedOnFocus
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            selectionPreservedOnEmptyClick={true}
            enterModalSelectionOnTouch={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            className={classes.table}
          />
        </>
      ) : null}
    </div>
  );
}

export default ReportHistoryTable;

const getClassNames = (theme: IStyledTheme) =>
  mergeStyleSets({
    label: {
      paddingBottom: 10,
      color: theme.palette.black,
    },
    table: {
      ".ms-List-cell:nth-child(odd) .ms-DetailsRow": {
        background: theme.isDark
          ? "rgb(2, 7, 10)"
          : theme.palette?.themeLighterAlt,
      },
      ".ms-DetailsRow ": {
        height: "30px !important",
      },

      ".ms-List-cell:hover, .ms-List-cell .is-selected": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
      },
      ".ms-List-cell .ms-DetailsRow:hover": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
      },

      ".ms-DetailsHeader-cellTitle": {
        justifyContent: "flex-start",
      },
      ".ms-DetailsHeader-cell:hover": {
        background: "transparent" /* Override hover background color */,
      },

      "[data-item-key='download-key'] *": {
        display: "flex",
        justifyContent: "center",
      },
      ".ms-DetailsHeader-cellName i": {
        fontSize: 16,
        marginRight: "5px",
        fontWeight: "400",
      },
      ".ms-DetailsHeader": {
        display: "flex",
        justifyContent: "space-between",
        background: theme.palette.neutralLight,
        paddingTop: 0,
      },

      ".ms-DetailsRow-cell": {
        display: "flex",
        justifyContent: "flex-start",
      },
      ".ms-DetailsRow-cell:first-child": {
        display: "flex",
        justifyContent: "flex-start",
      },
      ".ms-DetailsRow-cell:last-child": {
        display: "flex",
        justifyContent: "center",
      },
    },
  });
