import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18next from "i18next";
import * as API from "../../api/api";
import { SetRatingLimitsParams } from "../../api/api";
import { Client } from "../../types/types";
import { selectAllClients } from "../clients/clients";
import { AppThunk, LoadStatus } from "../store";
import { createAsyncRoutine, sort } from "../util";

export type ClientsPageState = {
  shownIds: Client["clientIds"][];
  search: string;
  sort: any;
  setRatingLimitsLoadStatus: LoadStatus;
  setRatingLimitsDialog: {
    clientId: number | null;
    body: any | null;
    isOpen: boolean;
  };
  reportDocumentDialog: {
    isOpen: boolean;
    clientId: number | null;
  };
  reportDocument: any;
  reportDocumentLoadStatus: LoadStatus;
  usersReportDocumentDialog: {
    isOpen: boolean;
    clientId: number | null;
  };
  usersReportDocument: any;
  usersReportDocumentLoadStatus: LoadStatus;
};

export const setRatingLimitsRoutine = createAsyncRoutine<
  SetRatingLimitsParams,
  void,
  void,
  any
>("clients/setRatingLimits");

export const reportDocumentRoutine = createAsyncRoutine<
  API.ClientReportDocumentParams,
  void,
  any,
  any
>("clients/reportDocument");

export const usersReportDocumentRoutine = createAsyncRoutine<
  API.UsersReportDocumentParams,
  void,
  any,
  any
>("clients/usersReportDocument");

export const applyFiltersClients = (): AppThunk => (dispatch, getState) => {
  const sortState = getState().clientsPage.sort;
  const clients = selectAllClients(getState());
  const search = getState().clientsPage.search.trim();
  const taxonomy = getState().taxonomy;

  let searchedClients = clients;

  let sixFiguresFlag: boolean;
  if (search.length === 6) {
    sixFiguresFlag = true;
  } else {
    sixFiguresFlag = false;
  }
  const digitsOnly = (string) =>
    [...string].every((c) => "0123456789".includes(c));

  if (search !== "") {
    if (digitsOnly(search) && sixFiguresFlag) {
      searchedClients = clients.filter((c) => {
        return c.clientId === parseInt(search);
      });
    } else {
      searchedClients = clients.filter((c) => {
        let clientNameFilter =
          c.clientName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        let cc = taxonomy.Country.byId[c.countryCodeID]?.code;
        let ctransl = cc != null ? i18next.t(cc) : "";
        let countryFilter =
          ctransl.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        let ccc = taxonomy.CostCenter.byId[c.costCenterCodeID]?.code;
        let cctransl = ccc != null ? i18next.t(ccc) : "";
        let costCenterFilter =
          cctransl.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        return (
          clientNameFilter === true ||
          countryFilter === true ||
          costCenterFilter === true
        );
      });
    }
  }

  const shownIds = searchedClients
    .slice(0)
    .sort(sort(sortState))
    .map((c) => c.clientId);

  dispatch(setShownIdsClients(shownIds));
};

export const clientsPageSlice = createSlice({
  name: "clientsPage",
  initialState: {
    shownIds: [],
    search: "",
    sort: {
      key: "clientName",
      dir: "asc",
    },
    setRatingLimitsLoadStatus: "none",
    setRatingLimitsDialog: { clientId: null, isOpen: false },
    reportDocumentDialog: { isOpen: false },
    reportDocument: null,
    reportDocumentLoadStatus: "none",
    usersReportDocumentDialog: { isOpen: false },
    usersReportDocument: null,
    usersReportDocumentLoadStatus: "none",
  } as ClientsPageState,
  reducers: {
    setShownIds: (s, a: PayloadAction<ClientsPageState["shownIds"]>) => {
      s.shownIds = a.payload;
    },
    setSearch: (s, a: PayloadAction<ClientsPageState["search"]>) => {
      s.search = a.payload;
    },
    setSort: (s, a: PayloadAction<ClientsPageState["sort"]>) => {
      s.sort = a.payload;
    },
    setSetRatingLimitsDialog: (
      s,
      a: PayloadAction<ClientsPageState["setRatingLimitsDialog"]>
    ) => {
      s.setRatingLimitsDialog = a.payload;
    },
    setReportDocumentDialog: (
      s,
      a: PayloadAction<ClientsPageState["reportDocumentDialog"]>
    ) => {
      s.reportDocumentDialog = a.payload;
      s.reportDocument = null;
    },
    setUsersReportDocumentDialog: (
      s,
      a: PayloadAction<ClientsPageState["usersReportDocumentDialog"]>
    ) => {
      s.usersReportDocumentDialog = a.payload;
      s.usersReportDocument = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setRatingLimitsRoutine.loading, (s, a) => {
      s.setRatingLimitsLoadStatus = "loading";
    });
    builder.addCase(setRatingLimitsRoutine.success, (s, a) => {
      s.setRatingLimitsLoadStatus = "success";
    });
    builder.addCase(setRatingLimitsRoutine.error, (s, a) => {
      s.setRatingLimitsLoadStatus = "error";
    });
    builder.addCase(reportDocumentRoutine.loading, (s, a) => {
      s.reportDocumentLoadStatus = "loading";
    });
    builder.addCase(reportDocumentRoutine.success, (s, a) => {
      s.reportDocumentLoadStatus = "success";
      s.reportDocument = a.payload;
    });
    builder.addCase(reportDocumentRoutine.error, (s, a) => {
      s.reportDocumentLoadStatus = "error";
    });
    builder.addCase(usersReportDocumentRoutine.loading, (s, a) => {
      s.usersReportDocumentLoadStatus = "loading";
    });
    builder.addCase(usersReportDocumentRoutine.success, (s, a) => {
      s.usersReportDocumentLoadStatus = "success";
      s.usersReportDocument = a.payload;
    });
    builder.addCase(usersReportDocumentRoutine.error, (s, a) => {
      s.usersReportDocumentLoadStatus = "error";
    });
  },
});

export const clientsPageReducer = clientsPageSlice.reducer;

export const {
  setShownIds: setShownIdsClients,
  setSearch: setSearchClients,
  setSort: setSortClients,
  setSetRatingLimitsDialog,
  setReportDocumentDialog,
  setUsersReportDocumentDialog,
} = clientsPageSlice.actions;

export const selectShownClients = createSelector(
  (s) => s.clientsPage.shownIds,
  (s) => s.clients.entities,
  (ids: any, byId) => ids.map((id) => byId[id]!)
);
