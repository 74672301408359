import React from "react";

export const ArcSpeedometer = ({ colors }: { colors: string[] }) => {
  return (
    <svg
      width="150"
      height="75"
      viewBox="0 0 150 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 75C0 65.1508 1.93993 55.3982 5.70904 46.2987C9.47814 37.1993 15.0026 28.9314 21.967 21.967L40.7915 40.7915C36.2992 45.2838 32.7357 50.617 30.3044 56.4865C27.8732 62.356 26.6219 68.6469 26.6219 75H0Z"
        fill={colors[0]}
      />
      <path
        d="M21.967 21.967C28.9314 15.0026 37.1993 9.47814 46.2987 5.70903C55.3982 1.93993 65.1509 -4.3052e-07 75 0L75 26.6219C68.6469 26.6219 62.356 27.8732 56.4865 30.3044C50.617 32.7357 45.2838 36.2992 40.7915 40.7915L21.967 21.967Z"
        fill={colors[1]}
      />
      <path
        d="M75 0C94.8912 8.69474e-07 113.968 7.90176 128.033 21.967L109.209 40.7915C100.136 31.7188 87.8307 26.6219 75 26.6219L75 0Z"
        fill={colors[2]}
      />
      <path
        d="M128.033 21.967C134.997 28.9314 140.522 37.1993 144.291 46.2987C148.06 55.3982 150 65.1509 150 75L123.378 75C123.378 68.6469 122.127 62.356 119.696 56.4865C117.264 50.617 113.701 45.2838 109.208 40.7915L128.033 21.967Z"
        fill={colors[3]}
      />
    </svg>
  );
};
