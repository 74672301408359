import React, { useEffect } from "react";
import { useSelector } from "../../../../store/hooks";

import { mergeStyleSets } from "@fluentui/react";
import AttachmentsFormField from "../../components/AttachmentsFormFields/AttachmentsFormField";
import useIsDisabledRecommendationForm from "../../hooks/useIsDisabledRecommendationForm";
import GeneralFormFields from "./GeneralFormFields/GeneralFormFields";
import RiskAssesmentFormFields from "./RiskAssesmentFormFields/RiskAssesmentFormFields";
import { useErrorMessagesHandler } from "../../hooks/useErrorMessagesHandler";

export type RecommendationSaveMode = "normal" | "notify" | "addNew";

export const RecommendationFormContent = () => {
  const sideMenuTab = useSelector((s) => s.recommendationPage.subTab);

  const isDisabled = useIsDisabledRecommendationForm();

  useErrorMessagesHandler({ fieldsConfig });

  const handleItemChangeFromLocation = () => {
    // if (location.search) {
    // const tab = location.search.split("?tab=")[1];
    const tabItem: any = document.querySelector(`#${sideMenuTab}`);

    document.querySelector("#recomendation-fields-wrapper")!.scroll({
      top: tabItem?.offsetTop,
      left: 0,
      behavior: "smooth",
    });

    // }
  };

  useEffect(() => {
    handleItemChangeFromLocation();
  }, [sideMenuTab]);

  return (
    <div className={classes.container}>
      <GeneralFormFields />
      <RiskAssesmentFormFields />
      <AttachmentsFormField isDisabled={isDisabled} />
    </div>
  );
};

const classes = mergeStyleSets({
  container: {},
});

const fieldsConfig = {
  dateOfRecommendation: {
    label: "ram.recommendationForm.dateOfRecommendation.label",
  },
  recommendationNumberInternal: {
    label: "ram.recommendationForm.recommendationNumberInternal.label",
  },
  recommendationNumberExternal: {
    label: "ram.recommendationForm.recommendationNumberExternal.label",
  },
  title: {
    label: "ram.recommendationForm.title.label",
  },
  typeCode: {
    label: "ram.recommendationForm.typeCode.label",
  },
  description: {
    label: "ram.recommendationForm.description.label",
  },
  statusCompanyCode: {
    label: "ram.recommendationForm.statusCompanyCode.label",
  },
  statusGrECoCode: {
    label: "ram.recommendationForm.statusGrECoCode.label",
  },
  priorityCompanyCode: {
    label: "ram.recommendationForm.priorityCompanyCode.label",
  },
  priorityCode: {
    label: "ram.recommendationForm.priorityCode.label",
  },
  subCategory1Code: {
    label: "ram.recommendationForm.subCategory1Code.label",
  },
  noGo: {
    label: "ram.recommendationForm.required.label",
  },
  improvementPotentialCode: {
    label: "ram.recommendationForm.improvementPotentialCode.label",
  },
  improvementPotentialCodeAsIf: {
    label: "ram.recommendationForm.improvementPotentialCodeAsIf.label",
  },
  costEstimateCode: {
    label: "ram.recommendationForm.costEstimateCode.label",
  },
};
