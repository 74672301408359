import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  getUsersList,
  getAllUsersForClient,
  getAllUsersForLocation,
} from "../api/api";
import { RiskUser } from "../types/types";
import { LoadStatus, RootState } from "./store";
import { createAsyncRoutine } from "./util";
import { store } from "../store/store";

export type UsersState = {
  users: EntityState<RiskUser>;
  usersLoadStatus: LoadStatus;
  shownUsersIds: RiskUser["userId"][];
  search: string;
  manageLocationsDialog: {
    user: RiskUser | null;
    isOpen: boolean;
  };
};

export const usersAdapter = createEntityAdapter<RiskUser>({
  selectId: (u) => u.userId,
});

export const loadUsersRoutine = createAsyncRoutine<void, void, RiskUser[], any>(
  "users/load"
);

export const {
  reducer: usersReducer,
  actions: { setShownUsersIds, setSearch, setManageLocationsDialog },
} = createSlice({
  name: "users",
  initialState: {
    users: usersAdapter.getInitialState(),
    shownUsersIds: [],
    usersLoadStatus: "none",
    search: "",
    manageLocationsDialog: {
      user: null,
      isOpen: false,
    },
  } as UsersState,
  reducers: {
    setShownUsersIds: (s, a: PayloadAction<UsersState["shownUsersIds"]>) => {
      s.shownUsersIds = a.payload;
    },
    setSearch: (s, a: PayloadAction<UsersState["search"]>) => {
      s.search = a.payload;
    },
    setManageLocationsDialog: (
      s,
      a: PayloadAction<UsersState["manageLocationsDialog"]>
    ) => {
      s.manageLocationsDialog = a.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUsersRoutine.loading, (s) => {
      s.usersLoadStatus = "loading";
    });
    builder.addCase(loadUsersRoutine.success, (s, a) => {
      s.usersLoadStatus = "success";
      usersAdapter.setAll(s.users, a.payload);
      s.shownUsersIds = a.payload.map((u) => u.userId);
    });
    builder.addCase(loadUsersRoutine.error, (s) => {
      s.usersLoadStatus = "error";
    });
  },
});

export const {
  selectAll: selectAllUsers,
  selectEntities: selectEntitiesUsers,
} = usersAdapter.getSelectors<RootState>((s) => s.users.users);

export const selectShownUsers = createSelector(
  (s) => s.users.shownUsersIds,
  selectEntitiesUsers,
  (ids: any, byId) => ids.map((id) => byId[id])
);

export function* loadUsersSaga() {
  yield takeEvery(loadUsersRoutine.trigger, function* () {
    try {
      yield put(loadUsersRoutine.loading());
      const clientsFilter = store.getState().filters.clientsFilter;
      const locationsFilter = store.getState().filters.locationsFilter;
      let res = null;
      if (locationsFilter.length > 0) {
        let locids = locationsFilter.join(",");
        res = yield call(getAllUsersForLocation, locids);
      } else if (clientsFilter.length > 0) {
        let clids = clientsFilter.join(",");
        res = yield call(getAllUsersForClient, clids);
      } else {
        res = yield call(getUsersList);
      }
      yield put(loadUsersRoutine.success(res.data));
      yield call(applyFilters);
    } catch (err) {
      yield put(loadUsersRoutine.error(err));
    }
  });
}

export function* applyFilters() {
  const search = yield select((s) => s.users.search);
  const users = selectAllUsers(yield select());

  const shownUsersIds = users
    .filter((u) => {
      const isUserName = u.userName
        .toLowerCase()
        .includes(search.toLowerCase());
      const isEmail = u.userEmailAddress
        .toLowerCase()
        .includes(search.toLowerCase());
      return isUserName || isEmail;
    })
    .map((u) => u.userId);

  yield put(setShownUsersIds(shownUsersIds));
}

export function* searchSaga() {
  yield takeEvery(setSearch.type, function* () {
    yield call(applyFilters);
  });
}

export function* usersSaga() {
  yield fork(loadUsersSaga);
  yield fork(searchSaga);
}
