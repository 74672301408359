import { NavigationSearch } from "@greco/components";
import Axios from "axios";
import { Checkbox, Persona, PersonaSize, PrimaryButton } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getReportsByUserId, updateReportsByUserId } from "../../api/api";
import { toast } from "../../components/FluentToast";
import { FormSpinner } from "../../components/FormSpinner";
import { Flex, HSpace, Right, VSpace } from "../../components/styled";
import { selectAllClients } from "../../store/clients/clients";
import { useSelector } from "../../store/hooks";
import { selectAllLocations } from "../../store/locations/locations";
import { selectAllReports } from "../../store/reports/reports";
import { store } from "../../store/store";
import {
  loadUsersInsurerRoutine,
  setManageReportsDialog,
} from "../../store/usersinsurer";
import { handleAxiosError } from "../../store/util";
import { Report } from "../../types/types";
import { UsersGrouped } from "./UsersGrouped";

let cancelSource: any;

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const ManageReportsDialog = () => {
  const dialog = useSelector((s) => s.usersinsurer.manageReportsDialog);
  const { isOpen, user } = dialog;
  const dispatch = useDispatch();
  const [userReports, setUserReports] = useState<Report[]>([]);
  const [userReportsFiltered, setUserReportsFiltered] = useState<Report[]>([]);
  const [showAssignedReports, setShowAssignedReports] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const clients = useSelector(selectAllClients);
  const clientsFilter = useSelector((s) => s.filters.clientsFilter);
  const locationsFilter = useSelector((s) => s.filters.locationsFilter);
  const locations = useSelector(selectAllLocations);
  const reports = useSelector(selectAllReports);
  const [reportsFiltered, setReportsFiltered] = useState(reports);
  const [search, setSearch] = useState("");
  let [reportIdList, setReportIdList] = useState<any>([]);

  const arrayIds = userReports.map((r: Report) => {
    return r.reportId;
  });

  const close = () => {
    dispatch(
      setManageReportsDialog({
        user: null,
        isOpen: false,
      })
    );
  };

  useEffect(() => {
    setReportIdList(arrayIds);
  }, [userReports, isOpen]);

  useEffect(() => {
    (async () => {
      await sleep(300);
      if (cancelSource) {
        cancelSource.cancel();
      }
      cancelSource = Axios.CancelToken.source();
      setLoading(true);
      try {
        const res = await getReportsByUserId(
          user.userId,
          clientsFilter.length > 0 ? clientsFilter.join(",") : "",
          locationsFilter.length > 0 ? locationsFilter.join(",") : "",
          cancelSource.token
        );
        setUserReports(res.data);
        setUserReportsFiltered(res.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    })();
  }, [user?.userId]);

  const updateUserReports = () => {
    (async () => {
      setSubmitting(true);
      try {
        const res = await updateReportsByUserId(
          user.userId,
          clientsFilter.length > 0 ? clientsFilter.join(",") : "",
          locationsFilter.length > 0 ? locationsFilter.join(",") : "",
          {
            riskInsurerUser: user,
            reportIds: reportIdList,
          }
        );
        toast.success(t("ram.insurerUsersPage.reportsUpdated"));
        setUserReports(res.data);
        setUserReportsFiltered(res.data);
        setReportIdList(
          res.data.map((r) => {
            return r.reportId;
          })
        );
        setSubmitting(false);
        dispatch(loadUsersInsurerRoutine.trigger());
      } catch (err) {
        handleAxiosError(err);
        setSubmitting(false);
      }
    })();
  };

  const searchReports = (text: string) => {
    setUserReportsFiltered(
      text
        ? userReports.filter((r: any) => {
            return r.reportName.toLowerCase().indexOf(text.toLowerCase()) > -1;
          })
        : userReports
    );

    setReportsFiltered(
      text
        ? reports.filter((r: any) => {
            return r.reportName.toLowerCase().indexOf(text.toLowerCase()) > -1;
          })
        : reports
    );
  };

  useEffect(() => {
    searchReports(search);
  }, [search]);

  return (
    <div
      style={{
        flex: 1,
        alignSelf: "baseline",
        height: "calc(100vh - 94px)",
        position: "relative",
      }}
    >
      {(loading || submitting) && <FormSpinner />}
      <div style={{ padding: "15px", height: "140px" }}>
        <Flex>
          <NavigationSearch
            placeholder={t("ram.insurerUsersList.searchByReport")}
            value={search}
            onChange={(value) => {
              setSearch(value);
            }}
          />
          <HSpace width={10} />
          <Checkbox
            label={t("ram.usersPage.showAssigned")}
            checked={showAssignedReports}
            onChange={() => setShowAssignedReports(!showAssignedReports)}
          />
          <Right>
            <PrimaryButton
              text={t("ram.usersPage.save")}
              onClick={() => updateUserReports()}
              iconProps={{ iconName: "Save" }}
            />
          </Right>
        </Flex>
        <VSpace />
        <Persona text={user.userName} size={PersonaSize.size32} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "15px 0px 0px 5px",
          }}
        >
          <HSpace width={15} />
          <Checkbox
            label={t("header.Notifications")}
            checked={user.shouldReceiveNotifications}
            onChange={(e, value) => {
              dispatch(
                setManageReportsDialog({
                  ...dialog,
                  user: {
                    ...dialog.user,
                    shouldReceiveNotifications: value,
                  },
                })
              );
            }}
          />
        </div>
      </div>

      {showAssignedReports ? (
        <AssignedReports>
          {userReportsFiltered.map((r: Report) => {
            return locationsFilter.length == 0 ||
              locationsFilter.indexOf(r.locationId) >= 0 ||
              (locationsFilter.length == 0 &&
                (clientsFilter.length == 0 ||
                  clientsFilter.indexOf(r.webBasePartnerNumber) >= 0)) ? (
              <AssignedReportsItem key={r.reportId}>
                <AssignedReportName>
                  {r.reportName +
                    " (" +
                    store.getState().locations.entities[r.locationId]
                      .locationName +
                    ")"}
                </AssignedReportName>
                <div>
                  <Checkbox
                    checked={reportIdList?.includes(r.reportId)}
                    onChange={(e, value) => {
                      if (value === true) {
                        setReportIdList([...reportIdList, r.reportId]);
                      } else if (value === false) {
                        setReportIdList(
                          reportIdList.filter((i) => i !== r.reportId)
                        );
                      }
                    }}
                  />
                </div>
              </AssignedReportsItem>
            ) : (
              ""
            );
          })}
        </AssignedReports>
      ) : (
        <TableContainer>
          <UsersGrouped
            reportsIdList={reportIdList}
            setReportIdList={setReportIdList}
            locations={locations}
            reportsFiltered={reportsFiltered}
          />
        </TableContainer>
      )}
    </div>
  );
};

const AssignedReports = styled.div`
  flex: 1;
  overflow: auto;
  border: 1px solid ${(p) => p.theme.palette.neutralLight};
  height: calc(100vh - 94px - 140px);
`;

const AssignedReportsItem = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
  display: flex;
  height: 42px;
  align-items: center;
  padding: 0 10px;
  background-color: ${(p) => p.theme.palette.white};
  &:hover {
    background: ${(p) => p.theme.palette.neutralLight};
  }
`;

const AssignedReportName = styled.div`
  font-size: 14px;
  flex: 1;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

const TableContainer = styled.div`
  height: calc(100vh - 94px - 140px);
  overflow: auto;
  border: 1px solid ${(p) => p.theme.palette.neutralLight};
`;
