import {
  DirectionalHint,
  Icon,
  Label,
  Link,
  Persona,
  PersonaPresence,
  PersonaSize,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import clsx from "clsx";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { NoData } from "../../../../../components/NoData";
import { Flex, StyledPrimaryButton } from "../../../../../components/styled";
import { useSelector } from "../../../../../store/hooks";
import { selectByIdLocations } from "../../../../../store/locations/locations";
import {
  responseShowHideActions,
  setAddResponseDialog,
  setDeleteResponseDialog,
} from "../../../../../store/recommendationPage";
import { ClientResponseWithAttachments } from "../../../../../types/types";
import {
  formatDateString,
  getFileExtension,
  getFileIcon,
  getIconColor,
} from "../../../../../utils/utils";
import { StatusCompanyCode } from "./StatusCompanyCode";
import { TooltipComponent } from "components/TooltipComponent";

type Props = {
  response: ClientResponseWithAttachments;
};

export const Response = ({ response: r }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const rec = useSelector((s) => s.recommendationPage.rec);
  const isWaitingForClient = rec?.recommendationStatusCode === 2;
  const isWaitingForGREG = rec?.recommendationStatusCode === 1;

  const isShowHideLoading = useSelector(
    (s) => s.recommendationPage.isShowHideLoading
  );

  const { locationId } = useParams() as any;
  const urlSearchParams = new URLSearchParams(location.search);
  const responseId = urlSearchParams.get("responseId");
  const loc = useSelector((s) => selectByIdLocations(s, locationId));

  const isAssessmentPeriod = loc?.assessmentPeriodStartedAt ? true : false;
  const userRole = useSelector((s) => s.userRole.userRole);
  const dispatch = useDispatch();
  const [showinsurerButtons, setShowInsurerButtons] = React.useState(false);

  useEffect(() => {
    if (userRole !== "insurer" && userRole !== "internal_limited") {
      setShowInsurerButtons(true);
    } else {
      setShowInsurerButtons(false);
    }
  }, [userRole]);

  const bubbleStyle = useMemo(() => {
    let retVal: any = {};
    if (r.clientResponse.hideFromInsurer) {
      retVal.border = "1px dashed #faa";
    }
    if (responseId === r.clientResponse.responseId + "") {
      retVal.borderWidth = "3px";
    } else {
      retVal.borderWidth = "1px";
    }
    return retVal;
  }, [r.clientResponse, responseId]);

  useEffect(() => {
    if (
      document.getElementById("response_" + responseId) &&
      document.getElementById("responses-tab-description")
    ) {
      document.getElementById("responses-tab").scrollTop =
        document.getElementById("response_" + responseId).offsetTop +
        document.getElementById("responses-tab-description").offsetHeight +
        50;
    }
  }, [responseId, rec]);

  return (
    <Root
      id={"response_" + r.clientResponse.responseId}
      data-responseid={r.clientResponse.responseId}
      className={clsx({
        draft: !r.clientResponse.isArchived,
        "not-draft": r.clientResponse.isArchived,
      })}
    >
      <Persona
        title={r.clientResponse.responseCreatedByUserName}
        text={r.clientResponse.responseCreatedByUserName}
        presence={PersonaPresence.online}
        styles={{
          root: { width: "40px", alignSelf: "flex-start", marginTop: "10px" },
        }}
        size={PersonaSize.size40}
      />
      <Bubble style={bubbleStyle}>
        <Top>
          <BubbleWrap>
            <div>
              <Label>{t("ram.responsesPage.dateOfResponse")}</Label>
              {r.clientResponse.dateOfResponse &&
                formatDateString(r.clientResponse.dateOfResponse)}
            </div>
            <div>
              <Label>{t("ram.responsesPage.plannedimplementation")}</Label>
              <div>
                {r.clientResponse.plannedImplementationDate ? (
                  formatDateString(r.clientResponse.plannedImplementationDate)
                ) : (
                  <NoData />
                )}
              </div>
            </div>
            <StatusCompanyCode
              statusCompany={r.clientResponse.statusCompanyCode}
            />
          </BubbleWrap>

          <Flex direction="column" gap={5}>
            <Flex
              direction="column"
              style={{
                visibility: !r.clientResponse.isArchived ? "visible" : "hidden",
              }}
            >
              <StyledPrimaryButton
                iconProps={{ iconName: "Edit" }}
                text={t("ram.responsePage.draft")}
                disabled={
                  (!isWaitingForClient &&
                    !isAssessmentPeriod &&
                    !isWaitingForGREG) ||
                  userRole === "internal_limited"
                }
                onClick={() => {
                  dispatch(
                    setAddResponseDialog({
                      isOpen: true,
                      response: r.clientResponse,
                      attachments: r.clientResponseAttachments,
                    })
                  );

                  const element = document.getElementById(
                    "recomendation-form-wrap"
                  );
                  element?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "start",
                  });
                }}
              />
              {/* // </RouterLink> */}
            </Flex>
            <Flex
              direction="column"
              style={{
                visibility:
                  userRole === "admin" ||
                  userRole === "internal" ||
                  userRole === "internal_role" ||
                  userRole === "external_advanced" ||
                  (!r.clientResponse.isArchived &&
                    userRole !== "internal_limited")
                    ? "visible"
                    : "hidden",
              }}
            >
              <PrimaryButton
                text={t("greco.delete")}
                iconProps={{ iconName: "Trash" }}
                onClick={() => {
                  dispatch(
                    setDeleteResponseDialog({
                      isOpen: true,
                      response: r.clientResponse,
                    })
                  );
                }}
              />
            </Flex>
          </Flex>
        </Top>
        <Middle>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Label>{t("ram.responsesPage.implementationCosts")}</Label>
            {r.clientResponse.implementationCostsEur ? (
              r.clientResponse.implementationCostsEur.toLocaleString("de-DE", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }) + " €"
            ) : (
              <NoData />
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Label>{t("ram.responsesPage.costEstimateCode")}</Label>
            {r.clientResponse.costEstimateCode ? (
              <span>
                {t(
                  taxonomy.RiskReductionCost.byId[
                    r.clientResponse.costEstimateCode
                  ]?.code
                )}
              </span>
            ) : (
              <NoData />
            )}
          </div>
        </Middle>
        <div style={{ paddingTop: "10px" }}>
          {r.clientResponse.openPointsAfterResponseCreatedAt ? (
            <div style={{ paddingBottom: "10px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <Label>{t("ram.responsesPage.openPointsAfterResponse")}</Label>
                {r.clientResponse.openPointsAfterResponseCreatedAt && (
                  <OpenPointsSetAt>
                    {formatDateString(
                      r.clientResponse.openPointsAfterResponseCreatedAt
                    )}
                  </OpenPointsSetAt>
                )}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Persona
                  title={
                    r.clientResponse.openPointsAfterResponseCreatedByUserName
                  }
                  imageInitials={
                    r.clientResponse.openPointsAfterResponseCreatedByUserName
                  }
                  size={PersonaSize.size24}
                  styles={{
                    root: {
                      width: "24px",
                      marginRight: "5px",
                      alignSelf: "flex-start",
                    },
                  }}
                />

                <div
                  style={{
                    fontSize: "14px",
                    color: "#323130",
                    width: "100%",
                  }}
                >
                  <OpenPoints
                    dangerouslySetInnerHTML={{
                      __html: r.clientResponse.openPointsAfterResponse,
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div style={{ marginBottom: "10px" }}>
            <Label>{t("ram.responsesPage.comment")}</Label>
            <div
              dangerouslySetInnerHTML={{ __html: r.clientResponse.comment }}
            />
          </div>
          {r.clientResponseAttachments.length !== 0 && (
            <div>
              <Label>{t("ram.responsesPage.attachments")}</Label>
              {r.clientResponseAttachments?.map((a) => {
                return (
                  <TooltipComponent
                    key={a.attachmentId}
                    content={a.attachmentName}
                    directionalHint={DirectionalHint.leftTopEdge}
                  >
                    <div
                      key={a.attachmentId}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Icon
                        iconName={getFileIcon(
                          getFileExtension(a.attachmentName)
                        )}
                        style={{
                          color: getIconColor(
                            getFileExtension(a.attachmentName)
                          ),
                          marginRight: "5px",
                          fontSize: "14px",
                        }}
                      ></Icon>
                      <Link
                        style={{
                          fontSize: "14px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          flex: 1,
                        }}
                        onClick={() => {
                          window.open(a.attachmentPath);
                        }}
                      >
                        {a.attachmentName}
                      </Link>
                    </div>
                  </TooltipComponent>
                );
              })}
            </div>
          )}
        </div>

        {showinsurerButtons ? (
          isShowHideLoading ? (
            <InsurerCheckboxSection>
              <InsurerSpinner>
                <Spinner />
              </InsurerSpinner>
            </InsurerCheckboxSection>
          ) : (
            <InsurerCheckboxSection>
              <InsurerCheckboxField
                onClick={() => {
                  if (isShowHideLoading) return;
                  dispatch(
                    responseShowHideActions.trigger({
                      response: r.clientResponse,
                    })
                  );
                }}
              >
                {r.clientResponse.hideFromInsurer
                  ? t("ram.responsePage.hiddenFromInsurer")
                  : t("ram.responsePage.displayedToInsurer")}
              </InsurerCheckboxField>
            </InsurerCheckboxSection>
          )
        ) : null}
      </Bubble>
    </Root>
  );
};

const InsurerCheckboxSection = styled.div`
  padding: 10px 0;
  border-top: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
`;

const InsurerSpinner = styled.div`
  float: right;
  text-align: right;
  margin-left: auto;
  margin-right: 45px;
  padding: 8px 15px;
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralSecondary};
  &:hover {
    // text-decoration: underline;
    color: ${(p) => p.theme.palette.neutralPrimary};
    cursor: pointer;
  }
`;

const InsurerCheckboxField = styled.div`
  float: right;
  text-align: right;
  margin-left: auto;
  padding: 8px 15px;
  font-size: 14px;
  border: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
  color: ${(p) => p.theme.palette.neutralSecondary};
  &:hover {
    // text-decoration: underline;
    color: ${(p) => p.theme.palette.neutralPrimary};
    cursor: pointer;
  }
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
  padding-bottom: 10px;
  @media (max-width: 700px) {
    padding-bottom: 0;
  }
`;

const BubbleWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex: 1;
  gap: 10px;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    > *:last-child {
      margin-bottom: 10px;
    }
  }
`;

const Middle = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
`;

const OpenPoints = styled.div`
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

const OpenPointsSetAt = styled.div`
  text-align: right;
  margin-left: auto;
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralSecondary};
`;

export const Bubble = styled.div`
  padding: 15px 20px;
  border: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
  max-width: 600px;
  flex: 1;
  background-color: ${(p) => p.theme.palette.white};
`;

export const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
  gap: 10px;
  font-size: 14px;
  background-color: ${(p) => p.theme.palette.white};
  color: ${(p) => p.theme.palette.neutralPrimary};
  @media (max-width: 500px) {
    flex-direction: column;
    &&.draft {
      flex-direction: column;
    }
  }
  &.not-draft {
    justify-content: flex-start;
    ${Bubble} {
      border-radius: 0px 40px 40px 40px;
    }
  }
  &.draft {
    justify-content: flex-start;
    ${Bubble} {
      border-radius: 0px 40px 40px 40px;
    }
  }
`;
