export const history = {
  navigate: null,
  location: null,
  params: null,
};

export function extractPathValues(pathname) {
  const segments = pathname.split("/").filter((segment) => segment !== ""); // Split pathname by '/' and remove empty segments

  const result = {};

  for (let i = 0; i < segments.length; i += 2) {
    const key = segments[i];
    const value = segments[i + 1];

    if (key && value) {
      result[key] = parseInt(value); // Assuming values are integers, you can parse them accordingly
    }
  }

  return result;
}
