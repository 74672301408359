import * as yup from "yup";
import { setYupLocale } from "../../../utils/setYupLocale";
import { useTranslation } from "react-i18next";

function useEvaluationFormValidationSchema() {
  const { t } = useTranslation();
  const validationSchema = (() => {
    setYupLocale(t);
    return yup.object().shape({
      statusGrECoCode: yup.mixed().required(),
      comment: yup.string().required(),
    });
  })();

  return validationSchema;
}

export default useEvaluationFormValidationSchema;
