import React from "react";
import styled, { useTheme } from "styled-components";
import { useSelector } from "../../../store/hooks";

function FixedCell({ row, no, children }) {
  const theme = useTheme();
  const selectedColumn = useSelector((s) => s.locations.selectedColumn);
  const hoveredColumn = useSelector((s) => s.locations.hoveredColumn);

  return (
    <FixedColumnStyled no={no} theme={theme}>
      {children}
    </FixedColumnStyled>
  );
}

export default FixedCell;

const FixedColumnStyled = styled.div<{
  no: number;
  theme;
}>`
  background: ${({ no, theme }) =>
    no % 2 ? theme?.palette?.white : theme.palette?.themeLighterAlt};

  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;

  &:hover {
    background: ${({ theme }) =>
      theme.isDark
        ? "rgb(9, 27, 41) !important"
        : "rgb(208, 231, 248) !important"};
  }
`;
