import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "../../store/hooks";
import { setSortRecommendations } from "../../store/recommendations-page/recommendationsPage";
import {
  loadRecommendationsActions,
  selectAllRecommendations,
  setSelectedColumn,
} from "../../store/recommendations/recommendations";

import {
  ConstrainMode,
  DetailsListLayoutMode,
  DetailsRow,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  IRenderFunction,
  ScrollablePane,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  TooltipHost,
  mergeStyleSets,
} from "@fluentui/react";
import { useConst } from "@fluentui/react-hooks";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { usePrevious } from "@uifabric/react-hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { ExportExcelRecommendations } from "../../components/ExportExcelRecommendations";
import { TableSpinner } from "../../components/TableSpinner";
import { selectColumnsTable } from "../../store/recommendations-page/selectors";
import { AppDispatch } from "../../store/store";
import { RecommendationAzure } from "../../types/types";
import { CopyDialog } from "./CopyDialog";
import { DeleteDialog } from "./DeleteDialog";
import "./Recommendations.scss";
import { Navigation } from "./navigation/Navigation";
import { RecommendationPanel } from "./recommendation-panel/RecommendationPanel";

export type PanelState = {
  isOpen: boolean;
  rec: RecommendationAzure | null;
};

const TOP = 40;

export const Recommendations = () => {
  const { t } = useTranslation();
  const [panel, setPanel] = useState<PanelState>({
    isOpen: false,
    rec: null,
  });

  const recommendations = useSelector(selectAllRecommendations);
  const totalRecommendations = useSelector((s) => s.recommendations.count);
  const [isLoadingNewFiles, setIsLoadingNewFiles] = useState(false);

  const isLoading = useSelector((s) => s.recommendations.isLoading);
  const sort = useSelector((s) => s.recommendationsPage.sort);
  const dispatch: AppDispatch = useDispatch();
  const defaultCols = useSelector(selectColumnsTable)(t);
  const columns = useSelector((s) => s.recommendationsPage.columns);

  const userRole = useSelector((s) => s.userRole.userRole);

  const cols = useMemo(() => {
    if (userRole === "insurer") {
      return defaultCols.filter(
        (col) =>
          col.key !== "dateOfLastResponse" &&
          col.key !== "commentOfLastResponse" &&
          col.key !== "numberOfResponses" &&
          col.key !== "dateOfLastAssessment" &&
          col.key !== "commentOfLastAssessment" &&
          col.key !== "numberOfAssessments" &&
          col.key !== "lastAction"
      );
    } else {
      return defaultCols.filter(
        (col) =>
          col.key !== "dateOfLastResponseInsurer" &&
          col.key !== "commentOfLastResponseInsurer" &&
          col.key !== "numberOfResponsesInsurer" &&
          col.key !== "dateOfLastAssessmentInsurer" &&
          col.key !== "commentOfLastAssessmentInsurer" &&
          col.key !== "numberOfAssessmentsInsurer" &&
          col.key !== "lastActionInsurer"
      );
    }
  }, [userRole, defaultCols]);

  const deleteDialog = useSelector(
    (s) => s.recommendationsPage.deleteRecommendationDialog
  );
  const copyDialog = useSelector(
    (s) => s.recommendationsPage.copyRecommendationDialog
  );
  const refExcelExport = useRef<ExcelExport>();

  const theme = useTheme() as IStyledTheme;
  const classes = getClassNames(theme);

  const prevRecLength = usePrevious(recommendations?.length);
  const endReached = recommendations?.length >= totalRecommendations;

  const fileName = useMemo(() => {
    return (
      "Recommendations" + moment(new Date()).format("_YYYY_MM_DD_HH_mm_ss")
    );
  }, []);

  const detailsListColumns = cols
    .concat([
      {
        key: "filler",
        label: "",
        width: 0,
      },
    ])
    .map((column) => ({
      ...column,
      minWidth: column.isResizable ? 0 : column.minWidth,
      maxWidth: column.isResizable
        ? column.maxWidth < column.minWidth
          ? column.maxWidth
          : column.minWidth
        : column.maxWidth, //getColumnContentWidth(locations, column.fieldName),
    }));

  const columnsToPassToExcel = useMemo(() => {
    return columns.filter((c) => {
      const foundCol = (cols as any[]).find((col) => col.key === c.key);
      if (
        foundCol &&
        foundCol.key !== "options" &&
        foundCol.key !== "statusIndicators"
      )
        return true;
      return false;
    }); //.filter((c) => c.isVisible);
  }, [columns, cols]);

  const selection = useConst(
    () =>
      new Selection({
        onSelectionChanged: async () => {
          const selected = selection.getSelection() as any;
          dispatch(setSelectedColumn(selected[0]));
        },
      })
  );

  const _getKey = (item: any, index?: number): string => {
    return item && item.vehicleId;
  };

  const onRenderRow = (props) => {
    if (props) {
      return <DetailsRow {...props} />;
    }
    return null;
  };

  const onRenderColumnHeaderTooltip: IRenderFunction<
    IDetailsColumnRenderTooltipProps
  > = (tooltipHostProps) => {
    return (
      <TooltipHost
        {...tooltipHostProps}
        content={tooltipHostProps?.column?.name}
      />
    );
  };

  const onRenderDetailsHeader = (
    props: IDetailsHeaderProps | undefined,
    defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
  ) => (
    <Sticky
      stickyPosition={StickyPositionType.Header}
      isScrollSynced
      stickyClassName={classes.table}
    >
      {defaultRender!({
        ...props,
        onRenderColumnHeaderTooltip,
      })}
    </Sticky>
  );

  const fetchMoreData: any = async () => {
    if (isLoading || isLoadingNewFiles) return;
    setIsLoadingNewFiles(true);
    await dispatch(
      loadRecommendationsActions.trigger({
        skip: recommendations.length,
        top: TOP,
        reset: false,
      })
    );
  };

  const fetchNewFiles = (ev) => {
    const element = ev.target;
    if (element && !isLoading) {
      let remaningPixels =
        element.scrollHeight - element.scrollTop - element.clientHeight <= 150;
      if (remaningPixels && !endReached) {
        fetchMoreData();
      }
    }
  };

  useEffect(() => {
    if (prevRecLength && prevRecLength !== recommendations.length) {
      setIsLoadingNewFiles(false);
    }
  }, [recommendations, prevRecLength]);

  useEffect(() => {
    dispatch(
      loadRecommendationsActions.trigger({
        skip: 0,
        top: TOP,
        reset: true,
      })
    );
  }, []);

  return (
    <>
      <Navigation refExcelExport={refExcelExport?.current} />
      <div className={classes.container} id="scrollableDiv">
        <ScrollablePane
          onScroll={(ev) => {
            if (!endReached && !isLoading) {
              fetchNewFiles(ev);
            }
          }}
        >
          <ShimmeredDetailsList
            // loadMoreRows={loadMoreRows}
            // threshold={15}
            compact={false}
            columns={detailsListColumns}
            items={recommendations}
            selectionMode={SelectionMode.single}
            setKey="single"
            isSelectedOnFocus
            selection={selection}
            getKey={_getKey}
            layoutMode={DetailsListLayoutMode.justified}
            enableShimmer={isLoading && !isLoadingNewFiles && !recommendations}
            shimmerLines={20}
            selectionPreservedOnEmptyClick={true}
            onColumnHeaderClick={(ev, column: any) => {
              if (!column.isSortable) return;
              const isSorted = sort.key === column.key;
              if (isSorted) {
                if (sort.dir === "asc") {
                  dispatch(
                    setSortRecommendations({
                      key: column.key,
                      dir: "desc",
                    })
                  );
                }
                //
                else {
                  dispatch(
                    setSortRecommendations({
                      key: "recommendationCreatedAt",
                      dir: "desc",
                    })
                  );
                }
              }
              //
              else {
                dispatch(
                  setSortRecommendations({
                    key: column.key,
                    dir: "asc",
                  })
                );
              }
            }}
            onItemInvoked={(row: any) => {
              setPanel({ isOpen: true, rec: row });
            }}
            enterModalSelectionOnTouch={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            constrainMode={ConstrainMode.unconstrained}
            onRenderRow={onRenderRow}
            className={classes.table}
            onRenderDetailsHeader={(
              props: IDetailsHeaderProps | undefined,
              defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
            ) => onRenderDetailsHeader(props, defaultRender)}
          />
        </ScrollablePane>
      </div>
      {deleteDialog.isOpen && <DeleteDialog />}
      {copyDialog.isOpen && <CopyDialog />}
      {isLoading && <TableSpinner />}{" "}
      {panel.isOpen && (
        <RecommendationPanel panel={panel} setPanel={setPanel} />
      )}
      <ExportExcelRecommendations
        ref={refExcelExport}
        columns={columnsToPassToExcel.filter((col) => col.key !== "filler")}
        fileName={fileName}
      />
    </>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    container: {
      // height: "90vh",
      height: "calc(100vh  - 100px)",

      marginBottom: "20px",
      position: "relative",
      overflow: "auto",
      width: "100%",
    },
    listContainer: {
      display: "flex",
    },

    table: {
      ".ms-List-cell:nth-child(odd) .ms-DetailsRow": {
        background: theme.isDark
          ? "rgb(2, 7, 10)"
          : theme.palette?.themeLighterAlt,
      },
      ".ms-DetailsRow ": {
        height: "30px !important",
      },

      ".ms-DetailsHeader-cellTitle": {
        justifyContent: "flex-start",
      },
      "[data-item-key='fleetName'] span": {
        justifyContent: "flex-start",
      },

      ".ms-DetailsRow-cell": {
        display: "flex",
        justifyContent: "flex-start",
      },
      ".ms-DetailsRow-cell:first-child": {
        display: "flex",
        justifyContent: "flex-start",
      },

      ".ms-DetailsHeader-cellName i": {
        marginRight: "5px",
        fontSize: "smaller",
        fontWeight: "400",
      },

      //******************************************************************************************************* */
      ///header cell

      ".ms-DetailsHeader-cell:nth-child(1)": {
        position: "sticky",
        inset: 0,
        zIndex: 2,
        background: theme.palette.white,
      },

      ".ms-DetailsHeader-cell:nth-child(2)": {
        position: "sticky",
        inset: 48,
        zIndex: 2,
        background: theme.palette.white,
      },
      ".ms-DetailsHeader-cell:nth-child(3)": {
        position: "sticky",
        inset: 108,
        zIndex: 2,
        background: theme.palette.white,
      },
      ".ms-DetailsHeader-cell:nth-child(4)": {
        position: "sticky",
        inset: 370,
        zIndex: 2,
        background: theme.palette.white,
      },

      ".ms-DetailsHeader-cell:nth-child(2):hover, .ms-DetailsHeader-cell:nth-child(3):hover, .ms-DetailsHeader-cell:nth-child(4):hover":
        {
          backgroundColor: theme.palette.neutralLighter,
        },

      /// row cell

      ".ms-DetailsRow-cell:nth-child(1)": {
        position: "sticky",
        left: 48,
        zIndex: 99999,
        alignItems: "center",
      },

      ".ms-DetailsRow-cell:nth-child(2)": {
        position: "sticky",
        left: 108,
        zIndex: 1,
      },

      ".ms-DetailsRow-cell:nth-child(3)": {
        position: "sticky",
        left: 370,
        zIndex: 1,
      },

      // checkbox in row
      ".ms-DetailsRow-cellCheck:nth-child(1)": {
        position: "sticky",
        left: 0,
        zIndex: 1,
      },

      ////

      // ********************************************************* //
      ".ms-List-cell:nth-child(odd) .ms-DetailsRow-cell:nth-child(1)": {
        background: theme.palette?.themeLighterAlt,
      },

      ".ms-List-cell:nth-child(odd) .ms-DetailsRow-cell:nth-child(2)": {
        background: theme.palette?.themeLighterAlt,
      },

      ".ms-List-cell:nth-child(odd) .ms-DetailsRow-cell:nth-child(3)": {
        background: theme.palette?.themeLighterAlt,
      },

      ".ms-List-cell:nth-child(even) .ms-DetailsRow-cell:nth-child(1)": {
        background: theme.palette?.white,
      },

      ".ms-List-cell:nth-child(even) .ms-DetailsRow-cell:nth-child(2)": {
        background: theme.palette?.white,
      },

      ".ms-List-cell:nth-child(even) .ms-DetailsRow-cell:nth-child(3)": {
        background: theme.palette?.white,
      },

      ////

      ".ms-List-cell:hover, .ms-List-cell .is-selected": {
        backgroundColor: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
      },
      ".ms-List-cell .ms-DetailsRow:hover": {
        backgroundColor: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
      },

      ///

      //
      ".ms-List-cell:hover .ms-DetailsRow-cell > * ": {
        height: "41px",
        padding: "0 !important",
        backgroundColor: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
        position: "relative",
      },
      //

      ".ms-List-cell:hover, .ms-List-cell .is-selected .ms-DetailsRow-cell > *":
        {
          height: "41px",
          padding: "0 !important",
          backgroundColor: theme.isDark
            ? "rgb(9, 27, 41) !important"
            : "rgb(208, 231, 248) !important",
          position: "relative",
        },

      //
      ".ms-List-cell:hover .ms-DetailsRow-cell > *  .ms-DetailsRow-cell[class^='cellUnpadded'], , div[class*=' cellUnpadded']":
        {
          paddingRight: "0 !important",
          paddingLeft: "0 !important",
        },

      ".ms-List-cell:hover, .ms-List-cell .is-selected .ms-DetailsRow-cell > *  .ms-DetailsRow-cell[class^='cellUnpadded'], , div[class*=' cellUnpadded']":
        {
          paddingRight: "0 !important",
          paddingLeft: "0 !important",
        },
    },
  });
