import React, { useEffect, useState } from "react";
import { models, Report } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { getPowerBIEmbedMeta } from "../../api/api";
import { PowerBIEmbedMeta } from "../../types/types";
import * as API from "../../api/api";
import styled from "styled-components";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IContextualMenuProps,
  IIconProps,
  PrimaryButton,
} from "@fluentui/react";
import { NavButtons } from "../../components/navigation/NavButtons";
import { NavigationBar, Right } from "../../components/styled";
import { useBoolean } from "@uifabric/react-hooks";
import { toast } from "../../components/FluentToast";
import { useTranslation } from "react-i18next";
import { handleAxiosError } from "../../store/util";
import { useSelector } from "../../store/hooks";

const buttonIcon: IIconProps = { iconName: "CloudImportExport" };

export const Analytics = () => {
  const [
    embedMetadata = { reportId: null, accessToken: null, embedUrl: "" },
    setEmbedMetadata,
  ] = useState<PowerBIEmbedMeta>();
  const [report, setReport] = useState<Report>();
  const [reportType, setReportType] = useState<string>();
  const { t } = useTranslation();

  const clientsFilter = useSelector((s) => s.filters.clientsFilter);

  const dialogContentProps = {
    type: DialogType.normal,
    title: "Export",
    closeButtonAriaLabel: "Close",
    subText: t("ram.analyticsPage.export.confirm.question"),
  };

  const refreshToken = () => {
    (async () => {
      let tmid = clientsFilter.join(",");
      const refreshEmbedObj: any = await getPowerBIEmbedMeta(tmid);
      if (report) {
        report.setAccessToken(refreshEmbedObj.data.accessToken);
      }
      setTimeout(refreshToken, 55 * 60 * 1000); // wait 55 minutes before refreshing token
    })();
  };

  const captureBookmark = async () => {
    if (report) {
      const reportBookmark = await report.bookmarksManager.capture();
      const pages = await report.getPages();
      let data = null;
      pages.forEach((page) => {
        if (page.isActive) {
          data = {
            extension: reportType,
            state: reportBookmark.state,
            page: page.name,
            pageDisplayName: page.displayName,
          };
        }
      });
      try {
        const res = await API.exportPowerBIReport(data);
        toast.success(t("ram.analyticsPage.export.confirm.success"));
        return res.data;
      } catch (err) {
        handleAxiosError(err);
      }
    }
  };

  useEffect(() => {
    (async () => {
      let tmid = clientsFilter.join(",");
      const embedObj: any = await getPowerBIEmbedMeta(tmid);
      setEmbedMetadata(embedObj.data);
    })();
    setTimeout(refreshToken, 55 * 60 * 1000); // wait 55 minutes before refreshing token
  }, []);

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: "exportPdf",
        text: t("ram.analyticsPage.export.button.pdf.label"),
        iconProps: { iconName: "PDF" },
        onClick: async () => exportAnalytics("PDF"),
      },
      {
        key: "exportPptx",
        text: t("ram.analyticsPage.export.button.pptx.label"),
        iconProps: { iconName: "PowerPointDocument" },
        onClick: () => exportAnalytics("PPTX"),
      },
      {
        key: "exportPng",
        text: t("ram.analyticsPage.export.button.png.label"),
        iconProps: { iconName: "PhotoCollection" },
        onClick: () => exportAnalytics("PNG"),
      },
    ],
  };

  const exportAnalytics = (extension: string) => {
    setReportType(extension);
    toggleHideDialog();
  };

  return (
    <>
      <NavigationBar>
        <NavButtons />
        <Right>
          <StyledDefaultButton
            text={t("ram.analyticsPage.export.button.label")}
            iconProps={buttonIcon}
            primary
            split
            menuProps={menuProps}
          />
        </Right>
        <Dialog
          hidden={hideDialog}
          onDismiss={toggleHideDialog}
          dialogContentProps={dialogContentProps}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                captureBookmark();
                toggleHideDialog();
              }}
              text={t("greco.yes")}
            />
            <DefaultButton onClick={toggleHideDialog} text={t("greco.no")} />
          </DialogFooter>
        </Dialog>
      </NavigationBar>
      <PowerBIEmbed
        embedConfig={{
          type: "report",
          id: embedMetadata.reportId,
          embedUrl: embedMetadata.embedUrl,
          accessToken: embedMetadata.accessToken,
          tokenType: models.TokenType.Embed,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: true,
          },
        }}
        cssClassName={"analytics-report-class"}
        getEmbeddedComponent={(embeddedReport) => {
          setReport(embeddedReport as Report);
        }}
      />
    </>
  );
};

const StyledDefaultButton = styled(DefaultButton)``;
