import { useSelector } from "../../../store/hooks";

function useIsDisabledAssesmentForm() {
  const recommendation = useSelector((s) => s.recommendationPage.rec);

  const userRole = useSelector((s) => s.userRole.userRole);
  const isWaitingForGreg = recommendation?.recommendationStatusCode === 1;
  const isWaitingForClient = recommendation?.recommendationStatusCode === 2;
  const isClosed = recommendation?.recommendationStatusCode === 3;
  const isDisabled =
    (isWaitingForGreg &&
      (userRole === "insurer" || userRole === "internal_limited")) ||
    isWaitingForClient ||
    isClosed;

  return isDisabled;
}

export default useIsDisabledAssesmentForm;
