import { Icon } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { StatusCellSpinner } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import { ClientLocation } from "../../types/types";
import { formatDateString } from "../../utils/utils";

type Props = {
  loc: ClientLocation;
};

export const StatusCell = ({ loc }: Props) => {
  const { t } = useTranslation();
  const isAssessmentLoading = useSelector(
    (s) => s.locationsPage.isAssessmentLoading
  );
  const locationIdAssessed = useSelector(
    (s) => s.locationsPage.locationIdAssessed
  );

  if (isAssessmentLoading && locationIdAssessed === loc?.locationId) {
    return <StatusCellSpinner />;
  }

  return (
    <Icon
      iconName="CircleFill"
      style={{
        color: getAssessmentStatusColors(loc?.assessmentPeriodStartedAt),
      }}
      data-for="tooltip"
      data-tip={
        loc?.assessmentPeriodStartedAt
          ? formatDateString(loc?.assessmentPeriodStartedAt!)
          : t("ram.locationList.assessmentNotStarted.tooltip")
      }
      styles={{
        root: {
          fontSize: "8px",
        },
      }}
    />
  );
};

export const getAssessmentStatusColors = (status: string | null) => {
  let color = "#a4262c";
  if (status === null) color = "green";
  return color;
};
