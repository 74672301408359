import React from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

const TableSkeleton = ({
  cols = 3,
  rows = 5,
}: {
  cols?: number;
  rows?: number;
}) => {
  return (
    <>
      {Array(rows)
        .fill(0)
        .map((_, idx) => {
          return (
            <tr key={idx}>
              {Array(cols)
                .fill(0)
                .map((_, idx) => {
                  return (
                    <Cell key={idx}>
                      <Skeleton />
                    </Cell>
                  );
                })}
            </tr>
          );
        })}
    </>
  );
};

export default TableSkeleton;

const Cell = styled.td`
  background-color: ${(p) => p.theme.palette.white};
  padding: 3px 10px;
  border: 1px solid #eee;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;
