import { Checkbox, IconButton } from "@fluentui/react";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { useSelector } from "../../store/hooks";
import { ClientLocation, Report } from "../../types/types";

export const UsersGrouped = ({
  reportsIdList,
  setReportIdList,
  locations,
  reportsFiltered,
}: {
  reportsIdList: [] | any;
  setReportIdList: Function;
  locations: [] | any;
  reportsFiltered: [] | any;
}) => {
  const [shown, setShown] = useState({});
  const clientsFilter = useSelector((s) => s.filters.clientsFilter);
  const locationsFilter = useSelector((s) => s.filters.locationsFilter);

  const toggleGroup = (locationId: number) => {
    setShown({ ...shown, [locationId]: !shown[locationId] });
  };

  const isElementOfArray = (arr: [] | any, id: number | any) => {
    return arr.includes(id);
  };

  const isGroupChecked = (locationId: Number) => {
    let checked = undefined;

    let res = true;
    reportsFiltered.map((r) => {
      if (Number(r.locationId) === Number(locationId)) {
        res = res && isElementOfArray(reportsIdList, r.reportId);
      }
    });
    checked = res;

    return checked;
  };

  const isGroupIndeterminated = (locationId: Number) => {
    let isIndeterminate = false;

    let reportsTotal = 0;
    let reportsChecked = 0;

    reportsFiltered.map((r) => {
      if (Number(r.locationId) === Number(locationId)) {
        reportsTotal = reportsTotal + 1;

        if (isElementOfArray(reportsIdList, r.reportId))
          reportsChecked = reportsChecked + 1;
      }
    });

    if (reportsChecked > 0 && reportsChecked < reportsTotal) {
      isIndeterminate = true;
    }

    return isIndeterminate;
  };

  return (
    <div>
      {locations
        .filter((l) => {
          let ret =
            locationsFilter.length === 0 ||
            locationsFilter.indexOf(l.locationId) >= 0;
          ret =
            ret &&
            (clientsFilter.length == 0 ||
              clientsFilter.indexOf(l.webBasePartnerNumber) >= 0) &&
            reportsFiltered.some(
              (r: Report) => Number(r.locationId) === Number(l.locationId)
            );
          return ret;
        })
        .map((l: ClientLocation) => {
          return (
            <Fragment key={l.locationId}>
              <Row>
                <div onClick={() => toggleGroup(l.locationId)}>
                  <IconButton
                    iconProps={{
                      iconName: shown[l.locationId]
                        ? "ChevronDownMed"
                        : "ChevronRightMed",
                    }}
                    styles={{
                      root: {
                        fontSize: 12,
                        "&:hover": {
                          background: "transparent",
                        },
                      },
                    }}
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    fontSize: 14,
                  }}
                  onClick={() => toggleGroup(l.locationId)}
                >
                  {l.locationName}
                </div>
                <div
                  style={{
                    width: "5%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={isGroupChecked(Number(l.locationId))}
                    disabled
                    indeterminate={isGroupIndeterminated(Number(l.locationId))}
                    onChange={(e, value) => {
                      if (value === true) {
                        reportsFiltered.map((r: Report) => {
                          if (Number(r.locationId) === Number(l.locationId)) {
                            reportsIdList = [...reportsIdList, r.reportId];
                          }
                        });

                        setReportIdList(
                          reportsIdList.filter(
                            (item: any, index: any) =>
                              reportsIdList.indexOf(item) === index
                          )
                        );
                      } else if (value === false) {
                        var remove = [];
                        reportsFiltered.map((r: Report) => {
                          if (Number(r.locationId) === Number(l.locationId)) {
                            remove.push(r.reportId);
                          }
                        });
                        setReportIdList(
                          reportsIdList.filter(function (item) {
                            return remove.indexOf(item) === -1;
                          })
                        );
                      }
                    }}
                  />
                </div>
              </Row>
              <div style={{ display: shown[l.locationId] ? "block" : "none" }}>
                {reportsFiltered.map((r: any) => {
                  if (l.locationId === r.locationId)
                    return (
                      <ReportRow key={r.reportId}>
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          {r.reportName}
                        </div>
                        <div>
                          <Checkbox
                            checked={reportsIdList?.includes(r.reportId)}
                            onChange={(e, value) => {
                              if (value === true) {
                                setReportIdList([...reportsIdList, r.reportId]);
                              } else if (value === false) {
                                setReportIdList(
                                  reportsIdList.filter((i) => i !== r.reportId)
                                );
                              }
                            }}
                          />
                        </div>
                      </ReportRow>
                    );
                })}
              </div>
            </Fragment>
          );
        })}
    </div>
  );
};

const ReportRow = styled.div`
  margin: auto;
  padding-left: 32px;
  padding-right: 20px;
  font-size: 14px !important;
  color: ${(p) => p.theme.palette.neutralPrimary};
  border-bottom: ${(p) => p.theme.palette.neutralLight};
  font-size: 16px;
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  &:hover {
    background: ${(p) => p.theme.palette.neutralLight};
    cursor: pointer;
  }
`;

const Row = styled.div`
  border-bottom: ${(p) => p.theme.palette.neutralLight};
  font-size: 16px;
  font-weight: 500;
  color: ${(p) => p.theme.palette.neutralPrimary};
  height: 42px;
  margin: auto;
  padding: 10px;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(p) => p.theme.palette.white};
  &:hover {
    background: ${(p) => p.theme.palette.neutralLight};
    cursor: pointer;
  }
`;
