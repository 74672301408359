import React from "react";
import { useParams } from "react-router-dom";
import { routes, useCurrentRoute } from "../config/routes";
import { Flex } from "./styled";

export const Breadcrumb = (props: any) => {
  const route = useCurrentRoute();
  const params = useParams();
  if (!routes[route]?.breadcrumb) return null;
  const rowLen = routes[route]?.breadcrumb.length;
  return (
    <Flex>
      {routes[route].breadcrumb.map((Crumb, index) => {
        if (rowLen === index + 1) {
          return <Crumb final={true} key={index} params={params} {...props} />;
        } else {
          return <Crumb key={index} params={params} {...props} />;
        }
      })}
    </Flex>
  );
};
