import React from "react";
import { useTranslation } from "react-i18next";
import { riskImprovementPotentialColors } from "../../../config/colors";
import { useSelector } from "../../../store/hooks";
import { Speedometer } from "../Speedometer";

export const ImprovementPotentialCode = () => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const recommendation = useSelector(
    (s) => s.recommendationPanel.recommendation
  );
  const speeds = [
    {
      key: "RiskImprovementPotential.SIGNIFICANT_IMPROVEMENT_POTENTIAL",
      color:
        riskImprovementPotentialColors[
          "RiskImprovementPotential.SIGNIFICANT_IMPROVEMENT_POTENTIAL"
        ],
      text: t("greco.significant"),
    },
    {
      key: "RiskImprovementPotential.IMPROVEMENT_POTENTIAL",
      color:
        riskImprovementPotentialColors[
          "RiskImprovementPotential.IMPROVEMENT_POTENTIAL"
        ],
      text: t("greco.some"),
    },
    {
      key: "RiskImprovementPotential.NO_IMPROVEMENT_POTENTIAL",
      color:
        riskImprovementPotentialColors[
          "RiskImprovementPotential.NO_IMPROVEMENT_POTENTIAL"
        ],
      text: t("ram.recommendationPanel.noImprovementPotential"),
    },
  ];

  const selectedSpeedIndex = speeds.findIndex(
    (s) =>
      s.key ===
      taxonomy.RiskImprovementPotential.byId[
        recommendation?.improvementPotentialCode
      ]?.code
  );

  const selectedSpeed = Math.max(selectedSpeedIndex, 0);

  return (
    <div>
      <strong style={{ marginBottom: "10px", display: "block" }}>
        {t("ram.recommendationForm.improvementPotentialCode.label")}
      </strong>
      <Speedometer speeds={speeds} selectedSpeed={selectedSpeed} />
    </div>
  );
};
