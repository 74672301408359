import * as yup from "yup";
import { setYupLocale } from "../../../utils/setYupLocale";
import { useTranslation } from "react-i18next";

function useAssesmentFormValidationSchema() {
  const { t } = useTranslation();
  const validationSchema = (() => {
    setYupLocale(t);
    return yup.object().shape({
      dateOfAssessment: yup.date().required().nullable(),
      improvementPotentialCode: yup.mixed().required(),
      statusGrECoCode: yup.mixed().required(),
      statusCompanyCode: yup.mixed().required(),
      noGo: yup.boolean().required(),
      shouldNotifyClient: yup.boolean().required(),
      comment: yup.string().trim().required(),

      // openPointsAfterResponse: yup.string().nullable().trim().max(16000),
    });
  })();

  return validationSchema;
}

export default useAssesmentFormValidationSchema;
