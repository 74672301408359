import { CommandBarButton } from "@fluentui/react";
import React from "react";
import { useDispatch } from "react-redux";
import { accent } from "../../../config/colors";
import { useSelector } from "../../../store/hooks";
import { exportPDFRecommendationsActions } from "../../../store/recommendations-page/recommendationsPage";
import { AppDispatch } from "../../../store/store";
import clsx from "clsx";
import { useWindowSize } from "../../../utils/useWindowSize";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";

type Props = {
  iconBreakpoint?: number;
};

export const ExportPDFButton = ({ iconBreakpoint }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const exportPDFStatus = useSelector(
    (s) => s.recommendationsPage.exportPDFStatus
  );
  const { t } = useTranslation();

  const windowsSize = useWindowSize();
  const windowWidth = windowsSize?.width;

  const isLoading = exportPDFStatus === "loading";

  return (
    <StyledCommandBarButton
      text={
        iconBreakpoint !== undefined
          ? windowWidth < iconBreakpoint
            ? undefined
            : t("greco.exportPDF")
          : t("greco.exportPDF")
      }
      onClick={() => {
        dispatch(exportPDFRecommendationsActions.trigger());
      }}
      disabled={isLoading}
      className={clsx({ isLoading })}
      iconProps={{
        iconName: isLoading ? "ProgressRingDots" : "PDF",
      }}
    />
  );
};

const spin = keyframes`
  from {
    transform: rotateZ(0deg)
  }
  to {
    transform: rotateZ(360deg)
  }
`;

const StyledCommandBarButton = styled(CommandBarButton)`
  height: 32px;
  .ms-Icon {
    color: ${accent.red};
  }
  &:hover {
    .ms-Icon {
      color: ${accent.red};
    }
  }
  &.isLoading {
    i {
      animation: ${spin} 500ms linear infinite;
    }
  }
`;
