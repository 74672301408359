import { Modal } from "@fluentui/react";
import React, { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  isOpen: boolean;
  close: () => void;
  children: ReactNode;
};

export const FiltersModal = ({ isOpen, close, children }: Props) => {
  return (
    <StyledModal isOpen={isOpen} onDismiss={close}>
      <InnerContainer>{children}</InnerContainer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ms-Dialog-main {
    min-width: 200px;
    background-color: ${(p) => p.theme.palette.white};
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
`;
