import { DetailsList } from "@greco/components";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import { TableSpinner } from "../../components/TableSpinner";
import { scrollBarWidth } from "../../config/ui";
import { useSelector } from "../../store/hooks";
import {
  selectShownReports,
  setSortReports,
} from "../../store/reports-page/reportsPage";
import { loadReportsActions } from "../../store/reports/reports";
import { AppDispatch } from "../../store/store";
import { useWindowSize } from "../../utils/useWindowSize";
import { PasteDialog } from "../recommendations/PasteDialog";
import { defaultColumns } from "./columns";
import { Navigation } from "./Navigation";
import { DeleteDialog } from "./DeleteDialog";
import styled from "styled-components";

export const Reports = () => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const reports = useSelector(selectShownReports);
  const { t } = useTranslation();
  const sort = useSelector((s) => s.reportsPage.sort);
  const dispatch: AppDispatch = useDispatch();
  const areReportsLoading = useSelector((s) => s.reports.isLoading);
  const taxonomy = useSelector((s) => s.taxonomy);
  const pasteDialog = useSelector(
    (s) => s.recommendationsPage.pasteRecommendationDialog
  );
  const deleteDialog = useSelector((s) => s.reportsPage.deleteReportDialog);
  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    dispatch(loadReportsActions.trigger());
  }, []);

  const detailsListColumns = useMemo(() => {
    return columns
      .map((c) => c.detailsListColumn({ width: c.width, t, taxonomy }))
      .concat([{ key: "filler", width: 0, label: "" }]);
  }, [columns, t, taxonomy]);

  const columnsWidth = useMemo(() => {
    return (
      detailsListColumns.reduce((sum, c) => sum + c.width, 0) - scrollBarWidth
    );
  }, [detailsListColumns]);

  return (
    <>
      <Navigation />
      <Wrap>
        <DetailsList
          id="reportsList"
          columns={detailsListColumns}
          rows={reports}
          width={windowWidth}
          height={windowHeight - (50 + 44)}
          rowCount={reports.length}
          rowHeight={42}
          cellRenderer={() => null}
          columnCount={detailsListColumns.length}
          columnWidth={({ index }) => {
            if (detailsListColumns[index].key === "filler") {
              const width = windowWidth - columnsWidth;
              return Math.max(width, 0);
            }
            return detailsListColumns[index].width;
          }}
          sort={sort}
          onSectionRendered={() => {
            ReactTooltip.rebuild();
          }}
          onClickHeaderCell={({ column }) => {
            if (!column.isSortable) return;
            const isSorted = sort.key === column.key;
            if (isSorted) {
              if (sort.dir === "asc") {
                dispatch(
                  setSortReports({
                    key: column.key,
                    dir: "desc",
                  })
                );
              }
              //
              else {
                dispatch(
                  setSortReports({
                    key: "reportCreatedAt",
                    dir: "desc",
                  })
                );
              }
            }
            //
            else {
              dispatch(
                setSortReports({
                  key: column.key,
                  dir: "asc",
                })
              );
            }
          }}
          onResizeColumn={({ column, deltaX }) => {
            setColumns(
              columns.map((c) => {
                if (c.key === column.key) {
                  return {
                    ...c,
                    width: column.width + deltaX,
                  };
                }
                return c;
              })
            );
          }}
        />
      </Wrap>
      {deleteDialog.isOpen && <DeleteDialog />}
      {areReportsLoading && <TableSpinner />}
      {pasteDialog.isOpen && <PasteDialog />}
    </>
  );
};

const Wrap = styled.div`
  .header-cell:nth-of-type(1),
  .header-cell:nth-of-type(2),
  .header-cell:nth-of-type(3),
  .header-cell:nth-of-type(4) {
    justify-content: flex-start;
  }

  [data-columnkey="locationName"],
  [data-columnkey="clientName"],
  [data-columnkey="reportName"] {
    .truncate {
      width: 100%;
    }
  }
`;
