import i18next from "i18next";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api/api";
import { toast } from "../../components/FluentToast";
import { loadClients, updateClient } from "../clients/clients";
import { handleAxiosError } from "../util";
import {
  applyFiltersClients,
  reportDocumentRoutine,
  setRatingLimitsRoutine,
  setSearchClients,
  setSetRatingLimitsDialog,
  setSortClients,
  usersReportDocumentRoutine,
} from "./clientsPage";

export function* sortClientsSaga() {
  yield takeEvery(setSortClients.type, function* () {
    yield put(applyFiltersClients() as any);
  });
}

export function* searchClientsSaga() {
  yield takeEvery(setSearchClients.type, function* () {
    yield put(applyFiltersClients() as any);
  });
}

export function* reportDocumentSaga() {
  yield takeEvery(reportDocumentRoutine.trigger, function* (a) {
    const { clientId } = a.payload;
    try {
      yield put(reportDocumentRoutine.loading());
      const res = yield call(API.clientReportDocument, {
        clientId,
      });
      yield put(reportDocumentRoutine.success(res.data));

      toast.success(i18next.t("greco.success"));
      // yield put(
      //   setReportDocumentDialog({
      //     isOpen: false,
      //   })
      // );
    } catch (err: any) {
      yield put(reportDocumentRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* usersReportDocumentSaga() {
  yield takeEvery(usersReportDocumentRoutine.trigger, function* (a) {
    const { clientId } = a.payload;
    try {
      yield put(usersReportDocumentRoutine.loading());
      const res = yield call(API.usersReportDocument, {
        clientId,
      });
      yield put(usersReportDocumentRoutine.success(res.data));

      toast.success(i18next.t("greco.success"));
      // yield put(
      //   setReportDocumentDialog({
      //     isOpen: false,
      //   })
      // );
    } catch (err: any) {
      yield put(usersReportDocumentRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* setRatingLimitsSaga() {
  yield takeEvery(setRatingLimitsRoutine.trigger, function* (a) {
    const { clientId, updateHierarchically, body } = a.payload;
    try {
      yield put(setRatingLimitsRoutine.loading());
      var res = null;
      if (updateHierarchically) {
        res = yield call(API.setRatingLimitsHierarchically, clientId, body);
      } else {
        res = yield call(API.setRatingLimits, clientId, body);
      }
      toast.success(i18next.t("greco.success"));
      yield put(
        setSetRatingLimitsDialog({
          isOpen: false,
          clientId: null,
          body: null,
        })
      );
      yield put(setRatingLimitsRoutine.success());
      if (updateHierarchically) {
        yield put(
          updateClient({
            id: clientId,
            changes: {
              goodRangeMin: res.data[0].goodRangeMin,
              moderateRangeMin: res.data[0].moderateRangeMin,
              poorRangeMin: res.data[0].poorRangeMin,
            },
          })
        );
        yield put(loadClients.trigger());
      } else {
        yield put(
          updateClient({
            id: clientId,
            changes: {
              goodRangeMin: res.data.goodRangeMin,
              moderateRangeMin: res.data.moderateRangeMin,
              poorRangeMin: res.data.poorRangeMin,
            },
          })
        );
      }
    } catch (err) {
      yield put(setRatingLimitsRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* clientsPageSaga() {
  yield fork(sortClientsSaga);
  yield fork(searchClientsSaga);
  yield fork(setRatingLimitsSaga);
  yield fork(reportDocumentSaga);
  yield fork(usersReportDocumentSaga);
}
