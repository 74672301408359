import React, { useEffect } from "react";
import DoubleNumberInputField from "../../../components/controls/DoubleNumberInputField";
import { useFormikContext } from "formik";
import CheckboxField from "../../../components/controls/CheckboxField";
import { transformNumberValue } from "../../../form/util";
import NumberInputField from "../../../components/controls/NumberInputField";

const numberFields = ["goodRangeMin", "moderateRangeMin", "poorRangeMin"];

const maxNumberValue = 100;

function FormContent() {
  const { values, handleChange, errors, setFieldValue } =
    useFormikContext<any>();

  useEffect(() => {
    numberFields.forEach((field) => {
      if (values[field]) {
        if (transformNumberValue(values[field].toString()) > maxNumberValue) {
          setFieldValue(field, maxNumberValue.toString());
        }
      }
    });
  }, [values]);

  return (
    <div>
      <NumberInputField
        required
        setFieldValue={setFieldValue}
        value={values?.goodRangeMin}
        onChange={handleChange}
        isNegativeAllowed={false}
        errors={errors}
        name={"goodRangeMin"}
        disabled={false}
        label={"ram.clientsList.goodRangeMin"}
      />
      <NumberInputField
        required
        setFieldValue={setFieldValue}
        value={values?.moderateRangeMin}
        onChange={handleChange}
        isNegativeAllowed={false}
        errors={errors}
        name={"moderateRangeMin"}
        disabled={false}
        label={"ram.clientsList.moderateRangeMin"}
      />
      <NumberInputField
        required
        setFieldValue={setFieldValue}
        value={values?.poorRangeMin}
        onChange={handleChange}
        isNegativeAllowed={false}
        errors={errors}
        name={"poorRangeMin"}
        disabled={false}
        label={"ram.clientsList.poorRangeMin"}
      />
      <CheckboxField
        checked={values?.updateHierarchically}
        errors={errors}
        setFieldValue={setFieldValue}
        name={"updateHierarchically"}
        disabled={false}
        label={"ram.clientsList.updateHierarchically"}
        required={false}
      />
    </div>
  );
}

export default FormContent;
