import React from "react";
import styled from "styled-components";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { CopyUsersEmailsToClipboard } from "../../../components/filters/CopyUsersEmailsToClipboard";
import { NavButtons } from "../../../components/navigation/NavButtons";
import { HSpace, NavigationBar } from "../../../components/styled";
import { routes } from "../../../config/routes";
import { useSelector } from "../../../store/hooks";
import { store } from "../../../store/store";
import { SaveButton } from "./SaveButton";
import { useLocation } from "react-router";

export const Navigation = () => {
  const riskUsers = useSelector((s) => s.reportPage.riskInsurerUsers);
  const loc = useLocation();
  const isDisabled =
    routes.addReport.path.substring(routes.addReport.path.lastIndexOf("/")) ===
    loc.pathname.substring(loc.pathname.lastIndexOf("/"));

  return (
    <NavigationBar>
      <NavButtons />
      <Breadcrumb />
      <Right>
        {!isDisabled ? (
          <CopyUsersEmailsToClipboard
            isDisabled={isDisabled}
            users={riskUsers}
            singleCopy={true}
          />
        ) : null}
        <HSpace width={10} />
        <SaveButton />
      </Right>
    </NavigationBar>
  );
};

const Right = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
