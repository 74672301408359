import { call, fork, put, takeEvery } from "redux-saga/effects";
import {
  applyFiltersReports,
  changeActiveStatusActions,
  deleteReportRoutine,
  setDeleteDialog,
  setSearchReports,
  setShowActiveReportsOnly,
  setSortReports,
} from "./reportsPage";
import * as API from "../../api/api";
import {
  loadReportsActions,
  removeOne,
  updateReport,
} from "../reports/reports";

import { handleAxiosError } from "../util";
import { loadRecommendationsActions } from "../recommendations/recommendations";

export function* sortReportsSaga() {
  yield takeEvery(setSortReports.type, function* () {
    yield put(applyFiltersReports() as any);
  });
}

export function* setShowActiveReportsOnlySaga() {
  yield takeEvery(setShowActiveReportsOnly.type, function* () {
    yield put(applyFiltersReports() as any);
  });
}

export function* searchReportsSaga() {
  yield takeEvery(setSearchReports.type, function* () {
    yield put(applyFiltersReports() as any);
  });
}

export function* deleteReportSaga() {
  yield takeEvery(deleteReportRoutine.trigger, function* (a) {
    const { locationId, reportId, onSuccess } = a.payload;
    try {
      yield put(deleteReportRoutine.loading());
      const res = yield call(API.deleteReport, {
        locationId,
        reportId,
      });
      yield put(deleteReportRoutine.success(res.data));
      yield put(removeOne(reportId));
      yield put(loadReportsActions.trigger());
      yield put(
        loadRecommendationsActions.trigger({
          skip: 0,
          top: 40,
          reset: true,
        })
      );

      onSuccess && onSuccess();
    } catch (err) {
      yield put(deleteReportRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* changeActiveStatusSaga() {
  yield takeEvery(
    changeActiveStatusActions.trigger,
    function* (a: ReturnType<typeof changeActiveStatusActions.trigger>) {
      try {
        yield put(changeActiveStatusActions.loading(a.payload));
        const res = yield call(API.changeReportActiveStatus, a.payload);
        yield put(changeActiveStatusActions.success(res.data));
        yield put(
          updateReport({
            id: res.data.reportId,
            changes: {
              isReportDeactivated: res.data.isReportDeactivated,
            },
          })
        );
      } catch (err) {
        yield put(changeActiveStatusActions.error(err));
      }
    }
  );
}

export function* reportsPageSaga() {
  yield fork(sortReportsSaga);
  yield fork(searchReportsSaga);
  yield fork(deleteReportSaga);
  yield fork(changeActiveStatusSaga);
  yield fork(setShowActiveReportsOnlySaga);
}
