import { NavigationSearch } from "@greco/components";
import Axios from "axios";
import { Checkbox, Persona, PersonaSize, PrimaryButton } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getLocationsByUserId, updateLocationsByUserId } from "../../api/api";
import { toast } from "../../components/FluentToast";
import { FormSpinner } from "../../components/FormSpinner";
import { Flex, HSpace, Right, VSpace } from "../../components/styled";
import { selectAllClients } from "../../store/clients/clients";
import { useSelector } from "../../store/hooks";
import { selectAllLocations } from "../../store/locations/locations";
import { store } from "../../store/store";
import { loadUsersRoutine, setManageLocationsDialog } from "../../store/users";
import { handleAxiosError } from "../../store/util";
import { ClientLocation } from "../../types/types";
import { UsersGrouped } from "./UsersGrouped";

let cancelSource: any;

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const ManageLocationsDialog = () => {
  const dialog = useSelector((s) => s.users.manageLocationsDialog);
  const { isOpen, user } = dialog;
  const dispatch = useDispatch();
  const [userLocations, setUserLocations] = useState<ClientLocation[]>([]);
  const [userLocationsFiltered, setUserLocationsFiltered] = useState<
    ClientLocation[]
  >([]);
  const [showAssignedLocations, setShowAssignedLocations] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const clients = useSelector(selectAllClients);
  const clientsFilter = useSelector((s) => s.filters.clientsFilter);
  const locationsFilter = useSelector((s) => s.filters.locationsFilter);
  const locations = useSelector(selectAllLocations);
  const [locationsFiltered, setLocationsFiltered] = useState(locations);
  const [search, setSearch] = useState("");
  let [locationsIdList, setLocationIdList] = useState<any>([]);

  const arrayIds = userLocations.map((l: ClientLocation) => {
    return l.locationId;
  });

  const close = () => {
    dispatch(
      setManageLocationsDialog({
        user: null,
        isOpen: false,
      })
    );
  };

  useEffect(() => {
    setLocationIdList(arrayIds);
  }, [userLocations, isOpen]);

  useEffect(() => {
    (async () => {
      await sleep(300);
      if (cancelSource) {
        cancelSource.cancel();
      }
      cancelSource = Axios.CancelToken.source();
      setLoading(true);
      try {
        const res = await getLocationsByUserId(
          user.userId,
          0,
          clientsFilter.length > 0 ? clientsFilter.join(",") : "",
          locationsFilter.length > 0 ? locationsFilter.join(",") : "",
          cancelSource.token
        );
        setUserLocations(res.data);
        setUserLocationsFiltered(res.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    })();
  }, [user?.userId]);

  const updateUserLocations = () => {
    (async () => {
      setSubmitting(true);
      try {
        const res = await updateLocationsByUserId(
          user.userId,
          clientsFilter.length > 0 ? clientsFilter.join(",") : "",
          locationsFilter.length > 0 ? locationsFilter.join(",") : "",
          {
            riskUser: user,
            clientLocationIds: locationsIdList,
          }
        );

        toast.success(t("ram.usersPage.locationsUpdated"));

        setUserLocations(res.data);
        setUserLocationsFiltered(res.data);
        setLocationIdList(
          res.data.map((r) => {
            return r.locationId;
          })
        );
        setSubmitting(false);
        dispatch(loadUsersRoutine.trigger());
      } catch (err) {
        handleAxiosError(err);
        setSubmitting(false);
      }
    })();
  };

  const searchLocations = (text: string) => {
    setUserLocationsFiltered(
      text
        ? userLocations.filter((loc: any) => {
            return (
              loc.locationName.toLowerCase().indexOf(text.toLowerCase()) > -1
            );
          })
        : userLocations
    );

    setLocationsFiltered(
      text
        ? locations.filter((loc: any) => {
            return (
              loc.locationName.toLowerCase().indexOf(text.toLowerCase()) > -1
            );
          })
        : locations
    );
  };

  useEffect(() => {
    searchLocations(search);
  }, [search]);

  return (
    <div
      style={{
        flex: 1,
        alignSelf: "baseline",
        height: "calc(100vh - 94px)",
        position: "relative",
      }}
    >
      {(loading || submitting) && <FormSpinner />}
      <div
        style={{
          padding: "15px",
          height: "140px",
        }}
      >
        <Flex>
          <NavigationSearch
            placeholder={t("ram.usersList.searchByLocation")}
            value={search}
            onChange={(value) => {
              setSearch(value);
            }}
          />
          <HSpace width={10} />
          <Checkbox
            label={t("ram.usersPage.showAssigned")}
            checked={showAssignedLocations}
            onChange={() => setShowAssignedLocations(!showAssignedLocations)}
          />
          <Right>
            <PrimaryButton
              text={t("ram.usersPage.save")}
              onClick={() => updateUserLocations()}
              iconProps={{ iconName: "Save" }}
            />
          </Right>
        </Flex>
        <VSpace />
        <Persona text={user.userName} size={PersonaSize.size32} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "15px 0px 0px 5px",
          }}
        >
          <Checkbox
            label={t("ram.users.internal")}
            disabled={true}
            checked={user.isInternal}
          />
          <Checkbox
            label={t("ram.users.limited")}
            styles={{
              root: { marginLeft: "15px" },
            }}
            disabled={!user.isInternal}
            checked={user.isLimited}
            onChange={(e, value) => {
              dispatch(
                setManageLocationsDialog({
                  ...dialog,
                  user: {
                    ...dialog.user,
                    isLimited: value,
                  },
                })
              );
            }}
          />
          <Checkbox
            label={t("ram.users.advanced")}
            styles={{
              root: { marginLeft: "15px" },
            }}
            disabled={user.isInternal}
            checked={user.isAdvanced}
            onChange={(e, value) => {
              dispatch(
                setManageLocationsDialog({
                  ...dialog,
                  user: {
                    ...dialog.user,
                    isAdvanced: value,
                  },
                })
              );
            }}
          />
          <HSpace width={15} />
          <Checkbox
            label={t("header.Notifications")}
            checked={user.shouldReceiveNotifications}
            onChange={(e, value) => {
              dispatch(
                setManageLocationsDialog({
                  ...dialog,
                  user: {
                    ...dialog.user,
                    shouldReceiveNotifications: value,
                  },
                })
              );
            }}
          />
        </div>
      </div>

      {showAssignedLocations ? (
        <AssignedLocations>
          {userLocationsFiltered.map((l: ClientLocation) => {
            return locationsFilter.length == 0 ||
              locationsFilter.indexOf(l.locationId) >= 0 ||
              (locationsFilter.length == 0 &&
                (clientsFilter.length == 0 ||
                  clientsFilter.indexOf(l.webBasePartnerNumber) >= 0)) ? (
              <AssignedLocationsItem key={l.locationId}>
                <AssignedLocationName>
                  {l.locationName +
                    " (" +
                    store.getState().clients.entities[l.webBasePartnerNumber]
                      .clientName +
                    ")"}
                </AssignedLocationName>
                <div>
                  <Checkbox
                    checked={locationsIdList?.includes(l.locationId)}
                    onChange={(e, value) => {
                      if (value === true) {
                        setLocationIdList([...locationsIdList, l.locationId]);
                      } else if (value === false) {
                        setLocationIdList(
                          locationsIdList.filter((i) => i !== l.locationId)
                        );
                      }
                    }}
                  />
                </div>
              </AssignedLocationsItem>
            ) : (
              ""
            );
          })}
        </AssignedLocations>
      ) : (
        <TableContainer>
          <UsersGrouped
            locationsIdList={locationsIdList}
            setLocationIdList={setLocationIdList}
            clients={clients}
            locationsFiltered={locationsFiltered}
          />
        </TableContainer>
      )}
    </div>
  );
};

const AssignedLocations = styled.div`
  flex: 1;
  overflow: auto;
  border: 1px solid ${(p) => p.theme.palette.neutralLight};
  height: calc(100vh - 94px - 140px);
`;

const AssignedLocationsItem = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
  display: flex;
  height: 42px;
  align-items: center;
  padding: 0 10px;
  background-color: ${(p) => p.theme.palette.white};
  &:hover {
    background: ${(p) => p.theme.palette.neutralLight};
  }
`;

const AssignedLocationName = styled.div`
  font-size: 14px;
  flex: 1;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

const TableContainer = styled.div`
  height: calc(100vh - 94px - 140px);
  overflow: auto;
  border: 1px solid ${(p) => p.theme.palette.neutralLight};
`;
