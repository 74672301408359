import { call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api/api";
import { setHasRights } from "../auth";
import { applyFiltersClients } from "../clients-page/clientsPage";
import { setClientsFilterIds } from "../filters/filters";
import { setIsLoadingUserRole, setUserRole } from "../userRole";
import { loadClients } from "./clients";

export function* loadClientsSaga() {
  yield takeEvery(loadClients.trigger, function* () {
    try {
      yield put(loadClients.loading());
      let res = yield call(API.getClientsList);
      yield put(setUserRole(res.headers.usertype));
      yield put(loadClients.success(res.data));
      yield put(setIsLoadingUserRole(false));
      yield put(applyFiltersClients() as any);
      yield put(setClientsFilterIds() as any);
    } catch (err) {
      yield put(loadClients.error(err));
      if (err.response.status === 403) {
        yield put(setHasRights(false));
      }
    }
  });
}

export function* clientsSaga() {
  yield fork(loadClientsSaga);
}
