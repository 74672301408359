import {
  IShimmerElement,
  Shimmer,
  ShimmerElementType,
} from "@fluentui/react/lib/Shimmer";
import * as React from "react";

const headerSkeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "20%",
    height: 44,
  },
];

const NavigationSkeleton = () => {
  return (
    <div>
      <Shimmer shimmerElements={headerSkeleton} />
    </div>
  );
};

export default NavigationSkeleton;
