import React from "react";
import styled from "styled-components";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { CopyUsersEmailsToClipboard } from "../../../components/filters/CopyUsersEmailsToClipboard";
import { NavButtons } from "../../../components/navigation/NavButtons";
import { NavigationBar, HSpace } from "../../../components/styled";
import { routes } from "../../../config/routes";
import { useSelector } from "../../../store/hooks";

import { SaveButton } from "./SaveButton";
import { CopyReportDataButton } from "../CopyReportDataDialog/CopyReportDataButton";
import { useLocation } from "react-router";

export const Navigation = () => {
  const riskUsers = useSelector((s) => s.locationPage.riskUsers);
  const loc = useLocation();
  const isDisabled =
    routes.addLocation.path.substring(
      routes.addLocation.path.lastIndexOf("/")
    ) === loc.pathname.substring(loc.pathname.lastIndexOf("/"));

  return (
    <NavigationBar>
      <NavButtons />
      <Breadcrumb />
      <Right>
        {!isDisabled ? (
          <>
            <CopyReportDataButton />
            <HSpace width={10} />
          </>
        ) : null}
        {!isDisabled ? (
          <>
            <CopyUsersEmailsToClipboard
              isDisabled={isDisabled}
              users={riskUsers}
              singleCopy={false}
            />
            <HSpace width={10} />
          </>
        ) : null}
        <SaveButton />
      </Right>
    </NavigationBar>
  );
};

const Right = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
