import debounce from "debounce-promise";
import { useFormikContext } from "formik";
import { Icon, Label } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { searchUsers } from "../../../../api/api";
import { PeoplePicker } from "../../../../components/PeoplePicker";
import { FieldContainer } from "../../../../components/controls/FieldContainer";
import { useSelector } from "../../../../store/hooks";
import { IStyledTheme } from "../../../../theme/types";
import { getSelectStyles } from "../../../../utils/utils";
import { Table } from "./Table";

export const RiskUsers = ({ name = "riskUsers" }) => {
  const { t } = useTranslation();
  const { clientId, locationId } = useParams() as any;
  const isEdit = !!locationId;
  const [areRiskUsersLoading, setAreRiskUsersLoading] = useState(false);
  const { values, setFieldValue } = useFormikContext() as any;
  const theme = useTheme() as IStyledTheme;
  const userRole = useSelector((s) => s.userRole.userRole);

  // if the user is creating the location with the context of client
  // take clientId from url params, make request for users of that client and set
  // the users field to the result
  useEffect(() => {
    if (isEdit) return;
    if (!clientId) return;
    (async () => {
      setAreRiskUsersLoading(true);
      //const res = await getAllUsersForClient(clientId);
      setAreRiskUsersLoading(false);
      setFieldValue(name, []);
    })();
  }, [clientId]);

  // if the user is creating the location without the context of client
  // take clientId from the webBasePartnerNumber field, make request for users of that client and set
  // the users field to the result
  // do this everytime webBasePartnerNumber field changes
  useEffect(() => {
    if (isEdit) return;
    if (clientId) return;
    if (!values.webBasePartnerNumber) return;
    (async () => {
      setAreRiskUsersLoading(true);
      // const res = await getAllUsersForClient(values.webBasePartnerNumber.value);
      setAreRiskUsersLoading(false);
      setFieldValue(name, []);
    })();
  }, [values.webBasePartnerNumber, clientId]);

  return (
    <>
      <FieldContainer
        isTooltipHidden={false}
        tooltipText={"ram.locationForm.users.tooltip"}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon
            iconName="AddFriend"
            style={{ marginRight: 6, color: "#0078d4" }}
          />
          <Label>{t("ram.locationForm.users.label")}</Label>
        </div>
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <PeoplePicker
            value={null}
            onChange={(o: any) => {
              const riskUserList = values?.riskUsers;

              if (riskUserList) {
                const isAdded = riskUserList?.find(
                  (u) => u.userId === o.user.userId
                );
                if (isAdded) return;
                setFieldValue(name, [...riskUserList, o.user]);
              } else {
                setFieldValue(name, [o.user]);
              }
            }}
            isMulti={false}
            isDisabled={userRole !== "admin"}
            loadOptions={debounce(async (input: string) => {
              const trimmedInput = input.trim();
              if (trimmedInput.length === 0) return [];
              const users = await searchUsers(trimmedInput);

              return users
                .filter((u) => {
                  const riskUserList = values?.riskUsers;
                  const isAdded = !!riskUserList?.find(
                    (user) => user.userId === u.userId
                  );

                  return !isAdded;
                })
                .map((u) => {
                  return {
                    user: u,
                    label: u.userName,
                    value: u.userId,
                    userName: u.userName,
                    userEmailAddress: u.userEmailAddress,
                  };
                });
            }, 300)}
            styles={getSelectStyles(false, theme)}
          />
        </div>
        <Table
          areRiskUsersLoading={areRiskUsersLoading}
          value={values[name]}
          onChange={(value) => {
            setFieldValue(name, value);
          }}
          isDisabled={userRole !== "admin"}
        />
      </FieldContainer>
    </>
  );
};
