import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ListContextMenuIconButton } from "../../components/styled";
import { routes } from "../../config/routes";
import { useSelector } from "../../store/hooks";
import {
  notifyRecommendation,
  setCopyDialog,
  setDeleteDialog,
} from "../../store/recommendations-page/recommendationsPage";
import { selectCanNotify } from "../../store/recommendations-page/selectors";
import { AppDispatch } from "../../store/store";
import { RecommendationAzure } from "../../types/types";

type Props = {
  rec: RecommendationAzure;
};

export const ContextButton = ({ rec }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const canNotify = useSelector(selectCanNotify)(rec);
  const dispatch: AppDispatch = useDispatch();
  const isNotifyLoading = useSelector(
    (s) => s.recommendationsPage.isNotifyLoading
  );
  const userRole = useSelector((s) => s.userRole.userRole);

  const onClickEditRecommendation = () => {
    navigate(
      routes.editRecommendation.getPath(
        rec.webBasePartnerNumber,
        rec.locationId,
        rec.reportId,
        rec.recommendationId
      ) + `?tab=recommendation`
    );
  };

  const onClickShowResponses = () => {
    navigate(
      routes.editRecommendation.getPath(
        rec.webBasePartnerNumber,
        rec.locationId,
        rec.reportId,
        rec.recommendationId
      ) + `?tab=responses`
    );
  };

  const onClickNotify = () => {
    dispatch(notifyRecommendation.trigger(rec));
  };

  const onClickDelete = () => {
    dispatch(
      setDeleteDialog({
        recommendation: rec,
        isOpen: true,
      })
    );
  };

  const onClickCopy = () => {
    dispatch(
      setCopyDialog({
        recommendation: rec,
        isOpen: true,
      })
    );
  };

  const isNotifyButtonLoading = isNotifyLoading || canNotify.isLoading;
  const isNotifyButtonDisabled = !canNotify.result || isNotifyButtonLoading;

  const items = [
    {
      key: "responses",
      text: t("ram.recommendationList.addResponse"),
      iconProps: { iconName: "Comment" },
      onClick: onClickShowResponses,
    },
  ];

  const editRecommendationObj = {
    key: "editRecommendation",
    text: t("ram.recommendationList.editRecommendation"),
    iconProps: { iconName: "Edit" },
    onClick: onClickEditRecommendation,
    disabled: false, //!isWaitingForGREG && !isClosed,
  };

  const notifyObj = {
    key: "notify",
    text: t("greco.notify"),
    iconProps: {
      iconName: isNotifyButtonLoading ? "ProgressRingDots" : "Ringer",
    },
    onClick: onClickNotify,
    disabled: isNotifyButtonDisabled,
  };

  const deleteOption = {
    key: "delete",
    text: t("greco.delete"),
    iconProps: {
      iconName: "Trash",
    },
    onClick: onClickDelete,
  };

  if (
    userRole === "admin" ||
    userRole === "internal" ||
    userRole === "internal_role" ||
    userRole === "external_advanced"
  ) {
    items.push(editRecommendationObj, notifyObj);
  }

  if (userRole === "admin") {
    items.push(deleteOption);
  }

  const copyOption = {
    key: "copy",
    text: t("ram.recommendationList.copyRecommendation"),
    iconProps: {
      iconName: "Copy",
    },
    onClick: onClickCopy,
  };

  if (
    userRole === "admin" ||
    userRole === "internal" ||
    userRole === "internal_role" ||
    userRole === "external_advanced"
  ) {
    items.push(copyOption);
  }

  return (
    <ListContextMenuIconButton
      iconProps={{ iconName: "MoreVertical" }}
      menuIconProps={{ style: { display: "none" } }}
      menuProps={{
        items: items,
      }}
    />
  );
};
