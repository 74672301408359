import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import i18next from "i18next";
import * as API from "../api/api";
import { toast } from "../components/FluentToast";
import { routes } from "../config/routes";
import { LocationSaveMode } from "../pages/location/Location";
import { ClientLocation, ClientLocationWithUsers } from "../types/types";
import { loadClients } from "./clients/clients";
import { RootState } from "./store";
import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import { addLocation, loadLocationsActions } from "./locations/locations";
import { handleAxiosError } from "./util";
import { history } from "utils/_helpers";
import { push, replace } from "redux-first-history";

export type LocationPageState = {
  location: ClientLocationWithUsers["clientLocation"] | null;
  riskUsers: ClientLocationWithUsers["riskUsers"];
  isLocationLoading: boolean;
  isSaveLoading: boolean;
  didTrySubmit: boolean;
  copyReportDataDialog: {
    reportData: any[];
    isOpen: boolean;
  };
  defaultWeightings: string[];
};

export const loadLocationWithUsers = createAsyncThunk<
  ClientLocationWithUsers,
  void,
  { state: RootState }
>("locationPage/loadLocationWithUsers", async (_, thunkAPI) => {
  const loc = thunkAPI.getState().router.location;
  const locationMatch = routes.editLocation.matchPath(loc);

  const { locationId } = locationMatch?.params;

  let res;
  try {
    res = await API.getLocation(locationId);
  } catch (err) {
    if (err.response.status === 403 || err.response.status === 404) {
      thunkAPI.dispatch(push("/clients"));
    }
  }

  return res.data;
});

export const createLocationWithUsers = createAsyncThunk<
  ClientLocationWithUsers,
  { locationWithUsers: ClientLocationWithUsers; mode: LocationSaveMode },
  { state: RootState }
>(
  "locationPage/createLocationWithUsers",
  async ({ locationWithUsers, mode }, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.createLocationWithUsers(locationWithUsers);
      const newLocation = res.data.clientLocation;
      toast.success(
        i18next.t("greco.notification.create.success", {
          object: `${i18next.t("ram.location").toLowerCase()} '${
            newLocation.locationName
          }'`,
        })
      );
      dispatch(loadClients.trigger());
      if (mode === "defaultValues") {
        dispatch(
          push(
            routes.locationDefaultValues.getPath(
              res.data.clientLocation.webBasePartnerNumber,
              res.data.clientLocation.locationId
            )
          )
        );
      } else {
        dispatch(push(routes.locations.getPath()));
      }
      return res.data;
    } catch (err) {
      handleAxiosError(err);
      return rejectWithValue(err);
    }
  }
);

export const createLocationWithoutUsers = createAsyncThunk<
  ClientLocation,
  { location: ClientLocation; mode: LocationSaveMode },
  { state: RootState }
>(
  "locationPage/createLocationWithoutUsers",
  async ({ location, mode }, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.createLocationWithoutUsers(location);
      const newLocation = res.data;
      toast.success(
        i18next.t("greco.notification.create.success", {
          object: `${i18next.t("ram.location").toLowerCase()} '${
            newLocation.locationName
          }'`,
        })
      );
      dispatch(loadClients.trigger());
      if (mode === "defaultValues") {
        dispatch(
          push(
            routes.locationDefaultValues.getPath(
              res.data.webBasePartnerNumber,
              res.data.locationId
            )
          )
        );
      } else {
        dispatch(push(routes.locations.getPath()));
      }
      return res.data;
    } catch (err) {
      handleAxiosError(err);
      return rejectWithValue(err);
    }
  }
);

export const updateLocationWithUsers = createAsyncThunk<
  ClientLocationWithUsers,
  { locationWithUsers: ClientLocationWithUsers; mode: LocationSaveMode },
  { state: RootState }
>(
  "locationPage/updateLocationWithUsers",
  async (
    { locationWithUsers, mode },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const routerLocation = getState().router.location;
      const editLocationMatch = routes.editLocation.matchPath(routerLocation);

      const { locationId } = editLocationMatch?.params;
      const res = await API.updateLocationWithUsers(
        locationId,
        locationWithUsers
      );
      const updatedLocation = res.data.clientLocation;
      dispatch(loadLocationsActions.trigger());
      toast.success(
        i18next.t("greco.notification.update.success", {
          object: `${i18next.t("ram.location").toLowerCase()} '${
            updatedLocation.locationName
          }'`,
        })
      );
      if (mode === "defaultValues") {
        dispatch(
          push(
            routes.locationDefaultValues.getPath(
              res.data.clientLocation.webBasePartnerNumber,
              res.data.clientLocation.locationId
            )
          )
        );
      }
      return res.data;
    } catch (err) {
      handleAxiosError(err);

      return rejectWithValue(err);
    }
  }
);

export const updateLocationWithoutUsers = createAsyncThunk<
  ClientLocation,
  { location: ClientLocation; mode: LocationSaveMode },
  { state: RootState }
>(
  "locationPage/updateLocationWithoutUsers",
  async ({ location, mode }, { rejectWithValue, dispatch, getState }) => {
    try {
      const routerLocation = getState().router.location;
      const editLocationMatch = routes.editLocation.matchPath(routerLocation);

      const { locationId } = editLocationMatch?.params;
      const res = await API.updateLocationWithoutUsers(locationId, location);
      const updatedLocation = res.data;
      dispatch(loadLocationsActions.trigger());
      toast.success(
        i18next.t("greco.notification.update.success", {
          object: `${i18next.t("ram.location").toLowerCase()} '${
            updatedLocation.locationName
          }'`,
        })
      );
      if (mode === "defaultValues") {
        dispatch(
          push(
            routes.locationDefaultValues.getPath(
              res.data.clientLocation.webBasePartnerNumber,
              res.data.clientLocation.locationId
            )
          )
        );
      }
      return res.data;
    } catch (err) {
      handleAxiosError(err);

      return rejectWithValue(err);
    }
  }
);

export const locationPageSlice = createSlice({
  name: "locationPage",
  initialState: {
    location: null,
    riskUsers: [],
    isLocationLoading: false,
    isSaveLoading: false,
    didTrySubmit: false,
    copyReportDataDialog: {
      reportData: [],
      isOpen: false,
    },
    defaultWeightings: [],
  } as LocationPageState,
  reducers: {
    setLocation: (s, a: PayloadAction<LocationPageState["location"]>) => {
      s.location = a.payload;
    },
    setRiskUsers: (s, a: PayloadAction<LocationPageState["riskUsers"]>) => {
      s.riskUsers = a.payload;
    },
    setDefaultWeightings: (
      s,
      a: PayloadAction<LocationPageState["defaultWeightings"]>
    ) => {
      s.defaultWeightings = a.payload;
    },
    setDidTrySubmit: (
      s,
      a: PayloadAction<LocationPageState["didTrySubmit"]>
    ) => {
      s.didTrySubmit = a.payload;
    },
    setCopyReportDataDialog: (
      s,
      a: PayloadAction<LocationPageState["copyReportDataDialog"]>
    ) => {
      s.copyReportDataDialog = a.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadLocationWithUsers.pending, (s, a) => {
      s.isLocationLoading = true;
    });
    builder.addCase(loadLocationWithUsers.fulfilled, (s, a) => {
      s.isLocationLoading = false;
      s.location =
        a.payload.clientLocation.isDeleted === false
          ? a.payload.clientLocation
          : null;
      s.riskUsers =
        a.payload.clientLocation.isDeleted === false
          ? a.payload.riskUsers
          : null;
    });
    builder.addCase(loadLocationWithUsers.rejected, (s, a) => {
      s.isLocationLoading = false;
    });
    builder.addCase(createLocationWithUsers.pending, (s, a) => {
      s.isSaveLoading = true;
    });
    builder.addCase(createLocationWithUsers.fulfilled, (s, a) => {
      s.isSaveLoading = false;
      s.location = null; //a.payload.clientLocation;
      s.riskUsers = []; // a.payload.riskUsers;
    });
    builder.addCase(createLocationWithUsers.rejected, (s, a) => {
      s.isSaveLoading = false;
    });
    builder.addCase(updateLocationWithUsers.pending, (s, a) => {
      s.isSaveLoading = true;
    });
    builder.addCase(updateLocationWithUsers.fulfilled, (s, a) => {
      s.isSaveLoading = false;
      s.location = a.payload.clientLocation;
      s.riskUsers = a.payload.riskUsers;
    });
    builder.addCase(updateLocationWithUsers.rejected, (s, a) => {
      s.isSaveLoading = false;
    });

    builder.addCase(createLocationWithoutUsers.pending, (s, a) => {
      s.isSaveLoading = true;
    });
    builder.addCase(createLocationWithoutUsers.fulfilled, (s, a) => {
      s.isSaveLoading = false;
      s.location = null; //a.payload.clientLocation;
    });
    builder.addCase(createLocationWithoutUsers.rejected, (s, a) => {
      s.isSaveLoading = false;
    });
    builder.addCase(updateLocationWithoutUsers.pending, (s, a) => {
      s.isSaveLoading = true;
    });
    builder.addCase(updateLocationWithoutUsers.fulfilled, (s, a) => {
      s.isSaveLoading = false;
      s.location = a.payload;
    });
    builder.addCase(updateLocationWithoutUsers.rejected, (s, a) => {
      s.isSaveLoading = false;
    });
  },
});

export const locationPageReducer = locationPageSlice.reducer;

export const {
  setLocation,
  setRiskUsers,
  setDidTrySubmit: setDidTrySubmitLocation,
  setCopyReportDataDialog,
  setDefaultWeightings,
} = locationPageSlice.actions;

export function* createLocationSuccessSaga() {
  yield takeEvery(createLocationWithUsers.fulfilled, function* (action) {
    const locWithUsers = action.payload as ClientLocationWithUsers;
    yield put(addLocation(locWithUsers.clientLocation));
    yield put(loadLocationsActions.trigger() as any);
  });
}

export function* locationPageSaga() {
  yield fork(createLocationSuccessSaga);
}
