import { IconButton } from "office-ui-fabric-react/";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { businessObjectIcons } from "../../config/icons";
import { useSelector } from "../../store/hooks";
import { useCurrentRoute } from "config/routes";

export const NavButtons = () => {
  const { t } = useTranslation();

  const userRole = useSelector((s) => s.userRole.userRole);

  const route = useCurrentRoute();

  return (
    <Container>
      <ButtonNavLink
        to="/clients"
        className={route === "clients" ? "isActive" : ""}
      >
        <IconButton
          iconProps={{ iconName: businessObjectIcons["client"] }}
          title={t("ram.clientsList.clients.label")}
        />
      </ButtonNavLink>

      <ButtonNavLink
        to="/locations"
        className={route === "locations" ? "isActive" : ""}
      >
        <IconButton
          iconProps={{ iconName: businessObjectIcons["location"] }}
          title={t("ram.selectMenu.locations")}
        />
      </ButtonNavLink>

      <ButtonNavLink
        to="/reports"
        className={route === "reports" ? "isActive" : ""}
      >
        <IconButton
          iconProps={{ iconName: businessObjectIcons["report"] }}
          title={t("ram.selectMenu.reports")}
        />
      </ButtonNavLink>
      <ButtonNavLink
        to="/recommendations"
        className={route === "recommendations" ? "isActive" : ""}
      >
        <IconButton
          iconProps={{ iconName: businessObjectIcons["recommendation"] }}
          title={t("ram.selectMenu.recommendations")}
        />
      </ButtonNavLink>
      {userRole === "admin" && (
        <ButtonNavLink
          to="/users"
          className={route === "users" ? "isActive" : ""}
        >
          <IconButton
            iconProps={{ iconName: "AddFriend" }}
            title={t("ram.navBar.userManagement")}
          />
        </ButtonNavLink>
      )}
      {userRole === "admin" && (
        <ButtonNavLink
          to="/insurerusers"
          className={route === "insurerusers" ? "isActive" : ""}
        >
          <IconButton
            iconProps={{ iconName: "AccountManagement" }}
            title={t("ram.navBar.insurerUserManagement")}
          />
        </ButtonNavLink>
      )}
      {userRole === "admin" && (
        <ButtonNavLink
          to="/defaultweightings"
          className={route === "defaultweightings" ? "isActive" : ""}
        >
          <IconButton
            iconProps={{ iconName: "Weights" }}
            title={t("ram.navBar.defaultWeightings")}
          />
        </ButtonNavLink>
      )}
      {userRole !== "insurer" && (
        <ButtonNavLink
          to="/analytics"
          className={route === "analytics" ? "isActive" : ""}
        >
          <IconButton
            iconProps={{ iconName: "Diagnostic" }}
            title={t("ram.selectMenu.analytics")}
          />
        </ButtonNavLink>
      )}
    </Container>
  );
};

const ButtonNavLink = styled(NavLink)`
  width: 32px;
  height: 32px;
  display: inline-block;
  &.isActive .ms-Button {
    background: ${(p) => p.theme.palette.themePrimary};
    color: ${(p) => (p.theme.isDark ? "#101010" : "white")};
    .ms-Icon {
      color: ${(p) => (p.theme.isDark ? "#101010" : "white")};
    }
  }
`;

const Container = styled.div`
  display: flex;
  gap: 3px;
  &:last-child {
    margin-right: 5px;
  }
`;
