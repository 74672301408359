import { useTranslation } from "react-i18next";
import { adjustTaxonomyValue } from "../../../form/util";
import { useSelector } from "../../../store/hooks";

function useEvaluationFormInitialValues() {
  const recommendation = useSelector((s) => s.recommendationPage.rec);

  const taxonomy = useSelector((s) => s.taxonomy);

  const evaluation = useSelector(
    (s) => s.recommendationPage.addEvaluationDialog.evaluation
  );

  const { t } = useTranslation();

  const initialValues = (() => {
    // if (recommendation && route === "addEvaluation") {
    if (recommendation) {
      return {
        // dateOfEvaluation: new Date(),
        implementationDateInsurer: recommendation?.implementationDateInsurer
          ? new Date(recommendation?.implementationDateInsurer)
          : null,

        statusGrECoCode: adjustTaxonomyValue(
          recommendation.statusGrECoCode,
          taxonomy,
          "RiskImplementationStatus",
          t
        ),

        priorityCode: adjustTaxonomyValue(
          recommendation.priorityCode,
          taxonomy,
          "RiskPriority",
          t
        ),
        attachments: [],
      };
    }

    if (evaluation) {
      return {
        implementationDateInsurer: new Date(
          evaluation?.implementationDateInsurer
        ),

        statusGrECoCode: adjustTaxonomyValue(
          evaluation.statusGrECoCode,
          taxonomy,
          "RiskImplementationStatus",
          t
        ),

        priorityCode: adjustTaxonomyValue(
          evaluation.priorityCode,
          taxonomy,
          "RiskPriority",
          t
        ),
        attachments: [],
      };
    }

    return {
      attachments: [],
    };
  })();

  return initialValues;
}

export default useEvaluationFormInitialValues;
