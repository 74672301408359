import { Column, ColumnOptionsUtils } from "@greco/components";
import axios, { AxiosError, CancelToken } from "axios";
import { formatISO } from "date-fns";
import { ExpandedColumn } from "../types/columns";
import {
  Client,
  ClientLocation,
  ClientLocationWithUsers,
  ClientResponseWithAttachments,
  LocationDefaultValue,
  PowerBIEmbedMeta,
  RamUser,
  Recommendation,
  RecommendationAzureResponse,
  RecommendationWithAttachments,
  Report,
  ReportWithInsurerUsers,
  RiskUser,
  RiskInsurerUser,
  Setting,
  SettingsResponse,
  TaxonomyResponse,
  TaxonomyUADResponse,
  ClientResponse,
  InsurerEvaluationWithAttachments,
  AssessmentWithAttachments,
  Assessment,
} from "../types/types";

const url = process.env.REACT_APP_BASE_URL;
const uadUrl = process.env.REACT_APP_BFF_HOST_UAD;

export const getClientsList = async () => {
  const res = await axios.get<Client[]>(`${url}/clients`);
  return res;
};

export const setRatingLimits = async (
  clientId: number | undefined,
  body: any
) => {
  const res = await axios.patch<Client>(`${url}/clients/${clientId}`, body);
  return res;
};

export const setRatingLimitsHierarchically = async (
  clientId: number | undefined,
  body: any
) => {
  const res = await axios.patch<Client>(
    `${url}/clients/hierarchically/${clientId}`,
    body
  );
  return res;
};

export const searchClients = async (firstLetters: string) => {
  const res = await axios.get<any[]>(`${url}/clientssearch/${firstLetters}`);
  return res;
};

export const getTaxonomyData = async () => {
  const res = await axios.get<TaxonomyResponse>(`${url}/taxonomy`);
  return res;
};

export const getLocations = async () => {
  const res = await axios.get<ClientLocation[]>(`${url}/clientlocations`);
  return res;
};

export const createLocationWithUsers = async (data: any) => {
  const res = await axios.post<ClientLocationWithUsers>(
    `${url}/clientlocationswithusers`,
    data
  );
  return res;
};

export const createLocationWithoutUsers = async (data: any) => {
  const res = await axios.post<ClientLocation>(`${url}/clientlocations`, data);
  return res;
};

export const exportToZip = async (locationId: number) => {
  const res = await axios.post<ClientLocationWithUsers>(
    `${url}/clientlocations/exportattachments/${locationId}`,
    locationId
  );
  return res;
};

export const exportRecommendationToZip = async (
  locationId: number,
  recommendationId: number
) => {
  const res = await axios.post<any>(
    `${url}/recommendations/exportattachments/${locationId}/${recommendationId}`
  );
  return res;
};

export const generateLocationReportDocument = async (
  clientId: number,
  locationId: number,
  body: any
) => {
  const res = await axios.post(
    `${url}/clientlocations/reportdocument/${clientId}/${locationId}`,
    body
  );
  return res;
};

export const exportPowerBIReport = async (data: any) => {
  const res = await axios.post<ClientLocationWithUsers>(
    `${url}/powerbi/export`,
    data
  );
  return res;
};

//gets list of possible default weighting names
export const getDefaultWeightings = async () => {
  let searchUrl = `${url}/defaultweightings`;
  const res = await axios.get<any>(searchUrl);
  return res;
};

// gets list of subcategory defaults for given name
export const getDefaultWeightingByName = async ({ name }) => {
  let searchUrl = `${url}/defaultweightings/${name}`;
  const res = await axios.get<any>(searchUrl);
  return res;
};

// rename default weighting by entering old and new name
export const renameDefaultWeighting = async (args: any) => {
  let searchUrl = `${url}/defaultweightings`;
  const res = await axios.patch<any>(searchUrl, { ...args });
  return res;
};

// update default weighting
export const updateDefaultWeighting = async (
  name: string,
  defaultWeightings
) => {
  let searchUrl = `${url}/defaultweightings/update/${name}`;
  const res = await axios.patch<any>(searchUrl, defaultWeightings);
  return res;
};

// create default weighting
export const createDefaultWeighting = async (args: any) => {
  console.log(args);
  let searchUrl = `${url}/defaultweightings/create`;
  const res = await axios.post<any>(searchUrl, args);
  return res;
};

// delete default weighting
export const deleteDefaultWeighting = async (name: string) => {
  let searchUrl = `${url}/defaultweightings/delete/${name}`;
  const res = await axios.delete<any>(searchUrl);
  return res;
};

export const searchUsers = async (firstLetters: string) => {
  const res = await axios.get<RamUser[]>(`${url}/userssearch/${firstLetters}`);
  return res.data;
};

export const createReportWithInsurerUsers = async (
  locationId: number | undefined,
  data: any
) => {
  const res = await axios.post<ReportWithInsurerUsers>(
    `${url}/reportswithinsurerusers/${locationId}`,
    data
  );
  return res;
};

export const createReportWithoutInsurerUsers = async (
  locationId: number | undefined,
  data: any
) => {
  const res = await axios.post<Report>(`${url}/reports/${locationId}`, data);
  return res;
};

export const getReports = async () => {
  const res = await axios.get(`${url}/reports`);
  return res;
};

export const getLocation = async (locationId: number | undefined) => {
  return await axios.get<ClientLocationWithUsers>(
    `${url}/clientlocationswithusers/${locationId}`
  );
};

export const updateLocationWithUsers = async (
  locationId: number | undefined,
  data: any
) => {
  const res = await axios.patch<ClientLocationWithUsers>(
    `${url}/clientlocationswithusers/${locationId}`,
    data
  );
  return res;
};

export const updateLocationWithoutUsers = async (
  locationId: number | undefined,
  data: any
) => {
  const res = await axios.patch<ClientLocation>(
    `${url}/clientlocations/${locationId}`,
    data
  );
  return res;
};

export const getReport = async (locationId: number, reportId: number) => {
  const res = await axios.get<ReportWithInsurerUsers>(
    `${url}/reportswithinsurerusers/${locationId}/${reportId}`
  );
  return res;
};

export const updateReportWithInsurerUsers = async (
  locationId: number | undefined,
  reportId: number | undefined,
  data: any
) => {
  const res = await axios.patch<any>(
    `${url}/reportswithinsurerusers/${locationId}/${reportId}`,
    data
  );
  return res;
};

export const updateReportWithoutInsurerUsers = async (
  locationId: number | undefined,
  reportId: number | undefined,
  data: any
) => {
  const res = await axios.patch<any>(
    `${url}/reports/${locationId}/${reportId}`,
    data
  );
  return res;
};

export const getUsersByLocation = async (locationId: number | undefined) => {
  const res = await axios.get<any>(`${url}/users/${locationId}`);
  return res.data;
};

export const searchRecommendations = async ({
  top,
  skip,
  sortDir = "asc",
  sortKey,
  clientsFilter,
  locationsFilter,
  reportsFilter,
  search,
  cancelToken,
  columns,
  taxonomy,
}: {
  top: number;
  skip: number;
  sortKey?: string;
  sortDir?: string;
  clientsFilter: Client["clientId"][];
  locationsFilter: ClientLocation["locationId"][];
  reportsFilter: Report["reportId"][];
  search: string;
  cancelToken: CancelToken;
  columns: ExpandedColumn[];
  taxonomy: any;
}) => {
  let url = `${process.env.REACT_APP_BASE_URL}/recommendations`;
  url += `?$top=${top}`;
  url += `&$skip=${skip}`;
  if (sortKey) {
    url += `&$orderby=${sortKey} ${sortDir.toLowerCase()}`;
  }

  const clientsFilterString =
    clientsFilter.length > 0
      ? `search.in(webBasePartnerNumber, '${clientsFilter.join(",")}')`
      : undefined;

  const locationsFilterString =
    locationsFilter.length > 0
      ? `search.in(locationId, '${locationsFilter.join(",")}')`
      : undefined;

  const reportsFilterString =
    reportsFilter.length > 0
      ? `search.in(reportId, '${reportsFilter.join(",")}')`
      : undefined;

  const columnFilterString = ColumnOptionsUtils.createAzureFilterParam(
    columns as any
  );

  let filterStr = [
    clientsFilterString,
    locationsFilterString,
    reportsFilterString,
  ]
    .filter((s) => !!s)
    .join(" and ");

  function replaceSearchInWithNull(input) {
    const riskClientReportCreatorId =
      taxonomy.RiskReportCreator.byCode["RiskReportCreator.RISK_CLIENT"]?.id;

    // Regular expression to match the pattern "search.in(FIELDNAME, '-1')"
    const reportCreatorCodeRegex = new RegExp(
      `search\\.in\\(reportCreatorCode, '-${riskClientReportCreatorId}'\\)`,
      "g"
    );
    let replacedInput = input.replace(
      reportCreatorCodeRegex,
      "reportCreatorCode ne '" + riskClientReportCreatorId + "'"
    );

    const regex = /search\.in\((\w+), '-1'\)/g;

    // Replace the matched pattern with "FIELDNAME eq null"
    return replacedInput.replace(regex, (match, fieldName) => {
      return `${fieldName} eq null`;
    });
  }

  if (filterStr.length > 0) {
    if (columnFilterString.length > 0) {
      filterStr += ` and ${replaceSearchInWithNull(columnFilterString)}`;
    } else {
      filterStr += columnFilterString;
    }
  } else {
    filterStr += columnFilterString;
  }

  if (filterStr.length > 0) {
    url += `&$filter=${filterStr}`;
  }

  if (search.length > 0) {
    url += `&searchCriteria=${search}*`;
  }

  url += "&$count=true";

  const res = await axios.get<RecommendationAzureResponse>(url, {
    cancelToken,
  });
  return res;
};

export const searchTemplates = async (search: string) => {
  const res = await axios.get(`${url}/recommendationtemplatessearch/${search}`);
  return res;
};

export const createRecommendationWithAttachments = async (
  locationId: number,
  reportId: number,
  body: any
) => {
  const res = await axios.post<RecommendationWithAttachments>(
    `${url}/recommendationwithattachments/${locationId}/${reportId}`,
    body
  );
  return res;
};

export const getLocationSummary = async (
  locId: number,
  categories: number[]
) => {
  let lsids = locId + "@" + categories.join(",");

  const res = await axios.get(`${url}/clientlocations/summary/${lsids}`);
  return res;
};

export const getDefaultValues = async (locId: number) => {
  const res = await axios.get<LocationDefaultValue[]>(
    `${url}/clientlocationassessmentsubcategorysetups/${locId}`
  );
  return res;
};

export const updateDefaultValues = async (
  locId: number,
  defaultValues: LocationDefaultValue[]
) => {
  const res = await axios.patch(
    `${url}/clientlocationassessmentsubcategorysetups/${locId}`,
    defaultValues
  );
  return res;
};

export const startAssessment = async (locationId: number) => {
  const res = await axios.patch(
    `${url}/clientlocations/startassessment/${locationId}`
  );
  return res;
};

export const endAssessment = async (locationId: number) => {
  const res = await axios.patch(
    `${url}/clientlocations/endassessment/${locationId}`
  );
  return res;
};

export const getAllUsersForClient = async (clientIds: string) => {
  const res = await axios.get<RiskUser[]>(`${url}/users/client/${clientIds}`);
  return res;
};

export const getAllUsersForLocation = async (locationIds: string) => {
  const res = await axios.get<RiskUser[]>(
    `${url}/users/location/${locationIds}`
  );
  return res;
};

export type GetRecommendationParams = {
  locationId: string;
  reportId: string;
  recommendationId: string;
};

export const getRecommendation = async (
  locationId: string,
  reportId: string,
  recommendationId: string
) => {
  const res = await axios.get<Recommendation>(
    `${url}/recommendations/${locationId}/${reportId}/${recommendationId}`
  );
  return res;
};

export const getRecommendationWithAttachments = async ({
  locationId,
  reportId,
  recommendationId,
}: {
  locationId: string;
  reportId: string;
  recommendationId: string;
}) => {
  const res = await axios.get<RecommendationWithAttachments>(
    `${url}/recommendationwithattachments/${locationId}/${reportId}/${recommendationId}`
  );
  return res;
};

export const updateRecommendationWithAttachments = async ({
  locationId,
  reportId,
  recommendationId,
  body,
}: {
  locationId: number;
  reportId: number;
  recommendationId: number;
  body: RecommendationWithAttachments;
}) => {
  const res = await axios.patch<RecommendationWithAttachments>(
    `${url}/recommendationwithattachments/${locationId}/${reportId}/${recommendationId}`,
    body
  );
  return res;
};

export const responseShowHide = async ({
  locationId,
  reportId,
  recommendationId,
  responseId,
  showHide,
}: {
  locationId: number;
  reportId: number;
  recommendationId: number;
  responseId: number;
  showHide: boolean;
}) => {
  const res = await axios.patch<ClientResponse>(
    `${url}/clientresponses/showhide/${locationId}/${reportId}/${recommendationId}/${responseId}/${showHide}`,
    null
  );
  return res;
};

export const assessmentShowHide = async ({
  locationId,
  reportId,
  recommendationId,
  assessmentId,
  showHide,
}: {
  locationId: number;
  reportId: number;
  recommendationId: number;
  assessmentId: number;
  showHide: boolean;
}) => {
  const res = await axios.patch<Assessment>(
    `${url}/assessments/showhide/${locationId}/${reportId}/${recommendationId}/${assessmentId}/${showHide}`,
    null
  );
  return res;
};

export type AssesRecommendationParams = {
  locationId: string;
  reportId: string;
  recommendationId: string;
  data: any;
};

export const assessRecommendation = async ({
  data,
  locationId,
  reportId,
  recommendationId,
}: AssesRecommendationParams) => {
  const res = await axios.patch<Recommendation>(
    `${url}/recommendations/assessment/${locationId}/${reportId}/${recommendationId}`,
    data
  );
  return res;
};

export type GetResponsesWithAttachmentsParams = {
  locationId: any;
  reportId: any;
  recommendationId: any;
};

export const getResponsesWithAttachments = async (
  locationId: number | undefined,
  reportId: number | undefined,
  recommendationId: number | undefined
) => {
  const res = await axios.get<ClientResponseWithAttachments[]>(
    `${url}/clientresponseswithattachments/${locationId}/${reportId}/${recommendationId}`
  );
  return res;
};

export type GetResponseWithAttachmentsParams = {
  locationId: number;
  reportId: number;
  recommendationId: number;
  responseId: number;
};

export const getResponseWithAttachments = async (
  locationId: number,
  reportId: number,
  recommendationId: number,
  responseId: number
) => {
  const res = await axios.get(
    `${url}/clientresponseswithattachments/${locationId}/${reportId}/${recommendationId}/${responseId}`
  );
  return res;
};

export type GetAssessmentsWithAttachmentsParams = {
  locationId: any;
  reportId: any;
  recommendationId: any;
};

export const getAssessmentsWithAttachments = async (
  locationId: number | undefined,
  reportId: number | undefined,
  recommendationId: number | undefined
) => {
  const res = await axios.get<AssessmentWithAttachments[]>(
    `${url}/assessmentswithattachments/${locationId}/${reportId}/${recommendationId}`
  );
  return res;
};

export type GetAssessmentWithAttachmentsParams = {
  locationId: number;
  reportId: number;
  recommendationId: number;
  assessmentId: number;
};

export const getAssessmentWithAttachments = async (
  locationId: number,
  reportId: number,
  recommendationId: number,
  assessmentId: number
) => {
  const res = await axios.get(
    `${url}/assessmentswithattachments/${locationId}/${reportId}/${recommendationId}/${assessmentId}`
  );
  return res;
};

export type CreateResponseWithAttachmentsParams = {
  locationId: number;
  reportId: number;
  recommendationId: number;
  body: any;
};

export type CreateAssessmentWithAttachmentsParams = {
  locationId: number;
  reportId: number;
  recommendationId: number;
  body: any;
};

export const createResponseWithAttachments = async (
  locationId: number,
  reportId: number,
  recommendationId: number,
  body: any
) => {
  const res = await axios.post(
    `${url}/clientresponseswithattachments/${locationId}/${reportId}/${recommendationId}`,
    body
  );
  return res;
};

export const createAssessmentWithAttachments = async (
  locationId: number,
  reportId: number,
  recommendationId: number,
  body: any
) => {
  const res = await axios.post(
    `${url}/assessmentswithattachments/${locationId}/${reportId}/${recommendationId}`,
    body
  );
  return res;
};

export type UpdateResponseWithAttachmentsParams = {
  locationId: number;
  reportId: number;
  recommendationId: number;
  responseId: number;
  body: any;
};

export type UpdateAssessmentWithAttachmentsParams = {
  locationId: number;
  reportId: number;
  recommendationId: number;
  assessmentId: number;
  body: any;
};

export const editResponseWithAttachments = async (
  locationId: number,
  reportId: number,
  recommendationId: number,
  responseId: number,
  body: any
) => {
  const res = await axios.patch(
    `${url}/clientresponseswithattachments/${locationId}/${reportId}/${recommendationId}/${responseId}`,
    body
  );
  return res;
};

export const editAssessmentWithAttachments = async (
  locationId: number,
  reportId: number,
  recommendationId: number,
  assessmentId: number,
  body: any
) => {
  const res = await axios.patch(
    `${url}/assessmenstwithattachments/${locationId}/${reportId}/${recommendationId}/${assessmentId}`,
    body
  );
  return res;
};

export const deleteResponseAttachments = async (
  locationId: number,
  reportId: number,
  recommendationId: number,
  responseId: number
) => {
  const res = await axios.delete(
    `${url}/clientresponseattachments/${locationId}/${reportId}/${recommendationId}/${responseId}`
  );
  return res;
};

export const deleteAssessmentAttachments = async (
  locationId: number,
  reportId: number,
  recommendationId: number,
  assessmentId: number
) => {
  const res = await axios.delete(
    `${url}/assessmentattachments/${locationId}/${reportId}/${recommendationId}/${assessmentId}`
  );
  return res;
};

export type GetEvaluationsWithAttachmentsParams = {
  locationId: any;
  reportId: any;
  recommendationId: any;
};

export const getEvaluationsWithAttachments = async (
  locationId: number | undefined,
  reportId: number | undefined,
  recommendationId: number | undefined
) => {
  const res = await axios.get<InsurerEvaluationWithAttachments[]>(
    `${url}/insurerevaluationswithattachments/${locationId}/${reportId}/${recommendationId}`
  );
  return res;
};
export type GetClientResponsesAndInsurerEvaluationsWithAttachmentsParams = {
  locationId: any;
  reportId: any;
  recommendationId: any;
};

export type ClientResponsesAndInsurerEvaluationsWithAttachmentsResponse = {
  clientResponsesWithAttachments: ClientResponseWithAttachments[];
  insurerEvaluationsWithAttachments: InsurerEvaluationWithAttachments[];
  assessmentsWithAttachments: AssessmentWithAttachments[];
};

export const getClientResponsesAndInsurerEvaluationsWithAttachments = async (
  locationId: number | undefined,
  reportId: number | undefined,
  recommendationId: number | undefined
) => {
  const res =
    await axios.get<ClientResponsesAndInsurerEvaluationsWithAttachmentsResponse>(
      `${url}/clientresponsesandinsurerevaluationswithattachments/${locationId}/${reportId}/${recommendationId}`
    );
  return res;
};

export type GetEvaluationWithAttachmentsParams = {
  locationId: number;
  reportId: number;
  recommendationId: number;
  evaluationId: number;
};

export const getEvaluationWithAttachments = async (
  locationId: number,
  reportId: number,
  recommendationId: number,
  evaluationId: number
) => {
  const res = await axios.get(
    `${url}/insurerevaluationswithattachments/${locationId}/${reportId}/${recommendationId}/${evaluationId}`
  );
  return res;
};

export type CreateEvaluationWithAttachmentsParams = {
  locationId: number;
  reportId: number;
  recommendationId: number;
  body: any;
};

export const createEvaluationWithAttachments = async (
  locationId: number,
  reportId: number,
  recommendationId: number,
  body: any
) => {
  const res = await axios.post(
    `${url}/insurerevaluationswithattachments/${locationId}/${reportId}/${recommendationId}`,
    body
  );
  return res;
};

export type UpdateEvaluationWithAttachmentsParams = {
  locationId: number;
  reportId: number;
  recommendationId: number;
  evaluationId: number;
  body: any;
};

export const editEvaluationWithAttachments = async (
  locationId: number,
  reportId: number,
  recommendationId: number,
  evaluationId: number,
  body: any
) => {
  const res = await axios.patch(
    `${url}/insurerevaluationswithattachments/${locationId}/${reportId}/${recommendationId}/${evaluationId}`,
    body
  );
  return res;
};

export const deleteEvaluationAttachments = async (
  locationId: number,
  reportId: number,
  recommendationId: number,
  evaluationId: number
) => {
  const res = await axios.delete(
    `${url}/insurerevaluationattachments/${locationId}/${reportId}/${recommendationId}/${evaluationId}`
  );
  return res;
};

export const changeRecommendationStatus = async (
  locationId: number,
  reportId: number,
  recommendationId: number,
  recommendationStatusCode: number
) => {
  const res = await axios.patch<Recommendation>(
    `${url}/recommendations/${locationId}/${reportId}/${recommendationId}/${recommendationStatusCode}`
  );
  return res;
};

export const getUsersList = async () => {
  const res = await axios.get<RiskUser[]>(`${url}/users`);
  return res;
};

export const getUsersListInsurer = async () => {
  const res = await axios.get<RiskInsurerUser[]>(`${url}/insurerusers`);
  return res;
};

export const getAllInsurerUsersForClient = async (clientIds: string) => {
  const res = await axios.get<RiskUser[]>(
    `${url}/insurerusers/client/${clientIds}`
  );
  return res;
};

export const getAllInsurerUsersForLocation = async (locationIds: string) => {
  const res = await axios.get<RiskUser[]>(
    `${url}/insurerusers/location/${locationIds}`
  );
  return res;
};

export const getLocationsByUserId = async (
  userId: string,
  userType: number,
  clientIds: string,
  locationIds: string,
  cancelToken: CancelToken
) => {
  const res = await axios.get(
    `${url}/clientlocations/user/${userId}?userType=${userType}&clientIds=${clientIds}&locationIds=${locationIds}`,
    {
      cancelToken,
    }
  );
  return res;
};

export const getReportsByUserId = async (
  userId: string,
  clientIds: string,
  locationIds: string,
  cancelToken: CancelToken
) => {
  const res = await axios.get(
    `${url}/reports/insureruser/${userId}?clientIds=${clientIds}&locationIds=${locationIds}`,
    {
      cancelToken,
    }
  );
  return res;
};

export const updateLocationsByUserId = async (
  userId: string,
  clientIds: string,
  locationIds: string,
  data: any
) => {
  const res = await axios.patch(
    `${url}/clientlocations/user/${userId}?clientIds=${clientIds}&locationIds=${locationIds}`,
    data
  );
  return res;
};

export const updateReportsByUserId = async (
  userId: string,
  clientIds: string,
  locationIds: string,
  data: any
) => {
  const res = await axios.patch(
    `${url}/reports/insureruser/${userId}?clientIds=${clientIds}&locationIds=${locationIds}`,
    data
  );
  return res;
};

export const getAppSettings = async (appID: number) => {
  return await axios.get<SettingsResponse>(`${uadUrl}/appsettings/${appID}`);
};

export const getUADTaxonomy = async () => {
  return await axios.get<TaxonomyUADResponse>(`${uadUrl}/taxonomy`);
};

export const addAppSetting = async (setting: Partial<Setting>) => {
  return await axios.post<Setting[]>(`${uadUrl}/addappsettings`, [setting]);
};

export const deleteAppSetting = async (
  userAppSettingId: Setting["userAppSettingId"]
) => {
  return await axios.delete<Setting[]>(`${uadUrl}/deleteappsettings`, {
    data: [userAppSettingId],
  });
};

export const updateAppSetting = async (setting: Setting) => {
  return await axios.patch<Setting[]>(`${uadUrl}/updateappsettings`, [setting]);
};

export const getPowerBIEmbedMeta = async (clientIds: string) => {
  var allOrTopMostSelectedClientId = clientIds ? clientIds : "all";
  const res = await axios.get<PowerBIEmbedMeta>(
    `${url}/powerbi/${allOrTopMostSelectedClientId}`
  );
  return res;
};

export type DeleteRecommendationParams = {
  locationId: any;
  reportId: any;
  recommendationId: any;
};

export type DeleteReportParams = {
  locationId: any;
  reportId: any;
  onSuccess?: () => void;
  onError?: (err: AxiosError<any>) => void;
};

export type DeleteLocationParams = {
  locationId: any;
};

export type DeleteDefaultWeightingParams = {
  name: any;
};

export type RenameDefaultWeightingParams = {
  oldName: string;
  newName: string;
};

export type ChangeClientParams = {
  locationId: any;
  clientId: any;
};

export const deleteRecommendation = async (
  params: DeleteRecommendationParams
) => {
  const { locationId, reportId, recommendationId } = params;
  return await axios.delete<any>(
    `${url}/recommendations/${locationId}/${reportId}/${recommendationId}`
  );
};

export const deleteReport = async (params: DeleteReportParams) => {
  const { locationId, reportId } = params;
  return await axios.delete<any>(`${url}/reports/${locationId}/${reportId}`);
};

export type ChangeActiveStatusParams = {
  locationId: number;
  reportId: number;
  activate: boolean;
};

export const changeReportActiveStatus = async (
  params: ChangeActiveStatusParams
) => {
  const { locationId, reportId, activate } = params;
  const res = await axios.patch(
    `${url}/reports/activatedeactivate/${locationId}/${reportId}/${activate}`
  );
  return res;
};

export const deleteLocation = async (params: DeleteLocationParams) => {
  const { locationId } = params;
  return await axios.delete<any>(`${url}/clientlocations/${locationId}`);
};

export const changeClient = async (params: ChangeClientParams) => {
  const { locationId, clientId } = params;
  return await axios.patch<any>(
    `${url}/clientlocations/${locationId}/${clientId}`
  );
};

export type SetRatingLimitsParams = {
  clientId: number;
  updateHierarchically: boolean;
  body: any;
};

export type GenerateLocationReportDocumentParams = {
  clientId: number;
  locationId: number;
  body: any;
};

export type DeleteResponseParams = {
  locationId: any;
  reportId: any;
  recommendationId: any;
  responseId: any;
};

export type DeleteAssessmentParams = {
  locationId: any;
  reportId: any;
  recommendationId: any;
  assessmentId: any;
};

export type DeleteEvaluationParams = {
  locationId: any;
  reportId: any;
  recommendationId: any;
  evaluationId: any;
};

export type ClientReportDocumentParams = {
  clientId: number;
};

export type UsersReportDocumentParams = {
  clientId: number;
};

export type LocationsReportDocumentParams = {
  clientId: number;
};

export type LocationUsersReportDocumentParams = {
  clientId: number;
  locationId: number;
};

export type ReportDocumentParams = {
  // clientId: any;
  // locationId: any;
  // recommendationId: any;
};

export const deleteResponse = async (params: DeleteResponseParams) => {
  const { locationId, reportId, recommendationId, responseId } = params;
  return await axios.delete<any>(
    `${url}/clientresponses/${locationId}/${reportId}/${recommendationId}/${responseId}`
  );
};

export const deleteAssessment = async (params: DeleteAssessmentParams) => {
  const { locationId, reportId, recommendationId, assessmentId } = params;
  return await axios.delete<any>(
    `${url}/assessments/${locationId}/${reportId}/${recommendationId}/${assessmentId}`
  );
};

export const deleteEvaluation = async (params: DeleteEvaluationParams) => {
  const { locationId, reportId, recommendationId, evaluationId } = params;
  return await axios.delete<any>(
    `${url}/insurerevaluations/${locationId}/${reportId}/${recommendationId}/${evaluationId}`
  );
};

export const clientReportDocument = async (params: any) => {
  const { clientId } = params;
  return await axios.post<any>(`${url}/clients/excelreport/${clientId}`);
};

export const usersReportDocument = async (params: any) => {
  const { clientId } = params;
  return await axios.post<any>(`${url}/clients/exceluser/${clientId}`);
};
export const locationsReportDocument = async (params: any) => {
  const { clientId, locationId } = params;
  return await axios.post<any>(
    `${url}/clients/exceluser/${clientId}/${locationId}`
  );
};
export const reportDocument = async (params: any) => {
  const { clientId, locationId, recommendationId } = params;
  return await axios.post<any>(
    `${url}/recommendation/reportdocument/${clientId}/${locationId}/${recommendationId}`
  );
};

export type ExportToZipParams = {
  locationId: any;
};

export type ExportRecommendationToZipParams = {
  recommendationId: any;
  locationId: any;
};

export type CopyRecommendationParams = {
  recommendation: any;
};

export type PasteRecommendationParams = {
  locationId: any;
  reportId: any;
  recommendationId: any;
  destinationReportIds: any[];
};

export const duplicateRecommendation = async (
  params: PasteRecommendationParams
) => {
  const { locationId, reportId, recommendationId, destinationReportIds } =
    params;
  return await axios.post<any>(
    `${url}/recommendations/duplicatetootherreports/${locationId}/${reportId}/${recommendationId}`,
    destinationReportIds
  );
};
