import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const NoData = () => {
  const { t } = useTranslation();

  return <Root>{t("greco.noData")}</Root>;
};

const Root = styled.div`
  color: ${(p) => p.theme.palette.neutralPrimary};
  font-size: 13px;
  font-style: italic;
`;
