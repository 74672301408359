import {
  INavLinkGroup,
  Nav,
  Sticky,
  StickyPositionType,
  mergeStyleSets,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { useSelector } from "../../../../store/hooks";
import { setSubTab } from "../../../../store/recommendationPage";
import { IStyledTheme } from "../../../../theme/types";
import { sideNavigationTypes } from "../../constants";
import { useCurrentRoute } from "../../../../config/routes";
import { RecommendationStatus } from "./RecommendationStatus";

function SideNav() {
  const { t } = useTranslation();
  const route = useCurrentRoute();
  const theme = useTheme() as IStyledTheme;
  const sideTab = useSelector((s) => s.recommendationPage.subTab);
  const mainTab = useSelector((s) => s.recommendationPage.tab);
  const dispatch = useDispatch();

  const handleNavItemClick = (e, tab) => {
    const tabItem: any = document.querySelector(`#${tab.key}`);

    document.querySelector("#recomendation-form-wrap")!.scroll({
      // document.querySelector("#app-wrapper")!.scroll({
      top: tabItem?.offsetTop,
      left: 0,
      behavior: "smooth",
    });
    if (!tab) return;
    dispatch(setSubTab(tab.key as any));
  };

  const classes = mergeStyleSets({
    container: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      boxSizing: "border-box",
      justifyContent: "space-between",
      height: "calc(100vh - 500px)",
      position: "sticky",
      top: 86, // 43 height of navigation + 43 height of app header
      minWidth: 200,
    },
    wrap: {
      // borderBottom: `1px solid ${theme.palette.neutralLight}`,
      height: "100%",
      "& .ms-Nav-groupContent": {
        marginBottom: "0",
      },
      ".ms-Nav-compositeLink.is-selected .ms-Button": {
        background: theme.isDark ? "#001321" : "#d0e7f8",
      },
    },
    navigation: {
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
    },
  });

  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
          name: t("ram.recommendationForm.generalTab.label"),
          url: "",
          key: sideNavigationTypes.General,
          isExpanded: true,
          target: "_blank",
          icon: "Info",
          //   onClick: () => {
          //     dispatch(setSubTab("general"));
          //   },
        },

        ...(mainTab === "recommendation"
          ? [
              {
                name: t("ram.recommendationForm.other.label"),
                url: "",
                key: sideNavigationTypes.Other,
                isExpanded: true,
                target: "_blank",
                icon: "ReportDocument",
                //   onClick: () => {
                //     dispatch(setSubTab("riskAssessment"));
                //   },
              },
              {
                name: t("ram.recommendationForm.attachmentsTab.label"),
                url: "",
                key: sideNavigationTypes.Attachments,
                isExpanded: true,
                target: "_blank",
                icon: "Attach",
                //   onClick: () => {
                //     dispatch(setSubTab("attachments"));
                //   },
              },
            ]
          : []),
      ],
    },
  ];

  return (
    <Sticky stickyPosition={StickyPositionType.Header}>
      <div className={classes.container}>
        <div className={classes.wrap}>
          <Nav
            onLinkClick={handleNavItemClick}
            selectedKey={sideTab}
            groups={navLinkGroups}
            className={classes.navigation}
          />
          {route === "editRecommendation" && <RecommendationStatus />}
        </div>
      </div>
    </Sticky>
  );
}

export default SideNav;
