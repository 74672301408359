import { DefaultButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
  isDisabled: boolean;
  onClear: () => void;
};

export const ClearFilters = ({ isDisabled, onClear }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledDefaultButton
      text={t("ram.nav.clearFilters.label")}
      iconProps={{
        iconName: "Cancel",
      }}
      onClick={() => onClear()}
      disabled={isDisabled}
    />
  );
};

const StyledDefaultButton = styled(DefaultButton)`
  &.ms-Button {
    border: 0;
    background: transparent;
    &:hover {
      background: ${(p) => p.theme.palette.neutralLighter};
    }
  }
  .ms-Button-label {
    font-weight: normal;
  }
`;
