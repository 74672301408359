import styled from "styled-components";
import { Label } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Flex } from "../../../../../components/styled";
import { useSelector } from "../../../../../store/hooks";

export const ImprovementPotential = ({
  improvementPotentialCode,
}: {
  improvementPotentialCode: number;
}) => {
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();
  const riskImprovementPotential =
    taxonomy.RiskImprovementPotential.byId[improvementPotentialCode]?.code;

  return (
    <div>
      <Label>
        {t("ram.recommendationForm.improvementPotentialCode.label")}
      </Label>
      <Flex>
        {/* <Icon
          iconName={riskImplmentationStatusIcons[riskImprovementPotential]}
          style={{ color: riskImplementationStatusColors[riskImprovementPotential] }}
          css={css`
            font-size: 18px;
            margin-right: 3px;
          `}
          data-for="tooltip"
          data-tip={t(riskImprovementPotential)}
        /> */}
        <Text>{t(riskImprovementPotential)}</Text>
      </Flex>
    </div>
  );
};

const Text = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;
