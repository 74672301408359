import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../store/hooks";
import { Speedometer } from "../Speedometer";
import { usePrioritySpeeds } from "./usePrioritySpeeds";

export const PriorityCode = () => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const recommendation = useSelector(
    (s) => s.recommendationPanel.recommendation
  );
  const speeds = usePrioritySpeeds();

  const selectedSpeedIndex = speeds.findIndex(
    (s) =>
      s.key === taxonomy.RiskPriority.byId[recommendation?.priorityCode]?.code
  );

  const selectedSpeed = Math.max(selectedSpeedIndex, 0);

  return (
    <div>
      <strong style={{ marginBottom: "10px", display: "block" }}>
        {t("ram.recommendationForm.priorityCode.label")}
      </strong>
      <Speedometer speeds={speeds} selectedSpeed={selectedSpeed} />
    </div>
  );
};
