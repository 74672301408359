import { useTranslation } from "react-i18next";
import { riskPriorityColors } from "../../../config/colors";

export const usePrioritySpeeds = () => {
  const { t } = useTranslation();

  const prioritySpeeds = [
    {
      key: "RiskPriority.NO_PRIORITY_DEFINED_BY_GREG",
      color: riskPriorityColors["RiskPriority.NO_PRIORITY_DEFINED_BY_GREG"],
      text: t("greco.notDefined"),
    },
    {
      key: "RiskPriority.MINOR",
      color: riskPriorityColors["RiskPriority.MINOR"],
      text: t("RiskPriority.MINOR"),
    },
    {
      key: "RiskPriority.MODERATE",
      color: riskPriorityColors["RiskPriority.MODERATE"],
      text: t("RiskPriority.MODERATE"),
    },
    {
      key: "RiskPriority.MAJOR",
      color: riskPriorityColors["RiskPriority.MAJOR"],
      text: t("RiskPriority.MAJOR"),
    },
  ];
  return prioritySpeeds;
};
