import { ITooltipHostProps, TooltipDelay, TooltipHost } from "@fluentui/react";
import React from "react";
import { useTheme } from "styled-components";

export const TooltipComponent = ({ children, ...props }: ITooltipHostProps) => {
  const theme = useTheme();

  const background = theme.palette.white;

  return (
    <TooltipHost
      tooltipProps={{
        styles: {
          root: {
            background: theme.palette.white,
          },
          content: {
            color: theme.palette.black,
          },
        },
        ...props.tooltipProps,
      }}
      delay={TooltipDelay.medium}
      calloutProps={{
        styles: {
          beakCurtain: { background },
          beak: { background },
          calloutMain: { background, borderRadius: 2 },
        },
        beakWidth: 10,
      }}
      {...props}
    >
      {children}
    </TooltipHost>
  );
};
