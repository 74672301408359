import {
  IShimmerElement,
  Shimmer,
  ShimmerElementType,
} from "@fluentui/react/lib/Shimmer";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import * as React from "react";

const classes = mergeStyleSets({
  navigation: {
    height: 44,
  },
  wrapp: {
    marginTop: 50,
  },
  content: {
    maxWidth: "1200px",
    margin: "auto",
    position: "relative",
  },
});

const formSeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "100%",
    height: 1500,
  },
];

type Props = {
  withMargin?: boolean;
};
const RecomendationSkeleton = ({ withMargin }: Props) => {
  return (
    <>
      <div className={classes.content}>
        <div className={withMargin ? classes.wrapp : ""}>
          <Shimmer shimmerElements={formSeleton} />
        </div>
      </div>
    </>
  );
};

export default RecomendationSkeleton;
