import { mergeStyleSets } from "@fluentui/react";
import { useFormikContext } from "formik";
import React from "react";
import CheckboxField from "../../../../../components/controls/CheckboxField";
import ComboBoxField from "../../../../../components/controls/ComboBoxField";
import DoubleNumberInputField from "../../../../../components/controls/DoubleNumberInputField";
import NumberInputField from "../../../../../components/controls/NumberInputField";
import useGetTaxonomyOptions from "../../../../../utils/hooks/useGetTaxonomyOptions";
import { sideNavigationTypes } from "../../../constants";
import useIsDisabledRecommendationForm from "../../../hooks/useIsDisabledRecommendationForm";
import { SubCategoryCodeField } from "./SubCategoryCodeField";
import { WeightingField } from "./WeightingField";
import useCostBenifitFactorCalculation from "./hooks/useCostBenifitFactorCalculation";
import {
  useEmlReductionPercentMaxValueValidation,
  useImplementationCostsMaxValueValidation,
} from "../../../hooks/useMaxValueValidation";
import useResultCalculation from "./hooks/useResultCalculation";
import useCalculateResultAsIf from "./hooks/useCalculateResultAsIf";

function RiskAssesmentFormFields() {
  useEmlReductionPercentMaxValueValidation();
  useImplementationCostsMaxValueValidation();
  useCostBenifitFactorCalculation();
  useResultCalculation();
  useCalculateResultAsIf();

  const { values, handleChange, errors, setFieldValue } =
    useFormikContext<any>();

  const isDisabled = useIsDisabledRecommendationForm();

  const riskImprovementPotentialOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskImprovementPotential",
    sort: false,
  }).sort((a, b) => b.value - a.value);

  const costEstimateCodeOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskReductionCost",
    sort: false,
  });

  return (
    <div id={sideNavigationTypes.Other} className={classes.container}>
      <div className={classes.grid}>
        <SubCategoryCodeField
          name="subCategory1Code"
          isRequired
          label="ram.recommendationForm.subCategory1Code.label"
        />
        <SubCategoryCodeField
          name="subCategory2Code"
          label="ram.recommendationForm.subCategory2Code.label"
        />
        <SubCategoryCodeField
          name="subCategory3Code"
          label="ram.recommendationForm.subCategory3Code.label"
        />
      </div>
      <div className={classes.grid}>
        <WeightingField />
        <CheckboxField
          checked={values?.noGo}
          disabled={isDisabled}
          label={"ram.recommendationForm.required.label"}
          name={"noGo"}
          setFieldValue={setFieldValue}
          errors={errors}
        />
      </div>
      <div className={classes.grid}>
        <ComboBoxField
          disabled={isDisabled}
          errors={errors}
          name="improvementPotentialCode"
          label={"ram.recommendationForm.improvementPotentialCode.label"}
          setFieldValue={setFieldValue}
          options={riskImprovementPotentialOptions || []}
          defaultValue={values?.improvementPotentialCode}
          confidentiality={null}
          required
        />
        <ComboBoxField
          disabled={isDisabled}
          errors={errors}
          name="improvementPotentialCodeAsIf"
          label={"ram.recommendationForm.improvementPotentialCodeAsIf.label"}
          setFieldValue={setFieldValue}
          options={riskImprovementPotentialOptions || []}
          defaultValue={values?.improvementPotentialCodeAsIf}
          confidentiality={null}
          required={false}
        />
        <NumberInputField
          value={values?.result}
          onChange={() => {}}
          errors={errors}
          name={"result"}
          disabled={isDisabled}
          required={false}
          label={"ram.recommendationForm.result.label"}
        />
        <NumberInputField
          value={values?.resultAsIf}
          onChange={() => {}}
          errors={errors}
          name={"resultAsIf"}
          disabled={isDisabled}
          required={false}
          label={"ram.recommendationForm.resultAsIf.label"}
        />
        <NumberInputField
          value={values?.emlReductionPercent}
          onChange={handleChange}
          errors={errors}
          name={"emlReductionPercent"}
          disabled={isDisabled}
          required={false}
          label={"ram.recommendationForm.emlReductionPercent.label"}
        />
        <DoubleNumberInputField
          value={values?.implementationCostsEur}
          onChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
          name={"implementationCostsEur"}
          disabled={isDisabled}
          required={false}
          label={"ram.recommendationForm.implementationCostsEUR.label"}
        />

        <DoubleNumberInputField
          setFieldValue={setFieldValue}
          value={values?.costBenifitFactor}
          onChange={handleChange}
          errors={errors}
          name={"costBenifitFactor"}
          disabled={isDisabled}
          required={false}
          label={"ram.recommendationForm.costBenifitFactor.label"}
        />
        <ComboBoxField
          disabled={isDisabled}
          errors={errors}
          name="costEstimateCode"
          label={"ram.recommendationForm.costEstimateCode.label"}
          setFieldValue={setFieldValue}
          options={costEstimateCodeOptions || []}
          defaultValue={values?.costEstimateCode}
          confidentiality={null}
          required={true}
        />
      </div>
    </div>
  );
}

export default RiskAssesmentFormFields;

{
  /* <TextInputField
value={values?.recommendationNumberInternal}
onChange={handleChange}
errors={errors}
name={"recommendationNumberInternal"}
disabled={isDisabled}
required
label={"ram.recommendationForm.recommendationNumberInternal.label"}
/>
     <TextInputField
value={values?.description}
onChange={handleChange}
errors={errors}
name={"description"}
disabled={isDisabled}
required
multiline
rows={8}
label={"ram.recommendationForm.description.label"}
/>
<ComboBoxField
disabled={false}
errors={errors}
name="typeCode"
label={"ram.recommendationForm.typeCode.label"}
setFieldValue={setFieldValue}
options={typeCodeOptions || []}
defaultValue={values?.typeCode}
confidentiality={null}
required
/> */
}

const classes = mergeStyleSets({
  container: {
    marginTop: 100,
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  grid: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
    overfow: "auto",
  },
  fullWidth: {
    width: "100%",
  },
});
