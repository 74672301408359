import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  getUsersListInsurer,
  getAllInsurerUsersForClient,
  getAllInsurerUsersForLocation,
} from "../api/api";
import { RiskInsurerUser } from "../types/types";
import { LoadStatus, RootState } from "./store";
import { createAsyncRoutine } from "./util";
import { store } from "../store/store";

export type UsersInsurerState = {
  usersInsurer: EntityState<RiskInsurerUser>;
  usersInsurerLoadStatus: LoadStatus;
  shownUsersInsurerIds: RiskInsurerUser["userId"][];
  search: string;
  manageReportsDialog: {
    user: RiskInsurerUser | null;
    isOpen: boolean;
  };
};

export const usersInsurerAdapter = createEntityAdapter<RiskInsurerUser>({
  selectId: (u) => u.userId,
});

export const loadUsersInsurerRoutine = createAsyncRoutine<
  void,
  void,
  RiskInsurerUser[],
  any
>("usersinsurer/load");

export const {
  reducer: usersInsurerReducer,
  actions: { setShownUsersIds, setSearch, setManageReportsDialog },
} = createSlice({
  name: "usersinsurer",
  initialState: {
    usersInsurer: usersInsurerAdapter.getInitialState(),
    shownUsersInsurerIds: [],
    usersInsurerLoadStatus: "none",
    search: "",
    manageReportsDialog: {
      user: null,
      isOpen: false,
    },
  } as UsersInsurerState,
  reducers: {
    setShownUsersIds: (
      s,
      a: PayloadAction<UsersInsurerState["shownUsersInsurerIds"]>
    ) => {
      s.shownUsersInsurerIds = a.payload;
    },
    setSearch: (s, a: PayloadAction<UsersInsurerState["search"]>) => {
      s.search = a.payload;
    },
    setManageReportsDialog: (
      s,
      a: PayloadAction<UsersInsurerState["manageReportsDialog"]>
    ) => {
      s.manageReportsDialog = a.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUsersInsurerRoutine.loading, (s) => {
      s.usersInsurerLoadStatus = "loading";
    });
    builder.addCase(loadUsersInsurerRoutine.success, (s, a) => {
      s.usersInsurerLoadStatus = "success";
      usersInsurerAdapter.setAll(s.usersInsurer, a.payload);
      s.shownUsersInsurerIds = a.payload.map((u) => u.userId);
    });
    builder.addCase(loadUsersInsurerRoutine.error, (s) => {
      s.usersInsurerLoadStatus = "error";
    });
  },
});

export const {
  selectAll: selectAllUsers,
  selectEntities: selectEntitiesUsers,
} = usersInsurerAdapter.getSelectors<RootState>(
  (s) => s.usersinsurer.usersInsurer
);

export const selectShownUsers = createSelector(
  (s) => s.usersinsurer.shownUsersInsurerIds,
  selectEntitiesUsers,
  (ids: any, byId) => ids.map((id) => byId[id])
);

export function* loadUsersInsurerSaga() {
  yield takeEvery(loadUsersInsurerRoutine.trigger, function* () {
    try {
      yield put(loadUsersInsurerRoutine.loading());
      const clientsFilter = store.getState().filters.clientsFilter;
      const locationsFilter = store.getState().filters.locationsFilter;
      let res = null;
      if (locationsFilter.length > 0) {
        let locids = locationsFilter.join(",");
        res = yield call(getAllInsurerUsersForLocation, locids);
      } else if (clientsFilter.length > 0) {
        let clids = clientsFilter.join(",");
        res = yield call(getAllInsurerUsersForClient, clids);
      } else {
        res = yield call(getUsersListInsurer);
      }
      yield put(loadUsersInsurerRoutine.success(res.data));
      yield call(applyFilters);
    } catch (err) {
      yield put(loadUsersInsurerRoutine.error(err));
    }
  });
}

export function* applyFilters() {
  const search = yield select((s) => s.usersinsurer.search);
  const users = selectAllUsers(yield select());

  const shownUsersIds = users
    .filter((u) => {
      const isUserName = u.userName
        .toLowerCase()
        .includes(search.toLowerCase());
      const isEmail = u.userEmailAddress
        .toLowerCase()
        .includes(search.toLowerCase());
      return isUserName || isEmail;
    })
    .map((u) => u.userId);

  yield put(setShownUsersIds(shownUsersIds));
}

export function* searchSaga() {
  yield takeEvery(setSearch.type, function* () {
    yield call(applyFilters);
  });
}

export function* usersInsurerSaga() {
  yield fork(loadUsersInsurerSaga);
  yield fork(searchSaga);
}
