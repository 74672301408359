import { Column, ColumnOptions as GrecoColumnOptions } from "@greco/components";
import _ from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "../../store/hooks";
import {
  setColumnsRecommendations,
  setSelectedViewIdRecommendations,
} from "../../store/recommendations-page/recommendationsPage";
import {
  selectColumnsSelectedView,
  selectSelectedView,
} from "../../store/recommendations-page/selectors";
import {
  clearAllFilters,
  hideAllColumns,
  reorderColumn,
  selectAllColumnsRecommendationsColumnOptions,
  setColumns,
  setFilter,
  showAllColumns,
  toggleColumnVisibility,
} from "../../store/recommendationsColumnOptions";
import {
  createSettingActions,
  deleteSettingActions,
  selectColumnOptionsSettings,
  updateSettingActions,
} from "../../store/settings";
import { AppDispatch } from "../../store/store";
import { sortAlphabetically } from "../../utils/utils";

export const ColumnOptions = ({ closePanel }: { closePanel: () => void }) => {
  const tempColumns = useSelector(selectAllColumnsRecommendationsColumnOptions);
  const dispatch: AppDispatch = useDispatch();
  const selectedView = useSelector(selectSelectedView);
  const selectedViewColumns = useSelector(selectColumnsSelectedView);
  const taxonomy = useSelector((s) => s.taxonomy);
  const applicationTax = taxonomy.ApplicationCode.byCode["Application.RAM"];
  const columnOptionsTax =
    taxonomy.ApplicationSetting.byCode["AppSetting.ColumnOptions"];
  const columnOptionsSettings = useSelector(selectColumnOptionsSettings);
  const areSettingsLoading = useSelector((s) => s.settings.isLoading);
  const isLoadingCreate = useSelector((s) => s.settings.isLoadingCreate);
  const isLoadingUpdate = useSelector((s) => s.settings.isLoadingUpdate);
  const isLoadingDelete = useSelector((s) => s.settings.isLoadingDelete);
  const areSettingsLoaded = useSelector((s) => s.settings.isLoaded);
  const { t } = useTranslation();
  const userRole = useSelector((s) => s.userRole.userRole);

  const isViewLoading =
    areSettingsLoading || isLoadingCreate || isLoadingUpdate || isLoadingDelete;

  const transformedColumns = useMemo(() => {
    if (userRole === "insurer") {
      return tempColumns
        .filter(
          (col) =>
            col.key !== "dateOfLastResponse" &&
            col.key !== "commentOfLastResponse" &&
            col.key !== "numberOfResponses" &&
            col.key !== "dateOfLastAssessment" &&
            col.key !== "commentOfLastAssessment" &&
            col.key !== "numberOfAssessments" &&
            col.key !== "lastAction"
        )
        .map((c) => {
          return {
            ...c,
            label: t(c.labelKey),
          };
        });
    } else {
      return tempColumns
        .filter(
          (col) =>
            col.key !== "dateOfLastResponseInsurer" &&
            col.key !== "commentOfLastResponseInsurer" &&
            col.key !== "numberOfResponsesInsurer" &&
            col.key !== "dateOfLastAssessmentInsurer" &&
            col.key !== "commentOfLastAssessmentInsurer" &&
            col.key !== "numberOfAssessmentsInsurer" &&
            col.key !== "lastActionInsurer"
        )
        .map((c) => {
          return {
            ...c,
            label: t(c.labelKey),
          };
        });
    }
  }, [tempColumns, userRole]);

  const newTempColumns = useRef([]);

  useEffect(() => {
    newTempColumns.current = _.cloneDeep(tempColumns);
  }, [tempColumns]);

  const changeTempColumns = () => {
    newTempColumns.current.forEach((m) => {
      if (m.filter?.type === "number") {
        if (m.filter?.number1 !== null) {
          m.filter.number1 = parseFloat(
            m.filter.number1.toString().replaceAll(".", "").replaceAll(",", ".")
          ).toString();
        }
        if (m.filter?.number2 !== null) {
          m.filter.number2 = parseFloat(
            m.filter.number2.toString().replaceAll(".", "").replaceAll(",", ".")
          ).toString();
        }
      }
    });
  };

  return (
    <div>
      <GrecoColumnOptions
        t={t}
        columns={transformedColumns as Column[]}
        isReady={areSettingsLoaded}
        isViewLoading={isViewLoading}
        closePanel={closePanel}
        onChangeView={(id) => {
          dispatch(setSelectedViewIdRecommendations(id));
        }}
        onClickApply={() => {
          const newerTempColumns =
            userRole === "insurer"
              ? (newTempColumns.current as any).filter(
                  (col) =>
                    col.key !== "dateOfLastResponse" &&
                    col.key !== "commentOfLastResponse" &&
                    col.key !== "numberOfResponses" &&
                    col.key !== "dateOfLastAssessment" &&
                    col.key !== "commentOfLastAssessment" &&
                    col.key !== "numberOfAssessments" &&
                    col.key !== "lastAction"
                )
              : (newTempColumns.current as any).filter(
                  (col) =>
                    col.key !== "dateOfLastResponseInsurer" &&
                    col.key !== "commentOfLastResponseInsurer" &&
                    col.key !== "numberOfResponsesInsurer" &&
                    col.key !== "dateOfLastAssessmentInsurer" &&
                    col.key !== "commentOfLastAssessmentInsurer" &&
                    col.key !== "numberOfAssessmentsInsurer" &&
                    col.key !== "lastActionInsurer"
                );
          changeTempColumns();
          dispatch(setColumnsRecommendations(newerTempColumns));
          closePanel();
        }}
        onClickClearAllFilters={() => {
          dispatch(clearAllFilters());
        }}
        onClickDeleteView={() => {
          dispatch(deleteSettingActions.trigger(selectedView.userAppSettingId));
        }}
        onClickReset={() => {
          const selectedColumns =
            userRole === "insurer"
              ? (selectedViewColumns as any).filter(
                  (col) =>
                    col.key !== "dateOfLastResponse" &&
                    col.key !== "commentOfLastResponse" &&
                    col.key !== "numberOfResponses" &&
                    col.key !== "dateOfLastAssessment" &&
                    col.key !== "commentOfLastAssessment" &&
                    col.key !== "numberOfAssessments" &&
                    col.key !== "lastAction"
                )
              : (selectedViewColumns as any).filter(
                  (col) =>
                    col.key !== "dateOfLastResponseInsurer" &&
                    col.key !== "commentOfLastResponseInsurer" &&
                    col.key !== "numberOfResponsesInsurer" &&
                    col.key !== "dateOfLastAssessmentInsurer" &&
                    col.key !== "commentOfLastAssessmentInsurer" &&
                    col.key !== "numberOfAssessmentsInsurer" &&
                    col.key !== "lastActionInsurer"
                );
          dispatch(setColumns(selectedColumns));
        }}
        onClickSaveView={() => {
          dispatch(
            updateSettingActions.trigger({
              setting: {
                ...selectedView,
                userAppSettingValue: JSON.stringify(tempColumns),
              },
              onSuccess: () => {},
            })
          );
        }}
        onCreateView={(data) => {
          dispatch(
            createSettingActions.trigger({
              setting: {
                applicationCodeId: applicationTax.id,
                appSettingCodeId: columnOptionsTax.id,
                userAppSettingValue: JSON.stringify(tempColumns),
                ...data,
              },
              onSuccess: closePanel,
            })
          );
        }}
        onUpdateView={(data) => {
          dispatch(
            updateSettingActions.trigger({
              setting: {
                ...selectedView,
                ...data,
              },
              onSuccess: closePanel,
            })
          );
        }}
        onDeselectAll={() => {
          dispatch(hideAllColumns());
        }}
        onSelectAll={() => {
          dispatch(showAllColumns());
        }}
        onReorderColumn={(src, dest) => {
          dispatch(
            reorderColumn({
              index1: src,
              index2: dest,
            })
          );
        }}
        onToggleColumnVisibility={(columnKey) => {
          dispatch(toggleColumnVisibility(columnKey));
        }}
        selectedView={selectedView}
        setFilter={(columnKey, filter) => {
          dispatch(setFilter({ columnKey, filter }));
        }}
        taxonomy={{
          ...taxonomy.response,
          Country: sortAlphabetically([...taxonomy.response.Country], t),
        }}
        views={columnOptionsSettings}
      />
    </div>
  );
};
