import React from "react";
import { useTranslation } from "react-i18next";
import { ArcSpeedometer } from "../../../assets/icons/ArcSpeedometer";
import { ReactComponent as BigNeedle2 } from "../../../assets/icons/big-needle2.svg";
import { riskCostColors } from "../../../config/colors";
import { useSelector } from "../../../store/hooks";

export const CostEstimateCode = () => {
  const { t } = useTranslation();
  const recommendation = useSelector(
    (s) => s.recommendationPanel.recommendation
  );
  const taxonomy = useSelector((s) => s.taxonomy);

  let costDegrees = -72;
  let costText = t("greco.noData");
  let costColor = riskCostColors["RiskReductionCost.NO_INFO"];

  let costEstimateCode = recommendation?.costEstimateCode
    ? taxonomy.RiskReductionCost.byId[recommendation?.costEstimateCode]?.code
    : null;

  if (costEstimateCode) {
    costColor = riskCostColors[costEstimateCode];
  }

  switch (costEstimateCode) {
    case "RiskReductionCost.LOW":
      costDegrees = -23;
      costText = t("RiskReductionCost.LOW");
      break;
    case "RiskReductionCost.MODERATE":
      costDegrees = 23;
      costText = t("RiskReductionCost.MODERATE");
      break;
    case "RiskReductionCost.HIGH":
      costDegrees = 69;
      costText = t("RiskReductionCost.HIGH");
      break;
  }

  return (
    <div
      style={{
        marginTop: "20px",
        alignSelf: "flex-end",
      }}
    >
      <strong
        style={{
          marginBottom: "10px",
          textAlign: "center",
          display: "block",
          width: "150px",
        }}
      >
        {t("ram.costEstimate")}
      </strong>
      <div style={{ width: "150px", height: "75px" }}>
        <div style={{ height: "77px" }}>
          <ArcSpeedometer colors={Object.values(riskCostColors)} />
          <BigNeedle2
            style={{
              position: "relative",
              top: "-59px",
              left: "66px",
              transition: "all 300ms",
              transformOrigin: "8px 54px",
              transform: `rotate(${costDegrees}deg)`,
            }}
          />
        </div>
        <div
          style={{
            textAlign: "center",
            fontWeight: 600,
            paddingTop: "10px",
            color: costColor, // Assuming costColor is a variable
          }}
        >
          {costText}
        </div>
      </div>
    </div>
  );
};
