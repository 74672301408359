import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "styled-components";
import * as yup from "yup";

import { mergeStyleSets } from "@fluentui/react";
import { Formik } from "formik";
import { toast } from "../../components/FluentToast";
import { routes, useCurrentRoute } from "../../config/routes";
import { useSelector } from "../../store/hooks";
import {
  createReportWithInsurerUsers,
  createReportWithoutInsurerUsers,
  loadReportWithInsurerUsers,
  reset,
  updateReportWithInsurerUsers,
  updateReportWithoutInsurerUsers,
} from "../../store/reportPage";
import { AppDispatch } from "../../store/store";
import { IStyledTheme } from "../../theme/types";
import useGetTaxonomyOptions from "../../utils/hooks/useGetTaxonomyOptions";
import { formBase64String } from "../../utils/utils";
import ReportForm from "./ReportForm";
import ReportHistoryTable from "./ReportHistoryTable";
import ReportSkeleton from "./ReportSkeleton/ReportSkeleton";
import { Navigation } from "./navigation/Navigation";
import { setYupLocale } from "../../utils/setYupLocale";
import moment from "moment";
import { adjustFloatNumberValue, transformNumberValue } from "../../form/util";

export type ReportSaveMode = "normal" | "addRecommendation";

export const Report = () => {
  const { t } = useTranslation();
  const route = useCurrentRoute();
  const report = useSelector((s) => s.reportPage.report);
  const isDisabled = report?.isReportDeactivated === true;
  const [initialValues, setInitialValues] = useState({});
  const reportHistory = useSelector((s) => s.reportPage.reportHistory);
  const isEdit = route === "editReport";
  const isReportLoading = useSelector((s) => s.reportPage.isReportLoading);
  const isSaveLoading = useSelector((s) => s.reportPage.isSaveLoading);
  const dispatch: AppDispatch = useDispatch();
  const riskInsurerUsers = useSelector((s) => s.reportPage.riskInsurerUsers);
  const didTrySubmit = useSelector((s) => s.reportPage.didTrySubmit);

  const userRole = useSelector((s) => s.userRole.userRole);

  const theme = useTheme() as IStyledTheme;

  const classes = getClassNames(theme);

  const createdByOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskReportCreator",
  });

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({
      reportName: yup.string().trim().required().max(64).nullable(),
      surveyDate: yup.date().required().nullable(),
      reportCreatorCode: yup.mixed().required(),
    });
  }, [t]);

  useEffect(() => {
    if (!isEdit) return;
    dispatch(loadReportWithInsurerUsers());
  }, [isEdit]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (isEdit && report) {
      setInitialValues({
        reportName: report?.reportName,
        file: null,
        surveyDate: new Date(report?.surveyDate),
        insurersRaiting: report?.insurersRaiting,
        pmlinMioEur: adjustFloatNumberValue(report?.pmlinMioEur),
        emlinMioEur: adjustFloatNumberValue(report?.emlinMioEur),

        reportDocumentPath: report?.reportDocumentPath,
        reportCreatorCode: createdByOptions.find(
          (option) => option.value === report?.reportCreatorCode
        ),
        riskInsurerUsers: riskInsurerUsers.map((user) => {
          return {
            label: user.userName,
            value: user.userId,
            userName: user.userName,
            userEmailAddress: user.userEmailAddress,
          };
        }),
      });
      return;
    }
    setInitialValues({
      reportName: null,
      reportDocumentContent: null,
      reportDocumentMimeType: null,
      reportDocumentPath: null,
      file: null,
      surveyDate: null,
      reportCreatorCode: null,
      riskInsurerUsers: null,
      insurersRaiting: null,
      pmlinMioEur: null,
      emlinMioEur: null,
    });
  }, [isEdit, report, riskInsurerUsers, createdByOptions, isSaveLoading]);

  const handleSubmitForm = useCallback(
    async (values) => {
      const mode = values?.mode;
      const fileSize: number = values?.file?.size;

      let reportDocumentContent;
      let reportDocumentMimeType;
      values.surveyDate = moment(values?.surveyDate).format("YYYY-MM-DD");

      values.emlinMioEur = transformNumberValue(values?.emlinMioEur);
      values.pmlinMioEur = transformNumberValue(values?.pmlinMioEur);

      values.riskInsurerUsers = values?.riskInsurerUsers || [];

      if (fileSize && fileSize > 50000000) {
        toast.error(t("ram.upload.overLimit"));
        return;
      }

      if (values?.file) {
        reportDocumentContent = await formBase64String(values?.file);
        reportDocumentMimeType = values?.file.type;
      }

      values.reportCreatorCode = isNaN(values.reportCreatorCode)
        ? values.reportCreatorCode?.value
        : values.reportCreatorCode;

      const riskInsurerUsersPayload = values?.riskInsurerUsers?.map((user) => {
        return {
          userEmailAddress: user.userEmailAddress,
          userId: user.value,
          userName: user.userName,
          isInternal: user.user?.userEmailAddress.endsWith("@greco.services"),
        };
      });

      if (isEdit) {
        if (userRole === "admin") {
          dispatch(
            updateReportWithInsurerUsers({
              reportWithInsurerUsers: {
                report: {
                  ...values,
                  reportDocumentContent,
                  reportDocumentMimeType,
                  rowVersion: report.rowVersion,
                },
                reportHistory: reportHistory,
                riskInsurerUsers: riskInsurerUsersPayload,
              },
              mode,
            })
          );
        } else {
          dispatch(
            updateReportWithoutInsurerUsers({
              report: {
                ...values,
                reportDocumentContent,
                reportDocumentMimeType,
                rowVersion: report.rowVersion,
              },
              mode,
            })
          );
        }
      } else {
        if (userRole === "admin") {
          dispatch(
            createReportWithInsurerUsers({
              reportWithInsurerUsers: {
                report: {
                  ...values,
                  reportDocumentContent,
                  reportDocumentMimeType,
                },
                reportHistory: reportHistory,
                riskInsurerUsers: riskInsurerUsersPayload,
              },
              mode,
            })
          );
        } else {
          dispatch(
            createReportWithoutInsurerUsers({
              report: {
                ...values,
                reportDocumentContent,
                reportDocumentMimeType,
              },
              mode,
            })
          );
        }
      }
    },
    [dispatch, isEdit, report, reportHistory, t, userRole]
  );

  const isLoading = isReportLoading || isSaveLoading;

  return (
    <Formik
      enableReinitialize
      validateOnChange={didTrySubmit}
      validateOnBlur={didTrySubmit}
      validateOnMount={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, props) => {
        props.setSubmitting(false);
        handleSubmitForm(values);
        props.resetForm();
      }}
    >
      {({ handleSubmit, values }) => {
        return isLoading ? (
          <ReportSkeleton />
        ) : (
          <>
            <Navigation />

            <div className={classes.main}>
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.wrap}>
                  <ReportForm />
                  <ReportHistoryTable />
                </div>
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    main: {
      maxWidth: "100%",
      display: "flex",
      margin: "0 auto",
      flexDirection: "column",
      height: "100vh",
      position: "relative",
      paddingTop: 0,
      overflow: "auto", //
    },
    form: {
      marginBottom: 200,
    },
    wrap: {
      background: theme.palette.white,
      boxShadow:
        "0 3.2px 7.2px 0 rgba(0,0,0,.132), 0 0.6px 1.8px 0 rgba(0,0,0,.108)",
      display: "flex",
      margin: "50px auto 100px",
      borderRadius: "4px",
      minWidth: 1200,
      maxWidth: 1200,
      position: "relative",
      flexDirection: "column",
      padding: "20px 20px 250px",
      gap: 50,
    },
    navigationWrap: {
      position: "sticky",
      top: 0,
      zIndex: 2,
    },
  });
