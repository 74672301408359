import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import { useCurrentRoute } from "../../config/routes";
import { useSelector } from "../../store/hooks";
import {
  loadDefaultValuesActions,
  loadRec,
  loadResponsesAndEvaluationsRoutine,
  reset,
  setTab,
} from "../../store/recommendationPage";

import { mergeStyleSets } from "@fluentui/react";
import { Form, Formik } from "formik";
import { useTheme } from "styled-components";
import NavigationSkeleton from "../../components/NavigationSkeleton/NavigationSkeleton";
import { AppDispatch } from "../../store/store";
import { IStyledTheme } from "../../theme/types";
import { MainNav } from "./components/MainNav/MainNav";
import RecomendationSkeleton from "./components/RecomendationSkeleton";
import { RecommendationFormContent } from "./components/RecommendationFormContent/RecommendationFormContent";
import ResponsesFormContent from "./components/ResponsesFormContent/ResponsesFormContent";
import SideNav from "./components/SideNav/SideNav";
import { mainNavigationTypes } from "./constants";
import useAssesmentFormInitialValues from "./hooks/useAssesmentFormInitialValues";
import useAssesmentFormValidationSchema from "./hooks/useAssesmentFormValidationSchema";
import useEvaluationFormInitialValues from "./hooks/useEvaluationFormInitialValues";
import useEvaluationFormValidationSchema from "./hooks/useEvaluationFormValidationSchema";
import useRecomendationFormInitialValues from "./hooks/useRecomendationFormInitialValues";
import useRecomendationFormValidationSchema from "./hooks/useRecomendationFormValidationSchema";
import useResponseFormInitialValues from "./hooks/useResponseFormInitialValues";
import useResponseValidationSchema from "./hooks/useResponseValidationSchema";
import useSubmitAssesmentForm from "./hooks/useSubmitAssesmentForm";
import useSubmitEvaluationForm from "./hooks/useSubmitEvaluationForm";
import useSubmitRecommendationForm from "./hooks/useSubmitRecommendationForm";
import useSubmitResponseForm from "./hooks/useSubmitResponseForm";
import { Navigation } from "./navigation/Navigation";
export type ServerErrorState = string | null;

const defaultSchema = yup.object().shape({});

export const Recommendation = () => {
  const tab = useSelector((s) => s.recommendationPage.tab);
  const route = useCurrentRoute();
  const dispatch: AppDispatch = useDispatch();
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const [pinnedMainHeader, setPinnedMainHeader] = useState(false);
  const { locationId, reportId, recommendationId } = useParams() as any;
  const userRole = useSelector((s) => s.userRole.userRole);
  const recommendation = useSelector((s) => s.recommendationPage.rec);
  const recSaveInProgress = useSelector(
    (s) => s.recommendationPage.isSaveLoading
  );
  const assessSaveInProgress =
    useSelector((s) => s.recommendationPage.assessRecommendationLoadStatus) ===
    "loading";

  const saveInProgress = recSaveInProgress || assessSaveInProgress;
  const location = useLocation();
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState(defaultSchema);

  const isEditRecommendation = route === "editRecommendation";

  const onSubmitRecommendationForm = useSubmitRecommendationForm();
  const onSubmitAssesmentForm = useSubmitAssesmentForm();
  const onSubmitEvaluationForm = useSubmitEvaluationForm();
  const onSubmitResponseForm = useSubmitResponseForm();

  const recommendationInitialValues = useRecomendationFormInitialValues();
  const assessmentFormInitialValues = useAssesmentFormInitialValues();
  const responseFormInitialValues = useResponseFormInitialValues();
  const evaluationFormInitialValues = useEvaluationFormInitialValues();

  const recommendationValidationSchema = useRecomendationFormValidationSchema();
  const assessmentFormValidationSchema = useAssesmentFormValidationSchema();
  const responseFormValidationSchema = useResponseValidationSchema();
  const evaluationFormValidationSchema = useEvaluationFormValidationSchema();

  const theme = useTheme() as IStyledTheme;
  const classes = getClassNames(theme);

  const addResponseDialogIsOpen = useSelector(
    (s) => s.recommendationPage.addResponseDialog.isOpen
  );
  const addAssessmentDialogIsOpen = useSelector(
    (s) => s.recommendationPage.addAssessmentDialog.isOpen
  );
  const addEvaluationDialogIsOpen = useSelector(
    (s) => s.recommendationPage.addEvaluationDialog.isOpen
  );

  const handleDidTrySubmit = useCallback(() => {
    setDidTrySubmit(true);
  }, []);

  const handleSubmitForm = useCallback(
    async (values) => {
      if (tab === mainNavigationTypes.Recommendation) {
        onSubmitRecommendationForm(values);
        return;
      }
      if (tab === mainNavigationTypes.Responses && addAssessmentDialogIsOpen) {
        onSubmitAssesmentForm(values);
        return;
      }
      if (tab === mainNavigationTypes.Responses && addEvaluationDialogIsOpen) {
        onSubmitEvaluationForm(values);

        return;
      }
      if (tab === mainNavigationTypes.Responses && addResponseDialogIsOpen) {
        onSubmitResponseForm(values);
        return;
      }
    },
    [
      tab,
      addEvaluationDialogIsOpen,
      addResponseDialogIsOpen,
      addAssessmentDialogIsOpen,
      onSubmitRecommendationForm,
      onSubmitAssesmentForm,
      onSubmitEvaluationForm,
      onSubmitResponseForm,
    ]
  );

  useEffect(() => {
    if (tab === mainNavigationTypes.Recommendation) {
      setInitialValues(recommendationInitialValues);
      setValidationSchema(recommendationValidationSchema);
      return;
    }

    if (tab === mainNavigationTypes.Responses && addResponseDialogIsOpen) {
      setInitialValues(responseFormInitialValues);
      setValidationSchema(responseFormValidationSchema);
      return;
    }
    if (tab === mainNavigationTypes.Responses && addAssessmentDialogIsOpen) {
      setInitialValues(assessmentFormInitialValues);
      setValidationSchema(assessmentFormValidationSchema);
      return;
    }
    if (tab === mainNavigationTypes.Responses && addEvaluationDialogIsOpen) {
      setInitialValues(evaluationFormInitialValues);
      setValidationSchema(evaluationFormValidationSchema);
      return;
    }
    setValidationSchema(defaultSchema);
    setInitialValues({});
  }, [
    tab,
    addEvaluationDialogIsOpen,
    addResponseDialogIsOpen,
    addAssessmentDialogIsOpen,
    recommendation,
  ]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const tab = urlSearchParams.get("tab");

    if (route === "editRecommendation") {
      if (userRole === "external" || userRole === "insurer" || !tab) {
        dispatch(setTab(mainNavigationTypes.Responses));
        return;
      }
      if (tab === "responses") {
        dispatch(setTab(mainNavigationTypes.Responses));
      } else {
        dispatch(setTab(mainNavigationTypes.Recommendation));
      }

      return;
    } else {
      if (route === "addRecommendation") {
        dispatch(setTab(mainNavigationTypes.Recommendation));
      } else {
        dispatch(setTab(mainNavigationTypes.Responses));
      }
    }
  }, [dispatch, route, userRole, tab]);

  useEffect(() => {
    if (route === "editRecommendation") {
      dispatch(
        loadResponsesAndEvaluationsRoutine.trigger({
          locationId,
          reportId,
          recommendationId,
        })
      );
    }
  }, [tab, route, locationId, reportId, recommendationId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [recommendationId]);

  useEffect(() => {
    if (route === "editRecommendation") {
      dispatch(loadRec());
    }
  }, [route, recommendationId]);

  useEffect(() => {
    dispatch(loadDefaultValuesActions.trigger(locationId));
  }, [locationId]);

  const mainNavRef = useRef<null>();

  useEffect(() => {
    const handleStickyHeaderPinnedStatus = (event) => {
      if (mainNavRef) {
        if (event.target.scrollTop > 95 && !pinnedMainHeader) {
          setPinnedMainHeader(true);
        }
        if (event.target.scrollTop < 95) {
          setPinnedMainHeader(false);
        }
      }
    };
    window.addEventListener("scroll", handleStickyHeaderPinnedStatus, true);

    return () => {
      window.removeEventListener(
        "scroll",
        handleStickyHeaderPinnedStatus,
        true
      );
    };
  }, []);

  return (
    <div id="recomendation-form-wrap" className={classes.container}>
      <Formik
        enableReinitialize
        validateOnBlur={didTrySubmit}
        validateOnChange={didTrySubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, props) => {
          props.setSubmitting(false);
          handleSubmitForm(values);
          // props.resetForm(values);
        }}
      >
        <Form>
          {isEditRecommendation && !recommendation ? (
            <>
              <NavigationSkeleton />
              <RecomendationSkeleton withMargin />
            </>
          ) : (
            <>
              <Navigation setDidTrySubmit={handleDidTrySubmit} />
              <div className={classes.main} id="recomendation-fields-wrapper">
                <div
                  className={`${classes.mainNavWrap} ${
                    pinnedMainHeader ? classes.pinned : classes.transparentBg
                  }`}
                  id="mainNav"
                  ref={mainNavRef}
                >
                  <MainNav />
                </div>
                {saveInProgress ? (
                  <RecomendationSkeleton />
                ) : (
                  <div className={classes.content}>
                    <SideNav />
                    <div className={classes.fullWidth}>
                      {tab === mainNavigationTypes.Recommendation && (
                        <RecommendationFormContent />
                      )}
                      {/* {tab === mainNavigationTypes.Assessment && (
                      <AssesmentFormContent />
                    )} */}
                      {tab === mainNavigationTypes.Responses && (
                        <ResponsesFormContent />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </Form>
      </Formik>
    </div>
  );
};

export type RecommendationFormState = {
  [key: string]: any;
};

const getClassNames = (theme) =>
  mergeStyleSets({
    container: {
      overflow: "auto", //
      height: "90vh",
    },
    fullWidth: {
      position: "relative",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      overflow: "auto",
      flexWrap: "wrap",
      padding: 20,
      paddingBottom: 150,
      borderLeft: `2px solid ${theme.palette.neutralLighterAlt}`,
    },
    content: {
      background: theme.palette.white,
      // borderTop: `5px solid ${status}`,
      boxShadow:
        "0 3.2px 7.2px 0 rgba(0,0,0,.132), 0 0.6px 1.8px 0 rgba(0,0,0,.108)",
      display: "flex",
      margin: "0 auto ",
      borderRadius: "4px",
      minWidth: 1200,
      maxWidth: 1200,
      position: "relative",
      marginBottom: "150px",
    },
    mainNavWrap: {
      minWidth: 1200,
      maxWidth: 1200,
      display: "flex",
      margin: "50px auto 10px",
      borderRadius: "4px",
    },
    transparentBg: {
      background: "transparent",
    },

    pinned: {
      background: theme.palette.white,
      borderBottom: `2px solid ${theme.palette.neutralLighterAlt}`,
      boxShadow: `0 10px 10px -15px ${theme.palette.black}`,
      position: "sticky",
      top: 44,
      zIndex: 55,
    },
    main: {
      position: "relative",
      paddingTop: 0,
      flex: 1,
    },
  });
