import { ColumnOptionsButton, SearchResultsCount } from "@greco/components";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ClearFilters } from "../../../components/filters/ClearFilters";
import { ClientsFilter } from "../../../components/filters/ClientsFilter";
import { Filters } from "../../../components/filters/Filters";
import { LocationsFilter } from "../../../components/filters/LocationsFilter";
import { ReportsFilter } from "../../../components/filters/ReportsFilter";
import { NavButtons } from "../../../components/navigation/NavButtons";
import { Flex, HSpace, NavigationBar, Right } from "../../../components/styled";
import {
  setClientsFilter,
  setLocationsFilter,
  setReportsFilter,
} from "../../../store/filters/filters";
import { useSelector } from "../../../store/hooks";
import { selectIsAnyFiltered } from "../../../store/recommendations-page/selectors";
import { AppDispatch } from "../../../store/store";
import { setManageLocationsDialog } from "../../../store/users";
import { setManageReportsDialog } from "../../../store/usersinsurer";
import { ColumnOptions } from "../ColumnOptions";
import { ExportExcelButton } from "./ExportExcelButton";
import { ExportPDFButton } from "./ExportPDFButton";
import { RefreshButton } from "./RefreshButton";
import { SearchRecommendations } from "./SearchRecommendations";

type Props = {
  refExcelExport: ExcelExport;
};

export const Navigation = ({ refExcelExport }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const clientsFilter = useSelector((s) => s.filters.clientsFilter);
  const locationsFilter = useSelector((s) => s.filters.locationsFilter);
  const reportsFilter = useSelector((s) => s.filters.reportsFilter);
  const count = useSelector((s) => s.recommendations.count);
  const { t } = useTranslation();
  const [isColumnOptionsOpen, setIsColumnOptionsOpen] = useState(false);
  const isAnyColumnFiltered = useSelector(selectIsAnyFiltered as any);

  const canClear = [clientsFilter, locationsFilter, reportsFilter].some(
    (f) => f.length > 0
  );

  return (
    <NavigationBar>
      <NavButtons />
      <SearchRecommendations />
      <HSpace />
      <RefreshButton />
      <Filters>
        <ClientsFilter />
        <LocationsFilter />
        <ReportsFilter />
        <ClearFilters
          isDisabled={!canClear}
          onClear={() => {
            dispatch(setClientsFilter([]));
            dispatch(setLocationsFilter([]));
            dispatch(setReportsFilter([]));
            dispatch(
              setManageLocationsDialog({
                user: null,
                isOpen: false,
              })
            );
            dispatch(
              setManageReportsDialog({
                user: null,
                isOpen: false,
              })
            );
          }}
        />
      </Filters>
      <ExportPDFButton iconBreakpoint={1500} />
      <ExportExcelButton refExcelExport={refExcelExport} />
      <Right>
        <Flex>
          <ColumnOptionsButton
            isActive={!!isAnyColumnFiltered}
            onClick={() => {
              setIsColumnOptionsOpen(true);
            }}
            t={t}
          />
          {isColumnOptionsOpen && (
            <ColumnOptions closePanel={() => setIsColumnOptionsOpen(false)} />
          )}
          <SearchResultsCount
            count={count || 0}
            label={t("greco.searchResults")}
          />
          <HSpace width={10} />
        </Flex>
      </Right>
    </NavigationBar>
  );
};
