import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Report,
  ReportWithInsurerUsers,
  RiskInsurerUser,
} from "../types/types";
import { RootState } from "./store";
import * as API from "../api/api";
import { routes } from "../config/routes";
import { ReportSaveMode } from "../pages/report/Report";
import { toast } from "../components/FluentToast";
import i18next from "i18next";
import { addReport } from "./reports/reports";
import { handleAxiosError } from "./util";
import { history } from "utils/_helpers";

import { push, replace, goBack } from "redux-first-history";

export type ReportPageState = {
  report: Report | null;
  reportHistory: Report[] | null;
  riskInsurerUsers: RiskInsurerUser[];
  isReportLoading: boolean;
  isSaveLoading: boolean;
  didTrySubmit: boolean;
};

export const loadReportWithInsurerUsers = createAsyncThunk<
  ReportWithInsurerUsers,
  void,
  { state: RootState }
>("reportPage/loadReportWithInsurerUsers", async (_, thunkAPI) => {
  const loc = thunkAPI.getState().router.location;
  const reportMatchPath = routes.editReport.matchPath(loc);

  const { locationId, reportId } = reportMatchPath?.params;

  let res;
  try {
    res = await API.getReport(locationId, reportId);
  } catch (err) {
    if (err.response.status === 403 || err.response.status === 404) {
      thunkAPI.dispatch(replace("/clients"));
    }
  }

  return res.data;
});

export const createReportWithInsurerUsers = createAsyncThunk<
  ReportWithInsurerUsers,
  {
    reportWithInsurerUsers: ReportWithInsurerUsers;
    mode: ReportSaveMode;
  },
  { state: RootState }
>(
  "reportPage/createReportWithInsurerUsers",
  async (
    { reportWithInsurerUsers, mode },
    { getState, dispatch, rejectWithValue }
  ) => {
    const loc = getState().router.location;
    const reportMatchPath = routes.addReport.matchPath(loc);

    const { clientId, locationId } = reportMatchPath?.params;
    try {
      const res = await API.createReportWithInsurerUsers(
        locationId,
        reportWithInsurerUsers
      );
      toast.success(i18next.t("ram.reportForm.addReportSuccess"));
      const createdReport = res.data.report;
      dispatch(addReport(createdReport));
      if (mode === "addRecommendation") {
        dispatch(
          push(
            routes.addRecommendation.getPath(
              clientId,
              locationId,
              createdReport.reportId
            )
          )
        );
      }
      //
      else {
        dispatch(push(routes.reports.getPath()));
      }
      return res.data;
    } catch (err) {
      handleAxiosError(err);
      return rejectWithValue(err);
    }
  }
);

export const createReportWithoutInsurerUsers = createAsyncThunk<
  Report,
  {
    report: Report;
    mode: ReportSaveMode;
  },
  { state: RootState }
>(
  "reportPage/createReportWithoutInsurerUsers",
  async ({ report, mode }, { getState, dispatch, rejectWithValue }) => {
    const loc = getState().router.location;
    const reportMatchPath = routes.addReport.matchPath(loc);

    const { clientId, locationId } = reportMatchPath?.params;
    try {
      const res = await API.createReportWithoutInsurerUsers(locationId, report);
      toast.success(i18next.t("ram.reportForm.addReportSuccess"));
      const createdReport = res.data;
      dispatch(addReport(createdReport));
      if (mode === "addRecommendation") {
        dispatch(
          push(
            routes.addRecommendation.getPath(
              clientId,
              locationId,
              createdReport.reportId
            )
          )
        );
      } else {
        dispatch(push(routes.reports.getPath()));
      }
      return res.data;
    } catch (err) {
      handleAxiosError(err);
      return rejectWithValue(err);
    }
  }
);

export const updateReportWithInsurerUsers = createAsyncThunk<
  ReportWithInsurerUsers,
  {
    reportWithInsurerUsers: ReportWithInsurerUsers;
    mode: ReportSaveMode;
  },
  { state: RootState }
>(
  "reportPage/updateReportWithInsurerUsers",
  async (
    { reportWithInsurerUsers, mode },
    { getState, dispatch, rejectWithValue }
  ) => {
    const loc = getState().router.location;
    const editReportMatchPath = routes.editReport.matchPath(loc);

    const { clientId, locationId, reportId } = editReportMatchPath?.params;
    try {
      const res = await API.updateReportWithInsurerUsers(
        locationId,
        reportId,
        reportWithInsurerUsers
      );
      toast.success(i18next.t("ram.reportForm.editReportSuccess"));
      if (mode === "addRecommendation") {
        dispatch(
          push(routes.addRecommendation.getPath(clientId, locationId, reportId))
        );
      }
      return res.data;
    } catch (err) {
      handleAxiosError(err);
      return rejectWithValue(err);
    }
  }
);

export const updateReportWithoutInsurerUsers = createAsyncThunk<
  Report,
  {
    report: Report;
    mode: ReportSaveMode;
  },
  { state: RootState }
>(
  "reportPage/updateReportWithoutInsurerUsers",
  async ({ report, mode }, { getState, dispatch, rejectWithValue }) => {
    const loc = getState().router.location;
    const editReportMatchPath = routes.editReport.matchPath(loc);

    const { clientId, locationId, reportId } = editReportMatchPath?.params;
    try {
      const res = await API.updateReportWithoutInsurerUsers(
        locationId,
        reportId,
        report
      );
      toast.success(i18next.t("ram.reportForm.editReportSuccess"));
      if (mode === "addRecommendation") {
        dispatch(
          push(routes.addRecommendation.getPath(clientId, locationId, reportId))
        );
      }
      return res.data;
    } catch (err) {
      handleAxiosError(err);
      return rejectWithValue(err);
    }
  }
);

const initialState: ReportPageState = {
  report: null,
  reportHistory: null,
  riskInsurerUsers: [],
  isReportLoading: false,
  isSaveLoading: false,
  didTrySubmit: false,
};

export const reportPageSlice = createSlice({
  name: "reportPage",
  initialState,
  reducers: {
    setDidTrySubmit: (s, a: PayloadAction<ReportPageState["didTrySubmit"]>) => {
      s.didTrySubmit = a.payload;
    },
    reset: (s) => {
      Object.assign(s, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadReportWithInsurerUsers.pending, (s, a) => {
      s.isReportLoading = true;
    });
    builder.addCase(loadReportWithInsurerUsers.fulfilled, (s, a) => {
      s.isReportLoading = false;
      s.report = a.payload.report.isDeleted === false ? a.payload.report : null;
      s.reportHistory =
        a.payload.report.isDeleted === false ? a.payload.reportHistory : null;
      s.riskInsurerUsers =
        a.payload.report.isDeleted === false
          ? a.payload.riskInsurerUsers
          : null;
    });
    builder.addCase(loadReportWithInsurerUsers.rejected, (s, a) => {
      s.isReportLoading = false;
    });
    builder.addCase(createReportWithInsurerUsers.pending, (s, a) => {
      s.isSaveLoading = true;
    });
    builder.addCase(createReportWithInsurerUsers.fulfilled, (s, a) => {
      s.isSaveLoading = false;
      s.report = null; // a.payload.report;
      s.riskInsurerUsers = []; //a.payload.riskInsurerUsers;
    });
    builder.addCase(createReportWithInsurerUsers.rejected, (s, a) => {
      s.isSaveLoading = false;
    });
    builder.addCase(updateReportWithInsurerUsers.pending, (s, a) => {
      s.isSaveLoading = true;
    });
    builder.addCase(updateReportWithInsurerUsers.fulfilled, (s, a) => {
      s.isSaveLoading = false;
      s.report = a.payload.report;
      s.riskInsurerUsers = a.payload.riskInsurerUsers;
    });
    builder.addCase(updateReportWithInsurerUsers.rejected, (s, a) => {
      s.isSaveLoading = false;
    });

    builder.addCase(createReportWithoutInsurerUsers.pending, (s, a) => {
      s.isSaveLoading = true;
    });
    builder.addCase(createReportWithoutInsurerUsers.fulfilled, (s, a) => {
      s.isSaveLoading = false;
      s.report = null; // a.payload.report;
    });
    builder.addCase(createReportWithoutInsurerUsers.rejected, (s, a) => {
      s.isSaveLoading = false;
    });
    builder.addCase(updateReportWithoutInsurerUsers.pending, (s, a) => {
      s.isSaveLoading = true;
    });
    builder.addCase(updateReportWithoutInsurerUsers.fulfilled, (s, a) => {
      s.isSaveLoading = false;
      s.report = a.payload;
    });
    builder.addCase(updateReportWithoutInsurerUsers.rejected, (s, a) => {
      s.isSaveLoading = false;
    });
  },
});

export const reportPageReducer = reportPageSlice.reducer;

export const { setDidTrySubmit: setDidTrySubmitReport, reset } =
  reportPageSlice.actions;
