import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  Taxonomy,
  TaxonomyResponse,
  TaxonomyUADResponse,
} from "../../types/types";
import { RootState } from "../store";
import { createAsyncActions, handleAxiosError } from "../util";

export type TaxonomyState = {
  Country: TaxonomyGroupState;
  CostCenter: TaxonomyGroupState;
  SicCode: TaxonomyGroupState;
  RiskRecommendationStatus: TaxonomyGroupState;
  RiskImplementationStatus: TaxonomyGroupState;
  RiskPriority: TaxonomyGroupState;
  RiskCategoryOfMeasure: TaxonomyGroupState;
  RiskImprovementPotential: TaxonomyGroupState;
  RiskRating: TaxonomyGroupState;
  RiskAssessmentCategory: TaxonomyGroupState;
  RiskAssessmentSubcategory: TaxonomyGroupState;
  RiskReductionCost: TaxonomyGroupState;
  RiskReportCreator: TaxonomyGroupState;
  ApplicationCode: TaxonomyGroupState;
  ApplicationSetting: TaxonomyGroupState;
  Gender: TaxonomyGroupState;
  SupportedLanguage: TaxonomyGroupState;
  LanguageListType: TaxonomyGroupState;
  LastAction: TaxonomyGroupState;
  response: TaxonomyResponse;
  isLoading: boolean;
  isLoadingUAD: boolean;
};

export const loadTaxonomyActions = createAsyncActions<
  void,
  void,
  TaxonomyResponse,
  any
>("taxonomy/load");

export const loadTaxonomyUADActions = createAsyncActions<
  void,
  void,
  TaxonomyUADResponse
>("taxonomy/loadUAD");

const initialTaxonomyGroupState: TaxonomyGroupState = {
  byId: {},
  allIds: [],
  byCode: {},
  items: [],
};

export const taxonomySlice = createSlice({
  name: "taxonomy",
  initialState: {
    Country: initialTaxonomyGroupState,
    CostCenter: initialTaxonomyGroupState,
    RiskAssessmentCategory: initialTaxonomyGroupState,
    RiskAssessmentSubcategory: initialTaxonomyGroupState,
    RiskCategoryOfMeasure: initialTaxonomyGroupState,
    RiskImplementationStatus: initialTaxonomyGroupState,
    RiskImprovementPotential: initialTaxonomyGroupState,
    RiskPriority: initialTaxonomyGroupState,
    RiskRating: initialTaxonomyGroupState,
    RiskRecommendationStatus: initialTaxonomyGroupState,
    RiskReductionCost: initialTaxonomyGroupState,
    RiskReportCreator: initialTaxonomyGroupState,
    SicCode: initialTaxonomyGroupState,
    ApplicationCode: initialTaxonomyGroupState,
    ApplicationSetting: initialTaxonomyGroupState,
    Gender: initialTaxonomyGroupState,
    LanguageListType: initialTaxonomyGroupState,
    SupportedLanguage: initialTaxonomyGroupState,
    LastAction: initialTaxonomyGroupState,
    isLoading: false,
  } as TaxonomyState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadTaxonomyActions.loading, (s, a) => {
      s.isLoading = true;
    });
    builder.addCase(loadTaxonomyActions.success, (s, a) => {
      createTaxonomyGroupsState(a.payload, s);
      s.response = a.payload;
      s.isLoading = false;
    });
    builder.addCase(loadTaxonomyActions.error, (s, a) => {
      s.isLoading = false;
    });
    builder.addCase(loadTaxonomyUADActions.loading, (s, a) => {
      s.isLoadingUAD = true;
    });
    builder.addCase(loadTaxonomyUADActions.success, (s, a) => {
      createTaxonomyGroupsState(a.payload, s);
      s.isLoadingUAD = false;
    });
    builder.addCase(loadTaxonomyUADActions.error, (s, a) => {
      s.isLoadingUAD = false;
    });
  },
});

const createTaxonomyGroupsState = (
  res: { [key: string]: any },
  state: TaxonomyState
) => {
  Object.entries(res).forEach(([key, taxonomyGroup]) => {
    const taxonomyStateByTaxonomy = {
      byId: taxonomyGroup.reduce((byId: any, t: any) => {
        byId[t.id] = t;
        return byId;
      }, {} as ByIdStateByTaxonomy),
      byCode: taxonomyGroup.reduce((byCode: any, t: any) => {
        byCode[t.code] = t;
        return byCode;
      }, {} as ByCodeStateByTaxonomy),
      items: taxonomyGroup,
    };
    state[key] = taxonomyStateByTaxonomy;
  });
};

export const taxonomyReducer = taxonomySlice.reducer;

type TaxonomyGroupState = {
  byId: ByIdStateByTaxonomy;
  allIds: AllIdsStateByTaxonomy;
  byCode: ByCodeStateByTaxonomy;
  items: Taxonomy[];
};

type ByIdStateByTaxonomy = { [id: number]: Taxonomy };
type AllIdsStateByTaxonomy = Taxonomy["id"][];
type ByCodeStateByTaxonomy = { [code: string]: Taxonomy };

export type TaxonomyKey =
  | "Country"
  | "SicCode"
  | "RiskRecommendationStatus"
  | "RiskImplementationStatus"
  | "RiskPriority"
  | "RiskCategoryOfMeasure"
  | "RiskImprovementPotential"
  | "RiskRating"
  | "RiskAssessmentCategory"
  | "RiskAssessmentSubcategory"
  | "RiskReductionCost"
  | "RiskReportCreator"
  | "ApplicationCode"
  | "ApplicationSetting"
  | "CostCenter"
  | "Gender"
  | "SupportedLanguage"
  | "LanguageListType"
  | "LastAction";

export const selectTaxonomy = (s: RootState) => s.taxonomy;

export const selectTaxonomyByCode = createSelector(
  (s) => s.taxonomy,
  (taxonomy) => (key, code) => {
    return taxonomy[key].byCode[code];
  }
);
