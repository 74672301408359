import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import ComboBoxField from "../../components/controls/ComboBoxField";
import DatePickerField from "../../components/controls/DatePickerField";
import TextInputField from "../../components/controls/TextInputField";
import { useSelector } from "../../store/hooks";
import useGetTaxonomyOptions from "../../utils/hooks/useGetTaxonomyOptions";
import { ReportDocument } from "./ReportDocument";
import { ReportInsurers } from "./ReportInsurers";
import { mergeStyleSets } from "@fluentui/react";
import DoubleNumberInputField from "../../components/controls/DoubleNumberInputField";

function ReportForm() {
  const report = useSelector((s) => s.reportPage.report);
  const reportDisabled = report?.isReportDeactivated === true;
  const { values, handleChange, errors, setFieldValue } =
    useFormikContext() as any;
  const { t } = useTranslation();

  const createdByOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskReportCreator",
  });

  return (
    <div className={classes.grid}>
      <div className={classes.gridRow}>
        <TextInputField
          value={values?.reportName}
          onChange={handleChange}
          errors={errors}
          required
          name={"reportName"}
          disabled={reportDisabled}
          label={t("ram.reportForm.reportName")}
        />
        <ComboBoxField
          disabled={reportDisabled}
          errors={errors}
          name="reportCreatorCode"
          label={"ram.reportForm.reportCreatorCode.label"}
          setFieldValue={setFieldValue}
          options={createdByOptions || []}
          defaultValue={values?.reportCreatorCode}
          confidentiality={null}
          required
        />
        <ReportInsurers
          setFieldValue={setFieldValue}
          value={values?.riskInsurerUsers}
          name={"riskInsurerUsers"}
          error={errors["riskInsurerUsers"]}
        />{" "}
        <TextInputField
          value={values?.insurersRaiting}
          onChange={handleChange}
          errors={errors}
          name={"insurersRaiting"}
          multiline
          rows={4}
          resizable={false}
          disabled={reportDisabled}
          label={"ram.reportForm.insurersRaiting.label"}
        />
      </div>
      <div className={classes.gridRow}>
        <DatePickerField
          disabled={reportDisabled}
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={
            typeof values?.surveyDate === "object" ? values?.surveyDate : null
          }
          name={"surveyDate"}
          label={"ram.reportForm.surveyDate.label"}
          placeholder={t("ram.selectDate")}
          required
          confidentiality={null}
          maxDate={new Date()}
        />
        <DoubleNumberInputField
          required={false}
          setFieldValue={setFieldValue}
          value={values?.pmlinMioEur}
          onChange={handleChange}
          isNegativeAllowed
          errors={errors}
          name={"pmlinMioEur"}
          disabled={reportDisabled}
          label={"ram.reportForm.pmlinMioEur.label"}
        />
        <DoubleNumberInputField
          required={false}
          setFieldValue={setFieldValue}
          value={values?.emlinMioEur}
          onChange={handleChange}
          isNegativeAllowed
          errors={errors}
          name={"emlinMioEur"}
          disabled={reportDisabled}
          label={"ram.reportForm.EMLInMioEUR.label"}
        />

        <ReportDocument values={values} setFieldValue={setFieldValue} />
      </div>
    </div>
  );
}

export default ReportForm;

const classes = mergeStyleSets({
  grid: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
    overfow: "auto",
  },
  gridRow: {
    width: "100%",
  },
});
