import { NavigationBar, SearchResultsCount } from "@greco/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavButtons } from "../../../components/navigation/NavButtons";
import { Right } from "../../../components/styled";
import { useSelector } from "../../../store/hooks";
import {
  loadUsersInsurerRoutine,
  selectShownUsers,
  setManageReportsDialog,
} from "../../../store/usersinsurer";
import { AddUser } from "./AddUser";
import { ClearFilters } from "../../../components/filters/ClearFilters";
import { ClientsFilter } from "../../../components/filters/ClientsFilter";
import { Filters } from "../../../components/filters/Filters";
import { setClientsFilter } from "../../../store/filters/filters";
import { setManageLocationsDialog } from "../../../store/users";
import { CopyUsersEmailsToClipboard } from "../../../components/filters/CopyUsersEmailsToClipboard";
import { LocationsFilter } from "../../../components/filters/LocationsFilter";

export const Navigation = () => {
  const shownUsers = useSelector(selectShownUsers);
  const { t } = useTranslation();
  const clientsFilter = useSelector((s) => s.filters.clientsFilter);
  const locationsFilter = useSelector((s) => s.filters.locationsFilter);
  const dispatch = useDispatch();

  return (
    <NavigationBar>
      <NavButtons />
      <AddUser />
      <Filters>
        <ClientsFilter />
        <LocationsFilter />
        <ClearFilters
          isDisabled={
            clientsFilter.length === 0 && locationsFilter.length === 0
          }
          onClear={() => {
            dispatch(setClientsFilter([]));
            dispatch(
              setManageLocationsDialog({
                user: null,
                isOpen: false,
              })
            );
            dispatch(
              setManageReportsDialog({
                user: null,
                isOpen: false,
              })
            );
            dispatch(loadUsersInsurerRoutine.trigger());
          }}
        />
      </Filters>
      <CopyUsersEmailsToClipboard
        isDisabled={false}
        users={shownUsers}
        singleCopy={true}
      />
      <Right>
        <SearchResultsCount
          count={shownUsers.length}
          label={t("greco.searchResults")}
        />
      </Right>
    </NavigationBar>
  );
};
