import { NormalPeoplePicker } from "@fluentui/react";
import React from "react";
import { useDispatch } from "react-redux";
import { searchUsers } from "../../../api/api";
import { setManageLocationsDialog, setSearch } from "../../../store/users";

export const AddUser = () => {
  const dispatch = useDispatch();

  return (
    <NormalPeoplePicker
      selectedItems={[]}
      onChange={(items) => {
        const option: any = items[0];
        dispatch(
          setManageLocationsDialog({
            isOpen: true,
            user: {
              ...option.user,
              isInternal: /((@greco.services)|(@vmg.at)|(@eghv.at))$/.test(
                option.user.userEmailAddress
              ),
              shouldReceiveNotifications: false,
            },
          })
        );
      }}
      onResolveSuggestions={async (input) => {
        const trimmedInput = input.trim();
        if (trimmedInput.length === 0) return [];
        const usersMatch = await searchUsers(trimmedInput);
        return usersMatch.map((u) => {
          return {
            text: u.userName,
            secondaryText: u.userEmailAddress,
            user: u,
          };
        });
      }}
      onInputChange={(input) => {
        dispatch(setSearch(input));
        return input;
      }}
    />
  );
};
