import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setReportsFilter } from "../../store/filters/filters";
import { selectReportsFilterReports } from "../../store/filters/selectors";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";
import { StyledCheckboxDropdown } from "../styled";

export const ReportsFilter = () => {
  const reportsFilter = useSelector((s) => s.filters.reportsFilter);
  const reportsEntities = useSelector((s) => s.reports.entities);
  const dispatch: AppDispatch = useDispatch();
  const reports = useSelector(selectReportsFilterReports);
  const { t } = useTranslation();

  const hasValue = reportsFilter.length > 0;

  return (
    <StyledCheckboxDropdown
      value={reportsFilter.map((id) => {
        return {
          label: reportsEntities[id]!.reportName,
          value: id,
        };
      })}
      onChange={(value) => {
        dispatch(setReportsFilter(value.map((o: any) => o.value)));
      }}
      options={reports
        .filter((r) => (r ? true : false))
        .map((r: any) => ({
          label: r.reportName,
          value: r.reportId,
        }))}
      label={t("ram.reports")}
      mulitpleSelectedLabel={`${reportsFilter.length} ${t(
        "ram.reports"
      ).toLowerCase()}`}
      searchPlaceholder={t("greco.searchPlaceholderWithObject", {
        object: t("ram.reports").toLowerCase(),
      })}
      className={clsx({
        hasValue,
      })}
    />
  );
};
