import { TFunction } from "i18next";
import { TaxonomyState } from "../store/taxonomy/taxonomy";
import {
  DateFilter,
  ExpandedColumn,
  NumberFilter,
  TaxonomyFilter,
  TextFilter,
} from "../types/columns";

export const isFilteredTaxonomy = (f: TaxonomyFilter) => f.value.length > 0;
export const isFilteredDate = (f: DateFilter) => {
  if (f.operator === "isBetween") {
    return !!f.date1 && !!f.date2;
  }
  return !!f.date1;
};
export const isFilteredNumber = (f: NumberFilter) => {
  if (f.operator === "between") {
    return (
      f.number1 !== null &&
      f.number2 !== null &&
      f.number1 !== ("" as any) &&
      f.number2 !== ("" as any)
    );
  }
  return f.number1 !== null && f.number1 !== ("" as any);
};
export const isFilteredText = (f: TextFilter) => f.value.length > 0;

export const hydrateColumn = ({
  column,
  defaultColumns,
}: {
  column: ExpandedColumn;
  defaultColumns: any[];
}) => {
  const defaultCol = defaultColumns.find((dc) => dc.key === column.key);
  const mappedCol = { ...column };
  if (defaultCol.filter && mappedCol.filter) {
    Object.keys(defaultCol.filter).forEach((key) => {
      const field = defaultCol.filter[key];
      if (typeof field === "function") {
        mappedCol.filter[key] = field;
      }
    });
  }
  if (mappedCol.filter && mappedCol.filter.type === "date") {
    if (mappedCol.filter.date1) {
      mappedCol.filter.date1 = new Date(mappedCol.filter.date1);
    }
    if (mappedCol.filter.date2) {
      mappedCol.filter.date2 = new Date(mappedCol.filter.date2);
    }
  }
  mappedCol.onRender = defaultCol.onRender;
  // mappedCol.detailsListColumn = defaultCol.detailsListColumn;
  mappedCol.pdf = defaultCol.pdf;
  return mappedCol;
};

export const transformSicCode = ({
  sicCode,
  taxonomy,
  t,
}: {
  sicCode: number | null;
  t: TFunction;
  taxonomy: TaxonomyState;
}) => {
  let codeNumber;
  const code = taxonomy.SicCode.byId[sicCode]?.code;
  if (!code) return null;
  codeNumber = code.split(".")[1];
  return `${codeNumber} ${t(taxonomy.SicCode.byId[sicCode]?.code)}`;
};
