import { useMemo } from "react";
import { useSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";

type Props = {
  taxonomyKey: string;
  include?: string[];
  exclude?: string[];
  sort?: boolean;
};
function useGetTaxonomyOptions({
  taxonomyKey,
  include = null,
  exclude = null,
  sort = true,
}: Props) {
  const taxonomy = useSelector((s) => s.taxonomy);

  const { t } = useTranslation();

  const options = useMemo(() => {
    if (!taxonomy[taxonomyKey]) return [];
    const options = taxonomy[taxonomyKey].items
      .filter((tax: any) => {
        if (include) {
          return include.includes(tax.code);
        } else {
          return true;
        }
      })
      .filter((tax) => {
        if (exclude) {
          return !exclude.includes(tax.code);
        } else {
          return true;
        }
      })
      .map((tax: any) => {
        return {
          value: tax.id,
          label: t(tax.code),
        };
      })
      .sort((a: any, b: any) => {
        if (sort) {
          return a.label < b.label ? -1 : 1;
        } else {
          return 0;
        }
      });

    return options;
  }, [taxonomy, taxonomyKey, include, exclude, t, sort]);

  return options;
}

export default useGetTaxonomyOptions;
