import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { ClientLocation } from "../../types/types";
import { RootState } from "../store";
import { createAsyncActions } from "../util";

export const locationsAdapter = createEntityAdapter<ClientLocation>({
  selectId: (loc) => loc.locationId,
});

export const loadLocationsActions = createAsyncActions<
  void,
  void,
  ClientLocation[],
  any
>("locations/load");

export const locationsSlice = createSlice({
  name: "locations",
  initialState: locationsAdapter.getInitialState({
    isLoading: false,
    selectedColumn: null,
    hoveredColumn: null,
  }),
  reducers: {
    setSelectedColumn: (s, a) => {
      s.selectedColumn = a.payload;
    },

    addLocation: locationsAdapter.addOne,
    updateLocation: locationsAdapter.updateOne,
    removeOne: locationsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(loadLocationsActions.loading, (s, a) => {
      s.isLoading = true;
    });
    builder.addCase(loadLocationsActions.success, (s, a) => {
      s.isLoading = false;
      locationsAdapter.setAll(
        s,
        a.payload.filter((l) => l.isDeleted === false)
      );
    });
    builder.addCase(loadLocationsActions.error, (s, a) => {
      s.isLoading = false;
    });
  },
});

export const locationsReducer = locationsSlice.reducer;

export const { addLocation, updateLocation, removeOne, setSelectedColumn } =
  locationsSlice.actions;

export const {
  selectAll: selectAllLocations,
  selectEntities: selectEntitiesLocations,
  selectById: selectByIdLocations,
} = locationsAdapter.getSelectors<RootState>((s) => s.locations);
