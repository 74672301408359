import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormSpinner } from "../../components/FormSpinner";
import { Flex, HSpace, StyledPrimaryButton } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import {
  pasteRecommendationRoutine,
  setPasteDialog,
} from "../../store/recommendations-page/recommendationsPage";

export const PasteDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { report, recommendation } = useSelector(
    (s) => s.recommendationsPage.pasteRecommendationDialog
  );
  const isLoading = useSelector(
    (s) => s.recommendationsPage.pasteRecommendationLoadStatus === "loading"
  );

  const close = () => {
    dispatch(
      setPasteDialog({
        recommendation: null,
        report: null,
        isOpen: false,
      })
    );
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        showCloseButton: true,
        title: (
          <Flex>
            <Icon iconName="Info" /> <HSpace />
            <div>{t("ram.recommendationList.pasteRecommendation")}</div>
          </Flex>
        ),
      }}
      onDismiss={close}
    >
      <div>
        {t("ram.recommendationList.pasteRecommendation.message", {
          recommendationName: recommendation.title,
        })}
      </div>
      <DialogFooter>
        <StyledPrimaryButton
          text={t("ram.recommendationList.pasteRecommendation")}
          onClick={() => {
            dispatch(
              pasteRecommendationRoutine.trigger({
                locationId: recommendation.locationId,
                reportId: recommendation.reportId,
                recommendationId: recommendation.recommendationId,
                destinationReportIds: [report.reportId],
              })
            );
          }}
          disabled={isLoading}
        />
      </DialogFooter>
      {isLoading && <FormSpinner />}
    </Dialog>
  );
};
