import { Icon, TooltipOverflowMode } from "@fluentui/react";
import { Tooltip } from "@greco/components";
import React from "react";
import {
  CenteredCell,
  ListContextMenuIconButton,
} from "../../components/styled";
import { store } from "../../store/store";
import { ExpandedColumn } from "../../types/columns";
import { formatNumber } from "../../utils/number";
import { formatDateString } from "../../utils/utils";
import { ActivateDeactivateCell } from "./ActivateDeactivateCell";
import { ContextButton } from "./ContextButton";
import { TooltipComponent } from "components/TooltipComponent";

export const defaultColumns: ExpandedColumn[] = [
  {
    key: "reportName",
    labelKey: "ram.reportList.reportName",
    width: 200,
    isVisible: true,
    detailsListColumn: ({ t, width }) => ({
      key: "reportName",
      label: t("ram.reportList.reportName"),
      isSortable: true,
      width,
      transform: ({ row }) => (
        <TooltipComponent
          content={row?.reportName}
          overflowMode={TooltipOverflowMode.Parent}
        >
          {row?.reportName}
        </TooltipComponent>
      ),
    }),
  },
  {
    key: "options",
    labelKey: "",
    width: 40,
    isVisible: true,
    detailsListColumn: ({ width, t, taxonomy }) => ({
      key: "options",
      label: "",
      isSortable: false,
      width,
      render: ({ row }) => {
        if (!row) return null;
        return (
          <CenteredCell>
            <ContextButton report={row} />
          </CenteredCell>
        );
      },
    }),
  },
  {
    key: "clientName",
    labelKey: "ram.clientsList.clientName",
    width: 200,
    isVisible: true,
    detailsListColumn: ({ t, width }) => ({
      key: "clientName",
      label: t("ram.clientsList.clientName"),
      width,
      isSortable: true,
      align: "center",
      transform: ({ row }) => {
        return row?.clientName;
      },
    }),
  },
  {
    key: "locationName",
    labelKey: "ram.locationList.locationName",
    width: 200,
    isVisible: true,
    detailsListColumn: ({ width, t }) => ({
      key: "locationName",
      label: t("ram.locationList.locationName"),
      isSortable: true,
      width,
      align: "center",
      transform: ({ row }) => (
        <Tooltip
          content={row?.locationName}
          overflowMode={TooltipOverflowMode.Parent}
        >
          {row?.locationName}
        </Tooltip>
      ),
    }),
  },
  {
    key: "surveyDate",
    labelKey: "ram.reportList.surveyDate",
    width: 150,
    isVisible: true,
    detailsListColumn: ({ width, t }) => ({
      key: "surveyDate",
      label: t("ram.reportList.surveyDate"),
      isSortable: true,
      width,
      transform: ({ row }) => {
        return row?.surveyDate ? formatDateString(row?.surveyDate) : "";
      },
      align: "center",
    }),
  },
  {
    key: "pmlinMioEur",
    labelKey: "ram.locationList.pmlinMioEur",
    width: 150,
    isVisible: true,
    detailsListColumn: ({ width, t }) => ({
      key: "pmlinMioEur",
      label: t("ram.locationList.pmlinMioEur"),
      width,
      isSortable: true,
      align: "center",
      transform: ({ row }) => {
        const pmlinMioEur = row?.pmlinMioEur;
        if (pmlinMioEur !== null && pmlinMioEur !== undefined) {
          return formatNumber(Number(pmlinMioEur));
        }
      },
    }),
  },
  {
    key: "emlinMioEur",
    labelKey: "ram.locationList.emlinMioEur",
    width: 150,
    isVisible: true,
    detailsListColumn: ({ width, t }) => ({
      key: "emlinMioEur",
      label: t("ram.locationList.emlinMioEur"),
      width,
      isSortable: true,
      align: "center",
      transform: ({ row }) => {
        const emlinMioEur = row?.emlinMioEur;
        if (emlinMioEur !== null && emlinMioEur !== undefined) {
          return formatNumber(Number(emlinMioEur));
        }
      },
    }),
  },

  {
    key: "insurersRaiting",
    labelKey: "ram.locationList.insurersRating",
    width: 150,
    isVisible: true,

    detailsListColumn: ({ width, t }) => ({
      key: "insurersRaiting",
      label: t("ram.locationList.insurersRating"),
      width,
      isSortable: true,
      align: "center",
    }),
  },
  {
    key: "reportCreatorCode",
    labelKey: "ram.reportList.reportCreatorCode",
    width: 150,
    isVisible: true,
    detailsListColumn: ({ width, t, taxonomy }) => ({
      key: "reportCreatorCode",
      label: t("ram.reportList.reportCreatorCode"),
      isSortable: true,
      width,
      transform: ({ row }) => {
        return t(taxonomy.RiskReportCreator.byId[row?.reportCreatorCode]?.code);
      },
      align: "center",
    }),
  },
  {
    key: "isReportDeactivated",
    labelKey: "ram.reportList.isActive",
    width: 200,
    isVisible: true,
    detailsListColumn: ({ t, width }) => ({
      key: "isReportDeactivated",
      label: t("ram.reportList.isActive"),
      isSortable: true,
      width,
      align: "center",
      transform: ({ row }) => {
        return <ActivateDeactivateCell report={row} />;
      },
    }),
  },
  {
    key: "reportDocumentPath",
    labelKey: "",
    width: 150,
    isVisible: true,
    detailsListColumn: ({ width }) => ({
      key: "reportDocumentPath",
      label: "",
      isSortable: true,
      width,
      renderLabel: () => {
        return <Icon iconName="Attach" />;
      },
      render: ({ row }) => {
        if (row?.reportDocumentPath) {
          return (
            <CenteredCell>
              <ListContextMenuIconButton
                iconProps={{ iconName: "Download" }}
                onClick={() => window.open(row?.reportDocumentPath)}
              />
            </CenteredCell>
          );
        }
        return null;
      },
      align: "center",
    }),
  },
];
