import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18next from "i18next";
import {
  ChangeClientParams,
  DeleteLocationParams,
  ExportToZipParams,
  GenerateLocationReportDocumentParams,
  LocationUsersReportDocumentParams,
} from "../../api/api";
import { ClientLocation } from "../../types/types";
import { selectAllLocations } from "../locations/locations";
import { AppThunk, LoadStatus, RootState } from "../store";
import { createAsyncActions, createAsyncRoutine, sort } from "../util";

export type LocationsPageState = {
  isAssessmentLoading: boolean;
  locationIdAssessed: number | null;
  shownIds: ClientLocation["locationId"][];
  search: string;
  sort: any;
  exportToZipLoadStatus: LoadStatus;
  exportToZipDialog: {
    locationId: number | null;
    isOpen: boolean;
  };
  generateLocationReportDocumentLoadStatus: LoadStatus;
  generateLocationReportDocumentDialog: {
    clientId: number | null;
    locationId: number | null;
    body: any | null;
    isOpen: boolean;
  };
  deleteLocationLoadStatus: LoadStatus;
  deleteLocationDialog: {
    location: ClientLocation | null;
    isOpen: boolean;
  };
  changeClientLoadStatus: LoadStatus;
  changeClientDialog: {
    location: ClientLocation | null;
    isOpen: boolean;
  };
  usersReportDocumentDialog: {
    isOpen: boolean;
    clientId: number | null;
    locationId: number | null;
  };
  usersReportDocument: any;
  usersReportDocumentLoadStatus: LoadStatus;
};

export const startAssessmentActions = createAsyncActions<
  ClientLocation["locationId"],
  ClientLocation["locationId"],
  ClientLocation,
  any
>("locationsPage/startAssessment");

export const endAssessmentActions = createAsyncActions<
  ClientLocation["locationId"],
  ClientLocation["locationId"],
  ClientLocation,
  any
>("locationsPage/endAssessment");

export const exportToZipRoutine = createAsyncRoutine<
  ExportToZipParams,
  void,
  void,
  any
>("locations/exportToZip");

export const generateLocationReportDocumentRoutine = createAsyncRoutine<
  GenerateLocationReportDocumentParams,
  void,
  void,
  any
>("locations/generateLocationReportDocument");

export const applyFiltersLocations = (): AppThunk => (dispatch, getState) => {
  const clients = getState().clients;
  const clientsFilter = getState().filters.clientsFilter;
  const locations = selectAllLocations(getState());
  const search = getState().locationsPage.search.trim();
  const sortState = getState().locationsPage.sort;
  const taxonomy = getState().taxonomy;

  const filteredLocations = locations.filter((l) => {
    let isSearch = true;
    if (search !== "") {
      let locationNameFilter =
        l.locationName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      let cn = clients.entities[l.webBasePartnerNumber]?.clientName;
      let companyNameFilter =
        cn != null
          ? cn.toLowerCase().indexOf(search.toLowerCase()) !== -1
          : false;
      let sc = taxonomy.SicCode.byId[l.siccode]?.code;
      let sct = sc != null ? sc.split(".")[1] + " " + i18next.t(sc) : "";
      let sicCodeFilter =
        sct.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      let cc = taxonomy.Country.byId[l.countryCode]?.code;
      let ctransl = cc != null ? i18next.t(cc) : "";
      let countryFilter =
        ctransl.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      let cityFilter =
        l.city.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      isSearch =
        locationNameFilter === true ||
        companyNameFilter === true ||
        sicCodeFilter === true ||
        countryFilter === true ||
        cityFilter === true;
    }
    let isFilters = true;
    if (clientsFilter.length > 0) {
      isFilters = clientsFilter.includes(l.webBasePartnerNumber);
    }
    return isSearch && isFilters;
  });

  const shownIds = filteredLocations
    .slice(0)
    .sort(sort(sortState))
    .map((l) => l.locationId);

  dispatch(setShownIds(shownIds));
};

export const deleteLocationRoutine = createAsyncRoutine<
  DeleteLocationParams,
  void,
  any,
  any
>("locations/delete");

export const changeClientRoutine = createAsyncRoutine<
  ChangeClientParams,
  void,
  any,
  any
>("locations/changeClient");

export const usersReportDocumentRoutine = createAsyncRoutine<
  LocationUsersReportDocumentParams,
  void,
  any,
  any
>("locations/usersReportDocument");

export const locationsPageSlice = createSlice({
  name: "locationsPage",
  initialState: {
    isAssessmentLoading: false,
    locationIdAssessed: null,
    shownIds: [],
    search: "",
    sort: {
      key: "locationCreatedAt",
      dir: "desc",
    },
    exportToZipLoadStatus: "none",
    exportToZipDialog: { locationId: null, isOpen: false },
    generateLocationReportDocumentLoadStatus: "none",
    generateLocationReportDocumentDialog: { locationId: null, isOpen: false },
    deleteLocationLoadStatus: "none",
    deleteLocationDialog: {
      location: null,
      isOpen: false,
    },
    changeClientDialog: {
      location: null,
      isOpen: false,
    },
    usersReportDocumentDialog: { isOpen: false },
    usersReportDocument: null,
    usersReportDocumentLoadStatus: "none",
  } as LocationsPageState,
  reducers: {
    setShownIds: (s, a: PayloadAction<LocationsPageState["shownIds"]>) => {
      s.shownIds = a.payload;
    },
    setSearch: (s, a: PayloadAction<LocationsPageState["search"]>) => {
      s.search = a.payload;
    },
    setSort: (s, a: PayloadAction<LocationsPageState["sort"]>) => {
      s.sort = a.payload;
    },
    setExportToZipDialog: (
      s,
      a: PayloadAction<LocationsPageState["exportToZipDialog"]>
    ) => {
      s.exportToZipDialog = a.payload;
    },
    setGenerateLocationReportDocumentDialog: (
      s,
      a: PayloadAction<
        LocationsPageState["generateLocationReportDocumentDialog"]
      >
    ) => {
      s.generateLocationReportDocumentDialog = a.payload;
    },
    setDeleteDialog: (
      s,
      a: PayloadAction<LocationsPageState["deleteLocationDialog"]>
    ) => {
      s.deleteLocationDialog = a.payload;
    },
    setChangeClientDialog: (
      s,
      a: PayloadAction<LocationsPageState["changeClientDialog"]>
    ) => {
      s.changeClientDialog = a.payload;
    },
    setUsersReportDocumentDialog: (
      s,
      a: PayloadAction<LocationsPageState["usersReportDocumentDialog"]>
    ) => {
      s.usersReportDocumentDialog = a.payload;
      s.usersReportDocument = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(startAssessmentActions.loading, (s, a) => {
      s.isAssessmentLoading = true;
      s.locationIdAssessed = a.payload;
    });
    builder.addCase(startAssessmentActions.success, (s, a) => {
      s.isAssessmentLoading = false;
      s.locationIdAssessed = null;
    });
    builder.addCase(startAssessmentActions.error, (s, a) => {
      s.isAssessmentLoading = false;
      s.locationIdAssessed = null;
    });
    builder.addCase(endAssessmentActions.loading, (s, a) => {
      s.isAssessmentLoading = true;
      s.locationIdAssessed = a.payload;
    });
    builder.addCase(endAssessmentActions.success, (s, a) => {
      s.isAssessmentLoading = false;
      s.locationIdAssessed = null;
    });
    builder.addCase(endAssessmentActions.error, (s, a) => {
      s.isAssessmentLoading = false;
      s.locationIdAssessed = null;
    });
    builder.addCase(exportToZipRoutine.loading, (s, a) => {
      s.exportToZipLoadStatus = "loading";
    });
    builder.addCase(exportToZipRoutine.success, (s, a) => {
      s.exportToZipLoadStatus = "success";
    });
    builder.addCase(exportToZipRoutine.error, (s, a) => {
      s.exportToZipLoadStatus = "error";
    });
    builder.addCase(generateLocationReportDocumentRoutine.loading, (s, a) => {
      s.generateLocationReportDocumentLoadStatus = "loading";
    });
    builder.addCase(generateLocationReportDocumentRoutine.success, (s, a) => {
      s.generateLocationReportDocumentLoadStatus = "success";
    });
    builder.addCase(generateLocationReportDocumentRoutine.error, (s, a) => {
      s.generateLocationReportDocumentLoadStatus = "error";
    });
    builder.addCase(deleteLocationRoutine.loading, (s, a) => {
      s.deleteLocationLoadStatus = "loading";
    });
    builder.addCase(deleteLocationRoutine.success, (s, a) => {
      s.deleteLocationLoadStatus = "success";
    });
    builder.addCase(deleteLocationRoutine.error, (s, a) => {
      s.deleteLocationLoadStatus = "error";
    });
    builder.addCase(changeClientRoutine.loading, (s, a) => {
      s.changeClientLoadStatus = "loading";
    });
    builder.addCase(changeClientRoutine.success, (s, a) => {
      s.changeClientLoadStatus = "success";
    });
    builder.addCase(changeClientRoutine.error, (s, a) => {
      s.changeClientLoadStatus = "error";
    });
    builder.addCase(usersReportDocumentRoutine.loading, (s, a) => {
      s.usersReportDocumentLoadStatus = "loading";
    });
    builder.addCase(usersReportDocumentRoutine.success, (s, a) => {
      s.usersReportDocumentLoadStatus = "success";
      s.usersReportDocument = a.payload;
    });
    builder.addCase(usersReportDocumentRoutine.error, (s, a) => {
      s.usersReportDocumentLoadStatus = "error";
    });
  },
});

export const locationsPageReducer = locationsPageSlice.reducer;

export const {
  setShownIds,
  setSearch: setSearchLocations,
  setSort: setSortLocations,
  setExportToZipDialog,
  setGenerateLocationReportDocumentDialog,
  setDeleteDialog,
  setChangeClientDialog,
  setUsersReportDocumentDialog,
} = locationsPageSlice.actions;

export const selectShownLocations = createSelector(
  (s: RootState) => s.locationsPage.shownIds,
  (s: RootState) => s.locations.entities,
  (ids, byId) => ids.map((id) => byId[id]!)
);
