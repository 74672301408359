import {
  IShimmerElement,
  Shimmer,
  ShimmerElementType,
} from "@fluentui/react/lib/Shimmer";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import * as React from "react";

const classes = mergeStyleSets({
  navigation: {
    height: 44,
  },
  wrapp: {
    marginTop: 50,
  },
  content: {
    maxWidth: "1200px",
    margin: "50px auto",
    position: "relative",
  },
});

const headerSkeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "20%",
    height: 44,
  },
];

const formSeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "100%",
    height: 800,
  },
];

const LocationSkeleton = () => {
  return (
    <>
      <div>
        <Shimmer shimmerElements={headerSkeleton} />
      </div>
      <div className={classes.content}>
        <div className={classes.wrapp}>
          <Shimmer shimmerElements={formSeleton} />
        </div>
      </div>
    </>
  );
};

export default LocationSkeleton;
