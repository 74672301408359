import {
  UpdateAssessmentWithAttachmentsParams,
  editAssessmentWithAttachments,
} from "api/api";
import { useMutation } from "react-query";
import { MUTATION_KEYS } from "store/constants";

import { handleAxiosError } from "store/util";

const updateAssesment = async ({
  locationId,
  reportId,
  recommendationId,
  assessmentId,
  body,
}: UpdateAssessmentWithAttachmentsParams) => {
  return await editAssessmentWithAttachments(
    locationId,
    reportId,
    recommendationId,
    assessmentId,
    body
  );
};

export function useUpdateAssesment() {
  return useMutation(updateAssesment, {
    mutationKey: MUTATION_KEYS.updateAssesment,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}
