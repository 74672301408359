import { useTranslation } from "react-i18next";
import {
  adjustFloatNumberValue,
  adjustTaxonomyValue,
} from "../../../form/util";
import { useSelector } from "../../../store/hooks";

function useRecomendationFormInitialValues() {
  const recommendation = useSelector((s) => s.recommendationPage.rec);

  const taxonomy = useSelector((s) => s.taxonomy);
  const recommendationAttachments = useSelector(
    (s) => s.recommendationPage.attachments
  );

  const { t } = useTranslation();

  const initialValues = (() => {
    if (recommendation) {
      return {
        dateOfRecommendation: recommendation?.dateOfRecommendation
          ? new Date(recommendation?.dateOfRecommendation)
          : null,
        recommendationNumberInternal:
          recommendation?.recommendationNumberInternal,
        recommendationNumberExternal:
          recommendation?.recommendationNumberExternal,
        title: recommendation?.title,
        description: recommendation?.description,
        typeCode: adjustTaxonomyValue(
          recommendation?.typeCode,
          taxonomy,
          "RiskCategoryOfMeasure",
          t
        ),
        statusCompanyCode: adjustTaxonomyValue(
          recommendation?.statusCompanyCode,
          taxonomy,
          "RiskImplementationStatus",
          t
        ),
        statusGrECoCode: adjustTaxonomyValue(
          recommendation?.statusGrECoCode,
          taxonomy,
          "RiskImplementationStatus",
          t
        ),
        priorityCode: adjustTaxonomyValue(
          recommendation?.priorityCode,
          taxonomy,
          "RiskPriority",
          t
        ),
        priorityCompanyCode: adjustTaxonomyValue(
          recommendation?.priorityCompanyCode,
          taxonomy,
          "RiskPriority",
          t
        ),
        implementationDateInsurer: recommendation?.implementationDateInsurer
          ? new Date(recommendation?.implementationDateInsurer)
          : null,
        plannedImplementationDate: recommendation?.plannedImplementationDate
          ? new Date(recommendation?.plannedImplementationDate)
          : null,
        implemented: recommendation?.implemented
          ? new Date(recommendation?.implemented)
          : null,
        objectOrInfrastructure: recommendation?.objectOrInfrastructure,

        //other - risk assesment
        subCategory1Code: adjustTaxonomyValue(
          recommendation.subCategory1Code,
          taxonomy,
          "RiskAssessmentSubcategory",
          t
        ),
        subCategory2Code: adjustTaxonomyValue(
          recommendation.subCategory2Code,
          taxonomy,
          "RiskAssessmentSubcategory",
          t
        ),
        subCategory3Code: adjustTaxonomyValue(
          recommendation.subCategory2Code,
          taxonomy,
          "RiskAssessmentSubcategory",
          t
        ),
        weighting: recommendation?.weighting,
        improvementPotentialCode: adjustTaxonomyValue(
          recommendation.improvementPotentialCode,
          taxonomy,
          "RiskImprovementPotential",
          t
        ),
        improvementPotentialCodeAsIf: adjustTaxonomyValue(
          recommendation.improvementPotentialCodeAsIf,
          taxonomy,
          "RiskImprovementPotential",
          t
        ),
        result: recommendation?.result,
        resultAsIf: recommendation?.resultAsIf,
        noGo: recommendation?.noGo,
        emlReductionPercent: recommendation?.emlReductionPercent,
        implementationCostsEur: adjustFloatNumberValue(
          recommendation?.implementationCostsEur
        ),
        costBenifitFactor: adjustFloatNumberValue(
          recommendation?.costBenifitFactor
        ),
        costEstimateCode: adjustTaxonomyValue(
          recommendation.costEstimateCode,
          taxonomy,
          "RiskReductionCost",
          t
        ),
        attachments: recommendationAttachments,
      };
    }
    return {
      dateOfRecommendation: new Date(),
      attachments: [],
      noGo: false,
      statusCompanyCode: adjustTaxonomyValue(
        4,
        taxonomy,
        "RiskImplementationStatus",
        t
      ),
      statusGrECoCode: adjustTaxonomyValue(
        4,
        taxonomy,
        "RiskImplementationStatus",
        t
      ),
    };
  })();

  return initialValues;
}

export default useRecomendationFormInitialValues;
