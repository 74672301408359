import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RenameDefaultWeightingParams } from "../../api/api";
import { LoadStatus, RootState } from "../store";
import { createAsyncRoutine } from "../util";

export type DefaultWeightingsPageState = {
  search: string;
  sort: any;
  deleteDefaultWeightingLoadStatus: LoadStatus;
  deleteDefaultWeightingDialog: {
    name: string | null;
    isOpen: boolean;
  };
  renameDefaultWeightingLoadStatus: LoadStatus;
  renameDefaultWeightingDialog: {
    name: string | null;
    isOpen: boolean;
  };
};

export const deleteDefaultWeightingRoutine = createAsyncRoutine<
  string,
  void,
  any,
  any
>("defaultWeightings/delete");

export const renameDefaultWeightingRoutine = createAsyncRoutine<
  RenameDefaultWeightingParams,
  void,
  any,
  any
>("defaultWeightings/renameDefaultWeighting");

export const defaultWeightingsPageSlice = createSlice({
  name: "defaultWeightingsPage",
  initialState: {
    search: "",
    sort: {
      key: "name",
      dir: "desc",
    },
    deleteDefaultWeightingLoadStatus: "none",
    deleteDefaultWeightingDialog: {
      name: null,
      isOpen: false,
    },
    renameDefaultWeightingLoadStatus: "none",
    renameDefaultWeightingDialog: {
      name: null,
      isOpen: false,
    },
  } as DefaultWeightingsPageState,
  reducers: {
    setSearch: (s, a: PayloadAction<DefaultWeightingsPageState["search"]>) => {
      s.search = a.payload;
    },
    setSort: (s, a: PayloadAction<DefaultWeightingsPageState["sort"]>) => {
      s.sort = a.payload;
    },
    setDeleteDialog: (
      s,
      a: PayloadAction<
        DefaultWeightingsPageState["deleteDefaultWeightingDialog"]
      >
    ) => {
      s.deleteDefaultWeightingDialog = a.payload;
    },
    setRenameDefaultWeightingDialog: (
      s,
      a: PayloadAction<
        DefaultWeightingsPageState["renameDefaultWeightingDialog"]
      >
    ) => {
      s.renameDefaultWeightingDialog = a.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(renameDefaultWeightingRoutine.loading, (s, a) => {
      s.renameDefaultWeightingLoadStatus = "loading";
    });
    builder.addCase(renameDefaultWeightingRoutine.success, (s, a) => {
      s.renameDefaultWeightingLoadStatus = "success";
    });
    builder.addCase(renameDefaultWeightingRoutine.error, (s, a) => {
      s.renameDefaultWeightingLoadStatus = "error";
    });
  },
});

export const defaultWeightingsPageReducer = defaultWeightingsPageSlice.reducer;

export const {
  setSearch: setSearchDefaultWeightings,
  setSort: setSortDefaultWeightings,
  setDeleteDialog,
  setRenameDefaultWeightingDialog,
} = defaultWeightingsPageSlice.actions;

export const selectShownDefaultWeightings = createSelector(
  (s: RootState) => s.defaultWeightings.ids,
  (s: RootState) => s.defaultWeightings.entities,
  (ids, byId) => ids.map((id) => byId[id]!)
);
