import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "../../../components/FluentToast";
import { routes, useCurrentRoute } from "../../../config/routes";
import { transformForPayload } from "../../../form/util";

import { useSelector } from "../../../store/hooks";
import {
  loadRec,
  loadResponsesAndEvaluationsRoutine,
  setAddEvaluationDialog,
} from "../../../store/recommendationPage";
import {
  attachmentToBase64,
  isUploadSizeOverLimit,
} from "../../../utils/utils";
import { useCreateEvaluation } from "store/react-query-hooks/useCreateEvaluation";
import { useUpdateEvaluation } from "store/react-query-hooks/useUpdateEvaluation";

function useSubmitEvaluationForm() {
  const { clientId, locationId, reportId, recommendationId } =
    useParams() as any;
  const navigate = useNavigate();

  const evaluation = useSelector(
    (s) => s.recommendationPage.addEvaluationDialog.evaluation
  );

  const { mutate: onCreateEvaluation } = useCreateEvaluation();
  const { mutate: onUpdateEvaluation } = useUpdateEvaluation();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const route = useCurrentRoute();
  const onSubmit = async (values) => {
    if (isUploadSizeOverLimit(values?.attachments)) {
      toast.error(t("ram.upload.overLimit"));
      return;
    }
    const mode = values?.mode;

    const payload = transformForPayload({
      values,
      dateFields: ["implementationDateInsurer"],
    });

    if (route === "editRecommendation") {
      onCreateEvaluation(
        {
          locationId,
          reportId,
          recommendationId,
          body: {
            dateOfEvaluation: new Date().toISOString(),
            insurerEvaluation: {
              ...payload,
            },
            insurerEvaluationAttachments: await Promise.all(
              values?.attachments.map((f) => {
                if (f.attachmentId) {
                  return {
                    attachmentId: f.attachmentId,
                    attachmentName: f.attachmentName,
                    rowVersion: f.rowVersion,
                  };
                } else {
                  return attachmentToBase64(f);
                }
              })
            ),
          },
        },
        {
          onSuccess: () => {
            toast.success(t("ram.evaluationPage.evaluationAdded"));

            dispatch(loadRec());
            dispatch(
              loadResponsesAndEvaluationsRoutine.trigger({
                locationId,
                reportId,
                recommendationId,
              })
            );
            dispatch(
              setAddEvaluationDialog({
                isOpen: false,
                evaluation: null,
                attachments: [],
              })
            );
            if (mode === "notify") {
              navigate(routes.recommendations.getPath());
            } else {
              navigate(
                routes.editRecommendation.getPath(
                  clientId,
                  locationId,
                  reportId,
                  recommendationId
                )
              );
            }
          },
        }
      );
    } else if (route === "editEvaluation") {
      onUpdateEvaluation(
        {
          locationId,
          reportId,
          recommendationId,
          evaluationId: evaluation.evaluationId,
          body: {
            dateOfEvaluation: evaluation.rowVersion,
            insurerEvaluation: {
              ...payload,
              rowVersion: evaluation.rowVersion,
            },
            insurerEvaluationAttachments: await Promise.all(
              values?.attachments.map((f) => {
                if (f.attachmentId) {
                  return {
                    attachmentId: f.attachmentId,
                    attachmentName: f.attachmentName,
                    rowVersion: f.rowVersion,
                  };
                } else {
                  return attachmentToBase64(f);
                }
              })
            ),
          },
        },
        {
          onSuccess: () => {
            toast.success(t("ram.evaluationPage.evaluationEdited"));
            if (mode === "notify") {
              navigate(routes.recommendations.getPath());
            } else {
              navigate(
                routes.editRecommendation.getPath(
                  clientId,
                  locationId,
                  reportId,
                  recommendationId
                )
              );
            }
          },
        }
      );
    }
  };

  return onSubmit;
}

export default useSubmitEvaluationForm;
