import { Persona, PersonaSize } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { components, mergeStyles, MultiValueProps } from "react-select";
import { Flex, Truncate } from "./styled";
import Async from "react-select/async";
import { IStyledTheme } from "../theme/types";
import { useTheme } from "styled-components";
import { getSelectStyles } from "../utils/utils";
import styled from "styled-components";

type Props = {
  value: any;
  onChange: (value: any) => void;
  loadOptions: (input: string) => Promise<any>;
  styles?: any;
  onInputChange?: (value: string) => void;
  onBlur?: () => void;
  selectRef?: any;
  isMulti?: boolean;
  [key: string]: any;
};

export const PeoplePicker = ({
  value,
  onChange,
  loadOptions,
  styles,
  onInputChange,
  selectRef,
  onBlur,
  isMulti = true,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const theme = useTheme() as IStyledTheme;

  return (
    <Async
      onInputChange={onInputChange}
      placeholder={t("greco.form.searchPlaceholder")}
      loadingMessage={() => t("greco.loading")}
      noOptionsMessage={() => t("greco.noResults")}
      loadOptions={loadOptions}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      onBlur={onBlur}
      components={{
        Option: CustomOptionUser,
        MultiValue: CustomMultiValue,
      }}
      styles={mergeStyles(getSelectStyles(!!false, theme), {
        container: (p) => ({
          ...p,
          width: "100%",
        }),
        menuPortal: (p) => ({
          ...p,
          background: theme.palette.neutralLighter,
          zIndex: 9999999,
        }),
        menu: (p) => ({
          ...p,
          zIndex: 9999,
          background: theme.palette.white,
        }),
        control: (p, state) => {
          return {
            ...p,

            background: state.isDisabled ? "transparent" : theme.palette.white,

            borderBottom: state.isFocused
              ? `2px solid rgb(0, 90, 161) !important`
              : `1px solid ${theme.palette.black} !important`,
            boxShadow: "none",
            ...(state.isDisabled && {
              borderBottom: `1px solid ${theme.palette.neutralLighter} !important`,
            }),
          };
        },
        option: (p, state) => ({
          ...p,
          background:
            state.isSelected || state.isFocused
              ? theme.palette.neutralLighter
              : theme.palette.white,
          "&:hover": {
            background: theme.palette.neutralLighter,
          },
          color: theme.palette.black,
          fontSize: "14px",
          fontWeight: 400,
          wordWrap: "break-word",
        }),
        input: (p) => ({
          ...p,
          color: theme.palette.black,
        }),
        singleValue: (p, state) => ({
          ...p,
          color: theme.palette.black,
          fontSize: "14px",
          fontWeight: 400,
        }),
        dropdownIndicator: (p, state) => ({
          ...p,
          ...(state.isDisabled && { visibility: "hidden" }),
        }),
        indicatorSeparator: (p, state) => ({
          ...p,
          ...(state.isDisabled && { visibility: "hidden" }),
        }),
        ...styles,
      })}
      ref={selectRef}
      {...props}
    />
  );
};

const CustomOptionUser = (props: any) => {
  return (
    <components.Option {...props}>
      <Flex
        id="stesic"
        style={{
          cursor: "pointer",
        }}
      >
        <Persona
          text={props.data.userName}
          hidePersonaDetails
          size={PersonaSize.size32}
          styles={{
            root: {
              marginRight: "5px",
            },
          }}
        />
        <div
          style={{
            flex: 1,
          }}
        >
          <Truncate>{props.data.userName}</Truncate>
          <Email>
            <Truncate>{props.data.userEmailAddress}</Truncate>
          </Email>
        </div>
      </Flex>
    </components.Option>
  );
};

const Email = styled.div`
  font-size: 12px;
  color: ${(p) => p.theme.palette.neutralSecondary};
`;

const CustomMultiValue = (props: MultiValueProps<any>) => {
  return (
    <components.MultiValue {...props}>
      <Flex>
        <Persona
          size={PersonaSize.size24}
          text={props.data.label}
          hidePersonaDetails
          styles={{
            root: {
              marginRight: "5px",
            },
          }}
        />
        <Truncate>{props.children}</Truncate>
      </Flex>
    </components.MultiValue>
  );
};
