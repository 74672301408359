import styled from "styled-components";
import { DirectionalHint, Icon, Link } from "office-ui-fabric-react";
import React, { FunctionComponent } from "react";
import { NavLink, matchPath, matchRoutes, useLocation } from "react-router-dom";
import { NoAccess } from "../components/NoAccess";
import { Flex, HSpace } from "../components/styled";
import { Analytics } from "../pages/analytics/Analytics";
import { ClientsTree } from "../pages/clients-tree/ClientsTree";
import { LocationDefaultValues } from "../pages/location-default-values/LocationDefaultValues";
import { Location as LocationForm } from "../pages/location/Location";
import { Locations } from "../pages/locations/Locations";
import { Recommendation } from "../pages/recommendation/Recommendation";
import { Assessment } from "../pages/recommendation/components/ResponsesFormContent/ResponseList/Assessment";
import { Recommendations } from "../pages/recommendations/Recommendations";
import { Report } from "../pages/report/Report";
import { Reports } from "../pages/reports/Reports";
import { UsersList as InsurerUsersList } from "../pages/users-insurer/UsersList";
import { UsersList } from "../pages/users/UsersList";
import { selectByIdClients } from "../store/clients/clients";
import { useSelector } from "../store/hooks";
import { selectByIdLocations } from "../store/locations/locations";
import { selectByIdReports } from "../store/reports/reports";
import { DefaultWeightings } from "pages/default-weightings/DefaultWeightings";
import { TooltipComponent } from "components/TooltipComponent";
import { DefaultWeighting } from "pages/default-weighting/DefaultWeighting";

const clientPath = "/clients/:clientId";
const clientTreePath = "/clients-tree/:clientId";
const locationPath = clientPath + "/locations/:locationId";
const locationDefaultValuesPath = locationPath + "/default-values";
const reportPath = locationPath + "/reports/:reportId";
const defaultWeightingsPath = "/defaultweightings";
const defaultWeightingPath = "/defaultweighting/:name";
const recommendationPath = reportPath + "/recommendations/:recommendationId";
const getClientTreePath = (clientId: string) => `/clients-tree/${clientId}`;
const getClientPath = (clientId: string) => `/clients/${clientId}`;
const getLocationPath = (clientId: string, locationId: string) =>
  getClientPath(clientId) + `/locations/${locationId}`;
const getReportPath = (
  clientId: string,
  locationId: string,
  reportId: string
) => getLocationPath(clientId, locationId) + `/reports/${reportId}`;
const getRecommendationPath = (
  clientId: string,
  locationId: string,
  reportId: string,
  recommendationId: string
) =>
  getReportPath(clientId, locationId, reportId) +
  `/recommendations/${recommendationId}`;

type RouteDef = {
  key: string;
  path: string;
  component: FunctionComponent<any>;
  getPath: (...args: any[]) => string;
  matchPath: (loc: any) => any;
  [key: string]: any;
};

type Routes = {
  clientsTree: RouteDef;
  clients: RouteDef;
  locations: RouteDef;
  reports: RouteDef;
  recommendations: RouteDef;
  addLocation: RouteDef;
  addLocationNoClient: RouteDef;
  editLocation: RouteDef;
  addReport: RouteDef;
  editReport: RouteDef;
  locationDefaultValues: RouteDef;
  addRecommendation: RouteDef;
  editRecommendation: RouteDef;
  users: RouteDef;
  insurerusers: RouteDef;
  analytics: RouteDef;
  noaccess: RouteDef;
  editAssessment: RouteDef;
  addAssessment: RouteDef;
  defaultWeightings: RouteDef;
  defaultWeighting: RouteDef;
  addDefaultWeighting: RouteDef;
};

export type RouteKey = keyof Routes;

const Crumb = ({
  path,
  text,
  final,
}: {
  path: string;
  text: string;
  final: boolean;
}) => {
  return (
    <StyledClientBreadcrumb to={path}>
      <Flex
        style={{
          flexWrap: "nowrap",
          overflow: "hidden",
        }}
      >
        <HSpace />
        <Link
          styles={{
            root: {
              fontSize: "12px",
              textOverflow: "ellipsis",
            },
          }}
        >
          <TooltipComponent
            directionalHint={DirectionalHint.leftTopEdge}
            content={text}
          >
            {text}
          </TooltipComponent>
        </Link>
        <HSpace />
        {!!!final ? <DividerIcon iconName="Line" /> : null}
      </Flex>
    </StyledClientBreadcrumb>
  );
};

const ClientCrumb = ({ params, final }) => {
  const client = useSelector((s) => selectByIdClients(s, params.clientId));
  return (
    <Crumb
      final={final}
      path={routes.clients.getPath()}
      text={client?.clientName}
    />
  );
};

const LocationBreadcrumb = ({ params, final }) => {
  const location = useSelector((s) =>
    selectByIdLocations(s, params.locationId)
  );
  return (
    <Crumb
      path={routes.editLocation.getPath(params.clientId, params.locationId)}
      text={location?.locationName}
      final={final}
    />
  );
};

const ReportBreadcrumb = ({ params, final }) => {
  const report = useSelector((s) => selectByIdReports(s, params.reportId));
  return (
    <Crumb
      final={final}
      path={routes.editReport.getPath(
        params.clientId,
        params.locationId,
        params.reportId
      )}
      text={report?.reportName}
    />
  );
};

const RecommendationBreadcrumb = ({ params, recommendation, final }) => {
  return (
    <Crumb
      final={final}
      path={routes.editRecommendation.getPath(
        params.clientId,
        params.locationId,
        params.reportId,
        params.recommendationId
      )}
      text={recommendation?.title}
    />
  );
};

const Recommendation2Breadcrumb = ({ params, final }) => {
  const rec = useSelector((s) => s.recommendationPage.rec);
  return (
    <Crumb
      final={final}
      path={routes.editRecommendation.getPath(
        params.clientId,
        params.locationId,
        params.reportId,
        params.recommendationId
      )}
      text={rec?.title}
    />
  );
};

const DividerIcon = styled(Icon)`
  transform: rotateZ(70deg);
  user-select: none;
  color: ${(p) => p.theme.palette.themePrimary};
  font-size: 12px;
`;

const StyledClientBreadcrumb = styled(NavLink)`
  color: ${(p) => p.theme.palette.neutralPrimary};
  text-decoration: none;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
`;

export const routes: Routes = {
  clientsTree: {
    key: "clientsTree",
    path: "/clients-tree",
    getPath: () => "/clients-tree",
    matchPath: (loc) => matchPath("/clients-tree", loc.pathname),

    component: ClientsTree,
  },
  clients: {
    key: "clients",
    path: "/clients",
    getPath: () => "/clients",
    matchPath: (loc) => matchPath("/clients", loc.pathname),
    component: ClientsTree,
  },
  locations: {
    key: "locations",
    path: "/locations",
    getPath: () => "/locations",
    matchPath: (loc) => matchPath("/locations", loc.pathname),

    component: Locations,
  },
  reports: {
    key: "reports",
    path: "/reports",
    getPath: () => "/reports",
    matchPath: (loc) => matchPath("/reports", loc.pathname),
    component: Reports,
  },
  recommendations: {
    key: "recommendations",
    path: "/recommendations",
    getPath: () => "/recommendations",
    matchPath: (loc) => matchPath("/recommendations", loc.pathname),

    component: Recommendations,
  },
  users: {
    key: "users",
    path: "/users",
    getPath: () => "/users",
    matchPath: (loc) => matchPath("/users", loc.pathname),

    component: UsersList,
  },
  insurerusers: {
    key: "insurerusers",
    path: "/insurerusers",
    getPath: () => "/insurerusers",
    matchPath: (loc) => matchPath("/insurerusers", loc.pathname),
    component: InsurerUsersList,
  },
  analytics: {
    key: "analytics",
    path: "/analytics",
    getPath: () => "/analytics",
    matchPath: (loc) => matchPath("/analytics", loc.pathname),

    component: Analytics,
  },
  noaccess: {
    key: "noaccess",
    path: "/noaccess",
    getPath: () => "/noaccess",
    matchPath: (loc) => matchPath("/noaccess", loc.pathname),
    component: NoAccess,
  },
  addLocation: {
    key: "addLocation",
    path: clientPath + "/add-location",
    getPath: (clientId: string) => getClientPath(clientId) + `/add-location`,
    component: LocationForm,
    matchPath: (loc) => matchPath(clientPath + "/add-location", loc.pathname),

    breadcrumb: [ClientCrumb],
  },
  addLocationNoClient: {
    key: "addLocationNoClient",
    path: "/add-location",
    getPath: () => "/add-location",
    matchPath: (loc) => matchPath("/add-location", loc.pathname),
    component: LocationForm,
  },
  editLocation: {
    key: "editLocation",
    path: locationPath,
    getPath: (clientId: string, locationId: string) =>
      getLocationPath(clientId, locationId),
    component: LocationForm,
    matchPath: (loc) => matchPath(locationPath, loc.pathname),

    breadcrumb: [ClientCrumb, LocationBreadcrumb],
  },
  addReport: {
    key: "addReport",
    path: locationPath + "/add-report",
    getPath: (clientId: string, locationId: string) =>
      getLocationPath(clientId, locationId) + `/add-report`,
    component: Report,
    matchPath: (loc) => matchPath(routes.addReport.path, loc.pathname),

    breadcrumb: [ClientCrumb, LocationBreadcrumb],
  },
  editReport: {
    key: "editReport",
    path: reportPath,
    getPath: (clientId: string, locationId: string, reportId: string) =>
      getReportPath(clientId, locationId, reportId),
    component: Report,
    matchPath: (loc) => matchPath(routes.editReport.path, loc.pathname),

    breadcrumb: [ClientCrumb, LocationBreadcrumb, ReportBreadcrumb],
  },
  addRecommendation: {
    key: "addRecommendation",
    path: reportPath + "/add-recommendation",
    getPath: (clientId: string, locationId: string, reportId: string) =>
      getReportPath(clientId, locationId, reportId) + `/add-recommendation`,
    component: Recommendation,
    matchPath: (loc) =>
      matchPath(reportPath + "/add-recommendation", loc.pathname),

    breadcrumb: [ClientCrumb, LocationBreadcrumb, ReportBreadcrumb],
  },
  editRecommendation: {
    key: "editRecommendation",
    path: reportPath + "/recommendation/:recommendationId",
    getPath: (
      clientId: string,
      locationId: string,
      reportId: string,
      recommendationId: string
    ) =>
      getReportPath(clientId, locationId, reportId) +
      `/recommendation/${recommendationId}`,
    component: Recommendation,
    matchPath: (loc) => matchPath(routes.editRecommendation.path, loc.pathname),
    breadcrumb: [
      ClientCrumb,
      LocationBreadcrumb,
      ReportBreadcrumb,
      Recommendation2Breadcrumb,
    ],
  },
  locationDefaultValues: {
    key: "locationDefaultValues",
    path: locationDefaultValuesPath,
    getPath: (clientId: string, locationId: string) =>
      getLocationPath(clientId, locationId) + "/default-values",
    component: LocationDefaultValues,
    matchPath: (loc) =>
      matchPath(routes.locationDefaultValues.path, loc.pathname),

    breadcrumb: [ClientCrumb, LocationBreadcrumb],
  },

  editAssessment: {
    key: "editAssessment",
    path: recommendationPath + "/assessments/:assessmentId",
    getPath: (
      clientId: string,
      locationId: string,
      reportId: string,
      recommendationId: string,
      assessmentId: string
    ) =>
      getRecommendationPath(clientId, locationId, reportId, recommendationId) +
      `/assessments/${assessmentId}`,
    component: Assessment,
    matchPath: (loc) => matchPath(routes.editAssessment.path, loc.pathname),

    breadcrumb: [
      ClientCrumb,
      LocationBreadcrumb,
      ReportBreadcrumb,
      RecommendationBreadcrumb,
    ],
  },
  addAssessment: {
    key: "addAssessment",
    path: recommendationPath + "/assessments/add-assessment",
    getPath: (
      clientId: string,
      locationId: string,
      reportId: string,
      recommendationId: string
    ) =>
      getRecommendationPath(clientId, locationId, reportId, recommendationId) +
      `/assessments/add-assessment`,
    component: Assessment,
    matchPath: (loc) => matchPath(routes.addAssessment.path, loc.pathname),

    breadcrumb: [
      ClientCrumb,
      LocationBreadcrumb,
      ReportBreadcrumb,
      RecommendationBreadcrumb,
    ],
  },
  defaultWeightings: {
    key: "defaultweightings",
    path: defaultWeightingsPath,
    getPath: () => "/defaultweightings",
    matchPath: (loc) => matchPath(routes.defaultWeightings.path, loc.pathname),
    component: DefaultWeightings,
  },
  defaultWeighting: {
    key: "defaultWeighting",
    path: defaultWeightingPath,
    getPath: (name: string) => `/defaultweighting/${name}`,
    matchPath: (dw) => matchPath(routes.defaultWeighting.path, dw.pathname),
    component: DefaultWeighting,
  },
  addDefaultWeighting: {
    key: "addDefaultWeighting",
    path: "/add-defaultweighting",
    getPath: () => "/add-defaultweighting",
    matchPath: (dw) => matchPath("/add-defaultweighting", dw.pathname),
    component: DefaultWeighting,
  },
};

export const useCurrentRoute = () => {
  const location = useLocation();
  // const matchResult = useMatch(location.pathname);
  const realRoutes = Object.keys(routes).map((route) => {
    return {
      path: routes[route].path,
    };
  });
  const matchResult = matchRoutes(realRoutes, location);

  return Object.values(routes).reduce((match: any | null, r: any) => {
    if (matchResult[0].route.path === r.path) {
      return r.key;
    }
    return match;
  }, null);
};
