import {
  UpdateResponseWithAttachmentsParams,
  editResponseWithAttachments,
} from "api/api";
import { useMutation } from "react-query";
import { MUTATION_KEYS } from "store/constants";

import { handleAxiosError } from "store/util";

const updateResponse = async ({
  locationId,
  reportId,
  recommendationId,
  responseId,
  body,
}: UpdateResponseWithAttachmentsParams) => {
  return await editResponseWithAttachments(
    locationId,
    reportId,
    recommendationId,
    responseId,
    body
  );
};

export function useUpdateResponse() {
  return useMutation(updateResponse, {
    mutationKey: MUTATION_KEYS.updateResponse,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}
