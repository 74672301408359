import React from "react";
import { NavButtons } from "../../components/navigation/NavButtons";
import { NavigationBar, Right } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import { Search } from "./Search";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DefaultButton } from "@fluentui/react";
import { Link } from "react-router-dom";
import { SearchResultsCount } from "@greco/components";

export const Navigation = () => {
  const count = useSelector((s) => s.clientsPage.shownIds.length);
  const { t } = useTranslation();
  const externalManual = process.env.REACT_APP_MANUAL_EXTERNAL_URL;
  const internalManual = process.env.REACT_APP_MANUAL_INTERNAL_URL;
  const insurerManual = process.env.REACT_APP_MANUAL_INSURER_URL;
  const userRole = useSelector((s) => s.userRole.userRole);
  return (
    <NavigationBar>
      <NavButtons />
      <Search />
      <Right>
        {(externalManual &&
          (userRole === "external" || userRole == "insurer")) ||
        (internalManual &&
          userRole !== "external" &&
          userRole !== "insurer") ? (
          <>
            <a
              href={
                externalManual &&
                (userRole === "external" || userRole === "external_advanced")
                  ? externalManual
                  : insurerManual && userRole === "insurer"
                  ? insurerManual
                  : internalManual
              }
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: "10px" }}
            >
              <StyledDefaultButton
                iconProps={{ iconName: "ReadingMode" }}
                title={t("ram.manual")}
                style={{ fontWeight: "800" }}
                text={t("ram.manual")}
              />
            </a>
          </>
        ) : null}
        <SearchResultsCount count={count} label={t("greco.searchResults")} />
      </Right>
    </NavigationBar>
  );
};

const StyledDefaultButton = styled(DefaultButton)`
  &.ms-Button {
    border: 0;
    background: transparent;
    &:hover {
      background: ${(p) => p.theme.palette.neutralLighter};
    }
  }
  .ms-Button-label {
    font-weight: normal;
  }
`;
