import { Spinner } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { IndicatorIcon } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import { RecommendationAzure } from "../../types/types";
import * as Utils from "../../utils/recommendation";
import { TooltipComponent } from "components/TooltipComponent";

type Props = {
  rec: RecommendationAzure;
};

export const StatusIndicators = ({ rec }: Props) => {
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();
  const userRole = useSelector((s) => s.userRole.userRole);

  if (taxonomy.isLoading) {
    return <Spinner />;
  }

  const statusCode =
    taxonomy.RiskRecommendationStatus.byId[rec?.recommendationStatusCode]?.code;

  const isPlannedImplementationLate = rec
    ? Utils.isPlannedImplementationLate(rec)
    : false;
  const isLate = rec ? Utils.isLate(rec) : false;
  let lastAction = rec.lastAction;
  if (userRole === "insurer") {
    lastAction = rec.lastActionInsurer;
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gridGap: "5px",
      }}
    >
      {rec?.noGo && (
        <TooltipComponent content={t("greco.required")}>
          <IndicatorIcon iconName="Warning12" />
        </TooltipComponent>
      )}
      {rec?.hasDraftResponses && (
        <TooltipComponent content={t("ram.recommendations.hasDraft")}>
          <IndicatorIcon iconName="EditNote" />
        </TooltipComponent>
      )}
      {statusCode === "RiskRecommendationStatus.WAITING_FOR_CLIENT" &&
        isLate && (
          <TooltipComponent content={t("greco.overdue")}>
            <IndicatorIcon iconName="Clock" />
          </TooltipComponent>
        )}
      {isPlannedImplementationLate &&
        statusCode !== "RiskRecommendationStatus.CLOSED" && (
          <TooltipComponent
            content={t("ram.recommendations.plannedImplementationLate")}
          >
            <IndicatorIcon iconName="EventDateMissed12" />
          </TooltipComponent>
        )}
      {(rec?.hasResponseAttachments || rec?.hasRecommendationAttachments) && (
        <TooltipComponent content={t("greco.attachment")}>
          <IndicatorIcon iconName="Attach" />
        </TooltipComponent>
      )}

      <TooltipComponent
        content={
          lastAction === "A"
            ? t("ram.riskAssessment.label")
            : lastAction === "R"
            ? t("ram.clientResponse.label")
            : lastAction === "E"
            ? t("ram.insurerEvaluation.label")
            : null
        }
      >
        <IndicatorIcon
          iconName={
            lastAction === "A"
              ? "CommentUrgent"
              : lastAction === "R"
              ? "CannedChat"
              : lastAction === "E"
              ? "Feedback"
              : null
          }
          style={{
            cursor: "default",
          }}
        />
      </TooltipComponent>
    </div>
  );
};
