import { ActionButton, Text } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { routes } from "../../../../config/routes";
import { useSelector } from "../../../../store/hooks";
import {
  selectCurrentRecommendationIndex,
  selectNextRecommendation,
  selectPreviousRecommendation,
  setTab,
} from "../../../../store/recommendationPage";
import { loadRecommendationsActions } from "../../../../store/recommendations/recommendations";
import { mainNavigationTypes } from "pages/recommendation/constants";

export function RecommendationPagination() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const recommendation = useSelector((s) => s.recommendationPage.rec);

  const previousRecommendation = useSelector(selectPreviousRecommendation);
  const nextRecommendation = useSelector(selectNextRecommendation);
  const numRecommendations = useSelector((s) => s.recommendations.count);

  const ids = useSelector((s) => s.recommendations.ids);

  const currentRecommendationIndex = useSelector(
    selectCurrentRecommendationIndex as any
  );

  const [prevButtonDisabled, setPrevButtonDisabled] = React.useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = React.useState(false);

  const loadMoreRows: any = async ({ startIndex, stopIndex }) => {
    dispatch(
      loadRecommendationsActions.trigger({
        skip: startIndex,
        top: stopIndex - startIndex,
        reset: false,
      })
    );
  };
  return (
    <>
      {currentRecommendationIndex !== undefined &&
      Number(currentRecommendationIndex) >= 0 ? (
        <IconsContainer>
          {previousRecommendation !== undefined ? (
            <ActionButton
              styles={{
                root: {
                  height: "auto",
                },
              }}
              iconProps={{
                iconName: "ChevronLeftMed",
                styles: {
                  root: {
                    fontSize: 13,
                  },
                },
              }}
              title={t("ram.recommendationForm.previousRecommendation.label")}
              ariaLabel={t(
                "ram.recommendationForm.previousRecommendation.label"
              )}
              onClick={() => {
                setPrevButtonDisabled(true);
                const path = routes.editRecommendation.getPath(
                  previousRecommendation.webBasePartnerNumber,
                  previousRecommendation.locationId,
                  previousRecommendation.reportId,
                  previousRecommendation.recommendationId
                );
                navigate(path + "?tab=" + mainNavigationTypes.Responses); // + "?tab=" + tab);
              }}
              disabled={prevButtonDisabled || !previousRecommendation}
            />
          ) : null}
          {previousRecommendation !== undefined ||
          nextRecommendation !== undefined ? (
            <Text>
              {t("ram.recommendationForm.recommendationCounterOf.label", [
                (currentRecommendationIndex
                  ? Number(currentRecommendationIndex)
                  : 0) + 1,
                numRecommendations,
              ])}
            </Text>
          ) : null}

          {nextRecommendation !== undefined ? (
            <ActionButton
              styles={{
                root: {
                  height: "auto",
                },
              }}
              iconProps={{
                iconName: "ChevronRightMed",
                styles: {
                  root: {
                    fontSize: 13,
                  },
                },
              }}
              title={t("ram.recommendationForm.nextRecommendation.label")}
              ariaLabel={t("ram.recommendationForm.nextRecommendation.label")}
              onClick={() => {
                setNextButtonDisabled(true);
                const path = routes.editRecommendation.getPath(
                  nextRecommendation.webBasePartnerNumber,
                  nextRecommendation.locationId,
                  nextRecommendation.reportId,
                  nextRecommendation.recommendationId
                );
                const recIndex = ids.findIndex(
                  (r) => r === recommendation.recommendationId + ""
                );
                const loadedCount = ids.length;
                if (
                  recIndex + 1 === ids.length - 1 &&
                  loadedCount !== numRecommendations
                ) {
                  loadMoreRows({
                    startIndex: loadedCount,
                    stopIndex: loadedCount + 30,
                  });
                }
                navigate(path + "?tab=" + mainNavigationTypes.Responses); // + "?tab=" + tab);
              }}
              disabled={nextButtonDisabled || !nextRecommendation}
            />
          ) : null}
        </IconsContainer>
      ) : null}
    </>
  );
}

const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
