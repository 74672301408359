import { Icon } from "@fluentui/react";
import React from "react";

type Props = {
  speeds: { key: string; text: string; color: string }[];
  selectedSpeed: number;
};

export const Speedometer = ({ speeds, selectedSpeed }: Props) => {
  return (
    <div>
      <div style={{ display: "flex" }}>
        {speeds.map((s) => {
          return (
            <div key={s.key} style={{ width: "80px" }}>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: "14px",
                  color: `${s.color}`,
                }}
              >
                {s.text}
              </div>
              <div
                style={{
                  height: "15px",
                  backgroundColor: `${s.color}`,
                }}
              ></div>
            </div>
          );
        })}
      </div>

      <Icon
        iconName="TriangleSolid"
        styles={{
          root: {
            color: "#005aa1",
            position: "relative",
            transition: "all 500ms",
            left: `${selectedSpeed * 80 + 40 - 7}px`,
          },
        }}
      />
    </div>
  );
};
