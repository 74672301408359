import React from "react";
import { Breadcrumb } from "../../components/Breadcrumb";
import { NavButtons } from "../../components/navigation/NavButtons";
import { NavigationBar, Right } from "../../components/styled";
import { SaveButton } from "./SaveButton";
import NavigationSkeleton from "../../components/NavigationSkeleton/NavigationSkeleton";

export const Navigation = ({ isLoading = false }) => {
  if (isLoading) {
    return <NavigationSkeleton />;
  }
  return (
    <NavigationBar>
      <NavButtons />
      <Breadcrumb />
      <Right>
        <SaveButton />
      </Right>
    </NavigationBar>
  );
};
