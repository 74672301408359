import { Overlay, Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import styled from "styled-components";

export const FormSpinner = () => {
  return (
    <StyledOverlay>
      <StyledSpinner size={SpinnerSize.large} />
    </StyledOverlay>
  );
};

const StyledOverlay = styled(Overlay)`
  z-index: 3;
  background-color: transparent;
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  & .ms-Spinner-circle {
    border-color: #005aa1 rgb(199, 224, 244) rgb(199, 224, 244);
  }
`;
