import React from "react";
import styled from "styled-components";
import { StyledRow } from "./Table";
import { useTranslation } from "react-i18next";

export const Header = () => {
  const { t } = useTranslation();
  return (
    <StyledRow
      style={{
        height: 42,
      }}
    >
      <HeaderCell
        style={{
          width: "40px",
        }}
      ></HeaderCell>
      <HeaderCell
        className="user-name-row"
        style={{
          flex: 1,
          maxWidth: "400px",
        }}
      ></HeaderCell>
      <HeaderCell
        className="center"
        style={{
          width: "70px",
        }}
      >
        <Truncate>{t("ram.users.internal")}</Truncate>
      </HeaderCell>
      <HeaderCell
        className="center"
        style={{
          width: "50px",
        }}
      ></HeaderCell>
    </StyledRow>
  );
};

export const Cell = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  &.center {
    justify-content: center;
  }
`;

export const Truncate = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderCell = styled(Cell)`
  font-weight: 600;
`;
