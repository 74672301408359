import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CSSProperties } from "styled-components";
import { selectEntitiesClients } from "../../store/clients/clients";
import { setClientsFilter } from "../../store/filters/filters";
import { selectClientsFilterClients } from "../../store/filters/selectors";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";
import { setManageLocationsDialog } from "../../store/users";
import { setManageReportsDialog } from "../../store/usersinsurer";
import { StyledCheckboxDropdown } from "../styled";

export const ClientsFilter = () => {
  const clients = useSelector(selectClientsFilterClients as any) as any;
  const clientsEntities = useSelector(selectEntitiesClients);
  const clientsFilter = useSelector((s) => s.filters.clientsFilter);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const hasValue = clientsFilter.length > 0;

  const hasChildren = (clientId) => {
    let childrenIds = clients
      .filter((item) => item !== undefined)
      .filter((c) => c.parentClientId === clientId)
      .map((fc) => fc.clientId);
    return childrenIds.length > 0;
  };

  const getChildrenIds = (selIds, parentId, childrenIds) => {
    let newChildrenIds = clients
      .filter((c) => c.parentClientId === parentId)
      .map((fc) => fc.clientId);
    newChildrenIds = newChildrenIds.filter((c) => {
      return !selIds.includes(c);
    });

    childrenIds.push(...newChildrenIds);

    newChildrenIds.forEach((cid) => {
      getChildrenIds(selIds, cid, childrenIds);
    });
  };

  return (
    <StyledCheckboxDropdown
      value={clientsFilter.map((id) => {
        const client = clientsEntities[id];
        return {
          label: client ? client.clientName : "",
          value: id,
        };
      })}
      onChange={(value) => {
        var selIds = value.map((o: any) => o.value);
        let newCS = selIds.filter((c) => {
          return !clientsFilter.includes(c);
        });

        let cids = [];
        newCS.forEach((c) => {
          getChildrenIds(selIds, c, cids);
        });
        selIds.push(...cids);

        dispatch(setClientsFilter(selIds));
        dispatch(
          setManageLocationsDialog({
            user: null,
            isOpen: false,
          })
        );
        dispatch(
          setManageReportsDialog({
            user: null,
            isOpen: false,
          })
        );
      }}
      options={clients
        .filter((item) => item !== undefined)
        .map((c, i) => ({
          label: `${c.clientName} (${c.clientId})`,
          value: c.clientId,
          style: {
            fontWeight: c.parentClientId ? "normal" : "bold",
            textDecorationLine: hasChildren(c.clientId) ? "underline" : "",
          } as CSSProperties,
        }))}
      label={t("ram.nav.clientsFilter.label")}
      mulitpleSelectedLabel={`${clientsFilter.length} ${t(
        "ram.nav.clientsFilter.multiSelectedLabel"
      )}`}
      searchPlaceholder={t("ram.nav.clientsFilter.searchPlaceholder")}
      className={clsx({
        hasValue,
      })}
    />
  );
};
