import { mergeStyleSets } from "@fluentui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Checkbox,
  DefaultButton,
  IDropdownOption,
  Label,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components";
import { StyledDropdown } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import {
  loadDefaultValues,
  updateDefaultValues,
} from "../../store/locationDefaultValues";
import { AppDispatch } from "../../store/store";
import { LocationDefaultValue } from "../../types/types";
import LocationDefaultValuesSkeleton from "./LocationDefaultValuesSkeleton";
import { Navigation } from "./Navigation";
import { IStyledTheme } from "../../theme/types";

export type LocationDefaultValuesSaveMode = "normal" | "addReport";

export const LocationDefaultValues = () => {
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();
  const [defaultValues, defaultValuesSet] = useState<LocationDefaultValue[]>(
    []
  );
  const theme = useTheme() as IStyledTheme;
  const classes = getClassNames(theme);
  const userRole = useSelector((s) => s.userRole.userRole);

  const [defaultValuesMap, defaultValuesMapSet] = useState({});

  const setDefaultValues = (defaultValues: LocationDefaultValue[]) => {
    defaultValuesSet(defaultValues);
    var valuesMap = defaultValues.reduce(function (map, obj) {
      map[obj.subcategoryCode] = obj;
      return map;
    }, {});
    defaultValuesMapSet(valuesMap);
  };

  const dispatch: AppDispatch = useDispatch();
  const areDefaultValuesLoading = useSelector(
    (s) => s.locationDefaultValues.areDefaultValuesLoading
  );
  const isUpdateLoading = useSelector(
    (s) => s.locationDefaultValues.isUpdateLoading
  );
  const originalDefaultValues = useSelector(
    (s) => s.locationDefaultValues.defaultValues
  );

  const isLoading = isUpdateLoading || areDefaultValuesLoading;
  const isDisabled = isLoading || userRole !== "admin";

  useEffect(() => {
    (async () => {
      const action = await dispatch(loadDefaultValues());
      const defaultValues = unwrapResult(action);
      setDefaultValues(defaultValues as LocationDefaultValue[]);
    })();
  }, []);

  const onSave = async (isAddReport?: boolean) => {
    (async () => {
      const action = await dispatch(
        updateDefaultValues({
          defaultValues,
          mode: isAddReport ? "addReport" : "normal",
        })
      );
      const updatedDefaultValues = unwrapResult(action);
      setDefaultValues(updatedDefaultValues as LocationDefaultValue[]);
    })();
  };

  const onChangeCheckbox = (taxId: number) => () => {
    setDefaultValues(
      defaultValues.map((dv) => {
        if (dv.subcategoryCode === taxId) {
          return {
            ...dv,
            isUsed: !dv.isUsed,
          };
        }
        return dv;
      })
    );
  };

  const onChangeDropdown = (taxId: number) => (_: any, o?: IDropdownOption) => {
    setDefaultValues(
      defaultValues.map((dv) => {
        if (dv.subcategoryCode === taxId) {
          return {
            ...dv,
            defaultWeighting: Number(o!.key),
          };
        }
        return dv;
      })
    );
  };

  const onClickReset = () => {
    setDefaultValues([...originalDefaultValues]);
  };

  const shouldShowSpinner = areDefaultValuesLoading || isUpdateLoading;

  return (
    <>
      <Navigation isLoading={shouldShowSpinner} />
      {shouldShowSpinner ? (
        <LocationDefaultValuesSkeleton />
      ) : (
        <div className={classes.main}>
          <div className={classes.wrap}>
            {taxonomy.RiskAssessmentCategory.items.map((cat_tax) => {
              return (
                <Block key={cat_tax.code}>
                  <CategoryHeader>{t(cat_tax.code)}</CategoryHeader>
                  <Grid>
                    {taxonomy.RiskAssessmentSubcategory.items
                      .filter(
                        (item) =>
                          item.code
                            .replaceAll("RiskAssessmentSubcategory.", "")
                            .substring(0, 1) ===
                          cat_tax.code.replaceAll("RiskAssessmentCategory.", "")
                      )
                      .map((tax) => {
                        const dv = defaultValuesMap[tax.id];
                        return (
                          <Row key={tax.id}>
                            <Checkbox
                              styles={{
                                root: {
                                  marginRight: "5px",
                                },
                              }}
                              checked={dv?.isUsed}
                              disabled={isDisabled}
                              onChange={onChangeCheckbox(tax.id)}
                            />
                            <StyledLabel>{t(tax.code)}</StyledLabel>
                            <StyledDropdown
                              styles={{
                                root: {
                                  marginBottom: 0,
                                },
                              }}
                              options={Array(10)
                                .fill(0)
                                .map((_, idx) => ({
                                  text: String(idx + 1),
                                  key: idx + 1,
                                }))}
                              selectedKey={dv?.defaultWeighting}
                              disabled={isDisabled}
                              onChange={onChangeDropdown(tax.id)}
                            />
                          </Row>
                        );
                      })}
                  </Grid>
                </Block>
              );
            })}
            <button
              id="loc-def-values-btn"
              onClick={() => onSave()}
              style={{
                display: "none",
              }}
            />
            <button
              id="loc-def-values-add-report-btn"
              onClick={() => onSave(true)}
              style={{
                display: "none",
              }}
            />
            <div
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <DefaultButton
                text={t("greco.reset")}
                styles={{
                  root: {
                    marginLeft: "auto",
                  },
                }}
                onClick={onClickReset}
                disabled={isDisabled}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 20px;
  flex: 1;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const Block = styled.div`
  padding: 10px 20px 0px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  padding-right: 30px;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
`;

const StyledLabel = styled(Label)`
  margin-right: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
`;

const CategoryHeader = styled(Label)`
  color: ${(p) => p.theme.palette.neutralPrimary};
  display: block;
  font-size: 1.17em;
  padding: 0;
  font-weight: bold;
`;

const getClassNames = (theme) =>
  mergeStyleSets({
    main: {
      maxWidth: "100%",
      display: "flex",
      margin: "0 auto",
      flexDirection: "column",
      height: "100vh",
      position: "relative",
      paddingTop: 0,
      overflow: "auto", //
    },

    wrap: {
      background: theme.palette.white,
      boxShadow:
        "0 3.2px 7.2px 0 rgba(0,0,0,.132), 0 0.6px 1.8px 0 rgba(0,0,0,.108)",
      display: "flex",
      margin: "50px auto 200px",
      borderRadius: "4px",
      minWidth: 1200,
      maxWidth: 1200,
      position: "relative",
      flexDirection: "column",
      padding: "20px 20px 50px",

      gap: 50,
    },
  });
