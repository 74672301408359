import { AsyncSelect } from "@greco/components";
import awesomeDebouncePromise from "awesome-debounce-promise";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { searchTemplates } from "../../../../../api/api";
import { getSelectStyles } from "../../../../../utils/utils";
import { Label } from "@fluentui/react";
import { useFormikContext } from "formik";
import { useTheme } from "styled-components";
import { IStyledTheme } from "../../../../../theme/types";

export const SearchTemplatesField = () => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const theme = useTheme() as IStyledTheme;
  const [value, setValue] = useState(null);

  return (
    <>
      <Label>{t("ram.recommendationForm.searchTemplates.label")}</Label>
      <AsyncSelect
        clearable
        placeholder={t("greco.form.searchPlaceholder")}
        noOptionsMessage={() => t("greco.noResults")}
        isClearable
        loadingMessage={() => t("greco.searching")}
        loadOptions={awesomeDebouncePromise(async (input: string) => {
          const res = await searchTemplates(input);
          return res.data.map((t: any) => {
            return {
              label: t.title,
              value: t.description,
            };
          });
        }, 300)}
        value={value}
        onChange={(option: any) => {
          setValue(option);

          setFieldValue("title", option ? option?.label : "");
          setFieldValue("description", option ? option?.value : "");
        }}
        styles={getSelectStyles(false, theme as IStyledTheme)}
      />
    </>
  );
};
