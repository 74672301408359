import clsx from "clsx";
import { Panel, PanelType } from "@fluentui/react";
import { IconButton } from "office-ui-fabric-react/";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getLocationSummary } from "../../api/api";
import { StyledCheckboxDropdown, VSpace } from "../../components/styled";
import {
  setCategoriesFilter,
  setClientsFilter,
  setLocationsFilter,
} from "../../store/filters/filters";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";
import {
  Client,
  ClientLocation,
  LocationSummary,
  RaitingInfo,
} from "../../types/types";
import { PanelState } from "./Locations";
import { useNavigate } from "react-router-dom";
import { routes } from "config/routes";
import {
  clearAllFilters,
  setColumns,
  setFilter,
} from "store/recommendationsColumnOptions";
import { setColumnsRecommendations } from "store/recommendations-page/recommendationsPage";
import { TaxonomyFilter } from "@greco/components/dist/components/column-options/types";

type Props = {
  panel: PanelState;
  setPanel: (panel: PanelState) => void;
};

export const Summary = ({ panel, setPanel }: Props) => {
  const navigate = useNavigate();
  const [summary, setSummary] = useState({} as LocationSummary);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const columns = useSelector((s) => s.recommendationsPage.columns);
  const columnOptions = useSelector(
    (s) => s.recommendationsColumnOptions.columns.entities
  );
  const categories = taxonomy.RiskAssessmentCategory;
  const categoriesFilter = useSelector((s) => s.filters.categoriesFilter);
  const dispatch: AppDispatch = useDispatch();

  const setRecommendationsFilter =
    (loc, columnKey, taxonomyKey, id) => async (dispatch) => {
      await dispatch(setClientsFilter([loc.webBasePartnerNumber]));
      await dispatch(setLocationsFilter([loc.locationId]));
      //await dispatch(clearAllFilters());
      const columnsOfInterest = {
        statusGrECoCode: "RiskImplementationStatus",
        statusCompanyCode: "RiskImplementationStatus",
        priorityCode: "RiskPriority",
        priorityCompanyCode: "RiskPriority",
        typeCode: "RiskCategoryOfMeasure",
        mainCategoryCode: "RiskAssessmentCategory",
        costEstimateCode: "RiskReductionCost",
      };

      Object.entries(columnsOfInterest).forEach(
        async ([columnKeyValue, taxonomyKeyValue]) => {
          await dispatch(
            setFilter({
              columnKey: columnKeyValue,
              filter: {
                ...columnOptions[columnKeyValue].filter,
                type: "taxonomy",
                taxonomyKey: taxonomyKeyValue,
                value: columnKey !== columnKeyValue ? [] : [id],
              },
            })
          );
        }
      );

      await dispatch(
        setFilter({
          columnKey: "reportCreatorCode",
          filter: {
            type: "taxonomy",
            taxonomyKey: "RiskReportCreator",
            value: [
              -taxonomy.RiskReportCreator.byCode[
                "RiskReportCreator.RISK_CLIENT"
              ]?.id,
            ],
          },
        })
      );

      await dispatch(
        setColumnsRecommendations(
          columns.map((c) => {
            if (c.key in columnsOfInterest) {
              return {
                ...c,
                filter: {
                  type: "taxonomy",
                  taxonomyKey: columnsOfInterest[c.key],
                  value: c.key === columnKey ? [id] : [],
                },
                isFiltered: (f) => f.value.length > 0,
              };
            } else if (c.key === "reportCreatorCode") {
              return {
                ...c,
                filter: {
                  type: "taxonomy",
                  taxonomyKey: "RiskReportCreator",
                  value: [
                    -taxonomy.RiskReportCreator.byCode[
                      "RiskReportCreator.RISK_CLIENT"
                    ]?.id,
                  ],
                },
                isFiltered: (f) => f.value.length > 0,
              };
            }
            return c;
          })
        )
      );
      navigate(routes.recommendations.getPath());
    };

  useEffect(() => {
    (async () => {
      if (!panel.loc) return;
      setIsLoading(true);
      const res = await getLocationSummary(
        panel.loc.locationId,
        categoriesFilter
      );
      setSummary(res.data);
      setIsLoading(false);
    })();
  }, [categoriesFilter]);

  const companies = useSelector((s) => s.clients);
  const company = companies.entities[panel.loc.webBasePartnerNumber];

  const ratingLevels = getRatingLevelsForCompany(company);
  const ratingLevelInfoTotal = getRatingLevelInfo(
    ratingLevels,
    summary?.totalRaiting
  );

  const ratingLevelInfoTotalAsIf = getRatingLevelInfoAsIf(
    ratingLevels,
    summary?.totalRaiting
  );

  if (!company) {
    return null;
  }

  return (
    <StyledPanel
      isOpen={panel.isOpen}
      onDismiss={() => setPanel({ isOpen: false, loc: null })}
      isLightDismiss
      type={PanelType.custom}
      headerText={panel.loc?.locationName}
      customWidth="900px"
    >
      <StyledCheckboxDropdown
        value={categoriesFilter.map((c) => {
          const category = categories.byId[c];
          return {
            label: t(category.code),
            value: category.id,
          };
        })}
        onChange={(value) => {
          var selIds = value.map((o: any) => o.value);
          dispatch(setCategoriesFilter(selIds));
        }}
        options={categories.items.map((c) => ({
          label: t(c.code),
          value: c.id,
        }))}
        label={t("ram.nav.categoriesFilter.label")}
        isSearchable={true}
        disabled={isLoading}
        mulitpleSelectedLabel={`${categoriesFilter.length} ${t(
          "ram.nav.categoriesFilter.multiSelectedLabel"
        )}`}
      />
      <div id="number_of_recommendations">
        <strong>
          {t("ram.locations.summary.numberOfRecommendations.label")}
        </strong>
        :{" "}
        {isLoading ? (
          <Skeleton width={40} />
        ) : (
          <span>{summary.numberOfRecommendations}</span>
        )}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "5px",
          marginTop: "5px",
          marginBottom: "5px",
        }}
      >
        <div>
          <StyledTable>
            <thead>
              <tr>
                <th
                  colSpan={5}
                  style={{
                    backgroundColor: "#005aa1",
                    padding: "5px",
                    color: "white",
                    borderBottom: "1px solid #fff",
                  }}
                >
                  {t(
                    "ram.locations.summary.implementationStatusSummaryTable.title"
                  )}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    backgroundColor: "#005aa1",
                    color: "white",
                    paddingLeft: "5px",
                    textAlign: "left",
                  }}
                >
                  {t(
                    "ram.locations.summary.implementationStatusSummaryTable.status.title"
                  )}
                </th>
                <th
                  colSpan={2}
                  style={{
                    backgroundColor: "#005aa1",
                    color: "white",
                  }}
                >
                  {t(
                    "ram.locations.summary.prioritySummaryTable.insurer.title"
                  )}
                </th>
                <th
                  colSpan={2}
                  style={{
                    backgroundColor: "#005aa1",
                    color: "white",
                  }}
                >
                  {t(
                    "ram.locations.summary.prioritySummaryTable.company.title"
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <SkeletonTableBody />
              ) : (
                summary.implementationStatusSummary.map((is) => {
                  return (
                    <tr key={is.code}>
                      <Cell>
                        {t(
                          taxonomy.RiskImplementationStatus.byId[is.code].code
                        )}
                      </Cell>
                      <Cell
                        onDoubleClick={(e) => {
                          dispatch(
                            setRecommendationsFilter(
                              panel.loc,
                              "statusGrECoCode",
                              "RiskImplementationStatus",
                              is.code
                            )
                          );
                        }}
                        style={{ textAlign: "center", cursor: "pointer" }}
                      >
                        {is.numberOfEntries}
                      </Cell>
                      <Cell
                        onDoubleClick={(e) => {
                          dispatch(
                            setRecommendationsFilter(
                              panel.loc,
                              "statusGrECoCode",
                              "RiskImplementationStatus",
                              is.code
                            )
                          );
                        }}
                        style={{ textAlign: "center", cursor: "pointer" }}
                      >
                        {Math.round(is.numberOfEntriesPercentage) + "%"}
                      </Cell>
                      <Cell
                        onDoubleClick={(e) => {
                          dispatch(
                            setRecommendationsFilter(
                              panel.loc,
                              "statusCompanyCode",
                              "RiskImplementationStatus",
                              is.code
                            )
                          );
                        }}
                        style={{ textAlign: "center", cursor: "pointer" }}
                      >
                        {
                          summary.implementationStatusCompanySummary.find(
                            (s) => s.code === is.code
                          )?.numberOfEntries
                        }
                      </Cell>
                      <Cell
                        onDoubleClick={(e) => {
                          dispatch(
                            setRecommendationsFilter(
                              panel.loc,
                              "statusCompanyCode",
                              "RiskImplementationStatus",
                              is.code
                            )
                          );
                        }}
                        style={{ textAlign: "center", cursor: "pointer" }}
                      >
                        {Math.round(
                          summary.implementationStatusCompanySummary.find(
                            (s) => s.code === is.code
                          )?.numberOfEntriesPercentage
                        ) + "%"}
                      </Cell>
                    </tr>
                  );
                })
              )}
            </tbody>
          </StyledTable>
          <VSpace height={5} />
          <StyledTable>
            <thead>
              <tr style={{ borderBottom: "1px solid #fff" }}>
                <th
                  colSpan={5}
                  style={{
                    backgroundColor: "#005aa1",
                    color: "white",
                  }}
                >
                  {t("ram.locations.summary.prioritySummaryTable.title")}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    backgroundColor: "#005aa1",
                    paddingLeft: "5px",
                    color: "white",
                    textAlign: "left",
                  }}
                >
                  {t(
                    "ram.locations.summary.prioritySummaryTable.category.title"
                  )}
                </th>
                <th
                  colSpan={2}
                  style={{
                    backgroundColor: "#005aa1",
                    color: "white",
                  }}
                >
                  {t(
                    "ram.locations.summary.prioritySummaryTable.insurer.title"
                  )}
                </th>
                <th
                  colSpan={2}
                  style={{
                    backgroundColor: "#005aa1",
                    color: "white",
                  }}
                >
                  {t(
                    "ram.locations.summary.prioritySummaryTable.company.title"
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <SkeletonTableBody />
              ) : (
                summary.prioritySummary.map((is) => {
                  return (
                    <tr key={is.code}>
                      <Cell>{t(taxonomy.RiskPriority.byId[is.code].code)}</Cell>
                      <Cell
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onDoubleClick={(e) => {
                          dispatch(
                            setRecommendationsFilter(
                              panel.loc,
                              "priorityCode",
                              "RiskPriority",
                              is.code > 3 ? -1 : is.code
                            )
                          );
                        }}
                      >
                        {is.numberOfEntries}
                      </Cell>
                      <Cell
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onDoubleClick={(e) => {
                          dispatch(
                            setRecommendationsFilter(
                              panel.loc,
                              "priorityCode",
                              "RiskPriority",
                              is.code > 3 ? -1 : is.code
                            )
                          );
                        }}
                      >
                        {Math.round(is.numberOfEntriesPercentage) + "%"}
                      </Cell>
                      <Cell
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onDoubleClick={(e) => {
                          dispatch(
                            setRecommendationsFilter(
                              panel.loc,
                              "priorityCompanyCode",
                              "RiskPriority",
                              is.code > 3 ? -1 : is.code
                            )
                          );
                        }}
                      >
                        {
                          summary.priorityCompanySummary.find(
                            (s) => s.code === is.code
                          )?.numberOfEntries
                        }
                      </Cell>
                      <Cell
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onDoubleClick={(e) => {
                          dispatch(
                            setRecommendationsFilter(
                              panel.loc,
                              "priorityCompanyCode",
                              "RiskPriority",
                              is.code > 3 ? -1 : is.code
                            )
                          );
                        }}
                      >
                        {Math.round(
                          summary.priorityCompanySummary.find(
                            (s) => s.code === is.code
                          )?.numberOfEntriesPercentage
                        ) + "%"}
                      </Cell>
                    </tr>
                  );
                })
              )}
            </tbody>
          </StyledTable>
        </div>
        <StyledTable>
          <thead>
            <tr>
              <th
                colSpan={3}
                style={{
                  backgroundColor: "#005aa1",
                  padding: "5px",
                  color: "white",
                }}
              >
                {t("ram.locations.summary.riskAnalysisTable.title")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Cell colSpan={3}>
                <strong>
                  {t("ram.locations.summary.riskAnalysisTable.byType.label")}
                </strong>
              </Cell>
            </tr>
            {isLoading ? (
              <SkeletonTableBody />
            ) : (
              summary.categoryOfMeasureSummary.map((is) => {
                return (
                  <tr key={is.code}>
                    <Cell>
                      {t(taxonomy.RiskCategoryOfMeasure.byId[is.code].code)}
                    </Cell>
                    <Cell
                      style={{ textAlign: "center", cursor: "pointer" }}
                      onDoubleClick={(e) => {
                        dispatch(
                          setRecommendationsFilter(
                            panel.loc,
                            "typeCode",
                            "RiskCategoryOfMeasure",
                            is.code
                          )
                        );
                      }}
                    >
                      {is.numberOfEntries}
                    </Cell>
                    <Cell
                      style={{ textAlign: "center", cursor: "pointer" }}
                      onDoubleClick={(e) => {
                        dispatch(
                          setRecommendationsFilter(
                            panel.loc,
                            "typeCode",
                            "RiskCategoryOfMeasure",
                            is.code
                          )
                        );
                      }}
                    >
                      {Math.round(is.numberOfEntriesPercentage) + "%"}
                    </Cell>
                  </tr>
                );
              })
            )}
            <tr>
              <Cell colSpan={3}>
                <strong>
                  {t(
                    "ram.locations.summary.riskAnalysisTable.byMainCategories.label"
                  )}
                </strong>
              </Cell>
            </tr>
            {isLoading ? (
              <SkeletonTableBody />
            ) : (
              summary.assessmentCategorySummary.map((is) => {
                return categoriesFilter.includes(is.code) ? (
                  <tr key={is.code}>
                    <Cell>
                      {t(taxonomy.RiskAssessmentCategory.byId[is.code].code)}
                    </Cell>
                    <Cell
                      style={{ textAlign: "center", cursor: "pointer" }}
                      onDoubleClick={(e) => {
                        dispatch(
                          setRecommendationsFilter(
                            panel.loc,
                            "mainCategoryCode",
                            "RiskAssessmentCategory",
                            is.code
                          )
                        );
                      }}
                    >
                      {is.numberOfEntries}
                    </Cell>
                    <Cell
                      style={{ textAlign: "center", cursor: "pointer" }}
                      onDoubleClick={(e) => {
                        dispatch(
                          setRecommendationsFilter(
                            panel.loc,
                            "mainCategoryCode",
                            "RiskAssessmentCategory",
                            is.code
                          )
                        );
                      }}
                    >
                      {Math.round(is.numberOfEntriesPercentage) + "%"}
                    </Cell>
                  </tr>
                ) : (
                  <></>
                );
              })
            )}
          </tbody>
        </StyledTable>
      </div>
      <div>
        <StyledTable>
          <thead>
            <tr>
              <th
                colSpan={5}
                style={{
                  backgroundColor: "#005aa1",
                  padding: "5px",
                  color: "white",
                }}
              >
                {t("ram.locations.summary.riskReductionTable.title")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Cell></Cell>
              {summary.reductionCostSummary?.map((s) => {
                return (
                  <Cell
                    key={s.code}
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      t(taxonomy.RiskReductionCost.byId[s.code].code)
                    )}
                  </Cell>
                );
              })}
            </tr>
            <tr>
              <Cell>
                {t(
                  "ram.locations.summary.riskReductionTable.byRiskPoints.label"
                )}
              </Cell>
              {summary.reductionCostSummary?.map((s) => {
                return (
                  <Cell
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onDoubleClick={(e) => {
                      dispatch(
                        setRecommendationsFilter(
                          panel.loc,
                          "costEstimateCode",
                          "RiskReductionCost",
                          s.code
                        )
                      );
                    }}
                    key={s.code}
                  >
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      s.numberOfEntriesPercentage + "%"
                    )}
                  </Cell>
                );
              })}
            </tr>
            <tr>
              <Cell
                style={{
                  width: isLoading ? 200 : "auto",
                }}
              >
                {t("ram.locations.summary.riskReductionTable.number.label")}
              </Cell>
              {summary.reductionCostSummary?.map((s) => {
                return (
                  <Cell
                    key={s.code}
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onDoubleClick={(e) => {
                      dispatch(
                        setRecommendationsFilter(
                          panel.loc,
                          "costEstimateCode",
                          "RiskReductionCost",
                          s.code
                        )
                      );
                    }}
                  >
                    {isLoading ? <Skeleton /> : s.numberOfEntries}
                  </Cell>
                );
              })}
            </tr>
          </tbody>
        </StyledTable>
        <VSpace height={5} />
        {isLoading && <Skeleton height={120} />}
        {!isLoading && (
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={categoriesFilter.length + 1}
                  style={{
                    backgroundColor: "#005aa1",
                    padding: "5px",
                    color: "white",
                  }}
                >
                  {t("ram.locations.summary.currentAssessmentTable.title")}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ borderBottom: "1px solid #000" }}>
                {summary.raitingInfo?.map((s) => {
                  const ratingLevelInfo = getRatingLevelInfo(ratingLevels, s);
                  return categoriesFilter.includes(s.categoryCode) ? (
                    <Cell
                      key={s.categoryCode}
                      style={{
                        backgroundColor:
                          ratingLevelInfo.raitingLevel.backgroundColor,
                        color: ratingLevelInfo.raitingLevel.color,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {t(
                        taxonomy.RiskAssessmentCategory.byId[s.categoryCode]
                          .code
                      )}
                    </Cell>
                  ) : (
                    <></>
                  );
                })}
                <Cell
                  style={{
                    backgroundColor:
                      ratingLevelInfoTotal.raitingLevel.backgroundColor,
                    color: ratingLevelInfoTotal.raitingLevel.color,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {t("ram.locations.summary.ratingInfoTable.total.label")}
                </Cell>
              </tr>

              <tr>
                {summary.raitingInfo?.map((s) => {
                  const ratingLevelInfo = getRatingLevelInfo(ratingLevels, s);

                  return categoriesFilter.includes(s.categoryCode) ? (
                    <Cell
                      key={s.categoryCode}
                      style={{
                        backgroundColor:
                          ratingLevelInfo.raitingLevel.backgroundColor,
                        color: ratingLevelInfo.raitingLevel.color,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onDoubleClick={(e) => {
                        dispatch(
                          setRecommendationsFilter(
                            panel.loc,
                            "mainCategoryCode",
                            "RiskAssessmentCategory",
                            s.categoryCode
                          )
                        );
                      }}
                    >
                      {ratingLevelInfo.isNoGo === false ? s.numberOfPoints : ""}
                    </Cell>
                  ) : (
                    <></>
                  );
                })}
                <Cell
                  style={{
                    backgroundColor:
                      ratingLevelInfoTotal.raitingLevel.backgroundColor,
                    color: ratingLevelInfoTotal.raitingLevel.color,
                    textAlign: "center",
                  }}
                >
                  {ratingLevelInfoTotal.isNoGo === false
                    ? summary.totalRaiting?.numberOfPoints
                    : ""}
                </Cell>
              </tr>
              <tr>
                {summary.raitingInfo?.map((s) => {
                  const ratingLevelInfo = getRatingLevelInfo(ratingLevels, s);

                  return categoriesFilter.includes(s.categoryCode) ? (
                    <Cell
                      key={s.categoryCode}
                      style={{
                        backgroundColor:
                          ratingLevelInfo.raitingLevel.backgroundColor,
                        color: ratingLevelInfo.raitingLevel.color,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onDoubleClick={(e) => {
                        dispatch(
                          setRecommendationsFilter(
                            panel.loc,
                            "mainCategoryCode",
                            "RiskAssessmentCategory",
                            s.categoryCode
                          )
                        );
                      }}
                    >
                      {ratingLevelInfo.isNoGo === false ? (
                        Math.round(s.percentage) + "%"
                      ) : (
                        <IconButton iconProps={{ iconName: "Flag" }} />
                      )}
                    </Cell>
                  ) : (
                    <></>
                  );
                })}
                <Cell
                  style={{
                    backgroundColor:
                      ratingLevelInfoTotal.raitingLevel.backgroundColor,
                    color: ratingLevelInfoTotal.raitingLevel.color,
                    textAlign: "center",
                  }}
                >
                  {ratingLevelInfoTotal.isNoGo === false ? (
                    Math.round(summary.totalRaiting?.percentage) + "%"
                  ) : (
                    <IconButton iconProps={{ iconName: "Flag" }} />
                  )}
                </Cell>
              </tr>
            </tbody>
          </table>
        )}
        {!isLoading && (
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={categoriesFilter.length + 1}
                  style={{
                    backgroundColor: "#005aa1",
                    padding: "5px",
                    color: "white",
                  }}
                >
                  {t("ram.locations.summary.asIfAssessmentTable.title")}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ borderBottom: "1px solid #000" }}>
                {summary.raitingInfo?.map((s) => {
                  const ratingLevelInfoAsIf = getRatingLevelInfoAsIf(
                    ratingLevels,
                    s
                  );
                  return categoriesFilter.includes(s.categoryCode) ? (
                    <Cell
                      key={s.categoryCode}
                      style={{
                        backgroundColor:
                          ratingLevelInfoAsIf.raitingLevel.backgroundColor,
                        color: ratingLevelInfoAsIf.raitingLevel.color,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {t(
                        taxonomy.RiskAssessmentCategory.byId[s.categoryCode]
                          .code
                      )}
                    </Cell>
                  ) : (
                    <></>
                  );
                })}
                <Cell
                  style={{
                    backgroundColor:
                      ratingLevelInfoTotalAsIf.raitingLevel.backgroundColor,
                    color: ratingLevelInfoTotalAsIf.raitingLevel.color,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {t("ram.locations.summary.ratingInfoTable.total.label")}
                </Cell>
              </tr>

              <tr>
                {summary.raitingInfo?.map((s) => {
                  const ratingLevelInfoAsIf = getRatingLevelInfoAsIf(
                    ratingLevels,
                    s
                  );

                  return categoriesFilter.includes(s.categoryCode) ? (
                    <Cell
                      key={s.categoryCode}
                      style={{
                        backgroundColor:
                          ratingLevelInfoAsIf.raitingLevel.backgroundColor,
                        color: ratingLevelInfoAsIf.raitingLevel.color,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onDoubleClick={(e) => {
                        dispatch(
                          setRecommendationsFilter(
                            panel.loc,
                            "mainCategoryCode",
                            "RiskAssessmentCategory",
                            s.categoryCode
                          )
                        );
                      }}
                    >
                      {ratingLevelInfoAsIf.isNoGo === false
                        ? s.numberOfPointsAsIf
                        : ""}
                    </Cell>
                  ) : (
                    <></>
                  );
                })}
                <Cell
                  style={{
                    backgroundColor:
                      ratingLevelInfoTotalAsIf.raitingLevel.backgroundColor,
                    color: ratingLevelInfoTotalAsIf.raitingLevel.color,
                    textAlign: "center",
                  }}
                >
                  {ratingLevelInfoTotalAsIf.isNoGo === false
                    ? summary.totalRaiting?.numberOfPointsAsIf
                    : ""}
                </Cell>
              </tr>
              <tr>
                {summary.raitingInfo?.map((s) => {
                  const ratingLevelInfoAsIf = getRatingLevelInfoAsIf(
                    ratingLevels,
                    s
                  );

                  return categoriesFilter.includes(s.categoryCode) ? (
                    <Cell
                      key={s.categoryCode}
                      style={{
                        backgroundColor:
                          ratingLevelInfoAsIf.raitingLevel.backgroundColor,
                        color: ratingLevelInfoAsIf.raitingLevel.color,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onDoubleClick={(e) => {
                        dispatch(
                          setRecommendationsFilter(
                            panel.loc,
                            "mainCategoryCode",
                            "RiskAssessmentCategory",
                            s.categoryCode
                          )
                        );
                      }}
                    >
                      {ratingLevelInfoAsIf.isNoGo === false ? (
                        Math.round(s.percentageAsIf) + "%"
                      ) : (
                        <IconButton iconProps={{ iconName: "Flag" }} />
                      )}
                    </Cell>
                  ) : (
                    <></>
                  );
                })}
                <Cell
                  style={{
                    backgroundColor:
                      ratingLevelInfoTotalAsIf.raitingLevel.backgroundColor,
                    color: ratingLevelInfoTotalAsIf.raitingLevel.color,
                    textAlign: "center",
                  }}
                >
                  {ratingLevelInfoTotalAsIf.isNoGo === false ? (
                    Math.round(summary.totalRaiting?.percentageAsIf) + "%"
                  ) : (
                    <IconButton iconProps={{ iconName: "Flag" }} />
                  )}
                </Cell>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <VSpace height={10} />
      <div style={{ marginBottom: "5px", display: "inline-block" }}>
        <Cell
          style={{
            backgroundColor: ratingLevels.excellent.backgroundColor,
            color: ratingLevels.excellent.color,
            textAlign: "center",
          }}
        >
          {t("RiskRating.EXCELLENT")}
          <br />
          0-{company.goodRangeMin - 1}%
        </Cell>
        <Cell
          style={{
            backgroundColor: ratingLevels.good.backgroundColor,
            color: ratingLevels.good.color,
            textAlign: "center",
          }}
        >
          {t("RiskRating.GOOD")}
          <br />
          {company.goodRangeMin}-{company.moderateRangeMin - 1}%
        </Cell>
        <Cell
          style={{
            backgroundColor: ratingLevels.moderate.backgroundColor,
            color: ratingLevels.moderate.color,
            textAlign: "center",
          }}
        >
          {t("RiskRating.MODERATE")}
          <br />
          {company.moderateRangeMin}-{company.poorRangeMin - 1}%
        </Cell>
        <Cell
          style={{
            backgroundColor: ratingLevels.poor.backgroundColor,
            color: ratingLevels.poor.color,
            textAlign: "center",
          }}
        >
          {t("RiskRating.POOR")}
          <br />
          {company.poorRangeMin}-100%
        </Cell>
        <Cell
          style={{
            backgroundColor: ratingLevels.notDefined.backgroundColor,
            color: ratingLevels.notDefined.color,
            textAlign: "center",
          }}
        >
          {t("RiskRating.NOT_APPLICABLE")}
        </Cell>
        <Cell
          style={{
            backgroundColor: ratingLevels.nogo.backgroundColor,
            color: ratingLevels.nogo.color,
            textAlign: "center",
          }}
        >
          {t("ram.recommendationForm.noGo.label")}
        </Cell>
      </div>
    </StyledPanel>
  );
};

const getRatingLevelInfo = (ratingLevels: any, ratingInfo?: RaitingInfo) => {
  let ratingLevel;
  if (!ratingInfo) return { raitingLevel: ratingLevels.poor, isNoGo: false };

  const { hasNoGo, percentage, isUsed } = ratingInfo;

  if (hasNoGo) {
    ratingLevel = ratingLevels.nogo;
  } else if (Math.round(percentage) === ratingLevels.notDefined.percentage) {
    if (isUsed) {
      ratingLevel = ratingLevels.excellent;
    } else {
      ratingLevel = ratingLevels.notDefined;
    }
  } else if (Math.round(percentage) < ratingLevels.excellent.percentage)
    ratingLevel = ratingLevels.excellent;
  else if (Math.round(percentage) < ratingLevels.good.percentage)
    ratingLevel = ratingLevels.good;
  else if (Math.round(percentage) < ratingLevels.moderate.percentage)
    ratingLevel = ratingLevels.moderate;
  else ratingLevel = ratingLevels.poor;

  return { raitingLevel: ratingLevel, isNoGo: hasNoGo };
};

const getRatingLevelInfoAsIf = (
  ratingLevels: any,
  ratingInfo?: RaitingInfo
) => {
  let ratingLevel;
  if (!ratingInfo) return { raitingLevel: ratingLevels.poor, isNoGo: false };

  const { hasNoGoAsIf, percentageAsIf, isUsed } = ratingInfo;

  if (hasNoGoAsIf) {
    ratingLevel = ratingLevels.nogo;
  } else if (
    Math.round(percentageAsIf) === ratingLevels.notDefined.percentage
  ) {
    if (isUsed) {
      ratingLevel = ratingLevels.excellent;
    } else {
      ratingLevel = ratingLevels.notDefined;
    }
  } else if (Math.round(percentageAsIf) < ratingLevels.excellent.percentage)
    ratingLevel = ratingLevels.excellent;
  else if (Math.round(percentageAsIf) < ratingLevels.good.percentage)
    ratingLevel = ratingLevels.good;
  else if (Math.round(percentageAsIf) < ratingLevels.moderate.percentage)
    ratingLevel = ratingLevels.moderate;
  else ratingLevel = ratingLevels.poor;

  return { raitingLevel: ratingLevel, isNoGo: hasNoGoAsIf };
};

const getRatingLevelsForCompany = (company: Client) => {
  const ratingLevels = {
    nogo: {
      percentage: 0,
      backgroundColor: "#aaa582",
      color: "#252423",
    },
    poor: {
      percentage: 100,
      backgroundColor: "#f24236",
      color: "#252423",
    },
    moderate: {
      percentage: company?.poorRangeMin,
      backgroundColor: "#f57c00",
      color: "#252423",
    },
    good: {
      percentage: company?.moderateRangeMin,
      backgroundColor: "#ffe74c",
      color: "#252423",
    },
    excellent: {
      percentage: company?.goodRangeMin,
      backgroundColor: "#7ac74f",
      color: "#252423",
    },
    notDefined: {
      percentage: 0,
      backgroundColor: "#fff",
      color: "#252423",
    },
  };
  return ratingLevels;
};

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid ${(p) => p.theme.palette.neutralLight};
`;

const Cell = styled.td`
  background-color: ${(p) => p.theme.palette.white};
  padding: 3px 10px;
  border: 1px solid #eee;
`;

const SkeletonTableBody = ({
  cols = 3,
  rows = 5,
}: {
  cols?: number;
  rows?: number;
}) => {
  return (
    <>
      {Array(rows)
        .fill(0)
        .map((_, idx) => {
          return (
            <tr key={idx}>
              {Array(cols)
                .fill(0)
                .map((_, idx) => {
                  return (
                    <Cell key={idx}>
                      <Skeleton />
                    </Cell>
                  );
                })}
            </tr>
          );
        })}
    </>
  );
};

const StyledPanel = styled(Panel)`
  &&& .ms-Panel-commands {
    margin: 0;
  }
  .ms-Panel-closeButton {
    margin: 0;
  }
  .ms-Panel-header {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
  }
  .ms-Panel-content {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
