import jsonp from "jsonp";

export const searchLocationByPoint = async (lat: number, long: number) => {
  const url = `https://atlas.microsoft.com/search/address/reverse/json?subscription-key=${process.env.REACT_APP_AZURE_MAPS_KEY}&api-version=1.0&query=${lat},${long}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "x-ms-client-id": process.env.REACT_APP_AZURE_MAPS_CLIENT_ID,
    },
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  if (!data || data.addresses.length === 0) {
    return [];
  }
  const results = data.addresses as any[];

  return results;
};
