import styled from "styled-components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BoxSkeleton } from "../../../../../components/BoxSkeleton/BoxSkeleton";
import { useSelector } from "../../../../../store/hooks";
import { Assessment } from "./Assessment";
import { Evaluation } from "./Evaluation";
import { Response } from "./Response";

export const ResponseList = () => {
  const { t } = useTranslation();
  const responses = useSelector((s) => s.recommendationPage.responses);
  const evaluations = useSelector((s) => s.recommendationPage.evaluations);
  const assessments = useSelector((s) => s.recommendationPage.assessments);
  const responsesLoading = useSelector(
    (s) => s.recommendationPage.responsesLoadStatus === "loading"
  );
  const assessmentsLoading = useSelector(
    (s) => s.recommendationPage.assessRecommendationLoadStatus === "loading"
  );

  const evaluationsLoading = useSelector(
    (s) => s.recommendationPage.evaluationsLoadStatus === "loading"
  );

  const items = useMemo(() => {
    let retVal = [];
    responses.forEach((r) => {
      retVal.push({
        date: new Date(r.clientResponse.dateOfResponse),
        lastModifiedAt: new Date(r.clientResponse.responseLastModifiedAt),
        item: r,
        type: "ClientResponse",
      });
    });
    evaluations.forEach((r) => {
      retVal.push({
        date: new Date(r.insurerEvaluation.dateOfEvaluation),
        lastModifiedAt: new Date(r.insurerEvaluation.evaluationLastModifiedAt),
        item: r,
        type: "InsurerEvaluation",
      });
    });
    assessments.forEach((r) => {
      retVal.push({
        date: new Date(r.assessment.dateOfAssessment),
        lastModifiedAt: new Date(r.assessment.assessmentLastModifiedAt),
        item: r,
        type: "Assessment",
      });
    });
    retVal = retVal.sort((a, b) => {
      // First, sort by date in descending order
      const dateDifference = b.date - a.date;
      if (dateDifference !== 0) {
        return dateDifference;
      }
      // If the dates are the same, then sort by lastModifiedAt in descending order
      return b.lastModifiedAt - a.lastModifiedAt;
    });
    return retVal;
  }, [responses, evaluations, assessments]);

  const isLoading =
    responsesLoading || assessmentsLoading || evaluationsLoading;

  if (isLoading && items.length === 0) {
    return <BoxSkeleton />;
  }

  if (!isLoading && items.length === 0) {
    return <NoResponses>{t("ram.reponsesPage.noRecommendations")}</NoResponses>;
  }

  return (
    <>
      {items.map((i) => {
        if (i.type === "ClientResponse") {
          return (
            <Response
              key={i.item.clientResponse.responseId}
              response={i.item}
            />
          );
        } else if (i.type === "Assessment") {
          return (
            <Assessment
              key={i.item.assessment.assessmentId}
              assessment={i.item}
            />
          );
        } else if (i.type === "InsurerEvaluation") {
          return (
            <Evaluation
              key={i.item.insurerEvaluation.evaluationId}
              evaluation={i.item}
            />
          );
        }
      })}
    </>
  );
};

const NoResponses = styled.div`
  padding-left: 20px;
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  height: 500px;
`;
