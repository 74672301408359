import { useFormikContext } from "formik";
import React from "react";
import { sideNavigationTypes } from "../../constants";
import { AttachmentsList } from "./AttachmentList";

type Props = {
  isDisabled: boolean;
};

function AttachmentsFormField({ isDisabled }: Props) {
  const { values, setFieldValue } = useFormikContext<any>();

  return (
    <div id={sideNavigationTypes.Attachments}>
      <AttachmentsList
        attachments={values?.attachments ?? []}
        setAttachments={(attachments) => {
          setFieldValue("attachments", attachments);
        }}
        isReadOnly={isDisabled}
      />
    </div>
  );
}

export default AttachmentsFormField;
