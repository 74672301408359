import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormSpinner } from "../../../../components/FormSpinner";
import {
  Flex,
  HSpace,
  StyledPrimaryButton,
} from "../../../../components/styled";
import { useSelector } from "../../../../store/hooks";
import {
  exportToZipRoutine,
  setExportToZipDialog,
  setExportToZipLink,
} from "store/recommendationPage";

export const ExportToZipDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { locationId, recommendationId } = useSelector(
    (s) => s.recommendationPage.exportToZipDialog
  );
  const exportToZipLink = useSelector(
    (s) => s.recommendationPage.exportToZipLink
  );
  const isLoading = useSelector(
    (s) => s.recommendationPage.exportToZipLoadStatus === "loading"
  );

  const close = () => {
    dispatch(
      setExportToZipDialog({
        locationId: null,
        recommendationId: null,
        isOpen: false,
      })
    );
    dispatch(setExportToZipLink(null));
  };

  return (
    <Dialog
      hidden={false}
      css={{ width: "400px" }}
      dialogContentProps={{
        showCloseButton: true,
        title: (
          <Flex>
            <Icon iconName="Info" /> <HSpace />
            <div>{t("ram.recommendationExportToZip.label")}</div>
          </Flex>
        ),
      }}
      onDismiss={close}
    >
      <div>{t("ram.recommendationList.exportToZip.message")}</div>
      <DialogFooter>
        {!exportToZipLink && (
          <StyledPrimaryButton
            text={t("ram.reportDocumentDialogGenerate.label")}
            onClick={() => {
              dispatch(
                exportToZipRoutine.trigger({
                  recommendationId: recommendationId,
                  locationId: locationId,
                })
              );
            }}
            disabled={isLoading}
          />
        )}
        {exportToZipLink && (
          <StyledPrimaryButton
            text={t("ram.reportDocumentDialogDownload.label")}
            title={exportToZipLink.name}
            onClick={() => {
              window.open(exportToZipLink.url);
              dispatch(
                setExportToZipDialog({
                  isOpen: false,
                  recommendationId: null,
                  locationId: null,
                })
              );
              dispatch(setExportToZipLink(null));
            }}
            disabled={isLoading}
          />
        )}
      </DialogFooter>
      {isLoading && <FormSpinner />}
    </Dialog>
  );
};
