import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDidTrySubmitLocation } from "../../store/locationPage";

export const useResetState = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setDidTrySubmitLocation(false));
    };
  }, []);
};
