import { DetailsList, DetailsListColumn } from "@greco/components";
import { TFunction } from "i18next";
import { Checkbox, Persona, PersonaSize } from "@fluentui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components";
import { Flex, HSpace } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import {
  loadUsersRoutine,
  selectShownUsers,
  setManageLocationsDialog,
} from "../../store/users";
import { useWindowSize } from "../../utils/useWindowSize";
import { ManageLocationsDialog } from "./ManageLocationsDialog";
import { Navigation } from "./navigation/Navigation";
import { IStyledTheme } from "../../theme/types";

const getColumns = (t: TFunction) => {
  const columns: DetailsListColumn[] = [
    {
      key: "userName",
      width: 400,
      label: "User",
      align: "left",
      transform: ({ row }) => (
        <Flex
          style={{
            flexWrap: "nowrap",
          }}
        >
          <Persona
            text={row?.userName}
            hidePersonaDetails
            size={PersonaSize.size24}
          />
          <HSpace />
          <div>
            <div>{row?.userName}</div>
            <EmailAddress>{row?.userEmailAddress}</EmailAddress>
          </div>
        </Flex>
      ),
    },
    {
      key: "internal",
      width: 100,
      label: t("ram.users.internal"),
      transform: ({ row }) => <Checkbox checked={row?.isInternal} disabled />,
    },
    {
      key: "limited",
      width: 100,
      label: t("ram.users.limited"),
      transform: ({ row }) => <Checkbox checked={row?.isLimited} disabled />,
    },
    {
      key: "advanced",
      width: 100,
      label: t("ram.users.advanced"),
      transform: ({ row }) => <Checkbox checked={row?.isAdvanced} disabled />,
    },
    {
      key: "notifications",
      width: 120,
      label: t("header.Notifications"),
      transform: ({ row }) => (
        <Checkbox checked={row?.shouldReceiveNotifications} disabled />
      ),
    },
    {
      key: "filler",
      width: 0,
      label: "",
    },
  ];
  return columns;
};

const EmailAddress = styled.div`
  color: ${(p) => p.theme.palette.neutralSecondaryAlt};
`;

export const UsersList = () => {
  const { t } = useTranslation();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const dispatch = useDispatch();
  const shownUsers = useSelector(selectShownUsers);
  const dialog = useSelector((s) => s.users.manageLocationsDialog);
  const theme = useTheme() as IStyledTheme;

  useEffect(() => {
    dispatch(loadUsersRoutine.trigger());
  }, []);

  const columns = getColumns(t);

  return (
    <>
      <Navigation />
      <Flex>
        <DetailsList
          id="usersList"
          columns={columns}
          rows={shownUsers}
          columnWidth={({ index }) => {
            if (columns[index].key === "filler") {
              const width =
                windowWidth / 2 -
                columns.reduce((sum, c) => sum + c.width, 0) -
                5;
              return Math.max(width, 0);
            }
            return columns[index].width;
          }}
          style={{
            cursor: "pointer",
          }}
          width={windowWidth / 2}
          height={windowHeight - (50 + 44)}
          onClickCell={({ row }) => {
            dispatch(
              setManageLocationsDialog({
                user: row,
                isOpen: true,
              })
            );
          }}
          cellClassName={({ row }) => {
            if (row?.userId === dialog.user?.userId) {
              return `background: ${theme.palette.themeLight} !important;
           
              `;
            }
          }}
        />
        {dialog.isOpen && <ManageLocationsDialog />}
      </Flex>
    </>
  );
};
