import { IconButton } from "@fluentui/react";
import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { selectIsFiltered } from "../../store/filters/selectors";
import { useSelector } from "../../store/hooks";
import { useWindowSize } from "../../utils/useWindowSize";
import { FiltersModal } from "./FiltersModal";

type Props = {
  children: ReactNode;
};

export const Filters = ({ children }: Props) => {
  const { width } = useWindowSize();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isFiltered = useSelector(selectIsFiltered as any);

  if (width <= 1367) {
    return (
      <>
        <IconButton
          iconProps={{ iconName: isFiltered ? "FilterSolid" : "Filter" }}
          styles={{
            root: {
              marginLeft: "5px",
            },
          }}
          onClick={() => {
            setIsModalOpen(!isModalOpen);
          }}
        />
        <FiltersModal isOpen={isModalOpen} close={() => setIsModalOpen(false)}>
          {children}
        </FiltersModal>
      </>
    );
  }

  return <FlexContainer>{children}</FlexContainer>;
};

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  & > * {
    margin-right: 5px;
  }
`;
