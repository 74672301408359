import { createSelector } from "@reduxjs/toolkit";
import { defaultCols } from "../../pages/recommendations/cols";
import { ExpandedColumn } from "../../types/columns";
import { RecommendationAzure } from "../../types/types";
import { hydrateColumn } from "../../utils/columns";
import { RootState } from "../store";

export const selectCanNotify = createSelector(
  (s) => s.locations,
  (s) => s.taxonomy,
  (locationsState: any, taxonomy: any) => (rec: RecommendationAzure) => {
    if (locationsState.isLoading || taxonomy.isLoading) {
      return {
        isLoading: true,
        result: false,
      };
    }

    const loc = locationsState.entities[rec.locationId];

    if (!loc) {
      return {
        isLoading: true,
        result: false,
      };
    }

    const hasAssessmentPeriodStart = loc.assessmentPeriodStartedAt !== null;

    const statusCode =
      taxonomy.RiskRecommendationStatus.byId[rec.recommendationStatusCode].code;
    const isStatusGREG =
      statusCode === "RiskRecommendationStatus.WAITING_FOR_GREG";

    return {
      isLoading: false,
      result: !hasAssessmentPeriodStart && isStatusGREG,
    };
  }
);

export const selectColumnsTable = createSelector(
  (s) => s.recommendationsPage.columns,
  (s) => s.taxonomy,
  (s) => s.recommendationsPage.sort,

  (columns: any, taxonomy, sort: any) => (t) => {
    const filteredTableColumns = (columns as any)?.filter(
      (item) => item.isVisible
    );

    const withTranslatedHeader = filteredTableColumns.map((column: any) => {
      let iconName = column.iconName;
      if (column.key === sort.key && column.isSortable) {
        iconName =
          sort.key === column.key
            ? sort!.dir === "asc"
              ? "SortUp"
              : "SortDown"
            : "Sort";
      }
      return {
        ...column,
        name: t(column.name),
        iconName: iconName,
      };
    });
    return withTranslatedHeader;
    // return columns
    //   .filter((c) => c.isVisible)
    //   .map((c) => {
    //     // return c.detailsListColumn({ t, taxonomy, width: c.width });
    //     return c;
    //   });
  }
);

export const selectIsAnyFiltered = createSelector(
  (s) => s.recommendationsPage.columns,
  (columns: any) => {
    return columns.some((c) => {
      if (c.filter) {
        return c.filter.isFiltered
          ? c.filter.isFiltered(c.filter as any)
          : true;
      }
      return false;
    });
  }
);

export const selectSelectedView = createSelector(
  (s) => s.recommendationsPage.selectedViewId,
  (s) => s.settings.entities,
  (id: any, entities) => {
    if (id === null) return null;
    const view = entities[id];
    if (!view) return null;
    return entities[id];
  }
);

export const selectIsViewSelected = (s: RootState) => {
  return s.recommendationsPage.selectedViewId !== null;
};

export const selectColumnsSelectedView = createSelector(
  selectSelectedView,
  (view: any) => {
    if (view === null) return defaultCols;
    const columns = JSON.parse(view.userAppSettingValue) as any[];
    const mappedColumns = columns.map((c) =>
      hydrateColumn({ column: c, defaultColumns: defaultCols })
    );
    return mappedColumns;
  }
);

export const selectVisibleColumns = createSelector(
  (s) => s.recommendationsPage.columns,
  (columns: any) => columns.filter((c) => c.isVisible)
);
