import React from "react";
import { useTranslation } from "react-i18next";
import { StyledPrimaryButton } from "../../../components/styled";

export const SaveButton = ({
  onClick,
  isLoading,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledPrimaryButton
      onClick={onClick}
      text={t("greco.generate")}
      disabled={isDisabled}
      iconProps={{
        iconName: isLoading ? "ProgressRingDots" : "",
      }}
    />
  );
};
