import { TooltipComponent } from "components/TooltipComponent";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";

type Props = {
  value: Partial<string>;
  taxonomyItem: Partial<string>;
  showCodeNumber?: boolean;
};
function TranslatedTaxonomyCell({
  value,
  taxonomyItem,
  showCodeNumber = false,
}: Props) {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);

  const codeValue = taxonomy[taxonomyItem]?.byId[value]?.code;

  const codeNumber = codeValue?.split(".")[1];

  const displayValue = `${showCodeNumber ? `${codeNumber} ` : ""} ${t(
    codeValue
  )}`;

  return (
    <TooltipComponent content={displayValue}>{displayValue}</TooltipComponent>
  );
}

export default TranslatedTaxonomyCell;
