import * as React from "react";
import { logout } from "../api/GraphService";
import useAcc from "../assets/picker_account_add.svg";
import background from "../assets/background-login.jpg";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const NoAccess = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Content>
        <h2>{t("ram.noAccess")}</h2>

        <MsgWrap onClick={() => logout()}>
          <img src={useAcc} />
          <span>{t("ram.useAnotherAccount")}</span>
        </MsgWrap>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: url(${background}) no-repeat;
  background-size: cover;
`;

const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  position: relative;
  max-width: 440px;
  width: calc(100% - 40px);
  padding: 44px;
  margin-bottom: 28px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  min-width: 320px;
  min-height: 338px;
  overflow: hidden;
`;

const MsgWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  margin-top: 15px;

  &:hover {
    background: #faf9f8;
  }

  span {
    flex: 1;
    margin-left: 10px;
  }
`;
