import {
  IShimmerElement,
  Shimmer,
  ShimmerElementType,
} from "@fluentui/react/lib/Shimmer";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import * as React from "react";

const classes = mergeStyleSets({
  navigation: {
    height: 44,
  },
  wrapp: {
    marginTop: 50,
  },
  content: {
    maxWidth: "1200px",
    margin: "50px auto",
    position: "relative",
  },
});

type Props = {
  height?: number;
};

export const BoxSkeleton = ({ height = 500 }: Props) => {
  const formSeleton: IShimmerElement[] = [
    {
      type: ShimmerElementType.line,
      verticalAlign: "bottom",
      width: "100%",
      height,
    },
  ];
  return (
    <>
      <div className={classes.content}>
        <div className={classes.wrapp}>
          <Shimmer shimmerElements={formSeleton} />
        </div>
      </div>
    </>
  );
};
