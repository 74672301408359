import React, { Fragment, useState } from "react";
import { Client, ClientLocation } from "../../types/types";
import { Checkbox, IconButton } from "@fluentui/react";
import styled from "styled-components";
import { useSelector } from "../../store/hooks";

export const UsersGrouped = ({
  locationsIdList,
  setLocationIdList,
  clients,
  locationsFiltered,
}: {
  locationsIdList: [] | any;
  setLocationIdList: Function;
  clients: [] | any;
  locationsFiltered: [] | any;
}) => {
  const [shown, setShown] = useState({});
  const clientsFilter = useSelector((s) => s.filters.clientsFilter);
  const locationsFilter = useSelector((s) => s.filters.locationsFilter);

  const toggleGroup = (clientId: number) => {
    setShown({ ...shown, [clientId]: !shown[clientId] });
  };

  const isElementOfArray = (arr: [] | any, id: number | any) => {
    return arr.includes(id);
  };

  const isGroupChecked = (clientId: Number) => {
    let checked = undefined;

    let res = true;
    locationsFiltered.map((l) => {
      if (Number(l.webBasePartnerNumber) === Number(clientId)) {
        res = res && isElementOfArray(locationsIdList, l.locationId);
      }
    });
    checked = res;

    return checked;
  };

  const isGroupIndeterminated = (clientId: Number) => {
    let isIndeterminate = false;

    let locationsTotal = 0;
    let locationsChecked = 0;

    locationsFiltered.map((l) => {
      if (Number(l.webBasePartnerNumber) === Number(clientId)) {
        locationsTotal = locationsTotal + 1;

        if (isElementOfArray(locationsIdList, l.locationId))
          locationsChecked = locationsChecked + 1;
      }
    });

    if (locationsChecked > 0 && locationsChecked < locationsTotal) {
      isIndeterminate = true;
    }

    return isIndeterminate;
  };

  return (
    <div>
      {clients
        .filter((c) => {
          let ret = locationsFilter.length === 0;
          if (ret === false) {
            locationsFiltered.map((l) => {
              if (Number(l.webBasePartnerNumber) === Number(c.clientId)) {
                if (locationsFilter.indexOf(l.locationId) >= 0) {
                  ret = true;
                }
              }
            });
          }
          ret =
            ret &&
            (clientsFilter.length == 0 ||
              clientsFilter.indexOf(c.clientId) >= 0 ||
              locationsFilter.length > 0) &&
            locationsFiltered.some(
              (l: ClientLocation) =>
                Number(l.webBasePartnerNumber) === Number(c.clientId)
            );
          return ret;
        })
        .map((c: Client) => {
          return (
            <Fragment key={c.clientId}>
              <Row>
                <div onClick={() => toggleGroup(c.clientId)}>
                  <IconButton
                    iconProps={{
                      iconName: shown[c.clientId]
                        ? "ChevronDownMed"
                        : "ChevronRightMed",
                    }}
                    styles={{
                      root: {
                        fontSize: "12px",
                        "&:hover": {
                          background: "transparent",
                        },
                      },
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    fontSize: "14px",
                  }}
                  onClick={() => toggleGroup(c.clientId)}
                >
                  {c.clientName}
                </div>
                <div
                  style={{
                    width: "5%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={isGroupChecked(Number(c.clientId))}
                    indeterminate={isGroupIndeterminated(Number(c.clientId))}
                    onChange={(e, value) => {
                      if (value === true) {
                        locationsFiltered.map((l: ClientLocation) => {
                          if (
                            Number(l.webBasePartnerNumber) ===
                            Number(c.clientId)
                          ) {
                            locationsIdList = [
                              ...locationsIdList,
                              l.locationId,
                            ];
                          }
                        });

                        setLocationIdList(
                          locationsIdList.filter(
                            (item: any, index: any) =>
                              locationsIdList.indexOf(item) === index
                          )
                        );
                      } else if (value === false) {
                        var remove = [];
                        locationsFiltered.map((l: ClientLocation) => {
                          if (
                            Number(l.webBasePartnerNumber) ===
                            Number(c.clientId)
                          ) {
                            remove.push(l.locationId);
                          }
                        });
                        setLocationIdList(
                          locationsIdList.filter(function (item) {
                            return remove.indexOf(item) === -1;
                          })
                        );
                      }
                    }}
                  />
                </div>
              </Row>
              <div style={{ display: shown[c.clientId] ? "block" : "none" }}>
                {locationsFiltered.map((l: any) => {
                  if (c.clientId === l.webBasePartnerNumber)
                    return (
                      <LocationRow key={l.locationId}>
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          {l.locationName}
                        </div>
                        <div>
                          <Checkbox
                            checked={locationsIdList?.includes(l.locationId)}
                            onChange={(e, value) => {
                              if (value === true) {
                                setLocationIdList([
                                  ...locationsIdList,
                                  l.locationId,
                                ]);
                              } else if (value === false) {
                                setLocationIdList(
                                  locationsIdList.filter(
                                    (i) => i !== l.locationId
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                      </LocationRow>
                    );
                })}
              </div>
            </Fragment>
          );
        })}
    </div>
  );
};

const LocationRow = styled.div`
  margin: auto;
  padding-left: 32px;
  padding-right: 20px;
  font-size: 14px !important;
  color: ${(p) => p.theme.palette.neutralPrimary};
  border-bottom: ${(p) => p.theme.palette.neutralLight};
  font-size: 16px;
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  &:hover {
    background: ${(p) => p.theme.palette.neutralLight};
    cursor: pointer;
  }
`;

const Row = styled.div`
  border-bottom: ${(p) => p.theme.palette.neutralLight};
  font-size: 16px;
  font-weight: 500;
  color: ${(p) => p.theme.palette.neutralPrimary};
  height: 42px;
  margin: auto;
  padding: 10px;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(p) => p.theme.palette.white};
  &:hover {
    background: ${(p) => p.theme.palette.neutralLight};
    cursor: pointer;
  }
`;
