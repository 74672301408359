import * as API from "../../api/api";
import { setHasRights } from "../auth";
import {
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { setLocationsFilterIds } from "../filters/filters";
import { applyFiltersLocations } from "../locations-page/locationsPage";
import { loadLocationsActions } from "./locations";

export function* loadLocationsSaga() {
  yield takeEvery(loadLocationsActions.trigger, function* () {
    try {
      yield put(loadLocationsActions.loading());
      const res = yield call(API.getLocations);
      const clientEntities = yield select((s) => s.clients.entities);
      yield put(
        loadLocationsActions.success(
          res.data.map((e: any) => {
            return {
              ...e,
              clientName: clientEntities[e?.webBasePartnerNumber]?.clientName,
            };
          })
        )
      );
    } catch (err) {
      yield put(loadLocationsActions.error(err));
      if (err.response.status === 403) {
        yield put(setHasRights(false));
      }
    }
  });
}

export function* loadLocationsSuccessSaga() {
  yield takeEvery(loadLocationsActions.success, function* () {
    yield put(applyFiltersLocations() as any);
    yield put(setLocationsFilterIds() as any);
  });
}

export function* locationsSaga() {
  yield fork(loadLocationsSaga);
  yield fork(loadLocationsSuccessSaga);
}
