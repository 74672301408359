import { Icon } from "@fluentui/react";
import React from "react";
import { StatusCellSpinner } from "../../components/styled";
import { accent } from "../../config/colors";
import { useSelector } from "../../store/hooks";
import { Report } from "../../types/types";
import { useTheme } from "styled-components";

type Props = {
  report: Report;
};

export const ActivateDeactivateCell = ({ report }: Props) => {
  const theme = useTheme();
  const isChangeActiveStatusLoading = useSelector(
    (s) => s.reportsPage.isChangeActiveStatusLoading
  );
  const reportIdNotified = useSelector(
    (s) => s.reportsPage.reportIdStatusChanged
  );

  const shouldShowSpinner =
    isChangeActiveStatusLoading && reportIdNotified === report?.reportId;

  if (shouldShowSpinner) {
    return <StatusCellSpinner />;
  }

  if (!report?.isReportDeactivated ?? false) {
    return (
      <Icon iconName="Accept" style={{ color: theme.palette.greenDark }} />
    );
  } else {
    return (
      <Icon iconName="ChromeClose" style={{ color: theme.palette.redDark }} />
    );
  }
};
