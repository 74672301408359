import styled from "styled-components";
import { Panel, PanelType } from "@fluentui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { VSpace } from "../../../components/styled";
import { useSelector } from "../../../store/hooks";
import { loadRecommendationActions } from "../../../store/recommendationPanel";
import { AppDispatch } from "../../../store/store";
import { PanelState } from "../Recommendations";
import { AttachmentsList } from "./AttachmentsList";
import { CostBenifitFactor } from "./CostBenifitFactor";
import { CostEstimateCode } from "./CostEstimateCode";
import { Description } from "./Description";
import { EmlReductionPercent } from "./EmlReductionPercent";
import { ImplementationCostsEUR } from "./ImplementationCostsEUR";
import { ImplementationDateInsurer } from "./ImplementationDateInsurer";
import { ImplementationDateInternal } from "./ImplementationDateInternal";
import { Implemented } from "./Implemented";
import { ImprovementPotentialCode } from "./ImprovementPotentialCode";
import { MainCategoryCode } from "./MainCategoryCode";
import { NoGo } from "./NoGo";
import { ObjectInfrastructure } from "./ObjectInfrastructure";
import { PriorityCode } from "./PriorityCode";
import { PriorityCompanyCode } from "./PriorityCompanyCode";
import { Result } from "./Result";
import { StatusCompanyCode } from "./StatusCompanyCode";
import { StatusGrECoCode } from "./StatusGrECoCode";
import { SubCategory1Code } from "./SubCategory1Code";
import { SubCategory2Code } from "./SubCategory2Code";
import { SubCategory3Code } from "./SubCategory3Code";
import { TypeCode } from "./TypeCode";
import { Weighting } from "./Weighting";

export type Props = {
  panel: PanelState;
  setPanel: (panel: PanelState) => void;
};

export const RecommendationPanel = ({ panel, setPanel }: Props) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const recommendation = useSelector(
    (s) => s.recommendationPanel.recommendation
  );

  useEffect(() => {
    if (!panel.rec) return;
    dispatch(
      loadRecommendationActions.trigger({
        locationId: panel.rec.locationId,
        reportId: panel.rec.reportId,
        recommendationId: panel.rec.recommendationId,
      })
    );
  }, [panel.rec]);

  return (
    <StyledPanel
      isOpen={panel.isOpen}
      isLightDismiss
      headerText={
        recommendation?.title
          ? recommendation.title
          : t("greco.loading") + "..."
      }
      type={PanelType.custom}
      customWidth="800px"
      onDismiss={() => {
        setPanel({
          isOpen: false,
          rec: null,
        });
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            padding: "0 20px",
            flexWrap: "wrap",
          }}
        >
          <div>
            <ObjectInfrastructure />
            <TypeCode />
            <NoGo />
          </div>
          <div style={{ marginLeft: "auto" }}>
            <StatusCompanyCode />
            <StatusGrECoCode />
            <ImplementationDateInternal />
            <ImplementationDateInsurer />
            <Implemented />
          </div>
        </div>
        <VSpace height={20} />
        <AttachmentsList />
        <VSpace />
        <Description />
        <div
          style={{
            display: "flex",
            padding: "20px",
            paddingTop: "0",
            flexWrap: "wrap",
          }}
        >
          <div>
            <PriorityCode />
            <PriorityCompanyCode />
            <ImprovementPotentialCode />
            <VSpace />
            <MainCategoryCode />
            <Weighting />
          </div>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <SubCategory1Code />
              <SubCategory2Code />
              <SubCategory3Code />
            </div>
            <CostEstimateCode />
            <VSpace />
          </div>
        </div>
        <div style={{ padding: "0 20px" }}>
          <ImplementationCostsEUR />
          <EmlReductionPercent />
          <Result />
          <CostBenifitFactor />
        </div>
      </div>
    </StyledPanel>
  );
};

const StyledPanel = styled(Panel)`
  &&& .ms-Panel-commands {
    margin: 0;
  }
  .ms-Panel-closeButton {
    margin: 0;
  }
  .ms-Panel-header {
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 40px;
  }
  .ms-Panel-headerText {
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
  .ms-Panel-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ms-Panel-main {
    background-color: ${(p) => p.theme.palette.white};
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const BottomRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  border-bottom: 1px solid #f3f2f1;
  height: 55px;
`;

export const BottomRowRightText = styled.strong`
  font-size: 22px;
`;

export const Label = styled.strong`
  margin-right: 5px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;
