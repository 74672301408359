import {
  ActionButton,
  Icon,
  IconButton,
  Label,
  Link,
  Tooltip,
} from "@fluentui/react";
import React, { ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Attachment } from "../../../../types/types";
import {
  getFileExtension,
  getFileIcon,
  getIconColor,
} from "../../../../utils/utils";

type Props = {
  attachments: Attachment[];
  setAttachments: (attachments: Attachment[]) => void;
  isReadOnly?: boolean;
  shouldShowLabel?: boolean;
};

export const AttachmentsList = ({
  attachments,
  setAttachments,
  isReadOnly,
}: Props) => {
  const { t } = useTranslation();
  const refFileInput = useRef<HTMLInputElement>(null);

  const areSameFile = (file1Name: string, file2Name: string) => {
    return file1Name === file2Name;
  };

  const onChangeUploadFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    let fileArr = Array.from(e.target.files);
    const newAttachments = fileArr
      .filter((f) => {
        return !attachments.some((a) => areSameFile(a.attachmentName, f.name));
      })
      .map((f) => {
        let attachmentName = f.name;
        if (f.name.length > 128) {
          const ext = f.name.split(".").slice(-1).toString();
          attachmentName = f.name.slice(0, 128 - (ext.length + 1)) + "." + ext;
        }
        return {
          attachmentName,
          attachmentMimeType: f.type,
          attachmentContent: f,
          attachmentSize: f.size,
        } as any as Attachment;
      });
    setAttachments([...attachments, ...newAttachments]);
  };

  return (
    <div>
      <Label>{t("ram.responsesPage.attachments")}</Label>
      {!isReadOnly && (
        <ActionButton
          iconProps={{ iconName: "CalculatorAddition" }}
          allowDisabledFocus
          onClick={() => {
            if (!refFileInput.current) return;
            refFileInput.current.click();
          }}
        >
          Add attachment
        </ActionButton>
      )}
      <AttachmentListContainer>
        {attachments.map((att, index) => {
          return (
            <AttachmentListItem key={att.attachmentName}>
              <Icon
                iconName={getFileIcon(getFileExtension(att.attachmentName))}
                style={{
                  color: getIconColor(getFileExtension(att.attachmentName)),
                }}
              />
              <AttachmentName>
                {att.attachmentPath ? (
                  <Link href={att.attachmentPath} target="_blank">
                    {att.attachmentName}
                  </Link>
                ) : (
                  att.attachmentName
                )}
              </AttachmentName>
              {!isReadOnly && (
                <IconButton
                  iconProps={{ iconName: "Cancel" }}
                  onClick={() => {
                    setAttachments(
                      attachments.filter((a, idx) => idx !== index)
                    );
                    refFileInput.current.value = "";
                  }}
                />
              )}
            </AttachmentListItem>
          );
        })}
      </AttachmentListContainer>
      <input
        type="file"
        name="file"
        multiple
        style={{ display: "none" }}
        ref={refFileInput}
        onChange={onChangeUploadFiles}
      />
    </div>
  );
};

const AttachmentListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;
const AttachmentListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLighterAlt};
  width: 350px;
`;

const AttachmentName = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
  max-width: 300px;
`;
