import {
  IShimmerElement,
  Shimmer,
  ShimmerElementType,
} from "@fluentui/react/lib/Shimmer";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import * as React from "react";
import TableSkeleton from "./TableSkeleton";
import styled from "styled-components";

const classes = mergeStyleSets({
  navigation: {
    height: 44,
  },
  wrapp: {
    marginTop: 50,
  },
  content: {
    maxWidth: "1200px",
    margin: "50px auto",
    position: "relative",
  },
});

const headerSkeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "20%",
    height: 44,
  },
];

const formSeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "100%",
    height: 500,
  },
];

const ReportSkeleton = () => {
  return (
    <>
      <div>
        <Shimmer shimmerElements={headerSkeleton} />
      </div>
      <div className={classes.content}>
        <div className={classes.wrapp}>
          <Shimmer shimmerElements={formSeleton} />
        </div>
        <TableContainer>
          <StyledTable>
            <tbody>
              <TableSkeleton />
            </tbody>
          </StyledTable>
        </TableContainer>
      </div>
    </>
  );
};

export default ReportSkeleton;

const TableContainer = styled.div`
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid ${(p) => p.theme.palette.neutralLight};
  padding: 20;
`;
