import {
  Breadcrumb,
  Checkbox,
  ChoiceGroup,
  DatePicker,
  Dropdown,
  Icon,
  IconButton,
  NormalPeoplePicker,
  PrimaryButton,
  Spinner,
  TextField,
} from "@fluentui/react";

import styled, { keyframes } from "styled-components";
import { lightenDarkenColor } from "../utils/utils";
import { CheckboxDropdown } from "./CheckboxDropdown/CheckboxDropdown";

export const StyledTextField = styled(TextField)<{
  hasError?: boolean;
  background?: string;
}>`
  &.is-active {
    .ms-TextField-fieldGroup {
      border-color: ${(p) =>
        p.hasError ? p.theme.palette.red : p.theme.palette.themePrimary};
    }
  }
  &.is-active.hasError {
    .ms-TextField-fieldGroup {
      border-color: ${(p) => p.theme.palette.red};
    }
  }
  .ms-TextField-fieldGroup {
    border-bottom: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
    border-radius: 0;
    background-color: ${(p) => p.background ?? undefined};
  }
  &&& .ms-TextField-suffix {
    border: 0;
  }
  &.is-disabled {
    input {
      background: ${(p) => p.background ?? p.theme.palette.white};
    }
  }
  &.noMargin {
    margin-bottom: 0;
  }
  textarea[disabled] {
    background: ${(p) => p.background ?? p.theme.palette.white};
  }
`;

export const StyledDatePicker = styled(DatePicker)<{
  hasError: boolean;
  background?: string;
}>`
  .ms-TextField-fieldGroup {
    border: none;
    &::after {
      display: none;
    }
    background-color: ${(p) => p.background ?? undefined};
  }
  .ms-TextField-wrapper {
    border-bottom: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
    &:focus-within {
      border-color: ${(p) =>
        p.hasError ? p.theme.palette.red : p.theme.palette.themePrimary};
    }
  }
  .ms-TextField-suffix {
    padding-right: 32px;
    background: transparent;
  }
  .ms-TextField.is-disabled {
    .ms-TextField-fieldGroup {
      background-color: ${(p) => p.background ?? undefined};
    }
    input {
      background-color: transparent;
    }
  }
`;

export const HorizontalChoiceGroup = styled(ChoiceGroup)`
  .ms-ChoiceFieldGroup {
    display: block;
  }
  .ms-ChoiceFieldGroup-flexContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .ms-ChoiceField {
    width: 33%;
    flex-grow: 1;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  margin-bottom: 10px;

  .ms-Dropdown-title {
    border: 0;
  }
  .ms-Dropdown {
    border: 0;
    border-bottom: 1px solid #ccc;
    &:focus {
      border-color: ${(p) => (p.errorMessage ? "red" : "#005aa1")};
    }
    &::after {
      display: none;
    }
    &.is-open {
      border-color: ${(p) => (p.errorMessage ? "red" : "#005aa1")};
    }
  }
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: rgb(164, 38, 44);
  padding-top: 5px;
`;

export const Page = styled.section`
  background: ${(p) => p.theme.palette.white};
  margin: 0 auto;
  position: relative;
  border-radius: 2px;
  height: calc(100vh - 50px - 44px);
`;

export const CenteredCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CenteredTableCell = styled.div`
  width: 100%;
  text-align: center;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 10px 0;
`;

export const StyledPeoplePicker = styled(NormalPeoplePicker)<{
  hasError: boolean;
}>`
  .ms-BasePicker-text {
    border: 0;
    border-bottom: 1px solid #ccc;
    &::after {
      display: none;
    }
    &:focus {
      border-color: ${(p) => (p.hasError ? "red" : "#005aa1")};
    }
  }
  .ms-PickerPersona-container {
    margin-bottom: 4px;
  }
`;

export const ListContextMenuIconButton = styled(IconButton)`
  border: 1px solid transparent;

  &:hover {
    background: transparent;
    border: 1px solid ${(p) => p.theme.palette.themePrimary};
  }
  &.is-expanded {
    background: transparent;
    border: 1px solid ${(p) => p.theme.palette.themePrimary};
  }
`;

export const StyledCheckboxDropdown = styled(CheckboxDropdown)`
  width: 150px;
  background: transparent;
  .button {
    border: 0;
    background: transparent;
    color: ${(p) => p.theme.palette.neutralPrimary};
    /* &:hover {
      background: #f3f2f1;
    } */
  }
  &.hasValue {
    background: #005aa1;
    color: white;
    .button {
      color: white;
      .ms-Icon {
        color: white;
      }
      &:hover {
        background: ${(p) => lightenDarkenColor("#005aa1", 20)};
      }
    }
  }
`;

export const Concatenate = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const IndicatorIcon = styled(Icon)`
  color: #a4262c;
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
  margin-top: 0;
  padding: 5px 20px;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
  .ms-Breadcrumb-listItem:first-child {
    & > span {
      padding-left: 0;
    }
    .ms-Breadcrumb-item {
      padding-left: 0;
    }
  }
  .ms-Breadcrumb-item {
    font-size: 14px;
  }
  .ms-Breadcrumb-listItem:last-child {
    & > span {
      font-weight: 600;
      padding-right: 0;
    }
  }
`;

export const StatusCellSpinner = styled(Spinner)`
  .ms-Spinner-circle {
    width: 15px;
    height: 15px;
    border-width: 2.5px;
  }
`;

export const spin = keyframes`
  from {
    transform: rotateZ(0deg)
  }
  to {
    transform: rotateZ(360deg)
  }
`;

export const spinAnimation = keyframes`
  from {
    transform: rotateZ(0deg)
  }
  to {
    transform: rotateZ(360deg)
  }
`;

export const StyledPrimaryButton = styled(PrimaryButton)<{
  background?: string;
}>`
  && {
    &.ms-Button--hasMenu + .ms-Button {
      .ms-Icon {
        color: unset;
      }
    }
  }

  &.isLoading {
    i {
      animation: ${spinAnimation} 500ms linear infinite;
    }
  }
  &&.is-disabled {
    background-color: ${(p) => p.theme.palette.neutralLighter};
    &.ms-Button--hasMenu + .ms-Button.is-disabled {
      background-color: ${(p) => p.theme.palette.neutralLighter};
    }
  }
  &&:hover {
    background-color: ${(p) => {
      if (p.background) {
        return lightenDarkenColor(p.background, 15);
      } else {
        return undefined;
      }
    }};
  }
`;

export const NavigationBar = styled.div`
  height: 44px;
  background: ${(p) => p.theme.palette.white};
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
`;

export const Truncate = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const Left = styled.div`
  margin-left: auto;
`;

export const VSpace = styled.div<{ height?: number }>`
  height: ${(p) => (p.height ? `${p.height}px` : "10px")};
`;

export const Flex = styled.div<{
  gap?: number;
  direction?: "row" | "column";
  justifyContent?: "center" | "flex-end" | "flex-start";
  margin?: string;
}>`
  display: flex;
  align-items: center;
  gap: ${(p) => (p.gap !== undefined ? `${p.gap}px` : undefined)};
  margin: ${(p) => (p.margin !== undefined ? `${p.margin}` : undefined)};
  flex-direction: ${(p) => p.direction};
  justify-content: ${(p) => p.justifyContent};
  flex-wrap: wrap;
`;

export const StyledIconButton = styled(IconButton)<{
  background?: string;
  color?: string;
}>`
  background-color: ${(p) => p.background ?? undefined};
  &:hover {
    background: ${(p) => {
      if (p.background) {
        return lightenDarkenColor(p.background, 15);
      } else {
        return undefined;
      }
    }};
    border: 1px solid ${(p) => p.background ?? undefined};
  }
`;

export const HSpace = styled.div<{ width?: number }>`
  width: ${(p) => (p.width ? `${p.width}px` : "5px")};
`;
