import { useSelector } from "../../../store/hooks";
import {
  adjustFloatNumberValue,
  adjustTaxonomyValue,
} from "../../../form/util";
import { useTranslation } from "react-i18next";
import { useCurrentRoute } from "../../../config/routes";

function useResponseFormInitialValues() {
  const recommendation = useSelector((s) => s.recommendationPage.rec);

  const route = useCurrentRoute();
  const taxonomy = useSelector((s) => s.taxonomy);

  const { t } = useTranslation();

  const response = useSelector(
    (s) => s.recommendationPage.addResponseDialog.response
  );

  const responseAttachments = useSelector(
    (s) => s.recommendationPage.addResponseDialog.attachments
  );

  const initialValues = (() => {
    if (response) {
      return {
        dateOfResponse: response?.dateOfResponse
          ? new Date(response?.dateOfResponse)
          : null,
        plannedImplementationDate: response?.plannedImplementationDate
          ? new Date(response?.plannedImplementationDate)
          : null,

        statusCompanyCode: adjustTaxonomyValue(
          response?.statusCompanyCode,
          taxonomy,
          "RiskImplementationStatus",
          t
        ),
        priorityCompanyCode: adjustTaxonomyValue(
          response?.priorityCompanyCode,
          taxonomy,
          "RiskPriority",
          t
        ),
        costEstimateCode: response?.costEstimateCode
          ? adjustTaxonomyValue(
              Number(response?.costEstimateCode),
              taxonomy,
              "RiskReductionCost",
              t
            )
          : null,
        implementationCostsEur: adjustFloatNumberValue(
          response?.implementationCostsEur
        ),
        comment: response?.comment,
        useReminders: response?.useReminders || false,
        hideFromInsurer: response?.hideFromInsurer || false,
        attachments: response?.attachments || responseAttachments || [],
      };
    }
    if (
      recommendation &&
      (route === "addResponse" || route === "editRecommendation")
    ) {
      return {
        dateOfResponse: new Date(),
        plannedImplementationDate: recommendation?.plannedImplementationDate
          ? new Date(recommendation?.plannedImplementationDate)
          : null,

        statusCompanyCode: adjustTaxonomyValue(
          recommendation.statusCompanyCode,
          taxonomy,
          "RiskImplementationStatus",
          t
        ),
        priorityCompanyCode: adjustTaxonomyValue(
          recommendation.priorityCompanyCode,
          taxonomy,
          "RiskPriority",
          t
        ),
        costEstimateCode: adjustTaxonomyValue(
          recommendation.costEstimateCode,
          taxonomy,
          "RiskReductionCost",
          t
        ),
        implementationCostsEur: adjustFloatNumberValue(
          recommendation?.implementationCostsEur
        ),
        comment: recommendation?.comment,

        useReminders: recommendation?.useReminders || false,
        hideFromInsurer: recommendation?.hideFromInsurer || false,
        attachments: recommendation?.attachments || [],
      };
    }

    return {};
  })();

  return initialValues;
}

export default useResponseFormInitialValues;
