import { ExcelExport } from "@progress/kendo-react-excel-export";
import clsx from "clsx";
import { CommandBarButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../store/hooks";
import { loadRecommendationsExcelActions } from "../../../store/recommendations-page/recommendationsPage";
import { AppDispatch } from "../../../store/store";
import { transformRecommendationsForExcel } from "../../../utils/excel";
import styled, { css, keyframes } from "styled-components";
import { defaultCols } from "../cols";

type Props = {
  refExcelExport: ExcelExport;
};

export const ExportExcelButton = ({ refExcelExport }: Props) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const dispatch: AppDispatch = useDispatch();
  const loadRecommendationsExcelStatus = useSelector(
    (s) => s?.recommendationsPage?.loadRecommendationsExcelStatus
  );
  const columns = useSelector((s) => s?.recommendationsPage?.columns);
  const colsMap = defaultCols.reduce((acc, item) => {
    acc[item.key] = item; // Use the 'id' property as the key
    return acc;
  }, {});
  const isLoading = loadRecommendationsExcelStatus === "loading";

  return (
    <StyledCommandBarButton
      disabled={isLoading}
      className={clsx({ isLoading })}
      iconProps={{
        iconName: isLoading ? "ProgressRingDots" : "ExcelDocument",
      }}
      text="Export Excel"
      onClick={() => {
        dispatch(
          loadRecommendationsExcelActions.trigger({
            onSuccess: (res) => {
              const data = transformRecommendationsForExcel({
                recommendations: res.value,
                columns: columns.map((col) => {
                  return {
                    ...col,
                    excel: colsMap[col.key].excel,
                  };
                }),
                t,
                taxonomy,
              });
              refExcelExport?.save(data);
            },

            onError: () => {},
          })
        );
      }}
    />
  );
};

const spinAnimation = keyframes`
  from {
    transform: rotateZ(0deg)
  }
  to {
    transform: rotateZ(360deg)
  }
`;

const StyledCommandBarButton = styled(CommandBarButton)`
  height: 32px;
  .ms-Button-icon {
    color: green;
  }
  &:hover {
    .ms-Button-icon {
      color: green;
    }
  }
  &.isLoading {
    i {
      animation: ${spinAnimation} 500ms linear infinite;
    }
  }
`;
