import { CommandBarButton } from "@fluentui/react";
import clsx from "clsx";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../store/hooks";
import { setDidTrySubmitReport } from "../../../store/reportPage";
import { AppDispatch } from "../../../store/store";

export const SaveButton = () => {
  const { t } = useTranslation();
  const isReportLoading = useSelector((s) => s.reportPage.isReportLoading);
  const isSaveLoading = useSelector((s) => s.reportPage.isSaveLoading);
  const report = useSelector((s) => s.reportPage.report);
  const { submitForm, setFieldValue } = useFormikContext();
  const dispatch: AppDispatch = useDispatch();
  const didTrySubmit = useSelector((s) => s.reportPage.didTrySubmit);

  const isLoading = isReportLoading || isSaveLoading;
  const isDisabled = isLoading || report?.isReportDeactivated === true;

  return (
    <CommandBarButton
      text={t("greco.save")}
      iconProps={{ iconName: isLoading ? "ProgressRingDots" : "Save" }}
      split
      onClick={() => {
        setFieldValue("mode", "normal");
        if (!didTrySubmit) {
          dispatch(setDidTrySubmitReport(true));
        }
        submitForm();
      }}
      disabled={isDisabled}
      className={clsx({
        isLoading,
      })}
      menuProps={{
        items: [
          {
            key: "addRecommendation",
            text: t("ram.navBar.saveAndAddRecommendation"),
            iconProps: { iconName: "Add" },
            onClick: () => {
              setFieldValue("mode", "addRecommendation");

              if (!didTrySubmit) {
                dispatch(setDidTrySubmitReport(true));
              }
              submitForm();
            },
          },
        ],
      }}
    />
  );
};
