import { CommandBarButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "../../../config/routes";

export const AddDefaultWeightingButton = () => {
  const { t } = useTranslation();

  return (
    <Link to={routes.addDefaultWeighting.getPath()}>
      <CommandBarButton
        iconProps={{ iconName: "Add" }}
        text={t("ram.navBar.addDefaultWeighting")}
        styles={{
          root: {
            marginRight: "10px",
            height: "32px",
          },
        }}
      />
    </Link>
  );
};
