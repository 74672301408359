import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Report } from "../../types/types";
import { ReportsPageState } from "../reports-page/reportsPage";
import { RootState } from "../store";
import { createAsyncActions, handleAxiosError } from "../util";

export const reportsAdapter = createEntityAdapter<Report>({
  selectId: (r) => r.reportId,
});

export const loadReportsActions = createAsyncActions<void, void, Report[], any>(
  "reports/load"
);

export const reportsSlice = createSlice({
  name: "reports",
  initialState: reportsAdapter.getInitialState({
    isLoading: false,
  }),
  reducers: {
    addReport: reportsAdapter.addOne,
    updateReport: reportsAdapter.updateOne,
    removeOne: reportsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(loadReportsActions.loading, (s, a) => {
      s.isLoading = true;
    });
    builder.addCase(loadReportsActions.success, (s, a) => {
      s.isLoading = false;
      reportsAdapter.setAll(
        s,
        a.payload.filter((r) => r.isDeleted === false)
      );
    });
    builder.addCase(loadReportsActions.error, (s, a) => {
      s.isLoading = false;
    });
  },
});

export const {
  actions: { addReport, removeOne, updateReport },
} = reportsSlice;

export const reportsReducer = reportsSlice.reducer;

export const {
  selectAll: selectAllReports,
  selectEntities: selectEntitiesReports,
  selectById: selectByIdReports,
} = reportsAdapter.getSelectors<RootState>((s) => s.reports);
