import { mergeStyleSets, Text, TextField } from "@fluentui/react";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Rifm } from "rifm";

import { FieldContainer } from "./FieldContainer";

import { useTheme } from "styled-components";
import { IStyledTheme } from "../../theme/types";

const getTextFieldStyle = (transparent, theme, isMarked, confidentiality) => ({
  root: {
    ".ms-TextField-fieldGroup": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
      // background: transparent ? "transparent" : "inherit",
      // ...(isMarked &&
      //   !confidentiality && { background: theme.palette.yellowLight }),
      // ...(confidentiality && {
      //   background: `rgb(${confidentiality < 0.9 ? 255 : 200},${
      //     confidentiality >= 0.8 ? 255 : 200
      //   },200)`,
      // }),
    },
    ".ms-TextField-fieldGroup::after": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
    },
    ".ms-TextField-fieldGroup input": {
      color: isMarked
        ? "black"
        : theme.isDark
        ? confidentiality
          ? "black"
          : "white"
        : "black",
    },
    ".ms-TextField-wrapper span": {
      background: theme.palette.white,
    },
    ".ms-TextField-fieldGroup input:disabled": {
      background: theme.palette.white,
      color: theme.palette.neutralSecondaryAlt,
    },
  },
});

const NumberInputField = ({
  disabled,
  label,
  required = false,
  name,
  error = "",
  value,
  setFieldValue = () => {},
  errors,
  transparent,
  isNegativeAllowed,
  isMarked,
  defaultValueFunction = null,
  insurerSettingsFunction = null,
  confidentiality,
  ...props
}: any) => {
  const theme = useTheme() as IStyledTheme;
  const dispatch = useDispatch();
  const isDarkMode = theme.isDark;
  const { t } = useTranslation();
  const styles = getTextFieldStyle(
    transparent,
    theme,
    isMarked,
    confidentiality
  );

  const classes = mergeStyleSets({
    label: {
      fontSize: "14px",
      fontWeight: "600",
      padding: "5px",
      display: "block",

      color: (function () {
        if (disabled) {
          return theme.palette.neutralSecondaryAlt;
        }
        if (isDarkMode) {
          return "white";
        }
        return theme.palette.black;
      })(),
    },
    labelAsterix: {
      color: theme.palette.redDark,
    },
  });

  const onRenderLabel = () => (
    <Text className={classes.label}>
      {t(label)}

      <Text className={classes.labelAsterix}>{required && " *"}</Text>
    </Text>
  );

  const parseInteger = useMemo(
    () => (string) => {
      const integerAccept = isNegativeAllowed ? /-?[\d]*/g : /\d+/g;

      return (string?.toString().match(integerAccept) || []).join("");
    },
    [isNegativeAllowed]
  );

  const formatInteger = useMemo(() => {
    return (string) => {
      const parsed = parseInteger(string?.toString());
      const number = Number.parseInt(parsed, 10);
      if (Number.isNaN(number) && parsed === "-") {
        return parsed;
      }
      if (Number.isNaN(number)) {
        return "";
      }
      return number.toLocaleString("en").replace(/,/g, ".");
    };
  }, [parseInteger]);

  useEffect(() => {
    if (value) {
      const lastChar = value.toString().slice(-1);
      if (
        !Number(lastChar) &&
        lastChar !== "-" &&
        lastChar.toString() !== "0"
      ) {
        const numberValue = value.substring(0, value.length - 1);
        setFieldValue(name, numberValue);
      }
    }
  }, [value]);

  return (
    <FieldContainer isTooltipHidden={disabled} tooltipText={label}>
      <Rifm
        accept={/\d/g}
        format={formatInteger}
        value={value}
        onChange={(value) => setFieldValue(name, parseInteger(value))}
      >
        {({ value, onChange }) => (
          <TextField
            id={name + "-input"}
            type="tel"
            onRenderLabel={onRenderLabel}
            label={t(label)}
            name={name}
            styles={styles}
            disabled={disabled}
            onChange={onChange}
            value={value}
            errorMessage={errors[name]}
            autoComplete="off"
            {...props}
          />
        )}
      </Rifm>
    </FieldContainer>
  );
};

export default NumberInputField;

////for float numbers

// <div>Number with fractional part: {fixedFloat}</div>
// <Rifm
//   accept={/[\d.]/g}
//   format={v => formatFixedPointNumber(v, 2)}
//   // 00 is needed here see disadvantages comment at formatNumber
//   value={`${fixedFloat}00`}
//   onChange={value => setFixedFloat(parseNumber(value))}
// >
//   {renderInput}
// </Rifm>
