import React from "react";
import { useSelector } from "../../../../store/hooks";
import { DeleteEvaluationDialog } from "./DeleteEvaluationDialog";
import { DeleteResponseDialog } from "./DeleteResponseDialog";
import { Description } from "./Description";
import EvaluationFields from "./EvaluationFields";
import ResponseFields from "./ResponseFields";
import { ResponseList } from "./ResponseList/ResponseList";
import { ReportDocumentDialog } from "./ReportDocumentDialog";
import { DeleteAssessmentDialog } from "./DeleteAssessmentDialog";
import AssessmentFields from "./AssessmentFields";
import { ExportToZipDialog } from "./ExportToZipDialog";

function ResponsesFormContent() {
  const deleteResponseDialog = useSelector(
    (s) => s.recommendationPage.deleteResponseDialog
  );
  const deleteAssessmentDialog = useSelector(
    (s) => s.recommendationPage.deleteAssessmentDialog
  );
  const deleteEvaluationDialog = useSelector(
    (s) => s.recommendationPage.deleteEvaluationDialog
  );
  const addResponseDialogIsOpen = useSelector(
    (s) => s.recommendationPage.addResponseDialog.isOpen
  );
  const addAssessmentDialogIsOpen = useSelector(
    (s) => s.recommendationPage.addAssessmentDialog.isOpen
  );
  const addEvaluationDialogIsOpen = useSelector(
    (s) => s.recommendationPage.addEvaluationDialog.isOpen
  );
  const reportDocumentDialog = useSelector(
    (s) => s.recommendationPage.reportDocumentDialog
  );
  const exportToZipDialog = useSelector(
    (s) => s.recommendationPage.exportToZipDialog
  );
  const isLoading = useSelector(
    (s) => s.recommendationPage.responsesLoadStatus === "loading"
  );

  return (
    <div>
      {!isLoading && addResponseDialogIsOpen && <ResponseFields />}
      {!isLoading && addAssessmentDialogIsOpen && <AssessmentFields />}
      {!isLoading && addEvaluationDialogIsOpen && <EvaluationFields />}
      <Description />
      <div
        style={{
          marginTop: 20,
        }}
      >
        <ResponseList />
      </div>
      {deleteResponseDialog.isOpen && <DeleteResponseDialog />}
      {deleteAssessmentDialog.isOpen && <DeleteAssessmentDialog />}
      {deleteEvaluationDialog.isOpen && <DeleteEvaluationDialog />}
      {reportDocumentDialog.isOpen && <ReportDocumentDialog />}
      {exportToZipDialog.isOpen && <ExportToZipDialog />}
    </div>
  );
}

export default ResponsesFormContent;
