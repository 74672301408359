import { IconButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { formatDateString } from "../../../utils/utils";
import { useSelector } from "../../../store/hooks";
import { formatNumber } from "../../../utils/number";

type Props = {
  className?: string;
  callback: (report) => void;
};

export const ReportsList = ({ className, callback }: Props) => {
  const copyReportDataDialog = useSelector(
    (s) => s.locationPage.copyReportDataDialog
  );
  const { t } = useTranslation();
  const riskReportCreatorTaxonomy = useSelector(
    (s) => s.taxonomy.RiskReportCreator
  );
  return (
    <div className={className}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FlexTable>
          <FlexTableHeader>
            <FlexCell style={{ flex: 3 }}>
              {t("ram.reportList.reportName")}
            </FlexCell>
            <FlexCell>{t("ram.reportList.reportCreatorCode")}</FlexCell>
            <FlexCell>{t("ram.reportList.surveyDate")}</FlexCell>
            <FlexCell>{t("ram.locationForm.insurersRaiting.label")}</FlexCell>
            <FlexCell>{t("ram.locationForm.EMLInMioEUR.label")}</FlexCell>
            <FlexCell>{t("ram.locationForm.pmlinMioEur.label")}</FlexCell>
            <FlexCell></FlexCell>
          </FlexTableHeader>

          <FlexTableBody>
            {copyReportDataDialog.reportData.map((f, index) => {
              return (
                <FlexRow key={f.reportName}>
                  <FlexCell style={{ flex: 3 }}>{f.reportName}</FlexCell>
                  <FlexCell>
                    {t(
                      riskReportCreatorTaxonomy.byId[f.reportCreatorCode].code
                    )}
                  </FlexCell>
                  <FlexCell>{formatDateString(f.surveyDate)}</FlexCell>
                  <FlexCell>{f.insurersRaiting}</FlexCell>
                  <FlexCell>
                    {f.emlinMioEur ? formatNumber(f.emlinMioEur) : null}
                  </FlexCell>
                  <FlexCell>
                    {f.pmlinMioEur ? formatNumber(f.pmlinMioEur) : null}
                  </FlexCell>
                  <FlexCellRight>
                    <IconButton
                      iconProps={{ iconName: "Copy" }}
                      title={t("ram.locationForm.copyValueToLocation.label")}
                      onClick={() => {
                        callback(f);
                      }}
                    />
                  </FlexCellRight>
                </FlexRow>
              );
            })}
          </FlexTableBody>
        </FlexTable>
      </div>
    </div>
  );
};

const FlexTable = styled.div`
  width: 100%;
`;

const FlexTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(p) => p.theme.palette.themeDark};
  color: ${(p) => p.theme.palette.neutralLight};
  padding: 10px 0;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  height: 40px;
  &:nth-of-type(odd) {
    background-color: ${(p) => p.theme.palette.neutralLight};
    color: ${(p) => p.theme.palette.neutralDark};
  }
  &:nth-of-type(even) {
    background-color: ${(p) => p.theme.palette.neutralTertiary};
    color: ${(p) => p.theme.palette.neutralDark};
  }
`;

const FlexCell = styled.div`
  flex: 1;
  text-align: left;
  padding: 0 5px;
  vertical-align: middle;
`;

const FlexCellRight = styled.div`
  flex: 1;
  text-align: right;
  padding: 0 5px;
  vertical-align: middle;
  margin-top: -5px;
`;

const FlexTableBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 500px;
`;
