import styled from "styled-components";
import { Icon } from "@fluentui/react";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "../../../store/hooks";
import {
  getFileExtension,
  getFileIcon,
  getIconColor,
} from "../../../utils/utils";

export const AttachmentsList = () => {
  const attachments = useSelector((s) => s.recommendationPanel.attachments);
  const loadRecommendationStatus = useSelector(
    (s) => s.recommendationPanel.loadRecommendationStatus
  );

  const isLoading = loadRecommendationStatus === "loading";

  if (isLoading) return <Skeleton height={32} />;

  if (attachments.length === 0) return null;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        padding: "0 10px",
        paddingLeft: "20px",
        gap: "5px",
      }}
    >
      {attachments.map((a) => {
        return (
          <Item key={a.attachmentId} href={a.attachmentPath} target="_blank">
            <Icon
              iconName={getFileIcon(getFileExtension(a.attachmentName))}
              style={{
                marginRight: "5px",
                color: getIconColor(getFileExtension(a.attachmentName)),
                fontSize: "16px",
              }}
            />
            {a.attachmentName}
          </Item>
        );
      })}
    </div>
  );
};

const Item = styled.a`
  text-decoration: none;
  color: ${(p) => p.theme.palette.neutralPrimary};
  padding: 5px;
  border: 1px solid ${(p) => p.theme.palette.neutralTertiary};
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.palette.neutralLight};
  }
`;
