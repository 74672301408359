import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRole } from "../types/types";

export type UserRoleState = { userRole: UserRole | null; isLoading: boolean };

export const userRoleSlice = createSlice({
  name: "userRole",
  initialState: {
    userRole: null,
    isLoading: false,
  } as UserRoleState,
  reducers: {
    setUserRole: (s, a: PayloadAction<UserRole>) => {
      s.userRole = a.payload;
    },
    setIsLoading: (s, a: PayloadAction<UserRoleState["isLoading"]>) => {
      s.isLoading = a.payload;
    },
  },
});

export const userRoleReducer = userRoleSlice.reducer;

export const {
  setUserRole,
  setIsLoading: setIsLoadingUserRole,
} = userRoleSlice.actions;
