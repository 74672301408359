import { Dialog, DialogFooter } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../../store/hooks";
import {
  deleteEvaluationRoutine,
  setDeleteEvaluationDialog,
} from "../../../../store/recommendationPage";
import { StyledPrimaryButton } from "../../../../components/styled";
import { FormSpinner } from "../../../../components/FormSpinner";

export const DeleteEvaluationDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { evaluation } = useSelector(
    (s) => s.recommendationPage.deleteEvaluationDialog
  );
  const isLoading = useSelector(
    (s) => s.recommendationPage.deleteEvaluationLoadStatus === "loading"
  );

  const close = () => {
    dispatch(
      setDeleteEvaluationDialog({
        evaluation: null,
        isOpen: false,
      })
    );
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        showCloseButton: true,
        title: t("greco.areYouSure"),
      }}
      onDismiss={close}
    >
      {t("greco.deletingIsPermanent")}
      <DialogFooter>
        <StyledPrimaryButton
          text={t("greco.delete")}
          onClick={() => {
            dispatch(
              deleteEvaluationRoutine.trigger({
                locationId: evaluation.locationId,
                reportId: evaluation.reportId,
                recommendationId: evaluation.recommendationId,
                evaluationId: evaluation.evaluationId,
              })
            );
          }}
          disabled={isLoading}
        />
      </DialogFooter>
      {isLoading && <FormSpinner />}
    </Dialog>
  );
};
