import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ListContextMenuIconButton } from "../../components/styled";
import { routes } from "../../config/routes";
import { setClientsFilter } from "../../store/filters/filters";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";
import {
  setReportDocumentDialog,
  setSetRatingLimitsDialog,
  setUsersReportDocumentDialog,
} from "../../store/clients-page/clientsPage";

export const ContextButtonForTree = ({
  clientId,
  poorRangeMin,
  moderateRangeMin,
  goodRangeMin,
}) => {
  const userRole = useSelector((s) => s.userRole.userRole);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  if (!clientId) return null;

  const onClickAddLocation = () => {
    navigate(routes.addLocation.getPath(clientId));
  };

  const onClickShowLocations = () => {
    navigate(routes.locations.getPath());
    dispatch(setClientsFilter([clientId]));
  };

  const onClickShowReports = () => {
    navigate(routes.reports.getPath());
    dispatch(setClientsFilter([clientId]));
  };

  const onClickShowRecommendations = () => {
    navigate(routes.recommendations.getPath());
    dispatch(setClientsFilter([clientId]));
  };
  const onClickReportDocument = () => {
    dispatch(
      setReportDocumentDialog({
        isOpen: true,
        clientId: clientId,
      })
    );
  };
  const onClickUsersReportDocument = () => {
    dispatch(
      setUsersReportDocumentDialog({
        isOpen: true,
        clientId: clientId,
      })
    );
  };

  const onClickSetRatingLimits = async () => {
    dispatch(
      setSetRatingLimitsDialog({
        clientId: clientId,
        body: {
          poorRangeMin: poorRangeMin,
          moderateRangeMin: moderateRangeMin,
          goodRangeMin: goodRangeMin,
        },
        isOpen: true,
      })
    );
  };

  const items = [
    {
      key: "showLocations",
      text: t("ram.locations.contextMenu.showLocations"),
      iconProps: { iconName: "MapPin" },
      disabled: false,
      onClick: onClickShowLocations,
    },
    {
      key: "showReports",
      text: t("ram.locations.contextMenu.showReports"),
      iconProps: { iconName: "ReportDocument" },
      disabled: false,
      onClick: onClickShowReports,
    },
    {
      key: "showRecommendations",
      text: t("ram.locations.contextMenu.showRecommendations"),
      iconProps: { iconName: "DietPlanNotebook" },
      disabled: false,
      onClick: onClickShowRecommendations,
    },
    {
      key: "reportsDocument",
      text: t("ram.reportsDocument.label"),
      iconProps: { iconName: "ExcelDocument" },
      disabled: false,
      onClick: onClickReportDocument,
    },
    {
      key: "usersReport",
      text: t("ram.usersReport.label"),
      iconProps: { iconName: "ExcelDocument" },
      disabled: false,
      onClick: onClickUsersReportDocument,
    },
  ];

  var setRatingLimitsObj = {
    key: "setRatingLimits",
    text: t("ram.clientsList.contextMenu.setRatingLimits"),
    iconProps: { iconName: "GroupedList" },
    disabled: false,
    onClick: () => onClickSetRatingLimits(),
  };

  if (
    userRole === "admin" ||
    userRole === "internal" ||
    userRole === "internal_role"
  ) {
    items.push(setRatingLimitsObj);
  }

  if (userRole === "admin" || userRole === "internal_role") {
    items.unshift({
      key: "addLocation",
      text: t("ram.locations.contextMenu.addLocation"),
      iconProps: { iconName: "Add" },
      disabled: userRole !== "admin" && userRole !== "internal_role",
      onClick: onClickAddLocation,
    });
  }

  return (
    <ListContextMenuIconButton
      iconProps={{ iconName: "MoreVertical" }}
      menuIconProps={{ style: { display: "none" } }}
      menuProps={{
        items: items,
      }}
    />
  );
};
