import { Tooltip } from "@greco/components";
import { TooltipOverflowMode } from "@fluentui/react";
import React from "react";
import { CenteredCell } from "../../../components/styled";
import { store } from "../../../store/store";
import { formatNumber } from "../../../utils/number";
import { ContextButton } from "../ContextButton";
import { StatusCell } from "../StatusCell";
import CountryCode from "./CountryCode";
import FixedCell from "./FixedCell";
import SicCode from "./SicCode";

export const defaultColumns = [
  {
    key: "options",
    fieldName: "options",

    labelKey: "",
    name: "",
    minWidth: 20,
    maxWidth: 20,
    isVisible: true,
    isSortable: false,

    onRender: (row, index) => {
      if (!row) return null;
      return (
        <FixedCell no={index} row={row}>
          <CenteredCell>
            <ContextButton loc={row} />
          </CenteredCell>
        </FixedCell>
      );
    },
  },
  {
    key: "name",
    fieldName: "name",
    labelKey: "ram.navBar.defaultWeightings",
    name: "ram.navBar.defaultWeightings",
    maxWidth: 5550,
    minWidth: 1050,
    isVisible: true,
    isSortable: false,
    iconName: "Sort",
    isResizable: true,

    onRender: (row, index) => {
      if (!row) return null;
      return (
        <Tooltip
          content={row?.name}
          overflowMode={TooltipOverflowMode.Parent}
          styles={{
            root: {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          }}
        >
          <FixedCell row={row} no={index}>
            <span style={{ marginLeft: "10px" }}>{row?.name}</span>
          </FixedCell>
        </Tooltip>
      );
    },
  },
];
