import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
  CopyRecommendationParams,
  PasteRecommendationParams,
  DeleteRecommendationParams,
  ExportRecommendationToZipParams,
} from "../../api/api";
import { defaultCols } from "../../pages/recommendations/cols";
import { ExpandedColumn } from "../../types/columns";
import {
  Recommendation,
  RecommendationAzure,
  RecommendationAzureResponse,
  Report,
  Setting,
} from "../../types/types";
import { LoadStatus } from "../store";
import {
  createAsyncActions,
  createAsyncRoutine,
  handleAxiosError,
} from "../util";

export type RecommendationsPageState = {
  shownIds: RecommendationAzure["recommendationId"][];
  search: string;
  sort: any;
  exportToZipLoadStatus: LoadStatus;
  exportToZipLink: any | null;
  exportToZipDialog: {
    locationId: number | null;
    recommendationId: number | null;
    isOpen: boolean;
  };
  isNotifyLoading: boolean;
  recIdNotified: RecommendationAzure["recommendationId"] | null;
  columns: any[];
  selectedViewId: Setting["userAppSettingId"] | null;
  exportPDFStatus: LoadStatus;
  loadRecommendationsExcelStatus: LoadStatus;
  deleteRecommendationLoadStatus: LoadStatus;
  deleteRecommendationDialog: {
    recommendation: RecommendationAzure | null;
    isOpen: boolean;
  };
  copyRecommendationLoadStatus: LoadStatus;
  copyRecommendationDialog: {
    recommendation: RecommendationAzure | null;
    isOpen: boolean;
  };
  pasteRecommendationLoadStatus: LoadStatus;
  pasteRecommendationDialog: {
    recommendation: RecommendationAzure | null;
    report: Report | null;
    isOpen: boolean;
  };
  clipboardRecommendation: RecommendationAzure | null;
};

export const notifyRecommendation = createAsyncActions<
  RecommendationAzure,
  RecommendationAzure,
  Recommendation
>("recommendationsPage/notifyRecommendation");

export const exportPDFRecommendationsActions = createAsyncActions<
  void,
  void,
  void,
  any
>("recommendationsPage/exportPDFRecommendations");

export const loadRecommendationsExcelActions = createAsyncRoutine<
  any,
  void,
  void,
  void
>("fleetPage/loadRecommendationsExcel");

export const deleteRecommendationRoutine = createAsyncRoutine<
  DeleteRecommendationParams,
  void,
  any,
  any
>("recommendations/delete");

export const copyRecommendationRoutine = createAsyncRoutine<
  CopyRecommendationParams,
  void,
  any,
  any
>("recommendations/copy");

export const pasteRecommendationRoutine = createAsyncRoutine<
  PasteRecommendationParams,
  void,
  void,
  any
>("recommendations/paste");

const initialState: RecommendationsPageState = {
  shownIds: [],
  search: "",
  sort: {
    key: "recommendationCreatedAt",
    dir: "desc",
  },
  exportToZipLoadStatus: "none",
  exportToZipLink: null,
  exportToZipDialog: {
    locationId: null,
    recommendationId: null,
    isOpen: false,
  },
  isNotifyLoading: false,
  recIdNotified: null,
  columns: defaultCols,
  selectedViewId: null,
  exportPDFStatus: "none",
  loadRecommendationsExcelStatus: "none",
  deleteRecommendationLoadStatus: "none",
  deleteRecommendationDialog: {
    recommendation: null,
    isOpen: false,
  },
  copyRecommendationLoadStatus: "none",
  copyRecommendationDialog: {
    recommendation: null,
    isOpen: false,
  },
  pasteRecommendationLoadStatus: "none",
  pasteRecommendationDialog: {
    report: null,
    recommendation: null,
    isOpen: false,
  },
  clipboardRecommendation: null,
};

export const recommendationsPageSlice = createSlice({
  name: "recommendationsPage",
  initialState,
  reducers: {
    setSearch: (s, a: PayloadAction<RecommendationsPageState["search"]>) => {
      s.search = a.payload;
    },
    setSort: (s, a: PayloadAction<RecommendationsPageState["sort"]>) => {
      s.sort = a.payload;
    },
    setColumns: (s, a: PayloadAction<any[]>) => {
      s.columns = a.payload;
    },
    updateColumnWidth: (
      s,
      a: PayloadAction<{ key: ExpandedColumn["key"]; width: number }>
    ) => {
      const { key, width } = a.payload;
      const index = s.columns.findIndex((c) => c.key === key);
      s.columns[index].width = width;
    },
    setSelectedViewId: (
      s,
      a: PayloadAction<RecommendationsPageState["selectedViewId"]>
    ) => {
      s.selectedViewId = a.payload;
    },
    setDeleteDialog: (
      s,
      a: PayloadAction<RecommendationsPageState["deleteRecommendationDialog"]>
    ) => {
      s.deleteRecommendationDialog = a.payload;
    },
    setCopyDialog: (
      s,
      a: PayloadAction<RecommendationsPageState["copyRecommendationDialog"]>
    ) => {
      s.copyRecommendationDialog = a.payload;
    },
    setPasteDialog: (
      s,
      a: PayloadAction<RecommendationsPageState["pasteRecommendationDialog"]>
    ) => {
      s.pasteRecommendationDialog = a.payload;
    },

    reset: (s) => {
      Object.assign(s, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(notifyRecommendation.loading, (s, a) => {
      s.isNotifyLoading = true;
      s.recIdNotified = a.payload.recommendationId;
    });
    builder.addCase(notifyRecommendation.success, (s, a) => {
      s.isNotifyLoading = false;
      s.recIdNotified = null;
    });
    builder.addCase(notifyRecommendation.error, (s, a) => {
      s.isNotifyLoading = false;
      s.recIdNotified = null;
    });
    builder.addCase(exportPDFRecommendationsActions.loading, (s, a) => {
      s.exportPDFStatus = "loading";
    });
    builder.addCase(exportPDFRecommendationsActions.success, (s, a) => {
      s.exportPDFStatus = "success";
    });
    builder.addCase(exportPDFRecommendationsActions.error, (s, a) => {
      s.exportPDFStatus = "error";
    });
    builder.addCase(loadRecommendationsExcelActions.loading, (s, a) => {
      s.loadRecommendationsExcelStatus = "loading";
    });
    builder.addCase(loadRecommendationsExcelActions.success, (s, a) => {
      s.loadRecommendationsExcelStatus = "success";
    });
    builder.addCase(loadRecommendationsExcelActions.error, (s, a) => {
      s.loadRecommendationsExcelStatus = "error";
    });
    builder.addCase(deleteRecommendationRoutine.loading, (s, a) => {
      s.deleteRecommendationLoadStatus = "loading";
    });
    builder.addCase(deleteRecommendationRoutine.success, (s, a) => {
      s.deleteRecommendationLoadStatus = "success";
    });
    builder.addCase(deleteRecommendationRoutine.error, (s, a) => {
      s.deleteRecommendationLoadStatus = "error";
    });
    builder.addCase(copyRecommendationRoutine.loading, (s, a) => {
      s.copyRecommendationLoadStatus = "loading";
    });
    builder.addCase(copyRecommendationRoutine.success, (s, a) => {
      s.copyRecommendationLoadStatus = "success";
      s.clipboardRecommendation = a.payload;
    });
    builder.addCase(copyRecommendationRoutine.error, (s, a) => {
      s.copyRecommendationLoadStatus = "error";
    });
    builder.addCase(pasteRecommendationRoutine.loading, (s, a) => {
      s.pasteRecommendationLoadStatus = "loading";
    });
    builder.addCase(pasteRecommendationRoutine.success, (s, a) => {
      s.pasteRecommendationLoadStatus = "success";
    });
    builder.addCase(pasteRecommendationRoutine.error, (s, a) => {
      s.pasteRecommendationLoadStatus = "error";
    });
  },
});

export const recommendationsPageReducer = recommendationsPageSlice.reducer;

export const {
  setSearch: setSearchRecommendations,
  setSort: setSortRecommendations,
  setColumns: setColumnsRecommendations,
  updateColumnWidth: updateColumnWidthRecommendations,
  setSelectedViewId: setSelectedViewIdRecommendations,
  reset,
  setDeleteDialog,
  setCopyDialog,
  setPasteDialog,
} = recommendationsPageSlice.actions;
