import { mergeStyleSets } from "@fluentui/react";
import { useFormikContext } from "formik";
import React from "react";
import ComboBoxField from "../../../../components/controls/ComboBoxField";
import DatePickerField from "../../../../components/controls/DatePickerField";
import useGetTaxonomyOptions from "../../../../utils/hooks/useGetTaxonomyOptions";
import AttachmentsFormField from "../../components/AttachmentsFormFields/AttachmentsFormField";
import { EditorField } from "../../components/EditorField";

function EvaluationFields() {
  const { values, errors, setFieldValue } = useFormikContext<any>();

  const statusGrECoCodeOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskImplementationStatus",
  });
  const priorityCodeOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskPriority",
    include: [
      "RiskPriority.MINOR",
      "RiskPriority.MODERATE",
      "RiskPriority.MAJOR",
    ],
  });

  return (
    <div className={classes.container}>
      <div className={classes.grid}>
        {/* <DatePickerField
          disabled={false}
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values?.dateOfEvaluation}
          name={"dateOfEvaluation"}
          label={"ram.responsesPage.dateOfResponse.label"}
          placeholder={"ram.selectDate"}
          required={true}
          confidentiality={null}
        /> */}
        <DatePickerField
          disabled={false}
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values?.implementationDateInsurer}
          name={"implementationDateInsurer"}
          label={"ram.evaluationsPage.plannedimplementation.label"}
          placeholder={"ram.selectDate"}
          required={false}
          confidentiality={null}
        />
        <ComboBoxField
          disabled={false}
          errors={errors}
          name="statusGrECoCode"
          label={"ram.evaluationsPage.statusGrecoCode.label"}
          setFieldValue={setFieldValue}
          options={statusGrECoCodeOptions || []}
          defaultValue={values?.statusGrECoCode}
          confidentiality={null}
          required
        />
        <ComboBoxField
          disabled={false}
          errors={errors}
          name="priorityCode"
          label={"ram.evaluationsPage.priorityCode.label"}
          setFieldValue={setFieldValue}
          options={priorityCodeOptions || []}
          defaultValue={values?.priorityCode}
          confidentiality={null}
          required={false}
        />
      </div>
      <div className={classes.fullWidth}>
        <AttachmentsFormField isDisabled={false} />
      </div>
      <div className={classes.fullWidth}>
        <EditorField
          name="comment"
          isRequired
          label="ram.evaluationsPage.comment.label"
        />
      </div>
    </div>
  );
}

export default EvaluationFields;

const classes = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingBottom: 100,
  },
  grid: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
    overfow: "auto",
  },
  fullWidth: {
    width: "100%",
  },
});
