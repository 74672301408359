import { call, fork, put, takeEvery } from "redux-saga/effects";
// import { setDefaultWeightingsFilterIds } from "../filters/filters";
import * as API from "../../api/api";
import { setHasRights } from "../auth";
import { loadDefaultWeightingsActions } from "./defaultWeightings";

export function* loadDefaultWeightingsSaga() {
  yield takeEvery(loadDefaultWeightingsActions.trigger, function* () {
    try {
      yield put(loadDefaultWeightingsActions.loading());
      const res = yield call(API.getDefaultWeightings);
      yield put(loadDefaultWeightingsActions.success(res.data));
    } catch (err) {
      yield put(loadDefaultWeightingsActions.error(err));
      if (err.response.status === 403) {
        yield put(setHasRights(false));
      }
    }
  });
}

export function* loadDefaultWeightingsSuccessSaga() {
  yield takeEvery(loadDefaultWeightingsActions.success, function* () {
    // yield put(applyFiltersDefaultWeightings() as any);
    // yield put(setDefaultWeightingsFilterIds() as any);
  });
}

export function* defaultWeightingsSaga() {
  yield fork(loadDefaultWeightingsSaga);
  yield fork(loadDefaultWeightingsSuccessSaga);
}
