import { Dialog, DialogFooter } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  reportDocumentRoutine,
  setReportDocumentDialog,
  setUsersReportDocumentDialog,
  usersReportDocumentRoutine,
} from "../../store/clients-page/clientsPage";
import { StyledPrimaryButton } from "../../components/styled";
import { FormSpinner } from "../../components/FormSpinner";
import { useSelector } from "../../store/hooks";

export const UsersReportDocumentDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoading = useSelector(
    (s) => s.clientsPage.usersReportDocumentLoadStatus === "loading"
  );
  const usersReportDocumentDialog = useSelector(
    (s) => s.clientsPage.usersReportDocumentDialog
  );
  const usersReportDocument = useSelector(
    (s) => s.clientsPage.usersReportDocument
  );
  const close = () => {
    dispatch(
      setUsersReportDocumentDialog({
        isOpen: false,
        clientId: null,
      })
    );
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        showCloseButton: true,
        title: t("ram.usersReportsDocument.label"),
      }}
      onDismiss={close}
    >
      {t("ram.usersReportDocumentDialogText.label")}
      <DialogFooter>
        {usersReportDocument !== null ? (
          <StyledPrimaryButton
            iconProps={{ iconName: "Download" }}
            text={t("ram.usersReportDocumentDialogDownload.label")}
            onClick={() => {
              window.open(usersReportDocument.url, "_blank");
              close();
            }}
          />
        ) : (
          <StyledPrimaryButton
            iconProps={{ iconName: "TextDocument" }}
            text={t("ram.reportDocumentDialogGenerate.label")}
            onClick={() => {
              dispatch(
                usersReportDocumentRoutine.trigger({
                  clientId: usersReportDocumentDialog.clientId,
                })
              );
            }}
            disabled={isLoading}
          />
        )}
      </DialogFooter>
      {isLoading && <FormSpinner />}
    </Dialog>
  );
};
