import { Checkbox, IconButton, Persona, PersonaSize } from "@fluentui/react";
import React from "react";
import styled, { useTheme } from "styled-components";
import { RiskUser } from "../../../../types/types";
import { StyledRow } from "./Table";
import { IStyledTheme } from "../../../../theme/types";

type Props = {
  user: RiskUser;
  removeUser: (userId: RiskUser["userId"]) => void;
  isDisabled?: boolean;
};

export const Row = ({ user, removeUser, isDisabled = false }: Props) => {
  let isInternal = false;
  let isNewUser = "isInternal" in user;
  const theme = useTheme() as IStyledTheme;

  if (isNewUser) {
    isInternal = user.isInternal;
  }
  //
  else {
    isInternal = /((@greco.services)|(@vmg.at)|(@eghv.at))$/.test(
      (user as RiskUser).userEmailAddress
    );
  }

  return (
    <StyledRow>
      <Cell
        style={{
          width: 40,
        }}
      >
        <Persona
          text={user.userName}
          hidePersonaDetails
          size={PersonaSize.size32}
        />
      </Cell>
      <Cell
        style={{
          flex: 1,
          minWidth: 0,
          maxWidth: 400,
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <Truncate>{user.userName}</Truncate>
          <Truncate
            style={{
              color: theme.palette.neutralSecondaryAlt,
              fontSize: "12px",
            }}
          >
            {user.userEmailAddress}
          </Truncate>
        </div>
      </Cell>
      <Cell
        className="center"
        style={{
          width: 70,
        }}
      >
        <Checkbox defaultChecked={isInternal} disabled />
      </Cell>
      <Cell
        className="center"
        style={{
          width: 50,
          marginLeft: "auto",
        }}
      >
        {!isDisabled ? (
          <IconButton
            iconProps={{ iconName: "Cancel" }}
            onClick={() => removeUser(user.userId)}
          />
        ) : null}
      </Cell>
    </StyledRow>
  );
};

export const Cell = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  &.center {
    justify-content: center;
  }
`;

export const Truncate = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderCell = styled(Cell)`
  font-weight: 600;
`;
