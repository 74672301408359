import { Icon } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { NoData } from "../../../components/NoData";
import { riskImplementationStatusColors } from "../../../config/colors";
import { riskImplmentationStatusIcons } from "../../../config/icons";
import { useSelector } from "../../../store/hooks";
import { Label, Row } from "./RecommendationPanel";

export const StatusGrECoCode = () => {
  const { t } = useTranslation();
  const loadRecommendationStatus = useSelector(
    (s) => s.recommendationPanel.loadRecommendationStatus
  );
  const recommendation = useSelector(
    (s) => s.recommendationPanel.recommendation
  );
  const taxonomy = useSelector((s) => s.taxonomy);
  const isLoading = loadRecommendationStatus === "loading";

  const statusGrECoCode =
    taxonomy.RiskImplementationStatus.byId[recommendation?.statusGrECoCode]
      ?.code;

  let render;
  if (isLoading) render = <Skeleton width={100} />;
  else if (!recommendation || !recommendation.statusGrECoCode)
    render = <NoData />;
  else
    render = render = (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icon
          iconName={riskImplmentationStatusIcons[statusGrECoCode]}
          style={{
            color: riskImplementationStatusColors[statusGrECoCode],
            fontSize: "18px",
            marginRight: "3px",
          }}
          data-for="tooltip"
          data-tip={t(statusGrECoCode)}
        />
        {t(statusGrECoCode)}
      </div>
    );

  return (
    <Row>
      <Label>{t("ram.recommendationForm.statusGrECoCode.label")}:</Label>
      {render}
    </Row>
  );
};
