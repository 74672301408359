import { useSelector } from "../../../store/hooks";

function useIsDisabledRecommendationForm() {
  // const recommendation = useSelector((s) => s.recommendationPage.rec);

  // const userRole = useSelector((s) => s.userRole.userRole);
  // const isWaitingForGreg = recommendation?.recommendationStatusCode === 1;
  // const isWaitingForClient = recommendation?.recommendationStatusCode === 2;
  // const isClosed = recommendation?.recommendationStatusCode === 3;
  const isDisabled = false;
  // ((isWaitingForGreg || isClosed) &&
  //   (userRole === "insurer" || userRole === "internal_limited")) ||
  // isWaitingForClient;

  return isDisabled;
}

export default useIsDisabledRecommendationForm;
