import { createSlice } from "@reduxjs/toolkit";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../api/api";
import {
  Recommendation,
  RecommendationAttachment,
  RecommendationWithAttachments,
} from "../types/types";
import { LoadStatus } from "./store";
import { createAsyncActions } from "./util";

type RecommendationPanelState = {
  loadRecommendationStatus: LoadStatus;
  recommendation: Recommendation | null;
  attachments: RecommendationAttachment[];
};

export const loadRecommendationActions = createAsyncActions<
  {
    locationId: any;
    reportId: any;
    recommendationId: any;
  },
  void,
  RecommendationWithAttachments,
  any
>("recommendationPanel/loadRecommendation");

function* loadRecommendationSaga() {
  yield takeEvery(loadRecommendationActions.trigger, function* (
    a: ReturnType<typeof loadRecommendationActions.trigger>
  ) {
    try {
      yield put(loadRecommendationActions.loading());
      const res = yield call(API.getRecommendationWithAttachments, a.payload);
      yield put(loadRecommendationActions.success(res.data));
    } catch (err) {
      yield put(loadRecommendationActions.error(err));
    }
  });
}

export function* recommendationPanelSaga() {
  yield fork(loadRecommendationSaga);
}

export const recommendationPanelSlice = createSlice({
  name: "recommendationPanel",
  initialState: {
    loadRecommendationStatus: "none",
    recommendation: null,
  } as RecommendationPanelState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadRecommendationActions.loading, (s, a) => {
      s.loadRecommendationStatus = "loading";
    });
    builder.addCase(loadRecommendationActions.success, (s, a) => {
      s.loadRecommendationStatus = "success";
      s.recommendation = a.payload.recommendation;
      s.attachments = a.payload.recommendationAttachments;
    });
    builder.addCase(loadRecommendationActions.error, (s, a) => {
      s.loadRecommendationStatus = "error";
    });
  },
});

export const { reducer: recommendationPanelReducer } = recommendationPanelSlice;
