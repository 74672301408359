import { Icon, Label } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Flex } from "../../../../../components/styled";
import { riskImplementationStatusColors } from "../../../../../config/colors";
import { riskImplmentationStatusIcons } from "../../../../../config/icons";
import { useSelector } from "../../../../../store/hooks";

export const StatusGrecoCode = ({ statusGrECo }: { statusGrECo: number }) => {
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();
  const statusGrECoCode =
    taxonomy.RiskImplementationStatus.byId[statusGrECo]?.code;

  return (
    <div>
      <Label>{t("ram.evaluationsPage.statusGrecoCode.label")}</Label>
      <Flex>
        <Icon
          iconName={riskImplmentationStatusIcons[statusGrECoCode]}
          style={{ color: riskImplementationStatusColors[statusGrECoCode] }}
          styles={{
            root: {
              fontSize: "18px",
              marginRight: "3px",
            },
          }}
          data-for="tooltip"
          data-tip={t(statusGrECoCode)}
        />
        <Text>{t(statusGrECoCode)}</Text>
      </Flex>
    </div>
  );
};

const Text = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;
