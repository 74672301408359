import { Spinner } from "@fluentui/react";
import React from "react";

import { RiskUser } from "../../../../types/types";
import { Header } from "./Header";
import { Row } from "./Row";
import styled from "styled-components";

type Props = {
  areRiskUsersLoading: boolean;
  value: RiskUser[];
  onChange: (value: RiskUser[]) => void;
  isDisabled?: boolean;
};

export const Table = ({
  areRiskUsersLoading,
  value,
  onChange,
  isDisabled = false,
}: Props) => {
  if (areRiskUsersLoading) {
    return <StyledSpinner />;
  }

  if (!value || value.length === 0) {
    return null;
  }

  const removeUser = (userId: RiskUser["userId"]) => {
    onChange(value.filter((u) => u.userId !== userId));
  };

  return (
    <Root>
      <Header />
      {value?.map((u: RiskUser) => {
        return (
          <Row
            key={u.userId}
            user={u}
            removeUser={removeUser}
            isDisabled={isDisabled}
          />
        );
      })}
    </Root>
  );
};

const Root = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  background-color: ${(p) => p.theme.palette.white};
`;

const StyledSpinner = styled(Spinner)`
  margin-top: 20px;
  .ms-Spinner-circle {
    width: 30px;
    height: 30px;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  background-color: ${(p) => p.theme.palette.white};

  &:nth-child(even) {
    background: ${(p) => p.theme.palette.themeLighterAlt};
  }

  &:nth-child(odd) {
    background-color: ${(p) => p.theme.palette.white};
  }

  &:last-child {
    border: 0;
  }
`;

export const Cell = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  &.center {
    justify-content: center;
  }
`;

export const HeaderCell = styled(Cell)`
  font-weight: 600;
`;
