import { ToastOptions } from "react-toastify";
import { errorHandler } from "utils/errorHandler";

export const createToastFn =
  (type: ToastOptions["type"]) => (message: string, options?: ToastOptions) => {
    return errorHandler({
      value: message,
      type: type,
      label: message,
      fieldName: null,
      errors: null,
    });
  };

export const toast = {
  success: createToastFn("success"),
  error: createToastFn("error"),
  info: createToastFn("info"),
};

// import clsx from "clsx";
// import { IconButton, MessageBar, MessageBarType } from "@fluentui/react";
// import React from "react";
// import {
//   Slide,
//   toast as _toast,
//   ToastContentProps,
//   ToastOptions,
// } from "react-toastify";
// import styled from "styled-components";

// type Props = ToastContentProps & ToastOptions & { message: string };

// const colors = {
//   success: {
//     background: "#dff6dd",
//     progress: "#107c10",
//     icon: "#107c10",
//   },
//   error: {
//     background: "#FDE7E9",
//     progress: "#a80000",
//     icon: "#a80000",
//   },
// };

// const typeMap: { [key: string]: MessageBarType } = {
//   success: MessageBarType.success,
//   error: MessageBarType.error,
// };

// export const FluentToast = ({ closeToast, type, message }: Props) => {
//   return (
//     <Root>
//       <StyledMessageBar

//         messageBarType={typeMap[type || "success"]}
//         className={clsx({
//           success: type === "success",
//           error: type === "error",
//         })}
//       >
//         <Flex>
//           <Message>{message}</Message>
//           <CloseButton
//             iconProps={{ iconName: "Cancel" }}
//             onClick={closeToast}
//           />
//         </Flex>
//       </StyledMessageBar>
//     </Root>
//   );
// };

// export const createToastFn =
//   (type: ToastOptions["type"]) => (message: string, options?: ToastOptions) =>
//     _toast(
//       (toastProps) => (
//         <FluentToast
//           {...options}
//           {...toastProps}
//           message={message}
//           type={type}
//         />
//       ),
//       {
//         ...options,
//         transition: Slide,
//         type,
//       }
//     );

// export const toast = {
//   success: createToastFn("success"),
//   error: createToastFn("error"),
// };

// const StyledMessageBar = styled(MessageBar)`
//   height: 100%;
//   .ms-MessageBar-icon i {
//     margin: 0;
//   }
//   .ms-MessageBar-content {
//     display: flex;
//     align-items: center;
//   }
//   .ms-MessageBar-text {
//     margin-right: 0;
//   }
//   .ms-MessageBar-innerText {
//     width: 100%;
//   }
//   &.success {
//     background: ${() => colors.success.background};
//     .ms-MessageBar-icon i {
//       color: ${() => colors.success.icon};
//     }
//   }
//   &.error {
//     background: ${() => colors.error.background};
//     .ms-MessageBar-icon i {
//       color: ${() => colors.error.icon};
//     }
//   }
// `;

// const Root = styled.div`
//   width: 100%;
//   height: 100%;
// `;

// const Flex = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const CloseButton = styled(IconButton)`
//   margin-left: auto;
//   background-color: transparent;
//   &:hover {
//     background-color: transparent;
//   }
//   i {
//     color: #323130;
//     font-size: 12px;
//   }
// `;

// const Message = styled.div`
//   color: #323130;
//   flex: 1;
// `;
