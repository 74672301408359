import React from "react";
import { useDispatch } from "react-redux";
import { ClearFilters } from "../../../components/filters/ClearFilters";
import { ClientsFilter } from "../../../components/filters/ClientsFilter";
import { Filters } from "../../../components/filters/Filters";
import { NavButtons } from "../../../components/navigation/NavButtons";
import { NavigationBar, Right } from "../../../components/styled";
import { setClientsFilter } from "../../../store/filters/filters";
import { useSelector } from "../../../store/hooks";
import { Search } from "./Search";
import { useTranslation } from "react-i18next";
import { setManageReportsDialog } from "../../../store/usersinsurer";
import { setManageLocationsDialog } from "../../../store/users";
import { SearchResultsCount } from "@greco/components";
import { AddDefaultWeightingButton } from "./AddDefaultWeightingButton";

export const Navigation = () => {
  const count = useSelector((s) => s.locationsPage.shownIds.length);
  const dispatch = useDispatch();
  const clientsFilter = useSelector((s) => s.filters.clientsFilter);
  const userRole = useSelector((s) => s.userRole.userRole);
  const { t } = useTranslation();

  return (
    <NavigationBar>
      <NavButtons />

      {(userRole === "admin" || userRole === "internal_role") && (
        <AddDefaultWeightingButton />
      )}
      {/* <Right>
        <SearchResultsCount count={count} label={t("greco.searchResults")} />
      </Right> */}
    </NavigationBar>
  );
};
