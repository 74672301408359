import {
  createSelector,
  createSlice,
  Dictionary,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ChangeActiveStatusParams, DeleteReportParams } from "../../api/api";
import { Report } from "../../types/types";
import { selectEntitiesLocations } from "../locations/locations";
import { selectAllReports } from "../reports/reports";
import { AppDispatch, RootState, AppThunk, LoadStatus } from "../store";
import {
  sort,
  handleAxiosError,
  createAsyncRoutine,
  createAsyncActions,
} from "../util";
import i18next from "i18next";
import { formatDateString } from "../../utils/utils";

export type ReportsPageState = {
  isChangeActiveStatusLoading: boolean;
  reportIdStatusChanged: number | null;
  shownIds: Report["reportId"][];
  showActiveReportsOnly: boolean;
  search: string;
  sort: any;
  deleteReportLoadStatus: LoadStatus;
  deleteReportDialog: {
    report: Report | null;
    isOpen: boolean;
  };
};

export const applyFiltersReports = (): AppThunk => (dispatch, getState) => {
  const sortState = getState().reportsPage.sort;
  const showActiveReportsOnly = getState().reportsPage.showActiveReportsOnly;

  const searchReports = getState().reportsPage.search.trim();
  const clients = getState().clients;
  const locations = getState().locations;
  const reports = selectAllReports(getState());
  const clientsFilter = getState().filters.clientsFilter;
  const locationsFilter = getState().filters.locationsFilter;
  const taxonomy = getState().taxonomy;

  let filteredReports = reports;

  filteredReports = reports.filter((r) => {
    let isSearch = true;
    if (searchReports !== "") {
      let reportNameFilter =
        r.reportName.toLowerCase().indexOf(searchReports.toLowerCase()) !== -1;
      const clientId = locations.entities[r.locationId]?.webBasePartnerNumber;
      let cn = clientId != null ? clients.entities[clientId]?.clientName : null;
      let companyNameFilter =
        cn != null
          ? cn.toLowerCase().indexOf(searchReports.toLowerCase()) !== -1
          : false;
      const ln = locations.entities[r.locationId]?.locationName;
      let locationNameFilter =
        ln != null
          ? ln.toLowerCase().indexOf(searchReports.toLowerCase()) !== -1
          : false;
      let rc = taxonomy.RiskReportCreator.byId[r.reportCreatorCode]?.code;
      let rct = rc != null ? i18next.t(rc) : "";
      let reportCreatorFilter =
        rct.toLowerCase().indexOf(searchReports.toLowerCase()) !== -1;
      let surveyDateFilter =
        formatDateString(r.surveyDate).indexOf(searchReports.toLowerCase()) !==
        -1;
      isSearch =
        reportNameFilter === true ||
        companyNameFilter === true ||
        locationNameFilter === true ||
        reportCreatorFilter === true ||
        surveyDateFilter === true;
    }
    let isFilterClients = true;
    let isFilterLocations = true;

    if (clientsFilter.length > 0) {
      isFilterClients = clientsFilter.includes(r.webBasePartnerNumber);
    }
    if (locationsFilter.length > 0) {
      isFilterLocations = locationsFilter.includes(r.locationId);
    }
    let activityFilter = true;
    if (showActiveReportsOnly && r.isReportDeactivated) activityFilter = false;
    const isFilters = isFilterClients && isFilterLocations && activityFilter;

    return isSearch && isFilters;
  });

  const shownIds = filteredReports
    .slice(0)
    .sort(sort(sortState))
    .map((r) => r.reportId);

  dispatch(setShownIds(shownIds));
};

export const changeActiveStatusActions = createAsyncActions<
  ChangeActiveStatusParams,
  any,
  any,
  any
>("reportsPage/changeActiveStatus");

export const deleteReportRoutine = createAsyncRoutine<
  DeleteReportParams,
  void,
  any,
  any
>("reports/delete");

export const reportsPageSlice = createSlice({
  name: "reportsPage",
  initialState: {
    shownIds: [],
    search: "",
    sort: {
      key: "reportCreatedAt",
      dir: "desc",
    },
    showActiveReportsOnly: false,
    deleteReportLoadStatus: "none",
    deleteReportDialog: {
      report: null,
      isOpen: false,
    },
  } as ReportsPageState,
  reducers: {
    setShownIds: (s, a: PayloadAction<ReportsPageState["shownIds"]>) => {
      s.shownIds = a.payload;
    },
    setSearch: (s, a: PayloadAction<ReportsPageState["search"]>) => {
      s.search = a.payload;
    },
    setSort: (s, a: PayloadAction<ReportsPageState["sort"]>) => {
      s.sort = a.payload;
    },
    setShowActiveReportsOnly: (
      s,
      a: PayloadAction<ReportsPageState["showActiveReportsOnly"]>
    ) => {
      s.showActiveReportsOnly = a.payload;
    },
    setDeleteDialog: (
      s,
      a: PayloadAction<ReportsPageState["deleteReportDialog"]>
    ) => {
      s.deleteReportDialog = a.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteReportRoutine.loading, (s, a) => {
      s.deleteReportLoadStatus = "loading";
    });
    builder.addCase(deleteReportRoutine.success, (s, a) => {
      s.deleteReportLoadStatus = "success";
    });
    builder.addCase(deleteReportRoutine.error, (s, a) => {
      s.deleteReportLoadStatus = "error";
    });
    builder.addCase(changeActiveStatusActions.loading, (s, a) => {
      s.isChangeActiveStatusLoading = true;
      s.reportIdStatusChanged = a.payload.reportId;
    });
    builder.addCase(changeActiveStatusActions.success, (s, a) => {
      s.isChangeActiveStatusLoading = false;
      s.reportIdStatusChanged = null;
    });
    builder.addCase(changeActiveStatusActions.error, (s, a) => {
      s.isChangeActiveStatusLoading = false;
      s.reportIdStatusChanged = null;
    });
  },
});

export const reportsPageReducer = reportsPageSlice.reducer;

export const {
  setShownIds,
  setSearch: setSearchReports,
  setSort: setSortReports,
  setDeleteDialog,
  setShowActiveReportsOnly,
} = reportsPageSlice.actions;

export const selectShownReports = createSelector(
  (s) => s.reportsPage.shownIds,
  (s) => s.reports.entities,
  (ids: any, byId) => ids.map((id) => byId[id]!)
);
