import { createSelector } from "@reduxjs/toolkit";
import { selectEntitiesClients } from "../clients/clients";
import { selectEntitiesLocations } from "../locations/locations";
import { selectEntitiesReports } from "../reports/reports";
import { RootState } from "../store";

export const selectClientsFilterClients = createSelector(
  (s) => s.filters.clientsFilterIds,
  (s) => selectEntitiesClients(s),
  (ids: any, byId) => {
    return ids.map((id) => byId[id]);
  }
);

export const selectLocationsFilterLocations = createSelector(
  (s) => s.filters.locationsFilterIds,
  (s) => selectEntitiesLocations(s),
  (ids: any, byId) => {
    return ids.map((id) => byId[id]);
  }
);

export const selectReportsFilterReports = createSelector(
  (s) => s.filters.reportsFilterIds,
  (s) => selectEntitiesReports(s),
  (ids: any, byId) => {
    return ids.map((id) => byId[id]);
  }
);

export const selectReportsFilter = (s: RootState) => s.filters.reportsFilter;

export const selectIsFiltered = createSelector(
  (s) => s.filters,
  (filters: any) => {
    return [
      filters.clientsFilter,
      filters.locationsFilter,
      filters.reportsFilter,
    ].some((f) => f.length > 0);
  }
);
