import styled from "styled-components";

import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../../store/hooks";
import { getRecommendationStatusCircle } from "../../../../config/colors";
import { HSpace } from "../../../../components/styled";
import { TooltipComponent } from "components/TooltipComponent";

export const RecommendationStatus = () => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const recommendation = useSelector((s) => s.recommendationPage.rec);
  const locations = useSelector((s) => s.locations.entities);
  const statusCode =
    taxonomy.RiskRecommendationStatus.byId[
      recommendation?.recommendationStatusCode
    ]?.code;
  const isRecLoading = useSelector((s) => s.recommendationPage.isRecLoading);
  const [isLocationsLoading, setIsLocationsLoading] = useState(true);

  const [assessmentPeriodStartedAt, setAssessmentPeriodStartedAt] =
    useState(null);

  useEffect(() => {
    if (recommendation?.locationId && locations[recommendation?.locationId]) {
      setAssessmentPeriodStartedAt(
        locations[recommendation?.locationId].assessmentPeriodStartedAt
      );
      setIsLocationsLoading(false);
    }
  }, [recommendation, locations]);

  return (
    <Root>
      {!isRecLoading && !isLocationsLoading ? (
        <>
          <Icon
            iconName="CircleFill"
            style={
              recommendation?.isAssessed &&
              statusCode === "RiskRecommendationStatus.WAITING_FOR_GREG"
                ? {
                    color: getRecommendationStatusCircle(
                      recommendation,
                      recommendation?.recommendationStatusCode,
                      assessmentPeriodStartedAt
                    ),
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "16px",
                    height: "16px",
                    border: "1px solid transparent",
                    borderColor: "#323130",
                    borderRadius: "50%",
                    fontSize: "8px",
                    marginRight: "7px",
                  }
                : {
                    color: getRecommendationStatusCircle(
                      recommendation,
                      recommendation?.recommendationStatusCode,
                      assessmentPeriodStartedAt
                    ),
                    fontSize: "8px",
                    marginRight: "7px",
                  }
            }
          />
          <Text>{t(statusCode)}</Text>
          <HSpace width={10} />
          <TooltipComponent content={recommendation?.description}>
            <StyledIcon iconName="Info" />
          </TooltipComponent>
        </>
      ) : (
        <Spinner size={SpinnerSize.large} />
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  height: 47px;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
  padding-left: 20px;
`;

const Text = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

const StyledIcon = styled(Icon)`
  color: ${(p) => p.theme.palette.themePrimary};
`;
